import { Editor } from '@tinymce/tinymce-react';
import { createAndOpenNewTab } from '../../EditorDocum/entities/utils';

export const reviewfile = (editor: Editor['editor']) => () => {
  if (!editor) return null;
  const types = ['docx', 'doc', 'xlsx', 'xls', 'pdf', 'pptx'];

  const node = editor.$('a[data-mce-selected="inline-boundary"]')[0];
  if (!node || node.nodeName.toLowerCase() !== 'a') return null;

  const url = editor.$(node).attr('href');
  if (!url || !url.replace(window.location.href, '')) return null;

  const type = types.find((tp) => tp.includes(url.substring(url.lastIndexOf('.') + 1)));
  if (type === 'pdf') {
    createAndOpenNewTab(url);
  } else if (!!['docx', 'doc', 'xlsx', 'xls', 'pptx'].find((it) => it === type)) {
    createAndOpenNewTab(`https://view.officeapps.live.com/op/embed.aspx?src=${url}`);
  } else {
    const iframe = "<iframe width='100%' height='100%' src='" + url + "'></iframe>";
    const x = window.open();
    x?.document.write(iframe);
    x?.document.close();
  }
};
