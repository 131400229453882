import React from 'react';
import { MainMonthBlockForm } from '../../../../components/MainInMonth/MainMonthBlockForm';

export default function Form({ type }: { type: 'new' | 'edit' }) {
  return (
    <MainMonthBlockForm
      type={type}
      stepBack={3}
      name={`${type === 'new' ? 'Створити' : 'Редагувати'} блок шаблону Інтерактив`}
      fields={{
        title: { require: false },
        link: { require: true },
        width: { require: false },
        height: { require: false },
      }}
    />
  );
}
