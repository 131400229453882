import { Actions, TDefRequest } from '../Actions';
import { TypeUpdatePositionsR } from '../rubrics/actions';
import ActionTypes, { ICalendar, IEvent } from './types';

const GetCalendarAction = new Actions('GET_CALENDAR', ActionTypes);

export interface TypeGetCalendarR extends TDefRequest {
  data: {
    site_id: number;
    day?: string;
    date?: Date | null;
  };
}

export const GetCalendar = {
  request: (payload: TypeGetCalendarR) => GetCalendarAction.request(payload),
  success: (payload: ICalendar[]) => GetCalendarAction.success(payload),
  error: (payload: string) => GetCalendarAction.error(payload),
};

const SearchCalendarAction = new Actions('SEARCH_CALENDAR', ActionTypes);

export interface TypeSearchCalendarR extends TDefRequest {
  site_id: number;
  day: string;
  date: Date;
}

export const SearchCalendar = {
  request: (payload: TypeSearchCalendarR) => SearchCalendarAction.request(payload),
  success: (payload: ICalendar[]) => SearchCalendarAction.success(payload),
  error: (payload: string) => SearchCalendarAction.error(payload),
};

const UpdateCalendarPositionsAction = new Actions('UPDATE_CALENDAR_POSITIONS', ActionTypes);

export const UpdateCalendarPositions = {
  request: (payload: TypeUpdatePositionsR) => UpdateCalendarPositionsAction.request(payload),
  success: (payload: ICalendar[]) => UpdateCalendarPositionsAction.success(payload || {}),
  error: (message: string) => UpdateCalendarPositionsAction.error(message),
};

const UpdateEventPositionsAction = new Actions('UPDATE_EVENT_POSITIONS', ActionTypes);

export const UpdateEventPositions = {
  request: (payload: TypeUpdatePositionsR) => UpdateEventPositionsAction.request(payload),
  success: (payload: ICalendar[]) => UpdateEventPositionsAction.success(payload || {}),
  error: (message: string) => UpdateEventPositionsAction.error(message),
};

const CreateEventAction = new Actions('CREATE_EVENT', ActionTypes);

export interface TypeCreateEventR extends TDefRequest {
  data: {
    title: string;
    link?: string;
    description?: string;
    is_public: 0 | 1;
    is_main: 0 | 1;
    event_date: Date | null;
    site_id: number;
    calendar_id?: number;
  };
}
export const CreateEvent = {
  request: (payload: TypeCreateEventR) => CreateEventAction.request(payload),
  success: (payload: ICalendar[]) => CreateEventAction.success(payload || {}),
  error: (message: string) => CreateEventAction.error(message),
};

const DeleteEventAction = new Actions('DELETE_EVENT', ActionTypes);

export interface TypeDeleteEventR extends TDefRequest {
  id: number;
  data: {
    calendar_id: number;
    site_id: number;
    ids: number[];
  };
}

export const DeleteEvent = {
  request: (payload: TypeDeleteEventR) => DeleteEventAction.request(payload),
  success: (payload: ICalendar[]) => DeleteEventAction.success(payload),
  error: (payload: string) => DeleteEventAction.error(payload),
};

export interface TypeUpdateEventR extends TDefRequest {
  data: {
    title: string;
    link: string;
    description: string;
    is_public: 0 | 1;
    is_main: 0 | 1;
    event_date: Date;
    site_id: number;
    calendar_id: number;
  };
  id: number;
}

const UpdateEventAction = new Actions('UPDATE_EVENT', ActionTypes);

export const UpdateEvent = {
  request: (payload: TypeUpdateEventR) => UpdateEventAction.request(payload),
  success: (payload: { calendars: ICalendar[]; current: IEvent }) => UpdateEventAction.success(payload),
  error: (payload: string) => UpdateEventAction.error(payload),
};

export interface TypeGetEventR extends TDefRequest {
  id: number;
}

const GetEventAction = new Actions('GET_EVENT', ActionTypes);

export const GetEventById = {
  request: (payload: TypeGetEventR) => GetEventAction.request(payload),
  success: (payload: IEvent) => GetEventAction.success(payload),
  error: (payload: string) => GetEventAction.error(payload),
};

const CloneMonthEventsAction = new Actions('CLONE_MONTH_EVENTS', ActionTypes);
export interface TypeCloneMonthEventsR extends TDefRequest {
  data: {
    from: Date | null;
    to: Date | null;
    site_id: number;
  };
}
export const CloneMonthEvents = {
  request: (payload: TypeCloneMonthEventsR) => CloneMonthEventsAction.request(payload),
  success: (payload: ICalendar[]) => CloneMonthEventsAction.success(payload),
  error: (payload: string) => CloneMonthEventsAction.error(payload),
};
export interface TypeGetAmountEventsInMonthR extends TDefRequest {
  data: {
    year: number;
    month: number;
    site_id: number;
  };
}
const GetAmountEventsInMonthAction = new Actions('GET_AMOUNT_EVENTS_IN_MONTH', ActionTypes);

export const GetAmountEventsInMonth = {
  request: (payload: TypeGetAmountEventsInMonthR) => GetAmountEventsInMonthAction.request(payload),
  success: (payload: number) => GetAmountEventsInMonthAction.success(payload),
  error: (payload: string) => GetAmountEventsInMonthAction.error(payload),
};
