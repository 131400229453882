import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import { AppStore } from '../../store/applicationState';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { format } from 'date-fns';
import { ROUTES } from '../../utils/routes';
import { StylesTable } from '../../components/Stylestable';
import { BasicDatePicker, SelectOutline } from '../../components/ui';
import { StylesRatings } from '../../components/Ratings/StylesMark';
import { Pagination } from '@mui/material';
import { useSearchAny } from '../../hooks/useSearchAny';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GetProductRatings, SearchProductRatings } from '../../store/productRatings/actions';
import Select from 'react-select';
import { Roles } from '../../utils/consts';
import { URI } from '../../config';

interface Column {
  id: 'date' | 'answer' | 'version' | 'comment' | 'mark' | 'review' | 'user_id';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'date', label: 'Дата', align: 'center', width: '12%' },
  {
    id: 'mark',
    label: 'Оцінка',
    align: 'center',
  },
  {
    id: 'user_id',
    label: 'User Id',
    align: 'center',
  },
  { id: 'comment', label: 'Відгук', align: 'center', width: '20%' },
  { id: 'version', label: 'Версія продукту', align: 'center', width: '20%' },
  {
    id: 'review',
    label: 'Переглянути',
    align: 'center',
    width: '10%',
  },
];
const options: { label: string; value: string }[] = [
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  // ... добавьте другие варианты по вашему выбору
];
type PropsType = {};

export const RatingsProductList: React.FC<PropsType> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const stylesRatings: any = StylesRatings();
  const classesTable = StylesTable();
  const [page, setPage] = useState(1);
  const { Configurations, ProductRatings, User } = useSelector((store: AppStore) => store);
  const [date_at, setDateAt] = useState<Date | null | string>(
    String(searchParams.get('f_date_at'))?.replace('null', '').replace('undefined', '')
  );
  const [date_to, setDateTo] = useState<Date | null | string>(
    String(searchParams.get('f_date_to'))?.replace('null', '').replace('undefined', '')
  );
  const [rate, setRate] = useState<{ label: string; value: string }[] | null>(
    searchParams.get('f_rate') && JSON.parse(searchParams.get('f_rate') || '')
  );
  const [version, setVersion] = useState(searchParams.get('version') || '');
  const [ratings, setRatings] = useState(ProductRatings.productRatings);
  const [totalPages, setTotalPages] = useState(ProductRatings.data?.pages || 1);

  const { loading, handleSearch } = useSearchAny({
    SearchData: SearchProductRatings,
  });

  const resetState = () => {
    setRate([]);
    setVersion('');
    setDateAt('');
    setDateTo('');
    dispatch(
      GetProductRatings.request({
        data: {
          siteId: Configurations.siteId,
          page: '1',
        },
      })
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (ProductRatings?.productRatings) {
      setRatings(ProductRatings?.productRatings || ProductRatings.data?.productRatings);
      if (ProductRatings.data) setTotalPages(ProductRatings?.pages);
    }
  }, [ProductRatings]);

  useEffect(() => {
    dispatch(
      GetProductRatings.request({
        data: {
          siteId: Configurations.siteId,
          page: String(page),
          rate: JSON.stringify(rate?.map((el) => el.value)) || undefined,
          date_at: String(date_at).replace('undefinde', '').replace('null', '') || '',
          date_to: String(date_to).replace('undefinde', '').replace('null', '') || '',
          version,
        },
        callBack: (s, res) => {
          if (res?.productRatings) {
            setRatings(res.productRatings);
            setTotalPages(res.pages);
          }
        },
      })
    );
    //eslint-disable-next-line
  }, [dispatch, Configurations, page]);

  const handleChangeRoute =
    (querry = '' as string | number) =>
    () => {
      navigate(ROUTES.routeChange(`product-list/${querry}&site_id=${Configurations.siteId}`));
    };

  return (
    <>
      <Box className={stylesRatings.header}>
        <div
          style={{ display: [Roles.HEAD, Roles.ADMIN].includes(User.data?.role || 0) ? 'flex' : 'none' }}
          className={stylesRatings.download}
          dangerouslySetInnerHTML={{
            __html: `<a 
            style="color: white; text-decoration:none;" 
            href="${URI}/api/product-ratings/downloadReport?siteId=${Configurations.siteId}
              &page=${page}
              &rate=${encodeURIComponent(JSON.stringify(rate?.map((el) => el.label)))}
              &version=${version}
              &date_at=${date_at || ''}
              &date_to=${date_to || ''}
              &user_id=${User.data?.id}"
            target="_blank" rel="noopener noreferrer"> Скачати
            
            </a>`,
          }}
        />

        <BasicDatePicker
          style={{ width: '150px' }}
          id='publicationAt'
          required
          label='Дата з'
          value={date_at ? new Date(String(date_at)) : null}
          error={false}
          onChange={(value) => {
            const data: any = {};
            if (page) data['page'] = 1;
            if (date_at) data['date_to'] = date_to;
            if (version) data['version'] = version;
            if (rate) data['rate'] = JSON.stringify(rate.map((el) => el.value));
            data['siteId'] = Configurations.siteId;

            handleSearch(String(value).replace('null', ''), 'date_at', data);
            setDateAt(String(value));
            setPage(1);
          }}
        />
        <BasicDatePicker
          style={{ width: '150px' }}
          id='publicationTo'
          required
          label={'Дата по'}
          value={date_to ? new Date(String(date_to)) : null}
          error={false}
          onChange={(value) => {
            const data: any = {};
            if (page) data['page'] = 1;
            if (date_at) data['date_at'] = date_at;
            if (rate) data['rate'] = JSON.stringify(rate.map((el) => el.value));
            if (version) data['version'] = version;
            data['siteId'] = Configurations.siteId;

            handleSearch(String(value).replace('null', ''), 'date_to', data);
            setDateTo(String(value));
            setPage(1);
          }}
        />
        <div style={{ zIndex: 10000 }}>
          <Select
            isMulti
            placeholder='Оцінки'
            name='ratings'
            options={options}
            value={rate}
            className='basic-multi-select'
            classNamePrefix='select'
            onChange={(value) => {
              const data: any = {};
              if (page) data['page'] = 1;
              if (date_at) data['date_at'] = date_at;
              if (date_to) data['date_to'] = date_to;
              if (version) data['version'] = version;
              data['siteId'] = Configurations.siteId;
              setRate(value.map((el) => ({ value: el.value, label: el.label })));
              handleSearch(JSON.stringify(value.map((el) => el.value)), 'rate', data);
              setPage(1);
            }}
          />
        </div>

        <SelectOutline
          required
          id='outlined-basic-status'
          title='Версія продукту'
          value={version || ''}
          error={false}
          className={stylesRatings.rateSelect}
          options={['VIP', 'Standart', 'Plus'].map((el, i) => ({ value: el, label: el, disabled: false })) as any[]}
          handleChange={(value) => {
            const data: any = {};
            if (page) data['page'] = 1;
            if (date_at) data['date_at'] = date_at;
            if (date_to) data['date_to'] = date_to;
            if (rate) data['rate'] = JSON.stringify(rate.map((el) => el.value));
            data['siteId'] = Configurations.siteId;
            setVersion(value);
            handleSearch(String(value), 'version', data);
            setPage(1);
          }}
        />

        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            resetState();
          }}
        >
          {loading ? <CircularProgress color='inherit' size={25} /> : 'Очистити фільтри'}
        </Button>
        <div className={stylesRatings.total}>Усього: {ProductRatings?.total || 0}</div>
        {ProductRatings.loading || loading ? <CircularProgress size={25} /> : null}
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!ProductRatings?.loading && ratings?.length ? (
              ratings?.map((row, i) => {
                return (
                  <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row?.id}`}>
                    <TableCell align='center'>
                      {format(new Date(String(row?.created_at)), 'dd.MM.yyyy HH.mm')}
                    </TableCell>
                    <TableCell style={{ fontWeight: '500' }} align='center' className={classesTable.row}>
                      {row?.rate}
                    </TableCell>
                    <TableCell style={{ fontWeight: '500' }} align='center' className={classesTable.row}>
                      {row.user_id}
                    </TableCell>
                    <TableCell style={{ fontWeight: '500' }} className={classesTable.row}>
                      {row?.comment}
                    </TableCell>
                    <TableCell align='center' className={classesTable.row}>
                      {row?.version === 0 ? 'Standart' : row?.version === 2 ? 'Plus' : row?.version === 1 ? 'VIP' : ''}
                    </TableCell>
                    <TableCell align='center' className={classesTable.row}>
                      <div
                        onClick={handleChangeRoute(
                          `${row?.id}?f_rate=${JSON.stringify(
                            rate
                          )}&f_date_at=${date_at}&f_date_to=${date_to}&version=${version}`
                        )}
                      >
                        <RemoveRedEyeOutlinedIcon color='inherit' className={stylesRatings.iconDetail} />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : ProductRatings.loading ? (
              <Box width={'1000px'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
                <CircularProgress color='secondary' />
              </Box>
            ) : (
              []
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination className={classesTable.pagination} count={totalPages} page={page} onChange={handleChangePage} />
    </>
  );
};
