import { IAuthor } from '../authors/types';
import { IDocumentVisitor } from '../consultations/types';
import { IFormFile } from '../formFiles/types';
import { RubricType } from '../rubrics/types';

export interface TFormsState {
  data: { forms: IForms[]; total: number; pages: number } | null;
  forms: IForms[] | null;
  total: number;
  pages: number;
  visitors: IDocumentVisitor[] | null;
  current: IForms | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IForms {
  id: number;
  site_id: number;
  rubric_id: number;
  author_id: number;
  unpublished_at: Date;
  title: string;
  title_short: string;
  body: string;
  description: string;
  img: string;
  template: string;
  sample: string;
  is_popular: number;
  is_open_publish: boolean;
  open_publication_at: Date;
  is_month: number;
  is_public: number;
  is_parse_link: number;
  is_in_cart: number;
  is_sitemap: number;
  is_parse_link_law: number;
  search_tags: string;
  adate: Date;
  update: Date;
  publication_at: Date;
  files: IFormFile[];
  rubric: RubricType;
  created_at: Date;
  updated_at: Date;
  url: string;
  author?: IAuthor;
  form_rubric_details: {
    rubric_id: number;
    form_id: number;
    rubric: RubricType;
  }[];
}

enum ActionTypes {
  SET_FORMS = '@@forms/SET_FORMS',
  RESET_FORMS = '@@forms/RESET_FORMS',

  GET_FORMS_R = '@@forms/GET_FORMS_R',
  GET_FORMS_S = '@@forms/GET_FORMS_S',
  GET_FORMS_E = '@@forms/GET_FORMS_E',

  GET_FORM_R = '@@forms/GET_FORM_R',
  GET_FORM_S = '@@forms/GET_FORM_S',
  GET_FORM_E = '@@forms/GET_FORM_E',

  SEARCH_FORMS_R = '@@forms/SEARCH_FORMS_R',
  SEARCH_FORMS_S = '@@forms/SEARCH_FORMS_S',
  SEARCH_FORMS_E = '@@forms/SEARCH_FORMS_E',

  CREATE_FORMS_R = '@@forms/CREATE_FORMS_R',
  CREATE_FORMS_S = '@@forms/CREATE_FORMS_S',
  CREATE_FORMS_E = '@@forms/CREATE_FORMS_E',

  UPDATE_FORMS_R = '@@forms/UPDATE_FORMS_R',
  UPDATE_FORMS_S = '@@forms/UPDATE_FORMS_S',
  UPDATE_FORMS_E = '@@forms/UPDATE_FORMS_E',

  DELETE_FORMS_R = '@@forms/DELETE_FORMS_R',
  DELETE_FORMS_S = '@@forms/DELETE_FORMS_S',
  DELETE_FORMS_E = '@@forms/DELETE_FORMS_E',

  CHECK_FORMS_VISITOR_R = '@@forms/CHECK_FORMS_VISITOR_R',
  CHECK_FORMS_VISITOR_S = '@@forms/CHECK_FORMS_VISITOR_S',
  CHECK_FORMS_VISITOR_E = '@@forms/CHECK_FORMS_VISITOR_E',

  DELETE_FORMS_VISITOR_R = '@@forms/DELETE_FORMS_VISITOR_R',
  DELETE_FORMS_VISITOR_S = '@@forms/DELETE_FORMS_VISITOR_S',
  DELETE_FORMS_VISITOR_E = '@@forms/DELETE_FORMS_VISITOR_E',
}

export default ActionTypes;
