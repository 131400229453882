import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  CreateUser,
  DeleteUser,
  GetUser,
  GetUsers,
  TypeCreateUserR,
  TypeDeleteUserR,
  TypeGetUserR,
  TypeGetUsersR,
  TypeUpdatePasswordR,
  TypeUpdateUserR,
  UpdatePassword,
  UpdateUser,
} from './actions';
import ActionTypes, { TUsersState } from './types';

function* getUsersWorker(action: ReturnType<typeof GetUsers.request>): Generator {
  const { data, callBack } = action.payload as TypeGetUsersR;

  let success = true;
  const query = buildQuery(data);
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/users?${query}`,
    })) as TUsersState['data'];
    yield put(GetUsers.success(resp));
  } catch (e) {
    success = false;
    yield put(GetUsers.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* getUserWorker(action: ReturnType<typeof GetUser.request>): Generator {
  const { id, callBack } = action.payload as TypeGetUserR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/users/user/${id}`,
    })) as TUsersState['current'];
    yield put(GetUser.success(resp));
  } catch (e) {
    success = false;
    yield put(GetUser.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createUserWorker(action: ReturnType<typeof CreateUser.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateUserR;

  let success = true;
  let resp = null;

  try {
    resp = (yield call(callApi, {
      method: 'post',
      data: data,
      path: '/users/user',
    })) as TUsersState['current'];
    yield put(CreateUser.success(resp));
  } catch (e: any) {
    success = false;
    resp = e;
    yield put(CreateUser.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updateUserWorker(action: ReturnType<typeof UpdateUser.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateUserR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data: data,
      path: `/users/user/${id}`,
    })) as TUsersState['current'];
    yield put(UpdateUser.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(UpdateUser.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* deleteUserWorker(action: ReturnType<typeof DeleteUser.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteUserR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/users/user/${id}`,
    })) as TUsersState['current'];
    yield put(DeleteUser.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteUser.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updatePasswordWorker(action: ReturnType<typeof UpdatePassword.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdatePasswordR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      data: data,
      path: `/users/password/${id}`,
    })) as TUsersState['current'];
    yield put(UpdateUser.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(UpdateUser.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_USERS_R, getUsersWorker);
  yield takeEvery(ActionTypes.GET_USER_R, getUserWorker);
  yield takeEvery(ActionTypes.CREATE_USER_R, createUserWorker);
  yield takeEvery(ActionTypes.UPDATE_USER_R, updateUserWorker);
  yield takeEvery(ActionTypes.DELETE_USER_R, deleteUserWorker);
  yield takeEvery(ActionTypes.UPDATE_PASSWORD_R, updatePasswordWorker);
}

export default function* usersSaga() {
  yield all([fork(watchFetchRequest)]);
}
