import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDataOpt } from '../components/ui/SelectSearchReq';
import { AppStore } from '../store/applicationState';
import { SearchDefinitions } from '../store/definition/actions';
import { IDefinition } from '../store/definition/types';

export const useSearchDefinitions = () => {
  const [options, setOptions] = React.useState<IDataOpt[] | undefined>();
  const { Configurations } = useSelector((store: AppStore) => store);
  const { Definitions } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Definitions.data && Definitions.data.definitions) {
      const opt =  Definitions?.data.definitions?.map((it) => ({ id: it.id, label: it.title }));
      setOptions(opt);
    }
  }, [Definitions.data]);

  const handleSearchDefinitions = (value: string | IDataOpt | null, callBack?: (resData: IDataOpt[]) => void) => {
    if (value) {
      dispatch(
        SearchDefinitions.request({
          data: { title: (value as unknown as IDataOpt).title || String(value), siteId: Configurations.siteId },
          callBack: (succ, data) => {
            if (succ && Array.isArray(data.definitions) && callBack) {
              const opt = data.definitions.map((it: IDefinition) => ({ id: it.id, label: it.title }));
              callBack(opt);
            } else {
              callBack && callBack(options || []);
            }
          },
        })
      );
    } else {
      callBack && callBack(options || []);
    }
  };

  return {
    options,
    handleSearchDefinitions,
  };
};
