import React from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { IMonthMainBlock } from '../../store/mainInMonth/types';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { WarningModal } from '../modals';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import {
  DeleteMainInMonthBlock,
  GetMainInMonthTemplate,
  UpdateMainInMonthTemplate,
} from '../../store/mainInMonth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import moment from 'moment';

export interface ColumnChats {
  id: keyof IMonthMainBlock;
  label: string;
  width?: string;
  align?: 'right' | 'center' | 'left';
}

interface Props {
  columns: ColumnChats[];
}

export const MainMonthListBlocks: React.FC<Props> = ({ columns }) => {
  const [deleteModal, setDeleteModal] = React.useState<{ id: number; title: string } | null>(null);
  const [loading, setLoading] = React.useState(false);

  const { MainInMonth } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (id && !Number.isNaN(Number(id))) {
      setLoading(true);
      dispatch(
        GetMainInMonthTemplate.request({
          id: Number(id),
          callBack: () => {
            setLoading(false);
          },
        })
      );
    }
  }, [dispatch, id]);

  const handleChangeRoute = (path: string) => () => {
    if (path) {
      navigate(path);
    } else {
      navigate(ROUTES.stepBack(2));
    }
  };

  const handleDragEnd = (result: DropResult, event: any) => {
    if (!result.destination || !MainInMonth.template?.month_main_blocks || !id) return null;

    const value = [...MainInMonth.template.month_main_blocks];
    const item = value[result.source.index];
    value.splice(result.source.index, 1);
    value.splice(result.destination.index, 0, item);

    setLoading(true);
    dispatch(
      UpdateMainInMonthTemplate.request({
        id: Number(id),
        data: {
          template_detailes: value.map((item, index) => ({ ...item, position: index })),
        },
        callBack: () => {
          setLoading(false);
        },
      })
    );
  };

  const handleDelete = (isApprove: boolean) => {
    if (isApprove && deleteModal && !loading) {
      setLoading(true);
      dispatch(
        DeleteMainInMonthBlock.request({
          id: deleteModal.id,
          callBack: () => {
            setLoading(false);
          },
        })
      );
    }
    setDeleteModal(null);
  };

  const checkLimit = () => {
    if (!MainInMonth.template || !MainInMonth.template.month_main_blocks) return false;
    if (MainInMonth.template.month_main_blocks.length >= MainInMonth.template?.month_main_template.max_length) {
      return true;
    }
  };

  return (
    <Box>
      <Box>
        <Typography component='h1' variant='h5'>
          Шаблон: {MainInMonth.template?.month_main_template.title || ''}
        </Typography>
      </Box>
      <Box p={'10px 0px'} display={'flex'} alignItems={'center'} gap={2}>
        <Button disabled={checkLimit()} variant='contained' color='primary' onClick={handleChangeRoute('new')}>
          Створити
        </Button>
        <Button variant='contained' color='inherit' onClick={handleChangeRoute('')}>
          Повернутися
        </Button>
      </Box>
      <TableContainer sx={{ height: 'calc(100vh - 220px)', position: 'relative' }}>
        {loading && (
          <Box
            position={'absolute'}
            zIndex={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            width={'100%'}
            height={'100%'}
          >
            <CircularProgress color='secondary' />
          </Box>
        )}
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column.width }}>
                  {column.label}
                </TableCell>
              ))}
              <TableCell align='center'>Редагувати</TableCell>
              <TableCell align='center'>Видалити</TableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='documents-list'>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {MainInMonth.template?.month_main_blocks
                    ?.sort((a, b) => a.position - b.position)
                    .map((row, index) => (
                      <Draggable draggableId={String(row.id)} index={index} key={`${index}-${row.id}`}>
                        {(provided, snapshot) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              cursor: 'grab',
                              backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white',
                              ...provided.draggableProps.style,
                            }}
                            hover
                            key={`${index}-${row.id}`}
                          >
                            {columns.map((column) => {
                              if (!Object.hasOwn(row, column.id)) return null;
                              const value = row[column.id];
                              if (column.id === 'author') {
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {row.author?.fio || ''}
                                  </TableCell>
                                );
                              }
                              if (column.id === 'renewal_at') {
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {moment(row.renewal_at).format('DD.MM.YYYY HH:mm')}
                                  </TableCell>
                                );
                              }
                              if (column.id === 'image' || column.id === 'link') {
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {value ? <AddIcon fontSize='small' /> : <RemoveIcon fontSize='small' />}
                                  </TableCell>
                                );
                              }
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {String(value)}
                                </TableCell>
                              );
                            })}
                            <TableCell align='center'>
                              <IconButton onClick={handleChangeRoute(String(row.id))}>
                                <EditIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell align='center'>
                              <IconButton onClick={() => setDeleteModal({ id: row.id, title: row.title })}>
                                <DeleteIcon color='error' />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      {deleteModal ? (
        <WarningModal
          handleApprove={handleDelete}
          type='delete'
          value={` "${deleteModal.title}" `}
          open={!!deleteModal}
        />
      ) : null}
    </Box>
  );
};
