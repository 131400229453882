import React from 'react';
import clsx from 'clsx';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircularProgress from '@mui/material/CircularProgress';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { Styles } from './Styles';
import { debounce } from '../../utils/debounce';
import { TIMEOUT_REQ } from '../../utils/consts';

export interface IDataOpt {
  id: number;
  label: string;
  title?: string;
}

type PropsType = {
  loadingOuter?: boolean;
  title?: string;
  value?: string;
  data?: IDataOpt | null;
  required?: boolean;
  options?: IDataOpt[];
  error?: boolean;
  style?: React.CSSProperties | undefined;
  className?: string;
  onChange: (data: IDataOpt | null) => void;
  handleRequest?: (value: string | IDataOpt, callback?: (data: IDataOpt[]) => void) => void;
  handleOutside?: () => void;
};

export const SelectSearchReq: React.FC<PropsType> = React.memo(
  ({
    style,
    value,
    className,
    title,
    required,
    error,
    data,
    options,
    onChange,
    handleRequest,
    handleOutside,
    loadingOuter,
  }) => {
    const [search, setSearch] = React.useState(value);
    const [items, setItems] = React.useState<IDataOpt[] | undefined>([]);
    const [isFocus, setIsFocus] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const { ref } = useOutsideClick(() => {
      setIsFocus(false);
      setItems(options || []);
    });
    const classes = Styles();

    React.useEffect(() => {
      setSearch(value);
    }, [value]);

    // eslint-disable-next-line
    const debounceSave = React.useCallback(
      debounce((data) => {
        setLoading(false);
        if (handleRequest) {
          handleRequest(data, (resData: IDataOpt[]) => {
            setItems(resData);
          });
        }
      }, TIMEOUT_REQ),
      [handleRequest]
    );

    React.useEffect(() => {
      if (options) {
        setItems(options);
      }
    }, [options]);

    React.useEffect(() => {
      if ((!data?.label && !value) || (!data?.label && !value && !isFocus)) {
        setSearch('');
      }
      if (data?.label && !value) {
        setSearch(data.label);
      }
    }, [data?.label, value, isFocus]);

    React.useEffect(() => {
      if (handleOutside && !isFocus) {
        handleOutside();
      }
    }, [handleOutside, isFocus]);

    const handleLeaveFocus = () => {
      setIsFocus(!isFocus);
    };

    const setClass = () => {
      if (isFocus) return classes.placeholderFocus;
      if (!!search) return classes.placeholderInit;
    };

    const handleSearch = (search: string) => {
      setSearch(search);
      setLoading(true);
      debounceSave(search);
      if (!search) {
        onChange(null);
      }
    };

    const handleChoice = (option: IDataOpt) => {
      setSearch(option.label);
      onChange(option);
      setIsFocus(false);
    };

    return (
      <div style={style} className={clsx(classes.root, className)} ref={ref}>
        {title && (
          <div
            onClick={() => setIsFocus(true)}
            className={clsx(classes.placeholder, setClass())}
            style={{ color: error && !isFocus ? 'red' : '' }}
          >
            {required ? `${title} *` : title}
          </div>
        )}
        <div
          className={clsx(classes.wrappField, isFocus && classes.wrappFieldFocus)}
          style={{ borderColor: error && !isFocus ? 'red' : '' }}
          onClick={() => setIsFocus(true)}
        >
          <div className={classes.field}>
            {isFocus ? (
              <input
                autoFocus
                className={classes.input}
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
              />
            ) : (
              search
            )}
          </div>
          <div className={clsx(classes.icon, isFocus && classes.iconFocus)} onClick={handleLeaveFocus}>
            {loading || loadingOuter ? <CircularProgress size={15} /> : <ArrowDropDownIcon />}
          </div>
        </div>
        {isFocus ? (
          <div className={classes.select}>
            <MenuList>
              {items?.map((option) => (
                <MenuItem key={Math.random()} value={option.id} onClick={() => handleChoice(option)}>
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </div>
        ) : null}
      </div>
    );
  }
);
