import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './App.css';
import { EditDocument, Login } from './pages';
import { AppStore } from './store/applicationState';
import { ConfigurationsActions, GetConfigurations, GetSites } from './store/configurations/actions';
import { GetRubrics } from './store/rubrics/actions';
import { setAuth, GetUser } from './store/user/actions';
import { checkToken } from './utils/checkToken';
import { Box, CircularProgress, ThemeProvider, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import { Header } from './components/Header';
import { Sidebar } from './components/Sidebar';
import { ROUTES } from './utils/routes';
import { OfficialDoc } from './components/OfficialDoc/OfficialDoc';
import { ListMainBlocks } from './components/Main/ListMainBlocks';
import { Normativka } from './components/Normativka';
import { ListAuthors } from './components/Authors/ListAuthors';
import { ListBlocks } from './components/Blocks/ListBlocks';
import { Configurator } from './components/Configurator';
import { ListFactoids } from './components/Factoids/ListFactoids';
import { PublicationCart } from './components/PublicationCart/PublicationCart';
import { MainWorkspace } from './components/MainWorkspace';
import { CreateConsultations, EditConsultation, ListConsultations } from './pages/consultations';
import { CreateForm, EditForm, ListForms } from './pages/forms';
import { CreateVideoPage, EditVideo, ListVideo } from './pages/video';
import { RedirectLaw } from './pages/RedirectLaw';
import { CreateCatalogues, EditCatalog, ListCatalogues } from './pages/catalogues';
import { CataloguesSelections } from './components/Selections/ListSelections';
import { WidgetsWorkSpace } from './components/Widgets/WidgetsWorkSpace';
import { QuickAccess } from './pages/widgets/QuickAccess';
import { Universal } from './pages/widgets/Universal';
import { Widgets } from './pages/widgets/Widgets';
import { RatingsList } from './pages/Ratings/RatingsList';
import { RatingDetails } from './pages/Ratings/RatingDetails';
import { OpenPublicationsList } from './pages/publications';
import { Calendar } from './pages/calendar/Calendar';
import { CreateCalendar } from './pages/calendar/CreateCalendar';
import { EditCalendar } from './pages/calendar/EditCalendar';
import { CoppyEvent } from './pages/calendar/CoppyEvent';
import { EditMonthMain, MonthMain } from './pages/monthMain';
import {
  CalculatorsService,
  CreateService,
  EditService,
  ListServices,
  MainService,
  NavigatorsService,
} from './pages/services';
import { ServiceWorkspace } from './components/Services';
import { RatingWorkSpace } from './components/Ratings/RatingWorkSpace';
import { RatingsProductList } from './pages/Ratings/RatingProductList';
import { RatingProductDetail } from './pages/Ratings/RatingProductDetail';
import { ListDefinitions } from './pages/definition/ListDefinitions';
import { EditDefinition } from './pages/definition/EditDefinition';
import { CreateDefinitions } from './pages/definition/CreateDefinitions';
import { CreateUser, EditUser, ListUsers } from './pages/users';
import { ListOfficialDocDrafts, EditOfficialDocDraft } from './pages/officialDocDrafts';
import { RedactionsList } from './components/OfficialDoc/RedactionsList';
import { RedactionWorckSpace } from './components/OfficialDoc/RedactionWorckSpace';
import { PublishersList } from './components/OfficialDoc/Publishers/PublishersList';
import { TypeDocList } from './components/OfficialDoc/TypeDoc/TypeDocList';
import { Statistics } from './components/OfficialDoc/Statistics';
import { GetPublishers } from './store/publishers/actions';
import { GetDocs, GetTypesDoc } from './store/officialDoc/actions';
import { TemplateFormConsultation, TemplateListConsultation } from './pages/monthMain/templates/consultations';
import { TemplateFormCatalog, TemplateListCatalog } from './pages/monthMain/templates/catalogues';
import { TemplateFormForms, TemplateListForms } from './pages/monthMain/templates/forms';
import { TemplateFormNews, TemplateListNews } from './pages/monthMain/templates/news';
import { TemplateFormDecription, TemplateListDecription } from './pages/monthMain/templates/decription';
import { TemplateFormWebinar, TemplateListWebinar } from './pages/monthMain/templates/webinars';
import { TemplateFormAttraction, TemplateListAttraction } from './pages/monthMain/templates/attractions';
import { TemplateFormBanner, TemplateListBanner } from './pages/monthMain/templates/banner';
import { TemplateFormInteractive, TemplateListInteractive } from './pages/monthMain/templates/interactive';
import { TemplateFormTextField, TemplateListTextField } from './pages/monthMain/templates/textField';

const theme = createTheme({
  palette: {
    primary: { main: '#3f51b5' },
  },
});

function App() {
  const [isLoading, setIsLoading] = React.useState(true);
  const { User, Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (window.location.href.includes('document')) {
      dispatch(ConfigurationsActions.setSiteId(6));
    }
  }, [dispatch]);

  React.useEffect(() => {
    const callBack = (isOk: boolean) => {
      dispatch(setAuth(isOk));
      setIsLoading(false);
    };

    (async () => {
      setIsLoading(true);
      const tokenData = await checkToken();
      if (tokenData.success) {
        dispatch(GetUser.request({ id: tokenData.id! }));
        callBack(true);
      } else {
        setIsLoading(false);
      }
    })();
  }, [dispatch]);

  React.useEffect(() => {
    if (User.isAuth) {
      dispatch(GetSites.request({}));
    }
  }, [User.isAuth, dispatch]);

  React.useEffect(() => {
    if (Configurations.siteId !== 0 && Configurations.siteId < 6) {
      dispatch(GetConfigurations.request({ id: Configurations.siteId }));
      dispatch(GetRubrics.request({ id: Configurations.siteId }));
    }
    if (Configurations.siteId === 6) {
      dispatch(GetDocs.request({ data: {} }));
      dispatch(GetTypesDoc.request({}));
      dispatch(GetPublishers.request({ data: {} }));
    }
  }, [Configurations.siteId, dispatch]);

  if (isLoading)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );

  if (!User.isAuth) {
    return <Login />;
  }

  if (Configurations.siteId === 6) {
    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Box display={'flex'}>
            <CssBaseline />
            <Header open={false} />
            <Box component='main' sx={{ flexGrow: 1, p: 0, background: 'rgb(241, 243, 246)', minHeight: '100vh' }}>
              <Paper sx={{ m: '80px 16px 16px', p: 2 }} style={{ minHeight: 'calc(100vh - 96px)' }}>
                <Routes>
                  <Route index element={<OfficialDoc />} />
                  <Route path={`${ROUTES.document}/:id`} element={<EditDocument />} />
                  <Route path={ROUTES.documents}>
                    <Route index element={<OfficialDoc />} />
                    <Route path={ROUTES.publishers} element={<OfficialDoc element={<PublishersList />} />} />
                    <Route path={ROUTES.types} element={<OfficialDoc element={<TypeDocList />} />} />
                    <Route path={ROUTES.statistics} element={<OfficialDoc element={<Statistics />} />} />
                    <Route path={`:group_id`}>
                      <Route
                        index
                        element={<OfficialDoc element={<RedactionWorckSpace element={<RedactionsList />} />} />}
                      />
                      <Route path={ROUTES.drafts}>
                        <Route
                          index
                          element={
                            <OfficialDoc element={<RedactionWorckSpace element={<ListOfficialDocDrafts />} />} />
                          }
                        />
                        <Route path={`:id`} element={<EditOfficialDocDraft />} />
                      </Route>
                    </Route>
                  </Route>
                </Routes>
              </Paper>
            </Box>
          </Box>
        </ThemeProvider>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Box display={'flex'}>
          <CssBaseline />
          <Header />
          <Sidebar />
          <Box component='main' sx={{ flexGrow: 1, p: 0, background: 'rgb(241, 243, 246)', minHeight: '100vh' }}>
            <Paper sx={{ m: '80px 16px 16px', p: 2 }} style={{ minHeight: 'calc(100vh - 96px)' }}>
              <Routes>
                <Route path={ROUTES.main} element={<ListMainBlocks />} />
                <Route path={ROUTES.consultations}>
                  <Route index element={<MainWorkspace element={<ListConsultations />} />} />
                  <Route path='list' element={<MainWorkspace element={<ListConsultations />} />} />
                  <Route path='list/new' element={<MainWorkspace element={<CreateConsultations />} />} />
                  <Route path='list/:id' element={<MainWorkspace element={<EditConsultation />} />} />
                </Route>
                <Route path={ROUTES.monthMain}>
                  <Route index element={<MonthMain />} />
                  <Route path={'edit/:id'}>
                    <Route index element={<EditMonthMain />} />
                    <Route path='decription/:id' element={<TemplateListDecription />} />
                    <Route path='decription/:id/new' element={<TemplateFormDecription type='new' />} />
                    <Route path='decription/:id/:blockId' element={<TemplateFormDecription type='edit' />} />

                    <Route path='textField/:id' element={<TemplateListTextField />} />
                    <Route path='textField/:id/new' element={<TemplateFormTextField type='new' />} />
                    <Route path='textField/:id/:blockId' element={<TemplateFormTextField type='edit' />} />

                    <Route path='interactive/:id' element={<TemplateListInteractive />} />
                    <Route path='interactive/:id/new' element={<TemplateFormInteractive type='new' />} />
                    <Route path='interactive/:id/:blockId' element={<TemplateFormInteractive type='edit' />} />

                    <Route path='banner-1/:id' element={<TemplateListBanner />} />
                    <Route path='banner-1/:id/new' element={<TemplateFormBanner type='new' title='Банер 1' />} />
                    <Route path='banner-1/:id/:blockId' element={<TemplateFormBanner type='edit' title='Банер 1' />} />

                    <Route path='banner-2/:id' element={<TemplateListBanner />} />
                    <Route path='banner-2/:id/new' element={<TemplateFormBanner type='new' title='Банер 2' />} />
                    <Route path='banner-2/:id/:blockId' element={<TemplateFormBanner type='edit' title='Банер 2' />} />

                    <Route path='consultations/:id' element={<TemplateListConsultation />} />
                    <Route path='consultations/:id/new' element={<TemplateFormConsultation type='new' />} />
                    <Route path='consultations/:id/:blockId' element={<TemplateFormConsultation type='edit' />} />
                    <Route path='catalogues/:id' element={<TemplateListCatalog />} />
                    <Route path='catalogues/:id/new' element={<TemplateFormCatalog type='new' />} />
                    <Route path='catalogues/:id/:blockId' element={<TemplateFormCatalog type='edit' />} />
                    <Route path='forms/:id' element={<TemplateListForms />} />
                    <Route path='forms/:id/new' element={<TemplateFormForms type='new' />} />
                    <Route path='forms/:id/:blockId' element={<TemplateFormForms type='edit' />} />
                    <Route path='news/:id' element={<TemplateListNews />} />
                    <Route path='news/:id/new' element={<TemplateFormNews type='new' />} />
                    <Route path='news/:id/:blockId' element={<TemplateFormNews type='edit' />} />
                    <Route path='webinars/:id' element={<TemplateListWebinar />} />
                    <Route path='webinars/:id/new' element={<TemplateFormWebinar type='new' />} />
                    <Route path='webinars/:id/:blockId' element={<TemplateFormWebinar type='edit' />} />
                    <Route path='attractions/:id' element={<TemplateListAttraction />} />
                    <Route path='attractions/:id/new' element={<TemplateFormAttraction type='new' />} />
                    <Route path='attractions/:id/:blockId' element={<TemplateFormAttraction type='edit' />} />
                  </Route>
                </Route>
                <Route path={ROUTES.calendar}>
                  <Route index element={<Calendar />} />
                  <Route path='new' element={<CreateCalendar />} />
                  <Route path='edit/:id' element={<EditCalendar />} />
                  <Route path='coppy/:id' element={<CoppyEvent />} />
                </Route>
                <Route path={ROUTES.definition}>
                  <Route index element={<ListDefinitions />} />
                  <Route path='list' element={<ListDefinitions />} />
                  <Route path='list/new' element={<CreateDefinitions />} />
                  <Route path='list/:id' element={<EditDefinition />} />
                </Route>
                <Route path={ROUTES.catalogues}>
                  <Route index element={<MainWorkspace element={<ListCatalogues />} />} />
                  <Route path='list' element={<MainWorkspace element={<ListCatalogues />} />} />
                  <Route path='selection' element={<MainWorkspace element={<CataloguesSelections />} />} />
                  <Route path='list/new' element={<MainWorkspace element={<CreateCatalogues />} />} />
                  <Route path='list/:id' element={<MainWorkspace element={<EditCatalog />} />} />
                </Route>
                <Route path={ROUTES.forms}>
                  <Route index element={<MainWorkspace element={<ListForms />} />} />
                  <Route path='list' element={<MainWorkspace element={<ListForms />} />} />
                  <Route path='list/new' element={<MainWorkspace element={<CreateForm />} />} />
                  <Route path='list/:id' element={<MainWorkspace element={<EditForm />} />} />
                </Route>
                <Route path={ROUTES.video}>
                  <Route index element={<MainWorkspace element={<ListVideo />} />} />
                  <Route path='list' element={<MainWorkspace element={<ListVideo />} />} />
                  <Route path='list/new' element={<MainWorkspace element={<CreateVideoPage />} />} />
                  <Route path='list/:id' element={<MainWorkspace element={<EditVideo />} />} />
                </Route>
                <Route path={ROUTES.users}>
                  <Route index element={<ListUsers />} />
                  <Route path='list' element={<ListUsers />} />
                  <Route path='list/new' element={<CreateUser />} />
                  <Route path='list/:id' element={<EditUser />} />
                </Route>
                <Route path={ROUTES.services}>
                  <Route index element={<ServiceWorkspace element={<ListServices />} />} />
                  <Route path='list' element={<ServiceWorkspace element={<ListServices />} />} />
                  <Route path='list/new' element={<ServiceWorkspace element={<CreateService />} />} />
                  <Route path='list/:id' element={<ServiceWorkspace element={<EditService />} />} />
                  <Route path='main' element={<ServiceWorkspace element={<MainService />} />} />
                  <Route path='calculators' element={<ServiceWorkspace element={<CalculatorsService />} />} />
                  <Route path='navigators' element={<ServiceWorkspace element={<NavigatorsService />} />} />
                </Route>
                <Route path={ROUTES.widgets}>
                  <Route index element={<WidgetsWorkSpace element={<Widgets />} />} />
                  <Route path='free-acces' element={<WidgetsWorkSpace element={<QuickAccess />} />} />
                  <Route path='universal' element={<WidgetsWorkSpace element={<Universal />} />} />
                </Route>
                <Route path={ROUTES.ratings}>
                  <Route index element={<RatingWorkSpace element={<RatingsList />} />} />
                  <Route path='list' element={<RatingWorkSpace element={<RatingsList />} />} />
                  <Route path='product-list' element={<RatingWorkSpace element={<RatingsProductList />} />} />
                  <Route path='product-list/:id' element={<RatingWorkSpace element={<RatingProductDetail />} />} />
                  <Route path='list/:id' element={<RatingDetails />} />
                </Route>
                <Route path={ROUTES.npd} element={<Normativka />} />
                <Route path={ROUTES.authors} element={<ListAuthors />} />
                <Route path={ROUTES.blocks} element={<ListBlocks />} />
                <Route path={ROUTES.configurator} element={<Configurator />} />
                <Route path={ROUTES.publications} element={<OpenPublicationsList />} />
                <Route path={ROUTES.factoids} element={<ListFactoids />} />
                <Route path={ROUTES.archive} element={<PublicationCart />} />
                <Route path='/law/:id' element={<RedirectLaw />} />
                <Route path='/laws/:id' element={<RedirectLaw />} />
              </Routes>
            </Paper>
          </Box>
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
