import Modal from '@mui/material/Modal';
import React from 'react';
import Button from '@mui/material/Button';
import './BoxModal.scss';
import { useStyles } from './styles';
import { SelectSearchReq } from '../ui';
import { IDataOpt } from '../ui/SelectSearchReq';
import { useSearchOfficialDoc } from '../../hooks/useSearchOfficialDoc';

interface BoxModalProps {
  title: string;
  open: boolean;
  initValue?: string;
  docum?: { docId: number; name: string };
  handleClose: () => void;
  handleConfirm: ({ docId, name }: { docId: number; name: string }) => void;
}

export const ModalOfficialDoc: React.FC<BoxModalProps> = ({ title, open, docum, handleClose, handleConfirm }) => {
  const [doc, setDoc] = React.useState<IDataOpt | null>(docum?.docId ? { id: docum.docId, label: docum.name } : null);
  const { options, officialDoc, handleSearch } = useSearchOfficialDoc();

  const classes = useStyles();

  const handleApprove = () => {
    const item = officialDoc.find((it) => it.id === doc?.id);
    if (item) {
      handleConfirm({ docId: item.id, name: item.title });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <div className='boxModal-inner' style={{ width: '650px' }}>
        <div className='boxBody'>
          <h2>{title}</h2>
          <SelectSearchReq
            style={{ marginTop: '20px' }}
            title='ID Документа'
            value={doc ? String(doc.id) : ''}
            className={classes.textField}
            onChange={setDoc}
            options={options}
            handleRequest={handleSearch}
          />
          <SelectSearchReq
            style={{ marginTop: '20px' }}
            title='Назва Документа'
            data={doc}
            value={officialDoc.find((it) => it.id === doc?.id)?.title || ''}
            className={classes.textField}
            onChange={setDoc}
            options={options}
            handleRequest={handleSearch}
          />
        </div>
        <div className='boxFooter'>
          <div style={{ display: 'flex' }}>
            <Button color='primary' variant='contained' disabled={!doc} onClick={handleApprove}>
              підтвердити
            </Button>
          </div>
          <Button variant='contained' onClick={handleClose} style={{ minWidth: '118px', height: '36px' }}>
            скасувати
          </Button>
        </div>
      </div>
    </Modal>
  );
};
