import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AppStore } from '../../store/applicationState';
import { ConfigurationsActions } from '../../store/configurations/actions';
import { ROUTES } from '../../utils/routes';
import { useNavigate } from 'react-router-dom';
import { drawerWidth } from '../../config';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { MENU } from '../../utils/menu';

interface SidebarProps {
  open?: boolean;
}

export const Sidebar: React.FC<SidebarProps> = React.memo(({ open = true }) => {
  const { Configurations, User } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkSelected = (path = '') => {
    const pathname = `/${window.location.pathname.split('/')[1]}`;
    if (
      (path === '/' && window.location.pathname === '/') ||
      (path !== '/' && window.location.pathname !== '/' && pathname.includes(path))
    ) {
      return true;
    }
    return false;
  };

  if (!open) return null;

  return (
    <Drawer
      variant='permanent'
      sx={{
        width: drawerWidth,
        overflow: 'hidden',
        flexShrink: 0,
      }}
      open={open}
    >
      <Box width={drawerWidth} p={'10px 20px'}>
        <FormControl fullWidth>
          <Select
            variant='standard'
            placeholder='Зробіть вибір'
            value={Configurations.siteId ? String(Configurations.siteId) : ''}
            onChange={(event) => {
              const subvision = Number(event.target.value);
              dispatch(ConfigurationsActions.setSiteId(subvision));
              navigate(ROUTES.main);
            }}
          >
            <MenuItem value='' disabled>
              Зробіть вибір
            </MenuItem>
            {Configurations.sites?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <MenuList style={{ paddingTop: '0' }}>
        {MENU(Configurations.siteId).map((it, idx) => {
          if (it.roles && !it.roles.includes(User.data?.role || 0)) return null;
          if (it.path === ROUTES.services && !User.data?.is_service) return null;
          if (it.path === ROUTES.ratings && !User.data?.is_raiting) return null;

          return (
            <MenuItem
              selected={checkSelected(it.path)}
              key={idx}
              onClick={() => {
                navigate(it.path);
              }}
            >
              <ListItemIcon>{it.icon}</ListItemIcon>
              <Typography variant='inherit'>{it.name}</Typography>
            </MenuItem>
          );
        })}
      </MenuList>
    </Drawer>
  );
});
