import React from 'react';
import { MainMonthListBlocks } from '../../../../components/MainInMonth/ListMainMonthBlocks';

export default function List() {
  return (
    <MainMonthListBlocks
      columns={[
        {
          id: 'id',
          label: '№',
        },
        {
          id: 'publication_id',
          label: 'ID публікації',
        },
        {
          id: 'title',
          label: 'Заголовок',
        },
        {
          id: 'author',
          label: 'Автор',
        },
      ]}
    />
  );
}
