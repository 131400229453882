import { IAuthor } from '../authors/types';
import { RubricType } from '../rubrics/types';

export interface TConsultationsState {
  data: { consultations: IConsultation[]; total: number; pages: number } | null;
  consultations: IConsultation[] | null;
  visitors: IDocumentVisitor[] | null;
  current: IConsultation | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
  pages: number;
  total: number;
}

export interface IConsultation {
  id: number;
  site_id: number;
  rubric_id: number;
  author_id: number;
  title: string;
  description: string;
  body: string;
  is_public: number;
  is_parse_link: number;
  is_news: number;
  is_in_cart: number;
  is_open_publish: boolean;
  is_sitemap: number;
  open_publication_at: Date;
  search_tags: string;
  adate: Date;
  update: Date;
  publication_at: Date;
  expire_at: Date;
  created_at?: Date;
  unpublished_at?: Date;
  updated_at?: Date;
  rubric?: RubricType;
  author?: IAuthor;
  preview_hash?:string;
  consultation_rubric_details: {
    rubric_id: number;
    consultation_id: number;
    rubric: RubricType;
  }[];

  authors: IAuthor[];
}

export interface IDocumentVisitor {
  id: number;
  visit_time: Date;
  user: {
    fio: string;
    id: string;
  };
}

enum ActionTypes {
  SET_CONSULTATIONS = '@@consultations/SET_CONSULTATIONS',
  RESET_CONSULTATIONS = '@@consultations/RESET_CONSULTATIONS',

  GET_CONSULTATIONS_R = '@@consultations/GET_CONSULTATIONS_R',
  GET_CONSULTATIONS_S = '@@consultations/GET_CONSULTATIONS_S',
  GET_CONSULTATIONS_E = '@@consultations/GET_CONSULTATIONS_E',

  GET_CONSULTATION_R = '@@consultations/GET_CONSULTATION_R',
  GET_CONSULTATION_S = '@@consultations/GET_CONSULTATION_S',
  GET_CONSULTATION_E = '@@consultations/GET_CONSULTATION_E',

  SEARCH_CONSULTATIONS_R = '@@consultations/SEARCH_CONSULTATIONS_R',
  SEARCH_CONSULTATIONS_S = '@@consultations/SEARCH_CONSULTATIONS_S',
  SEARCH_CONSULTATIONS_E = '@@consultations/SEARCH_CONSULTATIONS_E',

  CREATE_CONSULTATION_R = '@@consultations/CREATE_CONSULTATION_R',
  CREATE_CONSULTATION_S = '@@consultations/CREATE_CONSULTATION_S',
  CREATE_CONSULTATION_E = '@@consultations/CREATE_CONSULTATION_E',

  UPDATE_CONSULTATION_R = '@@consultations/UPDATE_CONSULTATION_R',
  UPDATE_CONSULTATION_S = '@@consultations/UPDATE_CONSULTATION_S',
  UPDATE_CONSULTATION_E = '@@consultations/UPDATE_CONSULTATION_E',

  DELETE_CONSULTATION_R = '@@consultations/DELETE_CONSULTATION_R',
  DELETE_CONSULTATION_S = '@@consultations/DELETE_CONSULTATION_S',
  DELETE_CONSULTATION_E = '@@consultations/DELETE_CONSULTATION_E',

  CHECK_CONSULTATIONS_DOCUMENT_VISITOR_R = '@@consultations/CHECK_CONSULTATIONS_DOCUMENT_VISITOR_R',
  CHECK_CONSULTATIONS_DOCUMENT_VISITOR_S = '@@consultations/CHECK_CONSULTATIONS_DOCUMENT_VISITOR_S',
  CHECK_CONSULTATIONS_DOCUMENT_VISITOR_E = '@@consultations/CHECK_CONSULTATIONS_DOCUMENT_VISITOR_E',

  DELETE_CONSULTATIONS_DOCUMENT_VISITOR_R = '@@consultations/DELETE_CONSULTATIONS_DOCUMENT_VISITOR_R',
  DELETE_CONSULTATIONS_DOCUMENT_VISITOR_S = '@@consultations/DELETE_CONSULTATIONS_DOCUMENT_VISITOR_S',
  DELETE_CONSULTATIONS_DOCUMENT_VISITOR_E = '@@consultations/DELETE_CONSULTATIONS_DOCUMENT_VISITOR_E',
}

export default ActionTypes;
