import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
  },
  textField: {
    width: '100%',
  },
}));
