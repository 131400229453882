import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import { IPublisherDoc } from '../publishers/types';
import ActionTypes, { TOfficialDocState, IOfficialDoc, ITypeDoc, IOfficialDocUpdate } from './types';
import { IDocumentVisitor } from '../consultations/types';

export const setEditDoc = (payload: TOfficialDocState['editData']) => action(ActionTypes.SET_EDIT_DOC, payload);
export const setDoc = (payload: IOfficialDoc | null) => action(ActionTypes.SET_DOC, payload);
export const resetData = (payload: TOfficialDocState['data']) => action(ActionTypes.RESET_DATA, payload);
export const setSearchData = (payload: TOfficialDocState['searchData']) => action(ActionTypes.SET_SEARCH_DATA, payload);

const GetDocsAction = new Actions('GET_DOCS', ActionTypes);
export interface TypeGetDocsR extends TDefRequest {
  data: {
    page?: number;
    id?: number;
    docDate?: Date;
    typeId?: number;
    title?: string;
    docNum?: string;
    approved?: number;
    status?: number;
  };
}
export const GetDocs = {
  request: (payload: TypeGetDocsR) => GetDocsAction.request(payload),
  success: (payload: TOfficialDocState['data']) => GetDocsAction.success(payload || {}),
  error: (message: string) => GetDocsAction.error(message),
};

const GetDocsOfSelectAction = new Actions('GET_DOCS_OF_SELECT', ActionTypes);
export const GetDocsOfSelect = {
  request: (payload: TypeGetDocsR) => GetDocsOfSelectAction.request(payload),
  success: (payload: TOfficialDocState['selectList']) => GetDocsOfSelectAction.success(payload || {}),
  error: (message: string) => GetDocsOfSelectAction.error(message),
};

const GetDocsStatisticAction = new Actions('GET_DOCS_STATISTICS', ActionTypes);
export interface TypeGetDocsStatisticsR extends TDefRequest {
  data: {
    dateFrom?: Date | null;
    dateTo?: Date | null;
    editor?: string;
    type?: string;
  };
}
export const GetDocsStatistic = {
  request: (payload: TypeGetDocsStatisticsR) => GetDocsStatisticAction.request(payload),
  success: (payload: TOfficialDocState['statistics']) => GetDocsStatisticAction.success(payload || {}),
  error: (message: string) => GetDocsStatisticAction.error(message),
};

const SearchDocsAction = new Actions('SEARCH_DOCS', ActionTypes);
export interface TypeSearchDocsR extends TDefRequest {
  data: {
    page?: number;
    id?: number;
    docDate?: Date;
    typeId?: number;
    title?: string;
    docNum?: string;
  };
}
export const SearchDocs = {
  request: (payload: TypeSearchDocsR) => SearchDocsAction.request(payload),
  success: (payload: TOfficialDocState['data']) => SearchDocsAction.success(payload || {}),
  error: (message: string) => SearchDocsAction.error(message),
};

const GetDocAction = new Actions('GET_DOC', ActionTypes);
export interface TypeGetDocR extends TDefRequest {
  id: number;
}
export const GetDoc = {
  request: (payload: TypeGetDocR) => GetDocAction.request(payload),
  success: (payload: IOfficialDoc) => GetDocAction.success(payload || {}),
  error: (message: string) => GetDocAction.error(message),
};

const GetRedactionsAction = new Actions('GET_REDACTIONS', ActionTypes);
export interface TypeGetRedactionsR extends TDefRequest {
  groupId: number;
}
export const GetRedactions = {
  request: (payload: TypeGetRedactionsR) => GetRedactionsAction.request(payload),
  success: (payload: TOfficialDocState['data']) => GetRedactionsAction.success(payload || {}),
  error: (message: string) => GetRedactionsAction.error(message),
};

const GetPublishersAction = new Actions('GET_PUBLISHERS', ActionTypes);
export interface TypeGetPublishersR extends TDefRequest {
  data: {
    name: string;
  };
}
export const GetPublishers = {
  request: (payload: TypeGetPublishersR) => GetPublishersAction.request(payload),
  success: (payload: IPublisherDoc[]) => GetPublishersAction.success(payload || {}),
  error: (message: string) => GetPublishersAction.error(message),
};

const GetTypesDocAction = new Actions('GET_TYPE_DOC', ActionTypes);
export interface TypeGetTypesDocR extends TDefRequest {}
export const GetTypesDoc = {
  request: (payload: TypeGetTypesDocR) => GetTypesDocAction.request(payload),
  success: (payload: ITypeDoc[]) => GetTypesDocAction.success(payload || {}),
  error: (message: string) => GetTypesDocAction.error(message),
};

const CreateGroupAction = new Actions('CREATE_GROUP', ActionTypes);
export interface TypeCreateGroupR extends TDefRequest {}
export const CreateGroup = {
  request: (payload: TypeCreateGroupR) => CreateGroupAction.request(payload),
  success: (payload: IOfficialDoc) => CreateGroupAction.success(payload || {}),
  error: (message: string) => CreateGroupAction.error(message),
};

const CreateDocAction = new Actions('CREATE_DOC', ActionTypes);
export interface TypeCreateDocR extends TDefRequest {
  id: number;
}
export const CreateDoc = {
  request: (payload: TypeCreateDocR) => CreateDocAction.request(payload),
  success: (payload: IOfficialDoc) => CreateDocAction.success(payload || {}),
  error: (message: string) => CreateDocAction.error(message),
};

const UpdateDocAction = new Actions('UPDATE_DOC', ActionTypes);
export interface TypeUpdateDocR extends TDefRequest {
  data: {
    groupId: number;
    typeId: number;
    publisherIds: number[];
    docNum: string;
    docDate: Date;
    title: string;
    status: number;
    body: string;
    startDate: Date | null;
    endDate: Date | null;
    registNum?: string | null;
    registDate?: Date | null;
    approved: number;
    searchTags: string;
  };
  id: number;
}
export const UpdateDoc = {
  request: (payload: TypeUpdateDocR) => UpdateDocAction.request(payload),
  success: (payload: IOfficialDoc) => UpdateDocAction.success(payload || {}),
  error: (message: string) => UpdateDocAction.error(message),
};

const DeleteDocAction = new Actions('DELETE_DOC', ActionTypes);
export interface TypeDeleteDocR extends TDefRequest {
  id: number;
}
export const DeleteDoc = {
  request: (payload: TypeDeleteDocR) => DeleteDocAction.request(payload),
  success: (payload: IOfficialDoc) => DeleteDocAction.success(payload || {}),
  error: (message: string) => DeleteDocAction.error(message),
};

const CreateTypeDocAction = new Actions('CREATE_TYPE_DOC', ActionTypes);
export interface TypeCreateTypeDocR extends TDefRequest {
  data: {
    name: string;
    shortName: string;
    approved: number;
  };
}
export const CreateTypeDoc = {
  request: (payload: TypeCreateTypeDocR) => CreateTypeDocAction.request(payload),
  success: (payload: ITypeDoc) => CreateTypeDocAction.success(payload || {}),
  error: (message: string) => CreateTypeDocAction.error(message),
};

const UpdateTypeDocAction = new Actions('UPDATE_TYPE_DOC', ActionTypes);
export interface TypeUpdateTypeDocR extends TDefRequest {
  data: {
    name: string;
    shortName: string;
    approved: number;
  };
  id: number;
}
export const UpdateTypeDoc = {
  request: (payload: TypeUpdateTypeDocR) => UpdateTypeDocAction.request(payload),
  success: (payload: ITypeDoc) => UpdateTypeDocAction.success(payload || {}),
  error: (message: string) => UpdateTypeDocAction.error(message),
};

const DeleteTypeDocAction = new Actions('DELETE_TYPE_DOC', ActionTypes);
export interface TypeDeleteTypeDocR extends TDefRequest {
  id: number;
}
export const DeleteTypeDoc = {
  request: (payload: TypeDeleteTypeDocR) => DeleteTypeDocAction.request(payload),
  success: (payload: ITypeDoc) => DeleteTypeDocAction.success(payload || {}),
  error: (message: string) => DeleteTypeDocAction.error(message),
};

const GetOfficialDocOfUpdateAction = new Actions('GET_OFFICIAL_DOCS_FROM_UPDATE', ActionTypes);
export interface TypeGetOfficialDocOfUpdateR extends TDefRequest {
  data: {
    siteId: number;
    page: number;
  };
}
export const GetOfficialDocOfUpdate = {
  request: (payload: TypeGetOfficialDocOfUpdateR) => GetOfficialDocOfUpdateAction.request(payload),
  success: (payload: IOfficialDocUpdate) => GetOfficialDocOfUpdateAction.success(payload || {}),
  error: (message: string) => GetOfficialDocOfUpdateAction.error(message),
};

const DeleteOfficialDocFromUpdateAction = new Actions('DELETE_OFFICIAL_DOCS_FROM_UPDATE', ActionTypes);
export interface TypeDeleteOfficialDocR extends TDefRequest {
  id: number;
}
export const DeleteOfficialDocFromUpdate = {
  request: (payload: TypeDeleteOfficialDocR) => DeleteOfficialDocFromUpdateAction.request(payload),
  success: (payload: IOfficialDocUpdate) => DeleteOfficialDocFromUpdateAction.success(payload || {}),
  error: (message: string) => DeleteOfficialDocFromUpdateAction.error(message),
};

export interface TypeUpdateTypeDocOfUpdateR extends TDefRequest {
  data: {
    title: string;
    doc_id: number;
    publication_at: Date;
    site_id: number;
  };
  id: number;
}
const UpdateOfficialDocFromUpdateAction = new Actions('UPDATE_OFFICIAL_DOCS_FROM_UPDATE', ActionTypes);
export const UpdateOfficialDocOfUpdate = {
  request: (payload: TypeUpdateTypeDocOfUpdateR) => UpdateOfficialDocFromUpdateAction.request(payload),
  success: (payload: IOfficialDocUpdate) => UpdateOfficialDocFromUpdateAction.success(payload || {}),
  error: (message: string) => UpdateOfficialDocFromUpdateAction.error(message),
};

const CreateOfficialDocOfUpdateAction = new Actions('CREATE_OFFICIAL_DOC_OF_UPDATE', ActionTypes);
export interface TypeCreateOfficialDocUpdateR extends TDefRequest {
  data: {
    siteId: number;
    title: string;
    docId: number;
    publicationAt: Date;
  };
}
export const CreateOfficialDocOfUpdate = {
  request: (payload: TypeCreateOfficialDocUpdateR) => CreateOfficialDocOfUpdateAction.request(payload),
  success: (payload: IOfficialDocUpdate) => CreateOfficialDocOfUpdateAction.success(payload || {}),
  error: (message: string) => CreateOfficialDocOfUpdateAction.error(message),
};

const CheckDocumentVisitorAction = new Actions('CHECK_OFFICIAL_DOCS_VISITOR', ActionTypes);
export interface TypeCheckDocumentVisitorR extends TDefRequest {
  id: number;
}
export const CheckOffDocVisitors = {
  request: (payload: TypeCheckDocumentVisitorR) => CheckDocumentVisitorAction.request(payload),
  success: (payload: IDocumentVisitor[]) => CheckDocumentVisitorAction.success(payload),
  error: (message: string) => CheckDocumentVisitorAction.error(message),
};

const DeleteDocumentVisitorAction = new Actions('DELETE_OFFICIAL_DOCS_VISITOR', ActionTypes);
export interface TypeDeleteDocumentVisitorR extends TDefRequest {
  id?: number;
}
export const DeleteOffDocVisitors = {
  request: (payload?: TypeDeleteDocumentVisitorR) =>
    DeleteDocumentVisitorAction.request(payload || { callback: () => {}, id: 0 }),
  success: (payload: IDocumentVisitor) => DeleteDocumentVisitorAction.success(payload),
  error: (payload: string) => DeleteDocumentVisitorAction.error(payload),
};

const GetValueOfDocAction = new Actions('GET_VALUE_OF_DOC', ActionTypes);
export interface TypeGetValueOfDocR extends TDefRequest {
  id: number;
  siteId: number;
}
export const GetValueOfDoc = {
  request: (payload?: TypeGetValueOfDocR) => GetValueOfDocAction.request(payload || { callback: () => {}, id: 0 }),
  success: (payload: IDocumentVisitor) => GetValueOfDocAction.success(payload),
  error: (payload: string) => GetValueOfDocAction.error(payload),
};

const DownloadValueOfDocAction = new Actions('DOWNLOAD_VALUE_OF_DOC', ActionTypes);
export interface TypeDownloadValueOfDocR extends TDefRequest {
  id: number;
}
export const DownloadValueOfDoc = {
  request: (payload?: TypeDownloadValueOfDocR) =>
    DownloadValueOfDocAction.request(payload || { callback: () => {}, id: 0 }),
  success: (payload: Buffer) => DownloadValueOfDocAction.success(payload),
  error: (payload: string) => DownloadValueOfDocAction.error(payload),
};
