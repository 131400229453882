import { Reducer } from 'redux';
import ActionTypes, { TWidgetsState } from './types';
import { isValidJSONString } from '../../utils/isValidJSONString';

export const initialState: TWidgetsState = {
  loading: false,
  errors: undefined,
  mainWidgets: [],
  additionalWidgets: [],
};

const reducer: Reducer<TWidgetsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_ADDITIONAL_WIDGET_POSITIONS_R:
    case ActionTypes.CREATE_ADDITIONAL_WIDGET_R:
    case ActionTypes.UPDATE_MAIN_WIDGET_POSITIONS_R:
    case ActionTypes.UPDATE_MAIN_WIDGET_R:
    case ActionTypes.UPDATE_ADDITION_WIDGET_R:
    case ActionTypes.GET_WIDGETES_R:
      return { ...state, loading: true, errors: undefined };

    case ActionTypes.SET_WIDGETES:
    case ActionTypes.GET_WIDGETES_S: {
      
      return {
        ...state,
        mainWidgets: action.payload.widgetsMain.map((widget: any) => {
          if (widget.title === 'Індекс інфляції') {
            const parseMonth: string[] = isValidJSONString(widget.month + '') ? JSON.parse(widget.month) : [];
            return { ...widget, month: parseMonth };
          }
          return widget;
        }),
        additionalWidgets: action.payload.widgetsAdditional,
        loading: false,
        errors: undefined,
      };
    }

    case ActionTypes.UPDATE_MAIN_WIDGET_POSITIONS_S: {
      return {
        ...state,
        loading: false,
        errors: undefined,
        mainWidgets: action.payload.map((widget: any) => {
          if (widget.title === 'Індекс інфляції') {
            const parseMonth: string[] = isValidJSONString(widget.month + '') ? JSON.parse(widget.month + '') : [];
            return { ...widget, month: parseMonth };
          }
          return widget;
        }),
      };
    }

    case ActionTypes.UPDATE_ADDITIONAL_WIDGET_POSITIONS_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        additionalWidgets: action.payload,
      };

    case ActionTypes.UPDATE_MAIN_WIDGET_S: {
      return {
        ...state,
        errors: undefined,
        loading: false,
        mainWidgets: state.mainWidgets?.map((el) => {
          if (el.id === action.payload.id) {
            if (action.payload.title === 'Індекс інфляції') {
              if (!action.payload.month) {
                return { ...action.payload, month: el.month };
              }
            }
            return action.payload;
          }
          return el;
        }),
      };
    }

    case ActionTypes.CREATE_ADDITIONAL_WIDGET_S: {
      return {
        ...state,
        loading: false,
        errors: undefined,
        additionalWidgets: action.payload,
      };
    }

    case ActionTypes.UPDATE_ADDITION_WIDGET_S: {
      return {
        ...state,
        loading: false,
        errors: undefined,
        additionalWidgets: state.additionalWidgets?.map((el) => {
          if (el.id === action.payload.id) {
            return action.payload;
          }
          return el;
        }),
      };
    }

    case ActionTypes.UPDATE_ADDITIONAL_WIDGET_POSITIONS_E:
    case ActionTypes.UPDATE_MAIN_WIDGET_POSITIONS_E:
    case ActionTypes.UPDATE_MAIN_WIDGET_E:
    case ActionTypes.UPDATE_ADDITION_WIDGET_E:
    case ActionTypes.CREATE_ADDITIONAL_WIDGET_E:
    case ActionTypes.GET_WIDGETES_E:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export { reducer as WidgetsReducer };
