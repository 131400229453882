import Modal from '@mui/material/Modal';
import React from 'react';
import Button from '@mui/material/Button';
import '../BoxModal.scss';
import { useStyles } from '../styles';
import { ListFile } from './ListFile';

interface Props {
  open: boolean;
  docId: number;
  urlLink?: string;
  type?: number;
  handleClose: () => void;
}

export const FilesModal: React.FC<Props> = ({ open, docId, handleClose, urlLink, type }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <div className='boxModal-inner'>
        <div style={{ padding: '10px', width: '1000px' }}>
          <ListFile docId={docId} urlLink={urlLink} type={type} />
        </div>
        <div className='boxFooter'>
          <Button variant='contained' onClick={handleClose}>
            Закрити
          </Button>
        </div>
      </div>
    </Modal>
  );
};
