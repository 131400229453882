import { Reducer } from 'redux';
import ActionTypes, { TMainMonthState } from './types';

const initialState: TMainMonthState = {
  loading: false,
  error: null,
  mainInMonth: null,
  current: null,
  template: null,
  block: null,
};

const reducer: Reducer<TMainMonthState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_MAIN_IN_MONTH_R:
    case ActionTypes.GET_ONE_MAIN_IN_MONTH_R:
    case ActionTypes.DELETE_MAIN_IN_MONTH_R:
    case ActionTypes.CREATE_MAIN_IN_MONTH_R:
    case ActionTypes.GET_MAIN_IN_MONTH_R:
    case ActionTypes.CREATE_TEMPLATE_R:
    case ActionTypes.DELETE_TEMPLATE_R:
      return { ...state, loading: true, error: null };

    case ActionTypes.GET_TEMPLATE_R:
      return { ...state, template: null };
    case ActionTypes.GET_BLOCK_R:
      return { ...state, block: null };

    case ActionTypes.UPDATE_MAIN_IN_MONTH_E:
    case ActionTypes.GET_ONE_MAIN_IN_MONTH_E:
    case ActionTypes.DELETE_MAIN_IN_MONTH_E:
    case ActionTypes.CREATE_MAIN_IN_MONTH_E:
    case ActionTypes.GET_MAIN_IN_MONTH_E:
    case ActionTypes.CREATE_TEMPLATE_E:
    case ActionTypes.DELETE_TEMPLATE_E:
      return { ...state, loading: false, error: action.payload };

    case ActionTypes.GET_MAIN_IN_MONTH_S:
      return { ...state, loading: false, error: null, mainInMonth: action.payload };

    case ActionTypes.CREATE_MAIN_IN_MONTH_S: {
      let obj = null;
      if (state.mainInMonth && state.mainInMonth[action.payload.year]) {
        obj = { ...state.mainInMonth };
        obj[action.payload.year] = state.mainInMonth[action.payload.year].map((item) =>
          item.id === action.payload.id ? action.payload : item
        );
      }

      return {
        ...state,
        loading: false,
        error: null,
        mainInMonth: state.mainInMonth && obj ? obj : state.mainInMonth,
      };
    }

    case ActionTypes.DELETE_MAIN_IN_MONTH_S: {
      let obj = null;
      if (state.mainInMonth && state.mainInMonth[action.payload.year]) {
        obj = { ...state.mainInMonth };
        obj[action.payload.year] = state.mainInMonth[action.payload.year].filter(
          (item) => item.id !== action.payload.id
        );

        if (!obj[action.payload.year].length) {
          delete obj[action.payload.year];
        }
      }

      return {
        ...state,
        loading: false,
        error: null,
        mainInMonth: state.mainInMonth && obj ? obj : state.mainInMonth,
      };
    }

    case ActionTypes.CREATE_TEMPLATE_S: {
      return {
        ...state,
        loading: false,
        error: null,
        current: state.current
          ? { ...state.current, templates: [...state.current.templates, action.payload] }
          : state.current,
      };
    }

    case ActionTypes.DELETE_TEMPLATE_S: {
      return {
        ...state,
        loading: false,
        error: null,
        current: state.current
          ? {
              ...state.current,
              templates: state.current.templates.filter((item) => item.template_detail_id !== action.payload.id),
            }
          : state.current,
      };
    }

    case ActionTypes.GET_ONE_MAIN_IN_MONTH_S:
      return { ...state, loading: false, error: null, current: action.payload };

    case ActionTypes.GET_TEMPLATE_S:
    case ActionTypes.UPDATE_TEMPLATE_S:
    case ActionTypes.SET_TEMPLATE:
      return { ...state, loading: false, error: null, template: action.payload };

    case ActionTypes.GET_BLOCK_S:
    case ActionTypes.UPDATE_BLOCK_S:
      return {
        ...state,
        loading: false,
        error: null,
        block: action.payload,
      };

    case ActionTypes.DELETE_BLOCK_S:
      return {
        ...state,
        loading: false,
        error: null,
        template: state.template
          ? {
              ...state.template,
              month_main_blocks: state.template.month_main_blocks?.filter((item) => item.id !== action.payload.id),
            }
          : state.template,
      };
  }
  return state;
};

export { reducer as MainInMonthReducer };
