export interface IEvent {
  position: number;
  link: string;
  title: string;
  description: string;
  is_public: 1 | 0;
  is_main: 1 | 0;
  id: number;
  calendar_id: number;
  children?: IEvent[];
  site_id?: number;
  calendar: ICalendar;
}

export interface ICalendar {
  id: number;
  site_id: number;
  year?: number;
  month?: number;
  position: number;
  title: Date;
  children: IEvent[];
}

export interface TCalendarState {
  loading: boolean;
  error: undefined | string;
  calendar: ICalendar[] | null;
  current: IEvent | null;
  data: ICalendar[] | null;
}

enum ActionTypes {
  GET_CALENDAR_R = '@@calendar/GET_CALENDAR_R',
  GET_CALENDAR_S = '@@calendar/GET_CALENDAR_S',
  GET_CALENDAR_E = '@@calendar/GET_CALENDAR_E',

  GET_EVENT_R = '@@calendar/GET_EVENT_R',
  GET_EVENT_S = '@@calendar/GET_EVENT_S',
  GET_EVENT_E = '@@calendar/GET_EVENT_E',

  UPDATE_CALENDAR_POSITIONS_R = '@@calendar/UPDATE_CALENDAR_POSITIONS_R',
  UPDATE_CALENDAR_POSITIONS_S = '@@calendar/UPDATE_CALENDAR_POSITIONS_S',
  UPDATE_CALENDAR_POSITIONS_E = '@@calendar/UPDATE_CALENDAR_POSITIONS_E',

  UPDATE_EVENT_POSITIONS_R = '@@calendar/UPDATE_EVENT_POSITIONS_R',
  UPDATE_EVENT_POSITIONS_S = '@@calendar/UPDATE_EVENT_POSITIONS_S',
  UPDATE_EVENT_POSITIONS_E = '@@calendar/UPDATE_EVENT_POSITIONS_E',

  CREATE_EVENT_R = '@@calendar/CREATE_EVENT_R',
  CREATE_EVENT_S = '@@calendar/CREATE_EVENT_S',
  CREATE_EVENT_E = '@@calendar/CREATE_EVENT_E',

  DELETE_EVENT_R = '@@calendar/DELETE_EVENT_R',
  DELETE_EVENT_S = '@@calendar/DELETE_EVENT_S',
  DELETE_EVENT_E = '@@calendar/DELETE_EVENT_E',

  UPDATE_EVENT_R = '@@calendar/UPDATE_EVENT_R',
  UPDATE_EVENT_S = '@@calendar/UPDATE_EVENT_S',
  UPDATE_EVENT_E = '@@calendar/UPDATE_EVENT_E',

  SEARCH_CALENDAR_R = '@@calendar/SEARCH_CALENDAR_R',
  SEARCH_CALENDAR_S = '@@calendar/SEARCH_CALENDAR_S',
  SEARCH_CALENDAR_E = '@@calendar/SEARCH_CALENDAR_E',

  CLONE_MONTH_EVENTS_R = `@@calendar/CLONE_MONTH_EVENTS_R`,
  CLONE_MONTH_EVENTS_S = `@@calendar/CLONE_MONTH_EVENTS_S`,
  CLONE_MONTH_EVENTS_E = `@@calendar/CLONE_MONTH_EVENTS_E`,

  GET_AMOUNT_EVENTS_IN_MONTH_R =`@@calendar/GET_AMOUNT_EVENTS_IN_MONTH_R`,
  GET_AMOUNT_EVENTS_IN_MONTH_S =`@@calendar/GET_AMOUNT_EVENTS_IN_MONTH_S`,
  GET_AMOUNT_EVENTS_IN_MONTH_E =`@@calendar/GET_AMOUNT_EVENTS_IN_MONTH_E`,
}

export default ActionTypes;
