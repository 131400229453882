import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import { StylesTable } from '../../components/Stylestable';
import { BasicDatePicker, SelectOutline } from '../../components/ui';
import { ROUTES } from '../../utils/routes';
import { AppStore } from '../../store/applicationState';
import { CalendarTree } from '../../components/Calendar/CalendarTree';
import { CloneMonthEvents, GetAmountEventsInMonth, GetCalendar } from '../../store/calendar/actions';
import { ICalendar } from '../../store/calendar/types';
import { makeStyles } from '@mui/styles';
import ConfirmModal from '../../components/modals/ConfirmModal';

type PropsType = {};

export const stylesCalendar: any = makeStyles(() => ({
  selectDay: {
    width: '200px',
  },
}));

function formatEventMessage(amountMonthEvents: number) {
  let message;

  if (amountMonthEvents === 1) {
    message = `Увага! Ви клонуєте ${amountMonthEvents} подію в новий місяць`;
  } else if (amountMonthEvents > 1 && amountMonthEvents < 5) {
    message = `Увага! Ви клонуєте ${amountMonthEvents} події в новий місяць`;
  } else {
    message = `Увага! Ви клонуєте ${amountMonthEvents} подій в новий місяць`;
  }

  return message;
}

export const Calendar: React.FC<PropsType> = () => {
  const { calendar } = useSelector((store: AppStore) => store.Calendar);
  const [calendarS, setCalendar] = useState<ICalendar[] | null>(calendar);
  const { Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classesTable: any = StylesTable();
  const [date, setDate] = useState<Date | null>(new Date());
  const [day, setDay] = useState('');

  const [cloneMontModal, setCloneMonthModal] = useState(false);

  const [cloneMonthTo, setCloneMonthTo] = useState<Date | null>(null);
  const [amountMonthEvents, setAmountMonthEvents] = useState(0);
  const [haveSimillarEvents, setHaveSimillarEvents] = useState(false);
  const [cloneMonthFrom, setCloneMonthFrom] = useState<Date | null>(date);

  const handleChangeRoute =
    (path = '' as string | number) =>
    () => {
      window.open(ROUTES.routeChange(`new?site_id=${Configurations.siteId}`));
    };

  useEffect(() => {
    dispatch(
      GetCalendar.request({
        data: {
          site_id: Configurations.siteId,
          date: date && new Date(date),
          day: day,
        },
      })
    );
  }, [Configurations, dispatch, date, day]);

  useEffect(() => {
    setCalendar(calendar);
    let count = 0;

    if (Array.isArray(calendar)) {
      calendar?.forEach((it: ICalendar) => {
        count += it.children.length;
      });

      setAmountMonthEvents(count);
    }
    checkForDuplicateTitles(calendar);
  }, [calendar]);

  function checkForDuplicateTitles(data: ICalendar[] | undefined | null): void {
    if (!data || !Array.isArray(data) || !data.length) return setHaveSimillarEvents(false);

    data.forEach((it: ICalendar) => {
      const titlesArray: string[] = [];
      const titles = it.children.map((el) => el.title);
      titlesArray.push(...titles);

      const titlesCount = titlesArray.reduce((acc, value) => {
        acc[value] = (acc[value] || 0) + 1;
        return acc;
      }, {} as Record<string, number>);

      const duplicateTitles = Object.keys(titlesCount).filter((title) => titlesCount[title] > 1);

      if (duplicateTitles.length) {
        setHaveSimillarEvents(true);
        return;
      } else {
        setHaveSimillarEvents(false);
      }
    });
  }

  const cloneMonthEvents = () => {
    dispatch(
      CloneMonthEvents.request({
        data: {
          site_id: Configurations.siteId,
          from: cloneMonthFrom,
          to: cloneMonthTo,
        },
        callBack(success, data) {
          if (success) {
            setCloneMonthModal(false);
            setDate(cloneMonthTo);
            setCloneMonthFrom(cloneMonthTo);
            if (calendar) {
              checkForDuplicateTitles(data);
            }
          }
        },
      })
    );
  };

  const handleMonthCoppychange = (value: Date | null) => {
    if (value) {
      const year = value.getFullYear();
      const month = value.getMonth() + 1;
      dispatch(
        GetAmountEventsInMonth.request({
          data: {
            year,
            month,
            site_id: Configurations.siteId,
          },
          callBack: (success, data) => {
            if (success) {
              setAmountMonthEvents(data);
              setCloneMonthFrom(value);
            }
          },
        })
      );
    }
  };
  useEffect(() => {
    setCloneMonthFrom(date);
  }, [date]);

  const days = Array.from({ length: 31 }, (_, index) => index + 1);

  return (
    <>
      <Box display={'flex'} mb={2} gap={1}>
        <Button variant={'contained'} color={'primary'} onClick={() => {}}>
          ПОДІЇ
        </Button>
        <Button variant={'contained'} color={'primary'} onClick={handleChangeRoute('new')}>
          СТВОРИТИ ПОДІЮ
        </Button>
        <Button variant={'contained'} color={'primary'} onClick={() => setCloneMonthModal(true)}>
          КЛОНУВАТИ ПОДІЇ
        </Button>
      </Box>
      <Box display={'flex'} gap={2} className={classesTable.header}>
        <BasicDatePicker
          disabled={true}
          format='MM.yyyy'
          style={{ width: '150px' }}
          id='publicationTo'
          views={['year', 'month']}
          required
          label={'Місяць-Рік'}
          value={date}
          error={false}
          onChange={(value) => {
            setDate(value);
          }}
        />

        <SelectOutline
          required
          style={{ width: 100 }}
          id='outlined-basic-status'
          title='день'
          value={day}
          error={false}
          options={days.map((el) => ({ label: String(el), value: String(el), disabled: false }))}
          handleChange={(value) => {
            setDay(value);
          }}
        />

        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            setDay('');
            setDate(new Date());
          }}
        >
          Очистити фільтри
        </Button>
      </Box>
      <Box>
        {haveSimillarEvents ? (
          <p style={{ color: `red`, fontSize: '0.75rem', marginTop: '5px' }}>
            Зверніть увагу! У вас збігаються назви подій
          </p>
        ) : null}
      </Box>
      <CalendarTree
        setAmountMonthEvents={setAmountMonthEvents}
        setHaveSimillarEvents={setHaveSimillarEvents}
        children={calendarS}
        cards={calendarS}
        categoryId={1}
        setCards={() => {}}
      />
      <ConfirmModal
        buttonSaveDisabled={!cloneMonthTo || !cloneMonthFrom}
        handleClose={() => setCloneMonthModal(false)}
        handleConfirm={cloneMonthEvents}
        open={cloneMontModal}
        title='Скопіювати події за місяць'
      >
        <Box>
          <Box>
            <BasicDatePicker
              disabled={true}
              format='MM.yyyy'
              id='cloneMonthFrom'
              views={['year', 'month']}
              required
              label={'Місяць-Рік копіювання'}
              value={cloneMonthFrom}
              error={false}
              onChange={(value) => handleMonthCoppychange(value)}
            />
            <p style={{ color: `red`, fontSize: '0.75rem', marginTop: '5px' }}>
              {formatEventMessage(amountMonthEvents)}
            </p>
          </Box>
          <BasicDatePicker
            disabled={true}
            format='MM.yyyy'
            id='cloneMonthTo'
            views={['year', 'month']}
            required
            label={'Місяць-Рік створення'}
            value={cloneMonthTo}
            error={false}
            onChange={(value) => {
              setCloneMonthTo(value);
            }}
          />
        </Box>
      </ConfirmModal>
    </>
  );
};
