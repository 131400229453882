import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Button, CircularProgress, CssBaseline, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ICatalog } from '../../store/catalogues/types';
import { TypeCreateCatalogR } from '../../store/catalogues/actions';
import { SelectRubrics, SelectPopup, BasicDatePicker, SelectSearchReq } from '../ui';
import { EditorDefault, EditorFeatured } from '../Editors';
import { StylesEdit } from '../StylesEdit';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { RubricsModal } from '../modals';
import { Editor } from '@tinymce/tinymce-react';
import { uuid } from '../Editors/EditorDocum/entities/utils';
import { CATEGORIES, defaultUsavedFlags } from '../../utils/consts';
import { useSearchAuthors } from '../../hooks/useSearchAuthors';
import { IDataOpt } from '../ui/SelectSearchReq';
import { UnsavedFlags } from '../ConsultationForm';
import { areArraysEqual } from '../../utils/areArraysEqual';
import { configSites } from '../../config';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { AlertMessage } from '../AlertMessage';
import { hideAnchor } from '../Editors/commands';

interface PropsType {
  type: 'edit' | 'new';
  data?: ICatalog | null;
  handleSubmit: (data: TypeCreateCatalogR['data'], callBack: (err?: { name: string; message: string }) => void) => void;
  handleCancel: () => void;
}

export const CatalogForm: React.FC<PropsType> = ({ type, data, handleSubmit, handleCancel }) => {
  const [searchTags, setSearchTags] = React.useState<string>(data?.search_tags || '');
  const [title, setTitle] = React.useState<string>(data?.title || '');
  const [publicationAt, setPublicationAt] = React.useState<Date | null>(data?.publication_at || null);
  const [updatedAt, setUpdatedAt] = React.useState<Date | null>(data?.updated_at || null);
  const [isPublic, setIsPublic] = React.useState(data?.is_public || 0);
  const [rubricId, setRubricId] = React.useState<string>(data ? String(data.rubric_id) : '');
  const [rubricIds, setRubricIds] = React.useState<{ id: number; name: string }[]>([]);
  const [error, setError] = React.useState<Record<string, string>>({});
  const [authorFirst, setAuthorFirst] = React.useState<IDataOpt | null>(null);
  const [authorSecond, setAuthorSecond] = React.useState<IDataOpt | null>(null);
  const [authorThird, setAuthorThird] = React.useState<IDataOpt | null>(null);
  const { options, handleSearchAuthors } = useSearchAuthors();

  const [isDisablePreview, setIsdisablePreview] = React.useState(false);
  const [unsavedData, setUnsavedData] = React.useState<UnsavedFlags>(defaultUsavedFlags);

  const editorDefRef = React.useRef<Editor['editor'] | null>(null);
  const editorRef = React.useRef<Editor['editor'] | null>(null);

  const { Catalogues, Rubrics, Configurations } = useSelector((store: AppStore) => store);

  const classes = StylesEdit();

  React.useEffect(() => {
    if (data) {
      if (data.authors[0]) setAuthorFirst({ id: data.authors[0].id, label: data.authors[0].fio });
      if (data.authors[1]) setAuthorSecond({ id: data.authors[1].id, label: data.authors[1].fio });
      if (data.authors[2]) setAuthorThird({ id: data.authors[2].id, label: data.authors[2].fio });

      if (data && data.catalog_rubric_details.length > 0) {
        setRubricIds(data.catalog_rubric_details.map((it) => ({ id: it.rubric.id, name: it.rubric.name })));
      }
    }
  }, [data]);

  React.useEffect(() => {
    const unsavedAuthorFirst = authorFirst?.id !== data?.authors[0]?.id;
    const unsavedAuthorSec = authorSecond?.id !== data?.authors[1]?.id;
    const unsavedAuthorThir = authorThird?.id !== data?.authors[2]?.id;
    const unsavedTitle = title !== data?.title;
    const unsavedRubrics = !areArraysEqual(
      data?.catalog_rubric_details.map((it) => it.rubric_id),
      rubricIds.map((it) => it.id)
    );

    const publicationAtLoc = new Date(publicationAt || new Date());
    const dataPublicationAt = new Date(data?.publication_at || new Date());

    const publicationAtUTC = new Date(publicationAtLoc.toUTCString());
    const dataPublicationAtUTC = new Date(dataPublicationAt.toUTCString());

    const unsavedPublicationAt = publicationAtUTC.getTime() !== dataPublicationAtUTC.getTime();

    const unsavedMainRubric = +rubricId !== data?.rubric_id;

    setUnsavedData({
      ...unsavedData,
      unsavedAuthorFirst,
      unsavedAuthorSec,
      unsavedAuthorThir,
      unsavedTitle,
      unsavedPublicationAt,
      unsavedMainRubric,
      unsavedRubrics,
    });

    //eslint-disable-next-line
  }, [
    title,
    authorFirst,
    authorSecond,
    authorThird,
    rubricId,
    data?.title,
    data?.rubric_id,
    data?.authors,
    data?.publication_at,
    publicationAt,
    rubricIds,
  ]);

  React.useEffect(() => {
    setIsdisablePreview(Object.values(unsavedData).some((flag) => flag));
  }, [unsavedData]);

  const handleChange = (name: string, callBack: (value: any) => void) => (value: any) => {
    const obj = { ...error };
    delete obj[name];
    setError(obj);
    callBack(value);
  };

  const onSubmit = () => {
    if (!title) return setError({ title: 'Заголовок має бути заповненим' });
    if (!publicationAt) return setError({ publicationAt: 'Дата дата публікації має бути заповнена.' });
    if (!Number(rubricId)) return setError({ rubricId: 'Рубрика має бути заповнена' });
    setError({});

    hideAnchor(editorRef.current);

    const description = editorDefRef.current?.getContent();
    const content = editorRef.current?.getContent();
    const tempElement = document.createElement('html');
    tempElement.innerHTML = content || '';

    Array.from(tempElement.getElementsByTagName('p')).forEach((item) => {
      if (!item.hasAttribute('id')) {
        item.setAttribute('id', uuid());
      }
    });

    const authorsIds = [authorFirst, authorSecond, authorThird]
      .map((it) => Number(it?.id))
      .filter((num) => !!num && !Number.isNaN(num));
    const body = tempElement.innerHTML;

    handleSubmit(
      {
        isPublic,
        authorsIds,
        title,
        publication_at: publicationAt,
        description,
        body,
        siteId: data ? data.site_id : Configurations.siteId,
        rubricId: Number(rubricId),
        searchTags,
        rubricsIds: rubricIds.map((it) => it.id),
        is_in_cart: data?.is_in_cart || 0,
        updated_at: updatedAt,
      },
      (err) => {
        setUnsavedData(defaultUsavedFlags);
        if (err) {
          setError({ [err.name]: err.message });
        }
      }
    );
  };

  return (
    <Box>
      <CssBaseline />
      <div className={classes.paper}>
        <Box className={classes.header}>
          <Typography component='h1' variant='h5'>
            {type === 'new' ? 'Створити довідник' : 'Редагувати довідник'}
          </Typography>
          {data && (
            <Box style={{ display: 'flex' }}>
              <Box className={classes.headerItem}>Дата створення {moment(data?.adate).format('DD.MM.YYYY HH:mm')}</Box>
              <Box>Дата редагування {moment(data?.update).format('DD.MM.YYYY HH:mm')}</Box>
            </Box>
          )}
        </Box>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '900px' }}>
          <FormControlLabel
            style={{ marginTop: '7px' }}
            control={<Checkbox checked={!!isPublic} />}
            onChange={() => {
              setIsPublic(isPublic ? 0 : 1);
            }}
            label='Публікувати'
          />
          <div
            title={isDisablePreview ? 'У вас є незбережені зміни.' : ''}
            style={{ cursor: isDisablePreview ? 'not-allowed' : 'pointer' }}
            onClick={() => {
              if (isDisablePreview) return;
              window.open(
                `${configSites[Configurations.siteId].url}/catalogues/preview-${Configurations.siteId}-8-${data?.id}-${
                  data?.preview_hash
                }`
              );
            }}
          >
            <RemoveRedEyeOutlinedIcon color={isDisablePreview ? 'disabled' : 'info'} />
          </div>
        </div>
        <TextField
          size='small'
          id='outlined-basic'
          label='Теги'
          variant='outlined'
          value={searchTags}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setSearchTags(event.target.value as string);
          }}
        />
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Заголовок'
          variant='outlined'
          value={title}
          error={!!error['title']}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            handleChange('title', setTitle)(event.target.value);
          }}
        />
        <div className={classes.textField} style={{ display: 'flex', gap: '4px' }}>
          <BasicDatePicker
            id='publicationAt'
            required
            label='Дата публікації'
            value={publicationAt}
            error={!!error['publicationAt']}
            onChange={handleChange('publicationAt', setPublicationAt)}
          />
          <BasicDatePicker id='updatedAt' required label='Дата оновлення' value={updatedAt} onChange={setUpdatedAt} />
        </div>

        <div style={{ marginTop: '20px' }}>
          <EditorDefault
            placeholder='Опис'
            editorRef={editorDefRef}
            initialValue={data?.description}
            setUnsavedChanges={setUnsavedData}
            unsavedChanges={unsavedData}
          />
        </div>
        <SelectRubrics
          required
          title='Рубрикатор'
          value={rubricId}
          error={!!error['rubricId']}
          className={classes.textField}
          options={Rubrics.rubrics && Rubrics.rubrics[CATEGORIES.CATALOGUES]}
          onChange={handleChange('rubricId', setRubricId)}
        />
        <SelectPopup
          title='Додатк. Рубрикатор'
          value={rubricIds}
          className={classes.textField}
          options={Rubrics.rubrics && Rubrics.rubrics[CATEGORIES.CATALOGUES]}
          onChange={setRubricIds}
          PropsModal={RubricsModal}
        />
        <SelectSearchReq
          title='Автор'
          data={authorFirst}
          className={classes.textField}
          onChange={setAuthorFirst}
          options={options}
          handleRequest={handleSearchAuthors}
        />
        <SelectSearchReq
          title='Автор'
          data={authorSecond}
          className={classes.textField}
          onChange={setAuthorSecond}
          options={options}
          handleRequest={handleSearchAuthors}
        />
        <SelectSearchReq
          title='Автор'
          data={authorThird}
          className={classes.textField}
          onChange={setAuthorThird}
          options={options}
          handleRequest={handleSearchAuthors}
        />
        <div style={{ marginTop: '20px', display: 'flex' }}>
          <EditorFeatured
            categoryId={CATEGORIES.CATALOGUES}
            unsavedChanges={unsavedData}
            setUnsavedChanges={setUnsavedData}
            editorRef={editorRef}
            initialValue={data?.body}
            siteId={Configurations.siteId}
            docId={data?.id}
          />
          <div style={{ padding: '130px 10px 0px' }}>Ширина 1100px</div>
        </div>
        <Box display={'flex'} mt={2} gap={2} justifyContent={'space-between'} width={900}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <Button variant='contained' color='primary' disabled={Catalogues.loading} onClick={onSubmit}>
              {Catalogues.loading ? <CircularProgress size={15} /> : 'Зберегти'}
            </Button>
            <Button variant='contained' color='inherit' onClick={handleCancel}>
              Повернутися
            </Button>
          </div>

          <Button
            style={{ cursor: isDisablePreview ? 'not-allowed' : 'pointer' }}
            disabled={isDisablePreview}
            variant='contained'
            color='inherit'
            onClick={() => {
              window.open(
                `${configSites[Configurations.siteId].url}/catalogues/preview-${Configurations.siteId}-8-${data?.id}-${
                  data?.preview_hash
                }`
              );
            }}
          >
            ПЕРЕГЛЯНУТИ
          </Button>
        </Box>
        {Object.keys(error).length ? <AlertMessage type='error' message={Object.entries(error)[0][1]} /> : null}
      </div>
    </Box>
  );
};
