import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppStore } from '../../store/applicationState';
import { DeleteConsultation, GetConsultations, SearchConsultations } from '../../store/consultations/actions';
import { IConsultation } from '../../store/consultations/types';
import { GetCart } from '../../store/cart/actions';
import { useSearchAuthors } from '../../hooks/useSearchAuthors';
import { ROUTES } from '../../utils/routes';
import { StylesTable } from '../../components/Stylestable';
import { SelectSearchReq } from '../../components/ui';
import { WarningModal } from '../../components/modals';
import { useSearchAny } from '../../hooks/useSearchAny';
import { Pagination } from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { configSites } from '../../config';

interface Column {
  id: 'id' | 'title' | 'author' | 'authorFio' | 'isMain' | 'isPublic' | 'rubric' | 'rubrics' | 'delete' | 'preview';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: '№', align: 'center', width: '6%' },
  { id: 'title', label: 'Заголовок', align: 'left' },
  { id: 'author', label: 'Автор', align: 'left', width: '10%' },
  {
    id: 'authorFio',
    label: 'Редактор',
    align: 'left',
  },
  {
    id: 'preview',
    label: `Прев'ю`,
    align: 'center',
    width: '6%',
  },
  {
    id: 'isPublic',
    label: 'Публікція',
    align: 'center',
  },
  {
    id: 'rubric',
    label: 'Рубрика',
    align: 'left',
  },
  {
    id: 'rubrics',
    label: 'Додатк. Рубрики',
    align: 'left',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '8%',
  },
];

type PropsType = {};

export const ListConsultations: React.FC<PropsType> = () => {
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const [consultations, setConsultations] = useState<IConsultation[]>([]);
  const { Consultations, Configurations } = useSelector((store: AppStore) => store);
  const { options, handleSearchAuthors } = useSearchAuthors();
  const [author, setAuthor] = useState<{ label: string; id: string | number } | null | undefined>({
    id: 0,
    label: '',
  });
  const [id, setId] = useState<any>('');
  const [title, setTitle] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Consultations.data?.pages || 1);

  const resetState = () => {
    setId('');
    setTitle('');
    setPage(1);
    setAuthor(null);
    dispatch(
      GetConsultations.request({
        data: {
          siteId: Configurations.siteId,
          page: 1,
        },
      })
    );
  };

  const dispatch = useDispatch();
  const classesTable: any = StylesTable();

  const { loading, handleSearch } = useSearchAny({
    SearchData: SearchConsultations,
  });

  useEffect(() => {
    dispatch(
      GetConsultations.request({
        data: {
          siteId: Configurations.siteId,
          page,
          author: String(author?.id) || undefined,
          title,
          id,
        },
        callBack: (success, data) => {
          if (success && data?.consultations) {
            setConsultations(data.consultations);
            setTotalPages(data.pages);
          }
        },
      })
    );
    //eslint-disable-next-line
  }, [page, Configurations, dispatch]);

  useEffect(() => {
    if (Consultations?.consultations) {
      setConsultations(Consultations?.consultations || Consultations.data?.consultations);
      setTotalPages(Consultations?.pages);
    }
  }, [Consultations]);

  const handleChangeRoute =
    (path = '' as string | number) =>
    () => {
      window.open(ROUTES.routeChange(`list/${path}?site_id=${Configurations.siteId}`));
    };

  const handleRemove = (id: number) => {
    dispatch(
      DeleteConsultation.request({
        id,
        callBack: (success) => {
          if (success) {
            dispatch(
              GetCart.request({
                data: {
                  siteId: Configurations.siteId,
                },
              })
            );
            dispatch(
              GetConsultations.request({
                data: {
                  siteId: Configurations.siteId,
                },
              })
            );
          }
        },
      })
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <Box className={classesTable.header}>
        <Button
          className={classesTable.searchField}
          variant='contained'
          color='primary'
          onClick={handleChangeRoute('new')}
        >
          Створити консультацію
        </Button>
        <TextField
          size='small'
          id='outlined-basic-id'
          label='Пошук по ID'
          variant='outlined'
          value={id}
          className={classesTable.searchField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const value = event.target.value as string;
            const data: any = {};
            if (page) data['page'] = 1;
            data['siteId'] = Configurations.siteId;
            handleSearch(value, 'id', data);
            setPage(1);
            setId(value);
          }}
        />
        <TextField
          className={classesTable.searchField}
          size='small'
          id='outlined-basic-title'
          label='Пошук по заголовку'
          variant='outlined'
          value={title}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const value = event.target.value as string;
            const data: any = {};
            if (page) data['page'] = 1;
            data['siteId'] = Configurations.siteId;
            handleSearch(value, 'title', data);
            setPage(1);
            setTitle(value);
          }}
        />
        <SelectSearchReq
          style={{ maxWidth: '200px' }}
          title='Автор'
          className={classesTable.searchField}
          onChange={(value) => {
            const data: any = {};
            if (page) data['page'] = 1;
            data['siteId'] = Configurations.siteId;
            handleSearch(String(value?.id) || '', 'author', data);
            setAuthor({ label: value?.label || '', id: value?.id || 0 });
            setPage(1);
          }}
          options={options}
          handleRequest={handleSearchAuthors}
          value={author?.label}
        />
        <Button className={classesTable.searchField} variant='contained' color='primary' onClick={resetState}>
          {loading ? <CircularProgress color='inherit' size={25} /> : 'Очистити фільтри'}
        </Button>
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {consultations?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>
                    <Link sx={{ cursor: 'pointer' }} onClick={handleChangeRoute(row.id)}>
                      {row.id}
                    </Link>
                  </TableCell>
                  <TableCell className={classesTable.row}>{row.title}</TableCell>
                  <TableCell className={classesTable.row}>
                    {row.authors && row.authors.length > 0
                      ? row.authors.map((author) => (
                          <div key={`${i}-${author.id}`}>
                            <span key={`${i}-${author.id}`}>{author.fio}</span>
                            <br />
                          </div>
                        ))
                      : ''}
                  </TableCell>
                  <TableCell className={classesTable.row}>{row.author?.fio || ''}</TableCell>
                  <TableCell align='center'>
                    <a
                      style={{ color: 'unset' }}
                      color='unset'
                      rel='noopener noreferrer'
                      target='_blank'
                      href={`${configSites[Configurations.siteId].url}/recommendations/preview-${
                        Configurations.siteId
                      }-1-${row?.id}-${row?.preview_hash}`}
                    >
                      <RemoveRedEyeOutlinedIcon />
                    </a>
                  </TableCell>
                  <TableCell align='center' className={classesTable.row}>
                    {row.is_public ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell className={classesTable.row}>{row.rubric?.name || ''}</TableCell>
                  <TableCell className={classesTable.row}>
                    {row.consultation_rubric_details.length > 0
                      ? row.consultation_rubric_details.map((item, idx) => (
                          <div key={`${i}-${idx}-${item.rubric.id}`}>
                            <span>{item.rubric.name}</span>
                            <br />
                          </div>
                        ))
                      : ''}
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton aria-label='delete' onClick={() => setIsModal({ id: row.id })}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination className={classesTable.pagination} count={totalPages} page={page} onChange={handleChangePage} />
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
