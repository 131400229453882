import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { EditFactoids } from './EditFactoids';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Apps from '@mui/icons-material/Apps';
import { StylesTable } from '../Stylestable';
import DoneIcon from '@mui/icons-material/Done';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { WarningModal } from '../modals';
import {
  DeleteFactoids,
  GetFactoids,
  SearchFactoids,
  setEditData,
  setListData,
} from '../../store/factoids/actions';
import moment from 'moment/moment';
import { ListFactoidBlocks } from './ListFactoidBlocks';
import { useSearchAny } from '../../hooks/useSearchAny';

interface Column {
  id: 'id' | 'title' | 'tags' | 'created_at' | 'approved' | 'blocks' | 'edit' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: '№', align: 'center', width: '6%' },
  { id: 'title', label: 'Заголовок', align: 'left' },
  { id: 'tags', label: 'Теги', align: 'left' },
  { id: 'approved', label: 'Опубліковано', align: 'center' },
  { id: 'created_at', label: 'Дата створення', align: 'center' },
  {
    id: 'blocks',
    label: 'Блоки',
    align: 'center',
    width: '9%',
  },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '9%',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '8%',
  },
];

type PropsType = {};

export const ListFactoids: React.FC<PropsType> = () => {
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const { Factoids, Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classesTable = StylesTable();
  const [searchId, setSearchId] = useState('');
  const [title, setTitle] = useState('');

  const { loading, handleSearch } = useSearchAny({
    SearchData: SearchFactoids,
  });

  React.useEffect(() => {
    if (Configurations.siteId) {
      dispatch(
        GetFactoids.request({
          data: {
            siteId: Configurations.siteId,
          },
        })
      );
    }
  }, [dispatch, Configurations.siteId]);

  const handleRemove = (id: number) => {
    dispatch(DeleteFactoids.request({ id }));
  };

  if (Factoids.editData) {
    return (
      <EditFactoids
        type={Factoids.editData.type}
        data={Factoids.editData.data}
        handleCancel={() => dispatch(setEditData(null))}
      />
    );
  }

  if (Factoids.listBlockData) {
    return (
      <ListFactoidBlocks factoid={Factoids.listBlockData.factoid} handleCancel={() => dispatch(setListData(null))} />
    );
  }

  return (
    <>
      <Box className={classesTable.header}>
        <Button
          className={classesTable.searchField}
          variant='contained'
          color='primary'
          onClick={() => {
            dispatch(setEditData({ type: 'new' }));
          }}
        >
          Створити
        </Button>
        <TextField
          size='small'
          id='outlined-basic-id'
          label='Пошук по ID'
          variant='outlined'
          value={searchId}
          className={classesTable.searchField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const data: any = {};
            data['siteId'] = Configurations.siteId;
            handleSearch(event.target.value as string, 'id', data);
            setSearchId(String(event.target.value));
          }}
        />
        <TextField
          className={classesTable.searchField}
          size='small'
          id='outlined-basic-title'
          label='Пошук по заголовку'
          variant='outlined'
          value={title}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const data: any = {};
            data['siteId'] = Configurations.siteId;
            handleSearch(event.target.value as string, 'title', data);
            setTitle(event.target.value as string);
          }}
        />
        {loading ? <CircularProgress size={25} /> : null}
      </Box>
      <TableContainer className={classesTable.container} style={{ maxHeight: 'calc(100vh - 200px)' }}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Factoids.factoids?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>{row.id}</TableCell>
                  <TableCell className={classesTable.row}>{row.title}</TableCell>
                  <TableCell className={classesTable.row}>{row.tags}</TableCell>
                  <TableCell align='center' className={classesTable.row}>
                    {row.approved ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    {moment(row.created_at).format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    <IconButton aria-label='Блоки' onClick={() => dispatch(setListData({ factoid: row }))}>
                      <Apps />
                    </IconButton>
                  </TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    <IconButton aria-label='edit' onClick={() => dispatch(setEditData({ type: 'edit', data: row }))}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton aria-label='delete' onClick={() => setIsModal({ id: row.id })}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
