import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { StylesTable } from '../../Stylestable';
import { IPublisherDoc } from '../../../store/publishers/types';
import { EditPublisher } from './EditPublisher';
import DoneIcon from '@mui/icons-material/Done';
import Pagination from '@mui/material/Pagination';
import { GetPublishers } from '../../../store/publishers/actions';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useSearchAny } from '../../../hooks/useSearchAny';
import { colorsStatus } from '../../../utils/consts';

interface Column {
  id:
    | 'id'
    | 'name'
    | 'shortName'
    | 'shortNameGenitive'
    | 'synonyms'
    | 'status'
    | 'approved'
    | 'docCount'
    | 'delete'
    | 'edit';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '3%' },
  { id: 'name', label: 'Назва', align: 'left' },
  { id: 'shortName', label: 'Скорочена назва', align: 'left' },
  { id: 'shortNameGenitive', label: 'Скорочена назва у род.відмінку', align: 'left' },
  { id: 'synonyms', label: 'Синоніми назви', align: 'left' },
  { id: 'docCount', label: 'К-сть документів', align: 'center' },
  { id: 'status', label: 'Чинний', align: 'center' },
  { id: 'approved', label: 'Статус', align: 'center' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '9%',
  },
];

type PropsType = {};

export const PublishersList: React.FC<PropsType> = () => {
  const [editData, setEditData] = React.useState<{ type: 'edit' | 'new'; data?: IPublisherDoc } | null>(null);
  const [id, setId] = React.useState('');
  const [name, setName] = React.useState('');

  const { OfficialDoc, Publishers, Sidebar } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const { loading, handleSearch } = useSearchAny({
    SearchData: GetPublishers,
  });

  const classesTable = StylesTable();

  React.useEffect(() => {
    if (Sidebar.page.type === 'main' || Sidebar.page.type === 'list') {
      setEditData(null);
    }
  }, [Sidebar.page]);

  const handleChangePage = (event: unknown, page: number) => {
    if (!Publishers.data) return null;
    if (Publishers.data.pages >= page) {
      dispatch(
        GetPublishers.request({
          data: {
            page,
            id: !Number.isNaN(Number(id)) ? id : '',
            name,
          },
        })
      );
    }
  };

  if (editData) {
    return <EditPublisher type={editData.type} data={editData.data} handleCancel={() => setEditData(null)} />;
  }

  return (
    <>
      <Box className={classesTable.header}>
        <Button
          className={classesTable.searchField}
          style={{ marginRight: '20px' }}
          variant='contained'
          color='primary'
          onClick={() => {
            setEditData({ type: 'new' });
          }}
        >
          Створити видавника
        </Button>
        <TextField
          size='small'
          id='outlined-basic-id'
          label='Пошук по ID'
          variant='outlined'
          value={id}
          className={classesTable.searchField}
          onChange={(event) => {
            const num = event.target.value.replace(/[^0-9.]/g, '');
            setId(num);
            handleSearch(num, 'id');
          }}
        />
        <TextField
          size='small'
          id='outlined-basic-id-2'
          label='Видавник'
          variant='outlined'
          value={name}
          className={classesTable.searchField}
          onChange={(e) => {
            setName(e.target.value);
            handleSearch(e.target.value, 'name');
          }}
        />
        {loading ? <CircularProgress size={25} /> : null}
      </Box>
      <TableContainer className={classesTable.container} style={{ maxHeight: 'calc(100vh - 280px)' }}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Publishers.data?.publishers.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>{row.id}</TableCell>
                  <TableCell className={classesTable.row}>{row.name}</TableCell>
                  <TableCell className={classesTable.row}>{row.short_name}</TableCell>
                  <TableCell className={classesTable.row}>{row.short_name_genitive}</TableCell>
                  <TableCell className={classesTable.row}>{row.synonyms}</TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    {(row.docCount || 0) > 0 ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: ` <a href="/api/publishers/${row.id}/download-docs" target="_blank">${row.docCount}</a>`,
                        }}
                      />
                    ) : (
                      row.docCount
                    )}
                  </TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    {row.status ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell className={classesTable.row} align='center' style={{ color: colorsStatus[row.approved] }}>
                    {OfficialDoc.status.approved[row.approved]}
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton aria-label='edit' onClick={() => setEditData({ type: 'edit', data: row })}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classesTable.pagination}
        count={Publishers.data ? Publishers.data.pages : 0}
        page={Publishers.data ? Publishers.data.currentPage : 1}
        onChange={handleChangePage}
      />
    </>
  );
};
