import { Editor } from '@tinymce/tinymce-react';

export const editIframe = (editor: Editor['editor']) => () => {
  if (!editor) return null;

  const collections = ['iframeFirst', 'iframeSecond'];

  const initialData = {};

  const node = editor.selection.getNode();
  const blocks = node.querySelectorAll('.templates-iframe-item');

  const iframes = node.querySelectorAll('iframe');
  iframes.forEach((value, i) => {
    const key = collections[i];
    Object.assign(initialData, { [key]: value.src });
  });

  editor.windowManager.open({
    title: 'Редагувати iframe',
    initialData,
    onSubmit(api) {
      const data: Record<string, string> = api.getData();
      Object.values(data)
        .sort((a, b) => (a > b ? -1 : 1))
        .forEach((value, i) => {
          if (blocks[i] && value) {
            const iframe = document.createElement('iframe');
            iframe.src = value;
            blocks[i].innerHTML = iframe.outerHTML;
          } else if (blocks[i]) {
            blocks[i].innerHTML = '';
          }
        });
      api.close();
    },
    buttons: [
      {
        type: 'cancel',
        text: 'Cancel',
      },
      {
        type: 'submit',
        text: 'Save',
        primary: true,
      },
    ],
    body: {
      type: 'panel',
      items: [
        {
          type: 'input',
          name: 'iframeFirst',
          placeholder: 'URL iframe 1',
        },
        {
          type: 'input',
          name: 'iframeSecond',
          placeholder: 'URL iframe 2',
        },
      ],
    },
  });
};
