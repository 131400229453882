import { Reducer } from 'redux';
import { ActionTypes, TUserState } from './types';

export const initialState: TUserState = {
  loading: true,
  data: null,
  isAuth: false,
  errors: undefined,
};

const userReducer: Reducer<TUserState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_USER_R:
      return { ...state, loading: true, errors: undefined };
    case ActionTypes.SET_IS_AUTH:
      return { ...state, loading: false, errors: undefined, isAuth: action.payload };
    case ActionTypes.GET_USER_S:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: undefined,
      };
    case ActionTypes.GET_USER_E:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};

export { userReducer as UserReducer };
