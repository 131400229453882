import React from 'react';
import clsx from 'clsx';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { Styles } from './Styles';
import TextareaAutosize from '@mui/material/TextareaAutosize';

type PropsType = {
  title?: string;
  value?: string;
  required?: boolean;
  error?: boolean;
  style?: React.CSSProperties | undefined;
  className?: string;
  onChange: (value: string) => void;
};

export const TextareaOutline: React.FC<PropsType> = ({ style, className, title, required, error, value, onChange }) => {
  const [isFocus, setIsFocus] = React.useState(false);
  const { ref } = useOutsideClick(() => setIsFocus(false));
  const classes = Styles();

  const setClass = () => {
    if (isFocus) return classes.placeholderFocus;
    if (!!value) return classes.placeholderInit;
  };

  return (
    <div style={style} className={clsx(classes.root, className)} ref={ref}>
      {title && (
        <div className={clsx(classes.placeholder, setClass())} style={{ color: error && !isFocus ? 'red' : '' }}>
          {required ? `${title} *` : title}
        </div>
      )}
      <div
        className={clsx(classes.wrappField, isFocus && classes.wrappFieldFocus)}
        style={{ borderColor: error && !isFocus ? 'red' : '', padding: '8.5px 14px', height: '100%' }}
        onClick={() => setIsFocus(true)}
      >
        <TextareaAutosize
            className={classes.input}
            style={{ height: '20px' }}
            value={value} onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </div>
  );
};
