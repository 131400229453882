import React from 'react';
import { Box, Button } from '@mui/material';
import { setSideBar } from '../../store/sidebar/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { ListNormativka } from './ListNormativka';
import { BlocksList } from './Blocks/BlocksList';
import { WrapBindDoc } from './BindDoc';
import { Rubrics } from './Rubrics';
import { NormativkaUpdates } from './NormativkaUpdates';
import { GetBlock } from '../../store/officialDocBlocks/actions';
import { GetDocs, GetTypesDoc } from '../../store/officialDoc/actions';

type PropsType = {};

export const Normativka: React.FC<PropsType> = () => {
  const [tab, setTab] = React.useState(1);

  const { Sidebar, Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Configurations.siteId) {
      dispatch(GetBlock.request({ id: Configurations.siteId }));
      dispatch(GetDocs.request({ data: {} }));
      dispatch(GetTypesDoc.request({}));
    }
  }, [dispatch, Configurations.siteId]);

  const renderRoutePage = () => {
    if (tab === 1) {
      return <Rubrics level={1} />;
    }
    if (tab === 2) {
      return <WrapBindDoc />;
    }
    if (tab === 3) {
      return <ListNormativka />;
    }
    if (tab === 4) {
      return <BlocksList />;
    }
    if (tab === 5) {
      return <NormativkaUpdates />;
    }

    return null;
  };

  return (
    <>
      <Box>
        <Button
          style={{ margin: '0 10px 20px 0' }}
          variant={tab === 1 ? 'contained' : 'outlined'}
          color={tab === 1 ? 'primary' : 'inherit'}
          onClick={() => {
            setTab(1);
          }}
        >
          Рубрикатор
        </Button>
        <Button
          style={{ margin: '0 10px 20px' }}
          variant={tab === 2 ? 'contained' : 'outlined'}
          color={tab === 2 ? 'primary' : 'inherit'}
          onClick={() => {
            setTab(2);
            dispatch(setSideBar({ value: Sidebar.page.value, name: Sidebar.page.name, type: 'list' }));
          }}
        >
          Прив'язати документ
        </Button>
        <Button
          style={{ margin: '0 10px 20px' }}
          variant={tab === 3 ? 'contained' : 'outlined'}
          color={tab === 3 ? 'primary' : 'inherit'}
          onClick={() => {
            setTab(3);
            dispatch(setSideBar({ value: Sidebar.page.value, name: Sidebar.page.name, type: 'list' }));
          }}
        >
          Список документів
        </Button>
        <Button
          style={{ margin: '0 10px 20px' }}
          variant={tab === 4 ? 'contained' : 'outlined'}
          color={tab === 4 ? 'primary' : 'inherit'}
          onClick={() => {
            setTab(4);
            dispatch(setSideBar({ value: Sidebar.page.value, name: Sidebar.page.name, type: 'list' }));
          }}
        >
          Блоки
        </Button>
        <Button
          style={{ margin: '0 10px 20px' }}
          variant={tab === 5 ? 'contained' : 'outlined'}
          color={tab === 5 ? 'primary' : 'inherit'}
          onClick={() => {
            setTab(5);
            dispatch(setSideBar({ value: Sidebar.page.value, name: Sidebar.page.name, type: 'list' }));
          }}
        >
          Оновлення
        </Button>
      </Box>
      {renderRoutePage()}
    </>
  );
};
