import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TConsultationsState, IConsultation, IDocumentVisitor } from './types';
import { TypeSearchData } from '../../hooks/useSearchAny';

export const setConsultations = (payload: TConsultationsState['data']) =>
  action(ActionTypes.SET_CONSULTATIONS, payload);
export const resetConsultations = () => action(ActionTypes.RESET_CONSULTATIONS, {});

const GetConsultationsAction = new Actions('GET_CONSULTATIONS', ActionTypes);
export interface TypeGetConsultationsR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    limit?: number;
    publication_at?: Date | null;
    page?: number;
    author?: string;
    is_news?: 0 | 1;
  };
}
export const GetConsultations = {
  request: (payload: TypeGetConsultationsR) => GetConsultationsAction.request(payload),
  success: (payload: TConsultationsState['data']) => GetConsultationsAction.success(payload || {}),
  error: (message: string) => GetConsultationsAction.error(message),
};

const CheckDocumentVisitorAction = new Actions('CHECK_CONSULTATIONS_DOCUMENT_VISITOR', ActionTypes);

export interface TypeCheckDocumentVisitorR extends TDefRequest {
  id: number;
}

export const CheckConsultationVisitor = {
  request: (payload: TypeCheckDocumentVisitorR) => CheckDocumentVisitorAction.request(payload),
  success: (payload: IDocumentVisitor[]) => CheckDocumentVisitorAction.success(payload),
  error: (message: string) => CheckDocumentVisitorAction.error(message),
};

const DeleteDocumentVisitorAction = new Actions('DELETE_CONSULTATIONS_DOCUMENT_VISITOR', ActionTypes);

export interface TypeDeleteDocumentVisitorR extends TDefRequest {
  id?: number;
}

export const DeleteVisitors = {
  request: (payload?: TypeDeleteDocumentVisitorR) =>
    DeleteDocumentVisitorAction.request(payload || { callback: () => {}, id: 0 }),
  success: (payload: IDocumentVisitor) => DeleteDocumentVisitorAction.success(payload),
  error: (payload: string) => DeleteDocumentVisitorAction.error(payload),
};

const SearchConsultationsAction = new Actions('SEARCH_CONSULTATIONS', ActionTypes);
export interface TypeSearchConsultationsR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    limit?: number;
    page?: number;
    author: string;
    publication_at?: Date | null;
  };
}
export const SearchConsultations = {
  request: (payload: TypeSearchConsultationsR | TypeSearchData) => SearchConsultationsAction.request(payload),
  success: (payload: TConsultationsState['data']) => SearchConsultationsAction.success(payload || {}),
  error: (message: string) => SearchConsultationsAction.error(message),
};

const CreateConsultationAction = new Actions('CREATE_CONSULTATION', ActionTypes);
export interface TypeCreateConsultationR extends TDefRequest {
  data: {
    rubricId: number;
    siteId: number;
    authorsIds: number[];
    rubricsIds: number[];
    title: string;
    body: string;
    description?: string;
    publication_at?: Date | null;
    isPublic: number;
    searchTags: string;
    expire_at: Date | null;
    is_news: number;
    is_in_cart: number;
  };
}
export const CreateConsultation = {
  request: (payload: TypeCreateConsultationR) => CreateConsultationAction.request(payload),
  success: (payload: IConsultation) => CreateConsultationAction.success(payload || {}),
  error: (message: string) => CreateConsultationAction.error(message),
};

const UpdateConsultationAction = new Actions('UPDATE_CONSULTATION', ActionTypes);
export interface TypeUpdateConsultationR extends TDefRequest {
  data: {
    rubricId: number;
    siteId: number;
    authorsIds: number[];
    rubricsIds: number[];
    title: string;
    body: string;
    description?: string;
    isPublic: number;
    searchTags: string;
    publication_at?: Date | null;
    expire_at: Date | null;
    is_news: number;
    is_in_cart: number;
  };
  id: number;
}
export const UpdateConsultation = {
  request: (payload: TypeUpdateConsultationR) => UpdateConsultationAction.request(payload),
  success: (payload: IConsultation) => UpdateConsultationAction.success(payload || {}),
  error: (message: string) => UpdateConsultationAction.error(message),
};

const DeleteConsultationAction = new Actions('DELETE_CONSULTATION', ActionTypes);
export interface TypeDeleteConsultationR extends TDefRequest {
  id: number;
}
export const DeleteConsultation = {
  request: (payload: TypeDeleteConsultationR) => DeleteConsultationAction.request(payload),
  success: (payload: IConsultation) => DeleteConsultationAction.success(payload || {}),
  error: (message: string) => DeleteConsultationAction.error(message),
};

const GetConsultationAction = new Actions('GET_CONSULTATION', ActionTypes);
export interface TypeGetConsultationR extends TDefRequest {
  id: number;
}
export const GetConsultation = {
  request: (payload: TypeGetConsultationR) => GetConsultationAction.request(payload),
  success: (payload: IConsultation) => GetConsultationAction.success(payload || {}),
  error: (message: string) => GetConsultationAction.error(message),
};
