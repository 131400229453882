export const convertUrl = (url: string) => {
  const regexName = /\b(\w*\.expertus\.com\.ua)\/law\w*\b/g;
  const regexInt = /^\d+/g;
  if (url.match(regexName)) {
    url = url.replace(
      // eslint-disable-next-line
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?/gm,
      ''
    );
    url = url.replace(/\/laws\//gm, '/law/');
  } else if (url.match(regexInt)) {
    url = `/law/${url}`;
  }
  return url;
};
