export default function combinedSort<T extends { file_name?: string }>(inputArray: T[] | undefined | null) {
  if (!inputArray) return inputArray;

  function customSort(item: T) {
    const match = item.file_name?.match(/(\d+)|[^\d]+/g);
    if (match) {
      return match.map((part: any) => (isNaN(parseInt(part, 10)) ? part : parseInt(part, 10)));
    } else {
      return [item.file_name];
    }
  }

  const sortedArray = inputArray.sort((a, b) => {
    const aParts = customSort(a);
    const bParts = customSort(b);

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aPart = aParts[i] || 0;
      const bPart = bParts[i] || 0;

      if (typeof aPart === 'string' || typeof bPart === 'string') {
        // Сортування текстових частин
        const textComparison = String(aPart).localeCompare(String(bPart));
        if (textComparison !== 0) {
          return textComparison;
        }
      } else {
        // Сортування числових частин
        const numComparison = aPart - bPart;
        if (numComparison !== 0) {
          return numComparison;
        }
      }
    }

    return 0;
  });

  return sortedArray;
}
