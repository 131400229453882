import React, { useCallback } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { uuid } from './EditorDocum/entities/utils';
import { Alert, AlertTitle } from '@mui/material';
import { UnsavedFlags } from '../ConsultationForm';

interface EditorProps {
  editorRef?: React.MutableRefObject<any | null>;
  initialValue?: string;
  placeholder?: string;
  initialHeight?: number;
  calendarDescriptionEditor?: boolean;
  errors?: string;
  setErrors?: (mes: string) => void;
  setUnsavedChanges?: (verdict: any) => void;
  unsavedChanges?: UnsavedFlags;
}

export const EditorDefault: React.FC<EditorProps> = ({
  editorRef,
  placeholder,
  initialValue,
  initialHeight,
  calendarDescriptionEditor,
  errors,
  setUnsavedChanges,
  unsavedChanges,
  setErrors,
}) => {
  const onCnange = useCallback(
    (e: any) => {
      const html = editorRef?.current.getContent({ format: 'html' });
      setUnsavedChanges  && setUnsavedChanges({ ...unsavedChanges, unsavedDesc: html !== (initialValue || '') });
    },

    [initialValue, editorRef, setUnsavedChanges, unsavedChanges]
  );

  return (
    <>
      {placeholder && (
        <h3
          style={{
            margin: '0 0 5px',
            fontWeight: 400,
            lineHeight: '1.334',
            letterSpacing: '0em',
          }}
        >
          {placeholder}
        </h3>
      )}
      <Editor
        onInit={(evt, editor) => editorRef && (editorRef.current = editor)}
        onEditorChange={onCnange}
        apiKey='uzm54hzb90l1dcm92ia4tfjp6aj0krmgjak7eof1jhyd17pv'
        initialValue={initialValue}
        init={{
          convert_urls: true,
          link_context_toolbar: true,
          target_list: [
            { title: 'Нова сторінка', value: '_blank' },
            { title: 'Та сама сторінка', value: '_self' },
          ],
          link_default_protocol: 'https',
          urlconverter_callback: function (url, node, on_save, name) {
            const regexName = /\b(\w*\.expertus\.com\.ua)\/law\w*\b/g;
            const regexInt = /^\d+/g;
            if (url.match(regexName)) {
              url = url.replace(
                // eslint-disable-next-line
                /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?/gm,
                ''
              );
              url = url.replace(/\/laws\//gm, '/law/');
            } else if (url.match(regexInt)) {
              url = `/law/${url}`;
            }

            return url;
          },
          paste_preprocess: (plugin: any, args: any) => {
            const text = args.content.replaceAll('border: 1px solid initial;', 'border: 1px solid black;');

            const div = document.createElement('div');
            div.insertAdjacentHTML('afterbegin', text);

            div.querySelectorAll('*').forEach((el) => {
              if (['a'].includes(el.nodeName.toLowerCase())) {
                if (!el.classList.contains('link-download')) {
                  el.removeAttribute('class');
                }
                el.removeAttribute('style');
              } else {
                //@ts-ignore
                el?.style?.removeProperty('color');
                el.removeAttribute('class');
              }
            });

            div.querySelectorAll('p').forEach((value) => value.setAttribute('id', uuid()));
          },
          language: 'uk',
          height: initialHeight || 150,
          width: '900px',
          plugins: 'paste preview advcode link charmap',
          menubar: false,
          toolbar:
            'undo redo | code | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | forecolor backcolor | charmap | link',
          paste_as_text: true,
          quickbars_selection_toolbar: false,
          content_style: `
          body { 
            font-size: 14px; 
          }
          a {
            color: #0047b3;
            text-decoration: none;
            cursor: pointer;
            transition: color .3s,border-color .3s;
          }
          .link-download {
            color: #0047b3;
            text-decoration: none;
            cursor: pointer;
          }
          `,
          placeholder,
          setup: (editor) => {
            editor.on('keydown', (e) => {
              if (!calendarDescriptionEditor) return;
              const maxCharCount = 100;
              setErrors && setErrors('');
              const text = editor.getContent({ format: 'text' });

              if (text.length > maxCharCount && e.code !== 'Backspace') {
                e.preventDefault();
                return false;
              }
            });
            editor.on('paste', (e) => {
              if (!calendarDescriptionEditor) return;
              setErrors && setErrors('');
              const pastedContent = e?.clipboardData?.getData('text/plain');
              const maxCharCount = 100;
              if (pastedContent?.length && pastedContent?.length > maxCharCount) {
                setErrors && setErrors(`Максимальна кількість символів 100`);
                e.preventDefault();
                return false;
              }
            });
          },
        }}
      />
      {errors ? (
        <Alert severity='error' style={{ width: '100%' }}>
          <AlertTitle>{errors}</AlertTitle>
        </Alert>
      ) : null}
    </>
  );
};
