export interface TFormFilesState {
  data: IFormFile[];
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IFormFile {
  id: number;
  file_name: string;
  original_name: string;
  pdf: string;
  image: string;
  path: string;
  form_id: number;
  type: number;
  position: number;
  is_top: boolean;
  created_at: string;
}

enum ActionTypes {
  SET_FORM_FILE_DATA = '@@formFiles/SET_FORM_FILE_DATA',

  CREATE_FORM_FILE_R = '@@formFiles/CREATE_FORM_FILE_R',
  CREATE_FORM_FILE_S = '@@formFiles/CREATE_FORM_FILE_S',
  CREATE_FORM_FILE_E = '@@formFiles/CREATE_FORM_FILE_E',

  UPDATE_FORM_FILE_R = '@@formFiles/UPDATE_FORM_FILE_R',
  UPDATE_FORM_FILE_S = '@@formFiles/UPDATE_FORM_FILE_S',
  UPDATE_FORM_FILE_E = '@@formFiles/UPDATE_FORM_FILE_E',

  DELETE_FORM_FILE_R = '@@formFiles/DELETE_FORM_FILE_R',
  DELETE_FORM_FILE_S = '@@formFiles/DELETE_FORM_FILE_S',
  DELETE_FORM_FILE_E = '@@formFiles/DELETE_FORM_FILE_E',

  UPDATE_FORM_FILE_POSITIONS_R = '@@rubrics/UPDATE_FORM_FILE_POSITIONS_R',
  UPDATE_FORM_FILE_POSITIONS_S = '@@rubrics/UPDATE_FORM_FILE_POSITIONS_S',
  UPDATE_FORM_FILE_POSITIONS_E = '@@rubrics/UPDATE_FORM_FILE_POSITIONS_E',
}

export default ActionTypes;
