import { Editor } from '@tinymce/tinymce-react';
import { findNearestAncestorWithClassName } from './helpers/findNearestAncestorWithClassName';

export const deleteTemplate = (editor: Editor['editor'], callback: () => void) => () => {
  if (!editor) return null;

  const node = editor.selection.getNode();
  if (!node) return null;

  const parent = findNearestAncestorWithClassName(node, ['toggle', 'templates-2__global-wrapper']);
  if (!parent) return null;

  parent.remove();

  document.querySelector('.tox-pop')?.remove();

  callback();
};
