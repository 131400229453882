import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IDocumentVisitor } from '../../store/consultations/types';
import moment from 'moment';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface ComponentProps {
  visitors: IDocumentVisitor[] | null | undefined;
}

export const AlertModal: React.FC<ComponentProps> = ({ visitors }) => {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    if (visitors?.length) {
      setOpen(true);
    }
  }, [visitors]);

  if (!visitors?.length) return null;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Користувачів на сторінці {visitors?.length}
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            {visitors?.map((visitor) => (
              <div>
                {' '}
                <span>Документ редагує: {visitor.user.fio}</span> <span>{moment(visitor.visit_time).format('HH:mm:ss')}</span>
              </div>
            ))}
          </Typography>
          <Button
            sx={{ border: '1px solid #000' }}
            style={{ position: 'absolute', right: 10, bottom: 10 }}
            onClick={handleClose}
          >
            ОК
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
