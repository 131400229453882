import React from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useStyles } from './styles';

interface ConfirmModalProps {
  handleClose: () => void;
  handleConfirm: () => void;
  title: string;
  open: boolean;
  buttonSaveDisabled: boolean;
  children: React.ReactElement;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  buttonSaveDisabled,
  handleClose,
  handleConfirm,
  title,
  open,
  children,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <div className='boxModal-inner'>
        <div className='boxBody'>
          <h2>{title}</h2>
          {children}
          <div style={{ marginTop: '16px', textAlign: 'right' }}>
            <Button variant='contained' onClick={handleClose} sx={{ marginRight: 1 }}>
              Закрити
            </Button>
            <Button disabled={buttonSaveDisabled} variant='contained' color='primary' onClick={handleConfirm}>
              Підтвердити
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
