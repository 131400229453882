import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { AppStore } from '../../../../store/applicationState';
import { GetMainInMonthTemplate } from '../../../../store/mainInMonth/actions';

export default function List() {
  const { MainInMonth } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const { id } = useParams();

  React.useEffect(() => {
    if (id && !Number.isNaN(Number(id))) {
      dispatch(
        GetMainInMonthTemplate.request({
          id: Number(id),
        })
      );
    }
  }, [dispatch, id]);

  if (!MainInMonth.template) return null;

  if (MainInMonth.template.month_main_blocks && MainInMonth.template.month_main_blocks[0]) {
    const id = MainInMonth.template?.month_main_blocks[0].id;
    return <Navigate to={String(id)} replace />;
  }

  return <Navigate to={'new'} replace />;
}
