import React from 'react';
import { ConsultationForm } from '../../components/ConsultationForm';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { CreateConsultation, TypeCreateConsultationR } from '../../store/consultations/actions';
import { useDispatch } from 'react-redux';
import { ConfigurationsActions } from '../../store/configurations/actions';

export const CreateConsultations: React.FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const query = new URLSearchParams(search);
    const siteId = query.get('site_id');
    if (siteId) {
      dispatch(ConfigurationsActions.setSiteId(Number(siteId)));
    }
  }, [dispatch, search]);

  const handleCreate = (
    data: TypeCreateConsultationR['data'],
    callBack: (err?: { name: string; message: string }) => void
  ) => {
    dispatch(
      CreateConsultation.request({
        data,
        callBack: (success, dta) => {
          if (!success && dta) {
            if (dta.status === 409) callBack({ name: 'title', message: 'Такий заголовок вже використовується' });
            else callBack({ name: '1', message: 'Сталася помилка' });
          } else {
            callBack();
            navigate(ROUTES.routeChange(`list/${dta?.id || ''}`));
          }
        },
      })
    );
  };

  return (
    <ConsultationForm
      type='new'
      handleSubmit={handleCreate}
      handleCancel={() => navigate(ROUTES.routeChange('list'))}
    />
  );
};
