import React, { useState, useEffect, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppStore } from '../../store/applicationState';
import { DeleteDefinition, GetDefinitions, SearchDefinitions } from '../../store/definition/actions';
import { IDefinition } from '../../store/definition/types';
import { GetCart } from '../../store/cart/actions';
import { ROUTES } from '../../utils/routes';
import { StylesTable } from '../../components/Stylestable';
import { WarningModal } from '../../components/modals';
import { Pagination } from '@mui/material';
import { useSearchAny } from '../../hooks/useSearchAny';
import { getTextContent } from '../../utils/getTextContent';
interface Column {
  id: 'id' | 'title' | 'description' | 'redactor' | 'isPublic' | 'rubric' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '6%' },
  { id: 'title', label: 'Заголовок', align: 'left' },
  { id: 'description', label: 'Опис', align: 'center', width: '35%' },
  {
    id: 'redactor',
    label: 'Редактор',
    align: 'left',
  },
  {
    id: 'isPublic',
    label: 'Публікція',
    align: 'center',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '8%',
  },
];

type PropsType = {};

export const ListDefinitions: FC<PropsType> = () => {
  const [isModal, setIsModal] = useState<{ id: number } | null>(null);
  const [definitions, setDefinitions] = useState<IDefinition[] | null>(null);
  const { Definitions, Configurations } = useSelector((store: AppStore) => store);
  const [id, setId] = useState<string | null>('');
  const [title, setTitle] = useState('');
  const [pages, setPages] = useState(Definitions?.data?.pages || 1);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const classesTable: any = StylesTable();

  const { loading, handleSearch } = useSearchAny({
    SearchData: SearchDefinitions,
  });

  useEffect(() => {
    dispatch(
      GetDefinitions.request({
        data: {
          page,
          siteId: Configurations.siteId,
        },
        callBack: (success, resp) => {
          if (success) {
            setDefinitions(resp.definitions);
            setPages(resp.pages);
          }
        },
      })
    );
  }, [dispatch, Configurations, page]);

  useEffect(() => {
    setDefinitions(Definitions.definitions);
    setPages(Definitions.pages);
  }, [Definitions]);

  const resetState = () => {
    setTitle('');
    setId('');
    dispatch(
      GetDefinitions.request({
        data: {
          page: 1,
          siteId: Configurations.siteId,
        },
        callBack: (success, resp) => {
          if (success) {
            setDefinitions(resp.definitions);
            setPages(resp.pages);
          }
        },
      })
    );
  };

  const handleChangeRoute =
    (path = '' as string | number) =>
    () => {
      window.open(ROUTES.routeChange(`list/${path}?site_id=${Configurations.siteId}`));
    };

  const handleRemove = (id: number) => {
    dispatch(
      DeleteDefinition.request({
        id,
        callBack: (success) => {
          if (success) {
            dispatch(
              GetCart.request({
                data: {
                  siteId: Configurations.siteId,
                },
              })
            );
          }
        },
      })
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

 

  return (
    <>
      <Box display={'flex'} alignItems={'center'} gap={2}>
        <Button variant='contained' color='primary' onClick={handleChangeRoute('new')}>
          Створити Визначення
        </Button>
        <TextField
          size='small'
          id='outlined-basic-id'
          label='Пошук по ID'
          variant='outlined'
          value={id}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const data: any = {};
            if (page) data['page'] = 1;
            if (Configurations) data['siteId'] = Configurations.siteId;
            if (title) data['title'] = title;
            handleSearch(event.target.value as string, 'id', data);
            setId(event.target.value as string);
          }}
        />
        <TextField
          size='small'
          id='outlined-basic-title'
          label='Пошук по заголовку'
          variant='outlined'
          value={title}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const data: any = {};
            if (Configurations) data['siteId'] = Configurations.siteId;
            if (page) data['page'] = 1;
            handleSearch(event.target.value as string, 'title', data);
            setTitle(event.target.value as string);
          }}
        />

        <Button variant='contained' color='primary' onClick={resetState}>
          {loading ? <CircularProgress color='inherit' size={25} /> : 'Очистити фільтри'}
        </Button>
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {definitions?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>
                    <Link sx={{ cursor: 'pointer' }} onClick={handleChangeRoute(row.id)}>
                      {row.id}
                    </Link>
                  </TableCell>
                  <TableCell className={classesTable.row}>{row.title}</TableCell>
                  <TableCell className={classesTable.row}>{getTextContent(row.description)}</TableCell>
                  <TableCell className={classesTable.row}>{row.redactor || ''}</TableCell>
                  <TableCell align='center' className={classesTable.row}>
                    {row.is_public ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton aria-label='delete' onClick={() => setIsModal({ id: row.id })}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {definitions?.length ? (
        <Pagination className={classesTable.pagination} count={pages} page={page} onChange={handleChangePage} />
      ) : null}

      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
