import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { StylesTable } from '../Stylestable';
import { GetRedactions } from '../../store/officialDoc/actions';
import moment from 'moment';
import { StatusNPD, colorsStatus } from '../../utils/consts';
import { useParams } from 'react-router-dom';
import { CreateOfficialDocDrafts } from '../../store/officialDocDrafts/actions';
import CircularProgress from '@mui/material/CircularProgress';

interface Column {
  id: 'id' | 'name' | 'dataStart' | 'approved' | 'update' | 'dataEnd' | 'status' | 'copy' | 'edit';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '5%' },
  { id: 'dataStart', label: 'Дата старту', align: 'left', width: '15%' },
  { id: 'dataEnd', label: 'Дата закінчення', align: 'left', width: '15%' },
  { id: 'status', label: 'Юр. Статус', align: 'left', width: '12%' },
  { id: 'approved', label: 'Статус', align: 'left', width: '10%' },
  { id: 'name', label: 'Назва', align: 'left' },
  {
    id: 'copy',
    label: 'Чернетка',
    align: 'center',
    width: '9%',
  },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '9%',
  },
];

type PropsType = {};

export const RedactionsList: React.FC<PropsType> = () => {
  const { OfficialDocDrafts, OfficialDoc } = useSelector((store: AppStore) => store);
  const { group_id } = useParams();
  const dispatch = useDispatch();
  const classesTable = StylesTable();

  React.useEffect(() => {
    if (group_id && !Number.isNaN(Number(group_id))) {
      dispatch(GetRedactions.request({ groupId: Number(group_id) }));
    }
  }, [dispatch, group_id]);

  const handleCreateDrafts = (docId: number) => () => {
    if (OfficialDocDrafts.loading) return null;

    dispatch(
      CreateOfficialDocDrafts.request({
        data: { doc_id: docId },
        callBack: () => {
          if (group_id && !Number.isNaN(Number(group_id))) {
            dispatch(GetRedactions.request({ groupId: Number(group_id) }));
          }
        },
      })
    );
  };

  return (
    <TableContainer className={classesTable.container} style={{ maxHeight: 'calc(100vh - 240px)' }}>
      <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              if (
                (column.id === 'copy' && OfficialDoc.redactions?.length !== 1) ||
                (column.id === 'copy' &&
                  OfficialDoc.redactions &&
                  OfficialDoc.redactions[0].status !== StatusNPD.VALID) ||
                (column.id === 'copy' && OfficialDoc.redactions && OfficialDoc.redactions[0].is_draft)
              )
                return null;
              return (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {OfficialDoc.redactions?.map((row, i) => {
            return (
              <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                <TableCell align='center'>{row.id}</TableCell>
                <TableCell className={classesTable.row}>
                  {row.start_date ? moment(row.start_date).format('DD.MM.YYYY HH:mm') : ''}
                </TableCell>
                <TableCell className={classesTable.row}>
                  {row.end_date ? moment(row.end_date).format('DD.MM.YYYY HH:mm') : ''}
                </TableCell>
                <TableCell className={classesTable.row}>{OfficialDoc.status.lowStatus[row.status]}</TableCell>
                <TableCell className={classesTable.row} style={{ color: colorsStatus[row.approved] }}>
                  {OfficialDoc.status.approved[row.approved]}
                </TableCell>
                <TableCell className={classesTable.row}>{row.title}</TableCell>
                {!row.is_draft && OfficialDoc.redactions?.length === 1 && row.status === StatusNPD.VALID ? (
                  <TableCell align='center'>
                    <IconButton disabled={OfficialDocDrafts.loading} onClick={handleCreateDrafts(row.id)}>
                      {OfficialDocDrafts.loading ? <CircularProgress size={25} /> : <ContentCopyIcon />}
                    </IconButton>
                  </TableCell>
                ) : null}
                <TableCell align='center'>
                  <IconButton
                    aria-label='edit'
                    onClick={() => {
                      window.open(`/document/${row.id}`);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
