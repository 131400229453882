import Modal from '@mui/material/Modal';
import React from 'react';
import Button from '@mui/material/Button';
import './BoxModal.scss';
import { useStyles } from './styles';
import { UploadFile } from '../ui';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { callApi } from '../../utils/callApi';

interface Props {
  open: boolean;
  value?: string | null;
  handleChange: (value: string) => void;
  handleClose: () => void;
}

export const FileModal: React.FC<Props> = ({ open, value, handleClose, handleChange }) => {
  const { Configurations } = useSelector((store: AppStore) => store);
  const classes = useStyles();

  const handleChangeImg = (file: string | File) => {
    if (typeof file === 'string') return null;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('siteId', String(Configurations.siteId));
    callApi({
      method: 'post',
      data: formData,
      isFormData: true,
      path: '/upload/file',
    })
      .then((res) => handleChange(res))
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <div className='boxModal-inner'>
        <div style={{ padding: '0 20px', width: '700px' }}>
          <h2>Завантажити картинку</h2>
          <UploadFile
            style={{ width: '500px' }}
            isImg
            accept='image/*'
            id='img-file'
            title='Зображення'
            value={value || ''}
            className={classes.textField}
            onChange={handleChangeImg}
            hint='Ширина 576px; Висота 300px'
          />
        </div>
        <div className='boxFooter'>
          <Button variant='contained' onClick={handleClose}>
            Закрити
          </Button>
        </div>
      </div>
    </Modal>
  );
};
