import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { StatusNPD } from '../../utils/consts';

type PropsType = {
  element: React.ReactElement;
};

export const RedactionWorckSpace: React.FC<PropsType> = ({ element }) => {
  const [isDraft, setIsDraft] = React.useState(false);
  const { OfficialDoc } = useSelector((store: AppStore) => store);
  const { group_id } = useParams();
  const navigate = useNavigate();

  const isDrafts = window.location.href.includes(ROUTES.drafts);

  React.useEffect(() => {
    if (OfficialDoc.redactions) {
      const redaction = OfficialDoc.redactions.find((item) => !!item.is_draft);
      setIsDraft(!!redaction);
    }
  }, [OfficialDoc.redactions]);

  return (
    <>
      <Box display={'flex'} gap={2}>
        <Button
          variant='outlined'
          color='inherit'
          onClick={() => {
            navigate('/');
          }}
        >
          Повернутися
        </Button>
        <Button
          variant={!isDrafts ? 'contained' : 'outlined'}
          color={!isDrafts ? 'primary' : 'inherit'}
          onClick={() => {
            navigate(`${ROUTES.documents}/${group_id}`);
          }}
        >
          Редакції
        </Button>
        <Button
          style={{
            display:
              OfficialDoc.redactions?.length === 1 && OfficialDoc.redactions[0].status === StatusNPD.VALID
                ? 'block'
                : 'none',
          }}
          variant={isDrafts ? 'contained' : 'outlined'}
          color={isDrafts ? 'primary' : isDraft ? 'warning' : 'inherit'}
          onClick={() => {
            navigate(`${ROUTES.documents}/${group_id}/${ROUTES.drafts}`);
          }}
        >
          Чернетка
        </Button>
      </Box>
      {element}
    </>
  );
};
