import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { IAuthor } from '../../store/authors/types';
import { UpdateAuthor, CreateAuthor } from '../../store/authors/actions';
import { UploadFile } from '../ui';
import { Alert, AlertTitle } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { AppStore } from '../../store/applicationState';
import { StylesEdit } from '../StylesEdit';

interface PropsType {
  type: 'edit' | 'new';
  data?: IAuthor | null;
  handleCancel: () => void;
}

export const EditAuthor: React.FC<PropsType> = ({ type, data, handleCancel }) => {
  const [fio, setFio] = React.useState<string>(data?.fio || '');
  const [position, setPosition] = React.useState<string>(data?.position || '');
  const [city, setCity] = React.useState<string>(data?.city || '');
  const [photo, setPhoto] = React.useState<File | string>(data?.photo || '');
  const [photoForVideo, setPhotoForVideo] = React.useState<File | string>(data?.photoForVideo || '');
  const [error, setError] = React.useState(false);

  const { Authors } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classes = StylesEdit();

  const onSubmit = () => {
    const callBack = (value: boolean) => {
      if (value) {
        handleCancel();
      }
    };
    if (!fio) return setError(true);
    setError(false);
    const fd = new FormData();
    fd.append('fio', fio);
    fd.append('position', position);
    fd.append('city', city);
    fd.append('photo', photo);
    fd.append('photoForVideo', photoForVideo);

    if (type === 'new') {
      dispatch(
        CreateAuthor.request({
          data: fd,
          callBack,
        })
      );
    }
    if (data && type === 'edit') {
      dispatch(
        UpdateAuthor.request({
          data: fd,
          id: data.id,
          callBack,
        })
      );
    }
  };

  return (
    <Box display='flex'>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component='h1' variant='h5'>
          {type === 'new' ? 'Створити автора' : 'Редагувати автора'}
        </Typography>
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='ПІБ'
          variant='outlined'
          value={fio}
          error={error && !fio}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setFio(event.target.value as string);
          }}
        />
        <UploadFile
          isImg
          accept='image/*'
          id='photo'
          title='Фото'
          value={photo}
          className={classes.textField}
          onChange={setPhoto}
          hint='Ширина: 107px, Висота: 132px'
        />
        <UploadFile
          isImg
          accept='image/*'
          id='photoForVideo'
          title='Фото для відео'
          value={photoForVideo}
          className={classes.textField}
          onChange={setPhotoForVideo}
          hint='Ширина: 560px, Висота: 320px'
        />
        <TextField
          size='small'
          id='outlined-basic'
          label='Посада'
          variant='outlined'
          value={position}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setPosition(event.target.value as string);
          }}
        />
        <TextField
          size='small'
          id='outlined-basic'
          label='Місто'
          variant='outlined'
          value={city}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setCity(event.target.value as string);
          }}
        />
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={Authors.loading} onClick={onSubmit}>
            {Authors.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {Authors.errors ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>Сталася помилка</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
