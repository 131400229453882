import React from 'react';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { Alert, AlertTitle } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { StylesEdit } from '../StylesEdit';
import { IAdditionalWidgets } from '../../store/widgets/types';
import { CreateAdditionalWidget, UpdateAdditionalWidget } from '../../store/widgets/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';

interface PropsType {
  type: 'edit' | 'new';
  data?: IAdditionalWidgets | null;
  handleCancel: () => void;
}

export const EditUniversalWidgets: React.FC<PropsType> = ({ data, handleCancel, type }) => {
  const [error, setError] = React.useState(false);
  const [title, setTitle] = React.useState(type === 'edit' ? data?.title || '' : '');
  const [url, setUrl] = React.useState(type === 'edit' ? data?.url || '' : '');
  const {  Configurations } = useSelector((store: AppStore) => store);

  const dispatch = useDispatch();
  const classes = StylesEdit();
  const onSubmit = () => {
    setError(false);
    if (type === 'edit') {
      if (!data || !data.title ) return setError(true);
      dispatch(
        UpdateAdditionalWidget.request({
          data: {
            ...data,
            title: title || data.title,
            url: url || data.url,
            site_id: Configurations.siteId,
          },
          id: data.id,
          callBack: (success) => {
            if (success) return handleCancel();
            return setError(true);
          },
        })
      );
    } else {
      dispatch(CreateAdditionalWidget.request({
        data:{
          title,
          url,
          site_id: Configurations.siteId,
        },
        callBack: (success) => {
          if (success) return handleCancel();
          return setError(true);
        },
      }))
    }
  };

  return (
    <Box display='flex'>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component='h1' variant='h5'>
          {type === 'new' ? 'Створити' : data?.title}
        </Typography>
        <TextField
         inputProps={{ maxLength: 25 }} 
          required
          size='small'
          id='outlined-basic' 
          label='Заголовок'
          variant='outlined'
          value={title}
          error={false}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setTitle(event.target.value);
          }}
        />
        <TextField
          size='small'
          id='outlined-basic'
          label='Посилання'
          variant='outlined'
          value={url}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setUrl(event.target.value);
          }}
        />
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={false} onClick={onSubmit}>
            {false ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {false ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>Сталася помилка</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
