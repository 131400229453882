import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TDefinitionsState, IDefinition } from './types';
import { TypeSearchData } from '../../hooks/useSearchAny';

export const setDefinitions = (payload: TDefinitionsState['data']) => action(ActionTypes.SET_DEFINITIONS, payload);
export const resetDefinitions = () => action(ActionTypes.RESET_DEFINITIONS, {});

const GetDefinitionsAction = new Actions('GET_DEFINITIONS', ActionTypes);

export interface TypeGetDefinitionsR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    limit?: number;
    page: number;
  };
}
export const GetDefinitions = {
  request: (payload: TypeGetDefinitionsR) => GetDefinitionsAction.request(payload),
  success: (payload: TDefinitionsState['data']) => GetDefinitionsAction.success(payload || {}),
  error: (message: string) => GetDefinitionsAction.error(message),
};

const SearchDefinitionsAction = new Actions('SEARCH_DEFINITIONS', ActionTypes);

export interface TypeSearchDefinitionsR extends TDefRequest {
  data: {
    site_id: number;
    id?: number;
    title?: string;
    limit?: number;
  };
}
export const SearchDefinitions = {
  request: (payload: TypeSearchData) => SearchDefinitionsAction.request(payload),
  success: (payload: IDefinition[] | {}) => SearchDefinitionsAction.success(payload),
  error: (message: string) => SearchDefinitionsAction.error(message),
};

const CreateDefinitionAction = new Actions('CREATE_DEFINITION', ActionTypes);

export interface TypeCreateDefinitionR extends TDefRequest {
  data: {
    site_id: number;
    title: string;
    description?: string;
    is_public: number;
  };
}

export const CreateDefinition = {
  request: (payload: TypeCreateDefinitionR) => CreateDefinitionAction.request(payload),
  success: (payload: IDefinition) => CreateDefinitionAction.success(payload || {}),
  error: (message: string) => CreateDefinitionAction.error(message),
};

const UpdateDefinitionAction = new Actions('UPDATE_DEFINITION', ActionTypes);

export interface TypeUpdateDefinitionR extends TDefRequest {
  data: {
    site_id: number;
    title?: string;
    description?: string;
    is_public?: number;
  };
  id: number;
}

export const UpdateDefinition = {
  request: (payload: TypeUpdateDefinitionR) => UpdateDefinitionAction.request(payload),
  success: (payload: IDefinition) => UpdateDefinitionAction.success(payload || {}),
  error: (message: string) => UpdateDefinitionAction.error(message),
};

const DeleteDefinitionAction = new Actions('DELETE_DEFINITION', ActionTypes);
export interface TypeDeleteDefinitionR extends TDefRequest {
  id: number;
}
export const DeleteDefinition = {
  request: (payload: TypeDeleteDefinitionR) => DeleteDefinitionAction.request(payload),
  success: (payload: number) => DeleteDefinitionAction.success(payload || {}),
  error: (message: string) => DeleteDefinitionAction.error(message),
};

const GetDefinitionAction = new Actions('GET_DEFINITION', ActionTypes);
export interface TypeGetDefinitionR extends TDefRequest {
  id: number;
}
export const GetDefinition = {
  request: (payload: TypeGetDefinitionR) => GetDefinitionAction.request(payload),
  success: (payload: IDefinition) => GetDefinitionAction.success(payload || {}),
  error: (message: string) => GetDefinitionAction.error(message),
};
