import { Editor } from '@tinymce/tinymce-react';

const getTable = (el: HTMLElement | null, level = 1): HTMLElement | null => {
  if (!el || level > 5) return null;
  if (el.nodeName.toLowerCase() === 'table') return el;
  return getTable(el.parentElement, level + 1);
};

const changePositionTable = (editor: Editor['editor'], alignment: 'left' | 'right') => () => {
  if (!editor) return null;

  const node = editor.selection.getNode();
  const table = getTable(node.parentElement);
  if (table) {
    const value = alignment === 'right' ? 'auto 0px auto auto' : 'auto auto auto 0px';
    editor.$(table).removeAttr('data-mce-style');
    editor.$(table).css('margin', value);
  }
};

export { changePositionTable };
