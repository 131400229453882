import { Editor } from '@tinymce/tinymce-react';

export const handlerPointsLiveSample = (editor: Editor['editor'] | null) => {
  if (!editor) return null;
  let popup: HTMLDivElement | null = null;

  editor.$('.templates-live-sample-item').each((i, node) => {
    editor.$(node).off('mouseenter');
    editor.$(node).on('mouseenter', (e) => {
      if (!e.target) return null;

      popup = document.createElement('div');
      popup.textContent = node.childNodes[1]?.textContent || '';
      popup.classList.add('templates-live-sample-item-description');
      popup.style.display = 'block';
      node.parentElement?.appendChild(popup);

      let x = e.target.offsetLeft + e.target.clientWidth;
      let y = e.target.offsetTop + e.target.clientHeight;

      const centerX = e.target.parentElement ? e.target.parentElement?.clientWidth / 2 : 0;
      const centerY = e.target.parentElement ? e.target.parentElement?.clientHeight / 2 : 0;

      if (e.target.offsetLeft > centerX) {
        x = e.target.offsetLeft - popup.clientWidth;
      }
      if (e.target.offsetTop > centerY) {
        y = e.target.offsetTop - popup.clientHeight;
      }

      popup.style.top = `${y}px`;
      popup.style.left = `${x}px`;
    });

    editor.$(node).off('mouseleave');
    editor.$(node).on('mouseleave', () => {
      if (popup) {
        popup.remove();
      }
    });
  });
};
