import Modal from '@mui/material/Modal';
import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import './BoxModal.scss';
import { useStyles } from './styles';
import { SelectSearchReq } from '../ui';
import { useSearchDefinitions } from '../../hooks/useSearchDefinitions';
import { useDispatch, useSelector } from 'react-redux';
import { GetDefinition, GetDefinitions } from '../../store/definition/actions';
import { AppStore } from '../../store/applicationState';
import { getTextContent } from '../../utils/getTextContent';

interface BoxModalProps {
  title: string;
  open: boolean;
  data?: { wordName?: string; title?: string; description?: string; id?: number | string; type: 'new' | 'edit' } | null;
  handleClose: () => void;
  handleConfirm: (value: {
    title?: string;
    description?: string;
    id?: number | string;
    type?: 'new' | 'edit' | 'delete';
    prevId?: number | string;
    wordName?: string;
  }) => void;
  type: 'edit' | 'new';
}

export const DefinitionModal: React.FC<BoxModalProps> = ({ title, open, data, handleClose, handleConfirm, type }) => {
  const classes: any = useStyles();
  const [definition, setDefinition] = React.useState<{
    title?: string;
    description?: string;
    id?: number | string;
    wordName?: string;
  } | null>({ wordName: data?.wordName });
  const [error, setError] = React.useState(false);
  const { options, handleSearchDefinitions } = useSearchDefinitions();
  const { Configurations, Definitions } = useSelector((state: AppStore) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      GetDefinitions.request({
        data: {
          page: 1,
          siteId: Configurations.siteId,
        },
      })
    );
  }, [Configurations, dispatch]);

  useEffect(() => {
    if (type === 'edit') {
      if (data?.id) {
        dispatch(
          GetDefinition.request({
            id: +String(data.id).split('.')[0],
            callBack: (s, res) => {
              setDefinition({ wordName: data?.wordName, title: res?.title, description: res?.description, id: res.id });
            },
          })
        );
      }
    }
  }, [data, dispatch, type]);

  useEffect(() => {
    setDefinition({
      ...definition,
      wordName: data?.wordName,
      description: type === 'new' ? '' : data?.description,
      title: type === 'new' ? '' : data?.title,
    });
    // eslint-disable-next-line
  }, [data?.wordName]);

  useEffect(() => {
    if (!definition?.title?.trim()) setError(true);
    if (!definition?.title?.trim()) setError(true);
    else if (definition?.title?.trim()) setError(false);
  }, [definition]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <div className='boxModal-inner'>
        <div className='boxBody'>
          <h2>{title}</h2>
          <div style={{ gap: '10px', flexDirection: 'column' }} className={classes.modal}>
            <TextField
              style={{ width: '80%' }}
              label='Назва'
              placeholder='Назва'
              fullWidth
              margin='normal'
              multiline
              value={definition?.wordName?.trimLeft()}
              onChange={(e) => {
                setDefinition({ ...definition, wordName: e.target.value.replaceAll('í', '') });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <SelectSearchReq
              title='Визначення'
              style={{ width: '80%', margin: '0 1rem' }}
              options={options}
              handleRequest={handleSearchDefinitions}
              onChange={(value) => {
                const desc = Definitions.definitions?.find((el) => el.id === value?.id);
                setDefinition({
                  ...definition,
                  title: desc?.title,
                  description: desc?.description,
                  id: `${value?.id}`,
                });
              }}
              value={definition?.title}
            />
            <TextField
              style={{ width: '80%', height: '100px', marginBottom: '30px' }}
              label='Опис:'
              disabled
              placeholder='Опис'
              fullWidth
              margin='normal'
              multiline
              value={getTextContent(definition?.description)}
              onChange={(e) => {}}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div
              onClick={() => {
                window.open('/definition/list/' + String(definition?.id).split('.')[0]);
              }}
              style={{
                position: 'absolute',
                right: 10,
                bottom: 60,
                color: 'blue',
                cursor: 'pointer',
                display: definition?.id ? 'block' : 'none',
              }}
            >
              Редагувати визначення &gt;&gt;
            </div>
          </div>
        </div>
        <div className='boxFooter'>
          <div style={{ display: 'flex' }}>
            <Button
              color='primary'
              variant='contained'
              disabled={!definition?.title?.trim().length}
              onClick={() => {
                if (!error && definition?.title) {
                  handleConfirm({ ...definition, type, prevId: data?.id, wordName: definition.wordName?.trim() });
                  setError(false);
                }
              }}
            >
              підтвердити
            </Button>
          </div>
          {type === 'new' ? null : (
            <Button
              variant='contained'
              color='error'
              onClick={() => {
                handleConfirm({ ...definition, prevId: data?.id, type: 'delete' });
                handleClose();
              }}
              style={{ minWidth: '118px', height: '36px' }}
            >
              видалити
            </Button>
          )}
          <Button
            variant='contained'
            color='inherit'
            onClick={() => {
              handleClose();
            }}
            style={{ minWidth: '118px', height: '36px' }}
          >
            скасувати
          </Button>
        </div>
      </div>
    </Modal>
  );
};
