import { TAuthorsState } from '../authors/types';

export interface TConfigurationsState {
  configurations: IConfigurations | null;
  sites: ISites[] | null;
  siteId: number;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IConfigurations {
  viewsBlocks: IRespViewBlock[];
  authors: TAuthorsState['data'];
}

export interface ISites {
  id: number;
  name: string;
}

export interface IViewBlock {
  id: number;
  mainId: number;
  name: string;
  link: string;
  contentType: number;
}

export interface IRespViewBlock {
  id: number;
  name: string;
  children: IViewBlock[];
}

enum ActionTypes {
  SET_SITE_ID = '@@configurations/SET_SITE_ID',

  GET_SITES_R = '@@configurations/GET_SITES_R',
  GET_SITES_S = '@@configurations/GET_SITES_S',
  GET_SITES_E = '@@configurations/GET_SITES_E',

  GET_CONFIGURATIONS_R = '@@configurations/GET_CONFIGURATIONS_R',
  GET_CONFIGURATIONS_S = '@@configurations/GET_CONFIGURATIONS_S',
  GET_CONFIGURATIONS_E = '@@configurations/GET_CONFIGURATIONS_E',
}

export default ActionTypes;
