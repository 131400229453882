import { Editor } from '@tinymce/tinymce-react';

export const editFileName = (editor: Editor['editor']) => () => {
  if (!editor) return null;

  const node = editor.$('a[data-mce-selected="inline-boundary"]')[0];
  if (!node || node.nodeName.toLowerCase() !== 'a') return null;

  editor.windowManager.open({
    title: 'Редагувати назву',
    initialData: {
      fileName: node.textContent,
    },
    onSubmit(api) {
      const data = api.getData();

      if (data.fileName) {
        editor.$(node).attr('download', decodeURI(`${data.fileName}.pptx`));
        node.textContent = data.fileName;
      }

      api.close();
    },
    buttons: [
      {
        type: 'cancel',
        text: 'Cancel',
      },
      {
        type: 'submit',
        text: 'Save',
        primary: true,
      },
    ],
    body: {
      type: 'panel',
      items: [
        {
          type: 'input',
          name: 'fileName',
          label: 'Введіть назву',
          placeholder: 'Введіть назву',
        },
      ],
    },
  });
};
