import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { GetBlocks } from '../../store/blocks/actions';
import { GetMainBlocks } from '../../store/main/actions';
import { AppStore } from '../../store/applicationState';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ConfigurationsActions } from '../../store/configurations/actions';
import { GetDefinition, TypeUpdateDefinitionR, UpdateDefinition } from '../../store/definition/actions';
import { DefinitionForm } from '../../components/DefinitionForm';

export const EditDefinition: React.FC = () => {
  const { Definitions } = useSelector((store: AppStore) => store);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (id) {
      dispatch(GetDefinition.request({ id: Number(id) }));
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    if (Definitions.current) {
      dispatch(ConfigurationsActions.setSiteId(Definitions.current.site_id));
    }
  }, [dispatch, Definitions]);

  const handleUpdate = (
    data: TypeUpdateDefinitionR['data'],
    callBack: (err?: { name: string; message: string }) => void
  ) => {
    if (!Definitions.current) return null;

    dispatch(
      UpdateDefinition.request({
        data,
        id: Definitions.current.id,
        callBack: (success, dta) => {
          if (!success && dta) {
            if (dta.status === 409) callBack({ name: 'title', message: 'Такий заголовок вже використовується' });
            else callBack({ name: '1', message: 'Сталася помилка' });
          } else {
            callBack();
          }
          if (success && Definitions.current?.is_public === 1 && data.is_public === 0) {
            dispatch(GetBlocks.request({ id: Definitions.current.site_id }));
            dispatch(GetMainBlocks.request({ id: Definitions.current.site_id }));
          }
        },
      })
    );
  };

  if (!Definitions.current)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );

  return (
    <DefinitionForm
      type='edit'
      data={Definitions.current}
      handleSubmit={handleUpdate}
      handleCancel={() => navigate(ROUTES.routeChange('list'))}
    />
  );
};
