import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { StylesTable } from '../Stylestable';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Pagination from '@mui/material/Pagination';
import { ROW_PAGE } from '../../utils/consts';
import { WarningModal } from '../modals';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { IBlock } from '../../store/blocks/types';
import {
  DeleteSelection,
  GetSelections,
  UpdateSelection,
  UpdateSelectionPosition,
} from '../../store/selections/actions';
import { EditCataloguesSelections } from './EditSelections';

interface Column {
  id: 'id' | 'name' | 'adate' | 'isPublic' | 'edit' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: '№', align: 'center', width: '3%' },
  { id: 'name', label: 'Назва', align: 'left', width: '10%' },

  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '5%',
  },
  { id: 'isPublic', label: 'Публікція', align: 'center', width: '5%' },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '5%',
  },
];

type PropsType = {};

export const CataloguesSelections: React.FC<PropsType> = () => {
  const [page, setPage] = React.useState(1);
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const [editData, setEditData] = React.useState<{ type: 'edit' | 'new'; data?: IBlock | null } | null>(null);
  const { Selections, Sidebar, Configurations } = useSelector((store: AppStore) => store);
  const [selections, setSelections] = useState(
    [...(Selections?.selections?.slice((page - 1) * ROW_PAGE, page * ROW_PAGE) || [])].sort((a: any, b: any) => {
      return Number(a.position) - Number(b.position);
    })
  );
  const dispatch = useDispatch();
  const classes = StylesTable();

  React.useEffect(() => {
    if (Configurations.siteId) {
      dispatch(GetSelections.request({ id: Configurations.siteId }));
    }
  }, [dispatch, Configurations.siteId]);

  React.useEffect(() => {
    if (Sidebar.page.type === 'main' || Sidebar.page.type === 'list') {
      setEditData(null);
    }
  }, [Sidebar.page]);

  useEffect(() => {
    setSelections(
      [...(Selections?.selections?.slice((page - 1) * ROW_PAGE, page * ROW_PAGE) || [])].sort((a: any, b: any) => {
        return Number(a.position) - Number(b.position);
      })
    );
  }, [Selections, page]);

  const handleRemove = (id: number) => {
    dispatch(DeleteSelection.request({ id }));
  };

  const onPublic = (id: number, value: number) => {
    dispatch(
      UpdateSelection.request({
        data: {
          isPublic: value ? 0 : 1,
        },
        id,
      })
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onDragEnd = (result: DropResult, event: any) => {
    if (!result.destination) {
      return;
    }

    const item = selections[result.source.index];
    const payload = [
      { id: result.draggableId, position: result.destination.index },
      { id: selections[result.destination.index]?.id, position: result.source.index },
    ];

    selections.splice(result.source.index, 1);
    selections.splice(result.destination.index, 0, item);
    if (item.siteId) {
      setSelections(selections);

      dispatch(
        UpdateSelectionPosition.request({
          data: {
            positions: payload,
            site_id: item.siteId,
          },
        })
      );
    }
  };

  if (editData) {
    return (
      <EditCataloguesSelections type={editData.type} data={editData.data} handleCancel={() => setEditData(null)} />
    );
  }

  return (
    <>
      <Box style={{ marginBottom: '20px' }}>
        <Box>
          <Button
            style={{ margin: '0 0 10px 0' }}
            variant='contained'
            color='primary'
            onClick={() => {
              setEditData({ type: 'new' });
            }}
          >
            Створити добірку
          </Button>
        </Box>
      </Box>
      <TableContainer className={classes.container}>
        <Table className={classes.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='documents-list'>
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps} style={{ width: '100%' }}>
                  {selections
                    .sort((a: any, b: any) => {
                      return Number(a.position) - Number(b.position);
                    })
                    .map((row, i) => {
                      if (row.name === 'Довідники') {
                        return (
                          <TableRow key={`${i}-${row.id}`}>
                            <TableCell align='center'>{row.id}</TableCell>
                            <TableCell className={classes.row}>{row.name || ''}</TableCell>
                            <TableCell align='center'>
                              <IconButton aria-label='edit' onClick={() => setEditData({ type: 'edit', data: row })}>
                                <EditIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell align='left' className={classes.row}></TableCell>
                            <TableCell align='left' className={classes.row}></TableCell>
                          </TableRow>
                        );
                      }
                      return (
                        <Draggable draggableId={`${row.id}`} index={i} key={`${i}-${row.id}`}>
                          {(provided, snapshot) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                cursor: 'grab',
                                backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white',
                                ...provided.draggableProps.style,
                              }}
                              hover
                              key={`${i}-${row.id}`}
                            >
                              <TableCell align='center'>{row.id}</TableCell>
                              <TableCell className={classes.row}>{row.name || ''}</TableCell>
                              <TableCell align='center'>
                                <IconButton aria-label='edit' onClick={() => setEditData({ type: 'edit', data: row })}>
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell align='center' className={classes.row}>
                                <IconButton
                                  aria-label='edit'
                                  onClick={() => {
                                    onPublic(row.id, row.isPublic);
                                  }}
                                >
                                  {
                                    //@ts-ignore
                                    row.isPublic || row?.is_public ? <PlayArrowIcon /> : <PauseIcon />
                                  }
                                </IconButton>
                              </TableCell>
                              <TableCell align='center'>
                                <IconButton aria-label='delete' onClick={() => setIsModal({ id: row.id })}>
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      );
                    })}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      <Pagination
        className={classes.pagination}
        count={Selections?.selections ? Math.ceil(Selections?.selections.length / ROW_PAGE) : 0}
        page={page}
        onChange={handleChangePage}
      />
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
