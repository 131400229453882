import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Button, CircularProgress, CssBaseline, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { EditorDefault } from '../Editors';
import { StylesEdit } from '../StylesEdit';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Editor } from '@tinymce/tinymce-react';
import { IDefinition } from '../../store/definition/types';
import { TypeCreateDefinitionR } from '../../store/definition/actions';
import { AlertMessage } from '../AlertMessage';

interface PropsType {
  type: 'edit' | 'new';
  data?: IDefinition | null;
  handleSubmit: (
    data: TypeCreateDefinitionR['data'],
    callBack: (err?: { name: string; message: string }) => void
  ) => void;
  handleCancel: () => void;
}

export const DefinitionForm: React.FC<PropsType> = ({ type, data, handleSubmit, handleCancel }) => {
  const [title, setTitle] = React.useState<string>(data?.title || '');
  const [isPublic, setIsPublic] = React.useState(data?.is_public || 0);
  const [error, setError] = React.useState<Record<string, string>>({});

  const editorDefRef = React.useRef<Editor['editor'] | null>(null);

  const { Definitions, Configurations } = useSelector((store: AppStore) => store);

  const classes = StylesEdit();

  const handleChange = (name: string, callBack: (value: any) => void) => (value: any) => {
    const obj = { ...error };
    delete obj[name];
    setError(obj);
    callBack(value);
  };

  const onSubmit = () => {
    if (!title) return setError({ title: 'Заголовок має бути заповненим' });
    setError({});

    const description = editorDefRef.current?.getContent();

    handleSubmit(
      {
        is_public: isPublic,
        title,
        description,
        site_id: data ? data.site_id : Configurations.siteId,
      },
      (err) => {
        if (err) {
          setError({ [err.name]: err.message });
        }
      }
    );
  };

  return (
    <Box>
      <CssBaseline />
      <div className={classes.paper}>
        <Box className={classes.header}>
          <Typography component='h1' variant='h5'>
            {type === 'new' ? 'Створити визначення' : 'Редагувати визначення'}
          </Typography>
        </Box>

        <FormControlLabel
          style={{ marginTop: '7px' }}
          control={<Checkbox checked={!!isPublic} />}
          onChange={() => {
            setIsPublic(isPublic ? 0 : 1);
          }}
          label='Публікувати'
        />
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Заголовок'
          variant='outlined'
          value={title}
          error={!!error['title']}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            handleChange('title', setTitle)(event.target.value);
          }}
        />

        <div style={{ marginTop: '20px' }}>
          <EditorDefault
            initialHeight={450}
            placeholder='Опис'
            editorRef={editorDefRef}
            initialValue={data?.description}
          />
        </div>

        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={Definitions.loading} onClick={onSubmit}>
            {Definitions.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {Object.keys(error).length ? <AlertMessage type='error' message={Object.entries(error)[0][1]} /> : null}
      </div>
    </Box>
  );
};
