import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StylesTable } from '../../Stylestable';
import moment from 'moment';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { setEditData, UpdateBlockPublic } from '../../../store/officialDocBlocks/actions';
import { BlocksMain } from './BlocksMain';
import { BlockMonth } from './BlockMonth';

interface Column {
  id: 'id' | 'name' | 'type' | 'approved' | 'docDate' | 'docNum' | 'update' | 'redactions' | 'delete' | 'edit';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '5%' },
  { id: 'name', label: 'Назва', align: 'left' },
  {
    id: 'update',
    label: 'Дата змін',
    align: 'center',
  },
  {
    id: 'approved',
    label: 'Публікація',
    align: 'center',
  },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '15%',
  },
];

type PropsType = {};

export const BlocksList: React.FC<PropsType> = () => {
  const { OfficialDocBlocks, Sidebar } = useSelector((store: AppStore) => store);
  const classesTable = StylesTable();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Sidebar.page.type === 'main' || Sidebar.page.type === 'list') {
      dispatch(setEditData(null));
    }
  }, [dispatch, Sidebar.page]);

  const onPublic = (id: number, value: number) => {
    dispatch(
      UpdateBlockPublic.request({
        data: {
          approved: value ? 0 : 1,
        },
        id,
      })
    );
  };

  if (OfficialDocBlocks.editData?.isMonth === 0) {
    return <BlocksMain block={OfficialDocBlocks.editData} handleCancel={() => dispatch(setEditData(null))} />;
  }

  if (OfficialDocBlocks.editData?.isMonth === 1) {
    return <BlockMonth block={OfficialDocBlocks.editData} handleCancel={() => dispatch(setEditData(null))} />;
  }

  return (
    <>
      <TableContainer className={classesTable.container} style={{ maxHeight: 'calc(100vh - 295px)' }}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {OfficialDocBlocks.data?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>{row.id}</TableCell>
                  <TableCell className={classesTable.row}>{row.name}</TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    {moment(row.update).format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    <IconButton aria-label='edit' onClick={() => onPublic(row.id, row.approved)}>
                      {row.approved ? <PlayArrowIcon /> : <PauseIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton aria-label='edit' onClick={() => dispatch(setEditData(row))}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
