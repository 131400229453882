import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TCartState } from './types';
import { TypeSearchData } from '../../hooks/useSearchAny';

export const setCart = (payload: TCartState["data"]) => action(ActionTypes.SET_CART, payload);
export const resetCart = () => action(ActionTypes.RESET_CART, {});

export interface TypeSearchCartR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    category?: string;
    limit?: number;
    publication_at?: Date | null; 
  };
}

const SearchCartAction = new Actions('SEARCH_CART', ActionTypes);

export const SearchCart = {
  request: (payload: TypeSearchCartR | TypeSearchData) => SearchCartAction.request(payload),
  success: (payload: any[]) => SearchCartAction.success(payload || {}),
  error: (message: string) => SearchCartAction.error(message),
};


const GetCartAction = new Actions('GET_CART', ActionTypes);

export interface TypeGetCartR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    limit?: number;
    publication_at?: Date | null;
  };
}

export const GetCart = {
  request: (payload: TypeGetCartR) => GetCartAction.request(payload),
  success: (payload: TCartState['data']) => GetCartAction.success(payload || {}),
  error: (message: string) => GetCartAction.error(message),
};