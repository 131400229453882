import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import { AppStore } from '../../store/applicationState';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { format } from 'date-fns';
import { ROUTES } from '../../utils/routes';
import { Roles, categoriesSelect } from '../../utils/consts';
import { StylesTable } from '../../components/Stylestable';
import { BasicDatePicker, SelectOutline, SelectSearchReq } from '../../components/ui';
import { StylesRatings } from '../../components/Ratings/StylesMark';
import { GetRatings, SearchRatings } from '../../store/ratings/actions';
import { useSearchCommentators } from '../../hooks/useSearchCommentators';
import { Pagination } from '@mui/material';
import { useSearchAny } from '../../hooks/useSearchAny';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { URI } from '../../config';

interface Column {
  id: 'date' | 'answer' | 'responce' | 'type' | 'pub_id' | 'mark' | 'review' | 'is_demo';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'date', label: 'Дата', align: 'center', width: '12%' },
  { id: 'answer', label: 'Відповідь', align: 'center', width: '20%' },
  { id: 'responce', label: 'Відгук', align: 'center', width: '20%' },
  {
    id: 'type',
    label: 'Тип публікації',
    align: 'center',
    width: '10%',
  },
  {
    id: 'pub_id',
    label: 'Публікція(ID)',
    align: 'center',
    width: '10%',
  },
  {
    id: 'is_demo',
    label: 'Підписник',
    align: 'center',
    width: '8%',
  },
  {
    id: 'mark',
    label: 'Оцінка',
    align: 'center',
    width: '5%',
  },
  {
    id: 'review',
    label: 'Переглянути',
    align: 'center',
    width: '10%',
  },
];

const marksOptions: { label: string; value: string }[] = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
];

type PropsType = {};

export const RatingsList: React.FC<PropsType> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { Ratings, Configurations, User } = useSelector((store: AppStore) => store);
  const { options, handleSearchCommentators } = useSearchCommentators();
  const [page, setPage] = React.useState(1);
  const stylesRatings: any = StylesRatings();
  const classesTable = StylesTable();
  const [totalPages, setTotalPages] = useState(Ratings.data?.pages || 1);
  const [rate, setRate] = useState<{ label: string; value: string }[] | null>(
    searchParams.get('f_rate') && JSON.parse(searchParams.get('f_rate') || '')
  );
  const [date_at, setDateAt] = useState<Date | null | string>(
    String(searchParams.get('f_date_at'))?.replace('null', '').replace('undefined', '')
  );
  const [date_to, setDateTo] = useState<Date | null | string>(
    String(searchParams.get('f_date_to'))?.replace('null', '').replace('undefined', '')
  );
  const [type, setType] = useState(searchParams.get('f_type') || '1');
  const [id, setId] = useState<any>(searchParams.get('f_pub_id')?.replace('null', '').replace('undefined', ''));
  const [author, setAuthor] = useState<{ label: string; id: string | number } | null | undefined>({
    id: searchParams.get('f_author') || '',
    label: searchParams.get('f_author_l') || '',
  });
  const [ratings, setRatings] = useState(Ratings.ratings);
  const [subscribe, setSubscribe] = useState('null');

  const resetState = () => {
    setRate([]);
    setDateAt('');
    setDateTo('');
    setType('1');
    setId('');
    setAuthor(null);
    setPage(1);
    setSubscribe('null');
    dispatch(
      GetRatings.request({
        data: {
          siteId: Configurations.siteId,
          page: '1',
          type: '1',
        },
      })
    );
  };

  useEffect(() => {
    dispatch(
      GetRatings.request({
        data: {
          page: String(page),
          siteId: Configurations.siteId,
          type,
          pub_id: id || undefined,
          rate: JSON.stringify(rate?.map((el) => el.value)) || undefined,
          date_at: String(date_at).replace('undefinde', '').replace('null', '') || '',
          date_to: String(date_to).replace('undefinde', '').replace('null', '') || '',
          author: String(author?.id) || undefined,
        },
        callBack: (s, res) => {
          if (res?.ratings) {
            setRatings(res.ratings);
            setTotalPages(res.pages);
          }
        },
      })
    );
    //eslint-disable-next-line
  }, [page, Configurations, dispatch]);

  useEffect(() => {
    setRatings(Ratings.ratings);
    setTotalPages(Ratings.pages);
  }, [Ratings]);

  const { loading, handleSearch } = useSearchAny({
    SearchData: SearchRatings,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRoute =
    (querry = '' as string | number) =>
    () => {
      navigate(ROUTES.routeChange(`list/${querry}&site_id=${Configurations.siteId}`));
    };

  return (
    <>
      <Box className={stylesRatings.header}>
        <div
          style={{ display: [Roles.HEAD, Roles.ADMIN].includes(User.data?.role || 0) ? 'flex' : 'none' }}
          className={stylesRatings.download}
          dangerouslySetInnerHTML={{
            __html: `<a 
            style="color: white; text-decoration:none;" 
            href="${URI}/api/ratings/downloadReport?siteId=${Configurations.siteId}
              &page=${page}
              &type=${type || 0}
              &is_demo=${subscribe} 
              &rate=${encodeURIComponent(JSON.stringify(rate?.map((el) => el.label)))}
              &author=${author?.id}
              &pub_id=${id}
              &date_at=${date_at || ''}
              &date_to=${date_to || ''}"
            target="_blank"> Скачати
            </a>`,
          }}
        />
        <BasicDatePicker
          style={{ width: '150px' }}
          id='publicationAt'
          required
          label='Дата з'
          value={date_at ? new Date(String(date_at)) : null}
          error={false}
          onChange={(value) => {
            const data: any = {};
            if (page) data['page'] = 1;
            if (type) data['type'] = type;
            if (author) data['author'] = author.id;
            if (date_to) data['date_to'] = date_to;
            if (rate) data['rate'] = JSON.stringify(rate?.map((el) => el.label));
            if (subscribe) data['subscribe'] = subscribe;
            data['siteId'] = Configurations.siteId;
            handleSearch(String(value).replace('null', ''), 'date_at', data);
            setDateAt(String(value));
            setPage(1);
          }}
        />
        <BasicDatePicker
          style={{ width: '150px' }}
          id='publicationTo'
          required
          label={'Дата по'}
          value={date_to ? new Date(String(date_to)) : null}
          error={false}
          onChange={(value) => {
            const data: any = {};
            if (page) data['page'] = 1;
            if (type) data['type'] = type;
            if (author) data['author'] = author.id;
            if (date_at) data['date_at'] = date_at;
            if (rate) data['rate'] = JSON.stringify(rate?.map((el) => el.label));
            if (subscribe) data['subscribe'] = subscribe;
            data['siteId'] = Configurations.siteId;
            handleSearch(String(value).replace('null', ''), 'date_to', data);
            setDateTo(String(value));
            setPage(1);
          }}
        />

        <div style={{ zIndex: 10000 }}>
          <Select
            isMulti
            placeholder='Оцінки'
            name='ratings'
            options={marksOptions}
            value={rate}
            className='basic-multi-select'
            classNamePrefix='select'
            onChange={(value) => {
              const data: any = {};
              if (page) data['page'] = 1;
              if (date_at) data['date_at'] = date_at;
              if (date_to) data['date_to'] = date_to;
              if (type) data['type'] = type;
              if (author) data['author'] = author.id;
              if (subscribe) data['subscribe'] = subscribe;
              data['siteId'] = Configurations.siteId;
              setRate(value.map((el) => ({ value: el.value, label: el.label })));
              handleSearch(JSON.stringify(value.map((el) => el.value)), 'rate', data);
              setPage(1);
            }}
          />
        </div>
        <SelectOutline
          required
          id='outlined-basic-status'
          title='Тип публікації'
          value={String(type)}
          error={false}
          className={stylesRatings.typeSelect}
          options={categoriesSelect.map((el, i) => ({ value: el.value, label: el.name }))}
          handleChange={(value) => {
            const data: any = {};
            if (page) data['page'] = 1;
            if (author) data['author'] = author.id;
            if (date_at) data['date_at'] = date_at;
            if (date_to) data['date_to'] = date_to;
            if (subscribe) data['subscribe'] = subscribe;
            if (rate) data['rate'] = JSON.stringify(rate?.map((el) => el.label));
            data['siteId'] = Configurations.siteId;

            handleSearch(value, 'type', data);
            setType(value);
            setPage(1);
          }}
        />
        <SelectOutline
          required
          id='outlined-basic-status'
          title='Доступ'
          value={String(subscribe)}
          error={false}
          className={stylesRatings.typeSelect}
          options={[
            { label: 'Усі', value: 'null' },
            { label: 'Підписник', value: '0' },
            { label: 'Демник', value: '1' },
          ]}
          handleChange={(value) => {
            const data: any = {};
            if (page) data['page'] = 1;
            if (author) data['author'] = author.id;
            if (date_at) data['date_at'] = date_at;
            if (date_to) data['date_to'] = date_to;
            if (rate) data['rate'] = JSON.stringify(rate?.map((el) => el.label));
            if (type) data['type'] = type;
            data['siteId'] = Configurations.siteId;

            handleSearch(value, 'is_demo', data);
            setSubscribe(value);
            setPage(1);
          }}
        />
        <TextField
          disabled={!type}
          size='small'
          id='outlined-basic-id'
          label='Пошук по ID'
          variant='outlined'
          value={id}
          className={stylesRatings.idField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const data: any = {};
            if (page) data['page'] = 1;
            if (type) data['type'] = type;
            if (date_at) data['date_at'] = date_at;
            if (date_to) data['date_to'] = date_to;
            if (subscribe) data['subscribe'] = subscribe;
            if (rate) data['rate'] = JSON.stringify(rate?.map((el) => el.label));
            data['siteId'] = Configurations.siteId;
            handleSearch(event.target.value as string, 'pub_id', data);
            setId(String(event.target.value)?.replace('null', '').replace('undefined', ''));
            setPage(1);
          }}
        />
        <SelectSearchReq
          style={{ maxWidth: '200px' }}
          title='Автор'
          className={stylesRatings.authorSearch}
          onChange={(value) => {
            const data: any = {};
            if (page) data['page'] = 1;
            if (type) data['type'] = type;
            if (date_at) data['date_at'] = date_at;
            if (date_to) data['date_to'] = date_to;
            if (subscribe) data['subscribe'] = subscribe;
            if (rate) data['rate'] = JSON.stringify(rate?.map((el) => el.label));
            data['siteId'] = Configurations.siteId;
            handleSearch(String(value?.id) || '', 'author', data);
            setAuthor({ label: value?.label || '', id: value?.id || 0 });
            setPage(1);
          }}
          options={options}
          handleRequest={handleSearchCommentators}
          value={author?.label}
        />
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            resetState();
          }}
        >
          {loading ? <CircularProgress color='inherit' size={25} /> : 'Очистити фільтри'}
        </Button>
        <div className={stylesRatings.total}>Усього: {Ratings.total}</div>
        {Ratings.loading || loading ? <CircularProgress size={25} /> : null}
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!Ratings.loading && ratings.length ? (
              ratings.map((row, i) => {
                return (
                  <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.title}`}>
                    <TableCell align='center'>{format(new Date(row.created_at), 'dd.MM.yyyy HH.mm')}</TableCell>
                    <TableCell style={{ fontWeight: '500' }} align='center' className={classesTable.row}>
                      {row.rating.title}
                    </TableCell>
                    <TableCell style={{ fontWeight: '500' }} className={classesTable.row}>
                      {row.comments}
                    </TableCell>
                    <TableCell style={{ fontWeight: '500' }} className={classesTable.row}>
                      {' '}
                      {row.type}
                    </TableCell>
                    <TableCell align='center' className={classesTable.row}>
                      {row.publication.id}
                    </TableCell>
                    <TableCell align='center' className={classesTable.row}>
                      {!row.is_demo ? 'УКД' : row.is_demo === 2 ? '-' : 'ДД'}
                    </TableCell>
                    <TableCell align='center' className={classesTable.row}>
                      {row.rating.rate}
                    </TableCell>
                    <TableCell align='center' className={classesTable.row}>
                      <div
                        onClick={handleChangeRoute(
                          `${row.id}?pub_id=${row.publication.id}&id=${row.id}&type=${
                            categoriesSelect.find((el) => el.name === row.type)?.value
                          }&f_author=${author?.id}&f_rate=${JSON.stringify(
                            rate
                          )}&f_date_at=${date_at}&f_date_to=${date_to}&f_type=${type}&f_pub_id=${id}&f_author_l=${
                            author?.label
                          }`
                        )}
                      >
                        <RemoveRedEyeOutlinedIcon color='inherit' className={stylesRatings.iconDetail} />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : Ratings.loading ? (
              <Box width={'1000px'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
                <CircularProgress color='secondary' />
              </Box>
            ) : (
              []
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination className={classesTable.pagination} count={totalPages} page={page} onChange={handleChangePage} />
    </>
  );
};
