import Modal from '@mui/material/Modal';
import React from 'react';
import Button from '@mui/material/Button';
import './BoxModal.scss';
import { useStyles } from './styles';
import { TypeOptions } from '../ui/SelectPopup';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStylesRubrics = makeStyles(() => ({
  root: {
    display: 'flex',
    marginTop: '5px',
  },
  wrappBody: {
    display: 'flex',
    borderRadius: ' 4px',
    border: 'solid 1px #3f51b5',
    marginTop: '5px',
    width: '520px',
  },
  expend: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    marginTop: '5px',
    cursor: 'pointer',
    minWidth: '30px',
  },
  item: {
    minWidth: '37px',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    cursor: 'pointer',
    fontSize: '16px',
    color: '#3f51b5',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    width: '481px',
    overflowWrap: 'anywhere',
    padding: '5px',
  },
  children: {
    paddingLeft: '20px',
  },
}));

interface RubricsProps {
  ids: { [x: string]: TypeOptions };
  level: number;
  option: TypeOptions;
  chain: string;
  disabled?: boolean;
  handlePin: (value: TypeOptions) => void;
  handleUnpin: (value: TypeOptions) => void;
}

const Rubrics: React.FC<RubricsProps> = ({ ids, option, level, chain, disabled, handlePin, handleUnpin }) => {
  const [selected, setSelected] = React.useState('');
  const classes = useStylesRubrics();

  const handleChange = (item: TypeOptions, checked: boolean) => {
    if (checked) {
      handlePin(item);
    } else {
      handleUnpin(item);
    }
  };

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.expend} onClick={() => setSelected(chain === selected ? '' : chain)}>
          {option.children && option.children.length > 0 ? (
            selected === chain ? (
              <RemoveIcon style={{ fontSize: '1rem' }} />
            ) : (
              <AddIcon style={{ fontSize: '1rem' }} />
            )
          ) : (
            ''
          )}
        </div>
        <div className={classes.wrappBody}>
          <div className={classes.title}>{option.name}</div>
          {!option.children ? (
            <FormControlLabel
              style={{ margin: '0' }}
              control={<Checkbox disabled={disabled && !ids[option.id]} checked={!!ids[option.id]} />}
              onChange={(ev, checked) => handleChange({ id: option.id, name: option.name }, checked)}
              label=''
            />
          ) : null}
        </div>
      </div>

      {selected === chain && (
        <div className={classes.children}>
          {option.children?.map((it, i) => (
            <Rubrics
              key={i}
              ids={ids}
              option={it}
              level={level + 1}
              chain={`${chain}-${i}`}
              disabled={disabled}
              handlePin={handlePin}
              handleUnpin={handleUnpin}
            />
          ))}
        </div>
      )}
    </div>
  );
};

interface Props {
  values?: TypeOptions[];
  open: boolean;
  handleApprove: (approved: boolean, value: TypeOptions[]) => void;
  options?: TypeOptions[] | null;
  limit?: number;
}

export const RubricsModal: React.FC<Props> = ({ limit, values, open, options, handleApprove }) => {
  const [ids, setIds] = React.useState<{ [x: string]: TypeOptions }>({});
  const [disabled, setDisabled] = React.useState(false);
  const classes = useStyles();

  const handleChangeDisabled = React.useCallback(
    (length: number) => {
      if (limit) {
        setDisabled(length >= limit);
      }
    },
    [limit]
  );

  React.useEffect(() => {
    if (values) {
      const obj = values.reduce((acum, val) => {
        acum[val.id] = val;
        return acum;
      }, {} as { [x: string]: TypeOptions });
      setIds(obj);
      handleChangeDisabled(Object.keys(obj).length);
    }
  }, [values, handleChangeDisabled]);

  const handlePin = (value: TypeOptions) => {
    setIds((prev) => {
      const obj = { ...prev };
      obj[value.id] = value;

      handleChangeDisabled(Object.keys(obj).length);

      return obj;
    });
  };

  const handleUnpin = (value: TypeOptions) => {
    setIds((prev) => {
      const obj = { ...prev };
      delete obj[value.id];

      handleChangeDisabled(Object.keys(obj).length);

      return obj;
    });
  };

  const handleClose = (approved: boolean) => {
    handleApprove(
      approved,
      Object.keys(ids).map((key) => ids[key])
    );
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose(false)}
      className={classes.modal}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <div className='boxModal-inner'>
        <h2 style={{ textAlign: 'center', margin: '10px 0' }}>Рубрикатор</h2>
        <div
          style={{
            padding: '0 20px 20px',
            height: 'calc(100vh - 300px)',
            overflow: 'auto',
          }}
        >
          {options?.map((it, i) => (
            <Rubrics
              key={i}
              ids={ids}
              option={it}
              level={1}
              chain={String(i)}
              handlePin={handlePin}
              handleUnpin={handleUnpin}
              disabled={disabled}
            />
          ))}
        </div>
        <div className='boxFooter' style={{ margin: 0 }}>
          <div style={{ display: 'flex' }}>
            <Button color='primary' variant='contained' onClick={() => handleClose(true)}>
              Зберегти
            </Button>
          </div>
          <Button color='inherit' variant='contained' onClick={() => handleClose(false)}>
            скасувати
          </Button>
        </div>
      </div>
    </Modal>
  );
};
