import React, { useCallback, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { configSites, URI } from '../../config';
import { ACTUAL, NOT_ACTUAL, templates } from './templates';
import { backlight, handleConvertSymbol, uuid } from './EditorDocum/entities/utils';
import { anchorIcon } from './EditorDocum/entities/consts';
import { CATEGORIES, categoryName } from '../../utils/consts';
import { useParams } from 'react-router-dom';
import { DefinitionModal } from '../modals/DefinitionModal';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { getTextContent } from '../../utils/getTextContent';

import {
  changeCellBackgroundColor,
  changeDash,
  changeLiveSample,
  changePositionTable,
  changeQuotes,
  convertUrl,
  copyIframe,
  copyLiveSample,
  copyTemplate,
  copyTemplateFile,
  deleteIframe,
  deleteLiveSample,
  deleteTemplate,
  deleteTemplateFile,
  downloadfile,
  editFileName,
  editIframe,
  editIframeWithOptions,
  editLiveSample,
  handlerPointsLiveSample,
  pastePreprocess,
  reviewfile,
  showAnchor,
  uploadcare,
} from './commands';
import { UnsavedFlags } from '../ConsultationForm';
import { imagesUpload } from './commands/handlers/imagesUpload';
import { ModalLiveSample } from './modals/ModalLiveSample';

interface EditorProps {
  setUnsavedChanges?: (verdict: UnsavedFlags) => void;
  editorRef?: React.MutableRefObject<Editor['editor'] | null>;
  initialValue?: string;
  placeholder?: string;
  categoryId: CATEGORIES;
  siteId: number;
  docId?: number;
  unsavedChanges?: UnsavedFlags;
  calendarDescriptionEditor?: boolean;
  customTemplates?: {
    title: string;
    description: string;
    content: string;
  }[];
  setEditorContent?: (value: string) => void;
  editorContent?: string;
  maxLength?: number;
}

export const EditorFeatured: React.FC<EditorProps> = ({
  placeholder,
  editorRef,
  initialValue,
  categoryId,
  siteId,
  docId,
  customTemplates,
  calendarDescriptionEditor,
  setUnsavedChanges,
  unsavedChanges,
  editorContent,
  setEditorContent,
  maxLength,
}) => {
  const [liveSample, setLiveSample] = React.useState<{
    imgSrc: string;
    items: { vertically: number | null; horizontally: number | null; description: string | null }[];
    node: Element | null;
  } | null>(null);
  const [showActiveQuotes, setShowActiveQuotes] = React.useState(false);

  const [definitionModal, setDefinitionModal] = useState<{
    id?: number | string;
    type: 'edit' | 'new';
    wordName?: string;
  } | null>(null);

  const { id } = useParams();

  const handleQuotesClick = (e: any) => {
    e.stopPropagation();
    changeQuotes(editorRef?.current);
    setShowActiveQuotes(true);
  };

  const handleDashClick = (e: any) => {
    e.stopPropagation();
    changeDash(editorRef?.current);
    setShowActiveQuotes(true);
  };

  const handleSymbolClick = (symbol: '-' | "'") => {
    handleConvertSymbol(editorRef, setShowActiveQuotes, symbol);
  };

  const handleWindowClick = (e: any) => {
    const content = editorRef?.current?.getContent();
    const tempElement = document.createElement('html');
    tempElement.innerHTML = content || '';
    if (!showActiveQuotes && content?.includes('<mark>')) {
      const body = backlight(false, tempElement.innerHTML);
      const editor = editorRef?.current;
      if (editor) editor.setContent(body);
    } else {
      setShowActiveQuotes(false);
    }
  };

  const detectUnsavedChanges = useCallback(
    (editor: any) => {
      setEditorContent && !editorContent && setEditorContent(editor.getContent());

      setUnsavedChanges &&
        unsavedChanges &&
        editorContent &&
        setUnsavedChanges({
          ...unsavedChanges,
          unsavedBody: editorContent?.trim() !== editor?.getContent()?.trim(),
        });
    },

    [setEditorContent, editorContent, setUnsavedChanges, unsavedChanges]
  );

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  });

  const trackDefinitionOnClick = (e: any) => {
    const targetElement = e.target;
    const isDefinition = targetElement.closest('.definition');
    const wordName = isDefinition?.textContent?.replace('i', '').trim() || '';
    if (isDefinition) {
      setDefinitionModal({
        type: 'edit',
        id: isDefinition?.id,
        wordName,
      });
    }
  };

  const handleCloseModalLiveSample = (node: Element | null) => {
    if (editorRef?.current && node) {
      editorRef?.current.selection.select(node);
    }
    setLiveSample(null);
  };

  return (
    <div onClick={handleWindowClick} style={{ position: 'relative' }}>
      {placeholder && (
        <h3
          style={{
            margin: '0 0 5px',
            fontWeight: 400,
            lineHeight: '1.334',
            letterSpacing: '0em',
          }}
        >
          {placeholder}
        </h3>
      )}
      <div
        className='tox-tbtn apostrophe'
        onClick={(e) => {
          e.stopPropagation();
          handleSymbolClick("'");
        }}
      >
        {String.fromCharCode(0x2019)}
      </div>
      <div className='tox-tbtn tire' onClick={handleDashClick}>
        <HorizontalRuleOutlinedIcon />
      </div>
      <div className='tox-tbtn quote' onClick={handleQuotesClick}>
        <KeyboardDoubleArrowLeftOutlinedIcon style={{ fontSize: '12px' }} />
        <KeyboardDoubleArrowRightOutlinedIcon style={{ fontSize: '12px' }} />
      </div>
      <Editor
        onEditorChange={(e, editor) => detectUnsavedChanges(editor)}
        onInit={(evt, editor) => editorRef && (editorRef.current = editor)}
        apiKey='uzm54hzb90l1dcm92ia4tfjp6aj0krmgjak7eof1jhyd17pv'
        initialValue={backlight(false, initialValue)}
        init={{
          convert_urls: true,
          urlconverter_callback: convertUrl,
          content_css: '/css/index.css',
          target_list: [
            { title: 'Нова сторінка', value: '_blank' },
            { title: 'Та сама сторінка', value: '_self' },
          ],
          link_default_protocol: 'https',
          language: 'uk',
          height: calendarDescriptionEditor ? 400 : 660,
          width: '900px',
          plugins:
            'powerpaste preview advcode visualblocks image link codesample table charmap hr lists checklist ' +
            'imagetools advtable template pageembed casechange searchreplace',
          menubar: 'scode tc insert format table definition',
          menu: {
            scode: {
              title: 'Код',
              items: 'code',
            },
            tc: {
              title: 'Шаблони',
              items: 'template',
            },
            insert: {
              title: 'Вставити',
              items: 'image link definition pageembed template codesample imagetools charmap hr',
            },
            format: {
              title: 'Формат',
              items:
                'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontsizes align | removeformat',
            },
          },
          toolbar:
            'undo redo | code | styleselect | anchorbutton | searchreplace | bold italic | ' +
            'alignleft aligncenter alignright alignjustify | forecolor backcolor | ' +
            'bullist | charmap | ' +
            (+categoryId === CATEGORIES.CONSULTATIONS ? ' ' : 'preview | ') +
            'imageoptions  link customEmbed',
          tiny_pageembed_classes: [],
          tiny_pageembed_inline_styles: true,
          table_toolbar:
            'tableprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | ' +
            'tableinsertcolbefore tableinsertcolafter tabledeletecol | tabledelete | alignmentLeft alignmentRight',
          paste_as_text: false,
          visualblocks_default_state: true,
          verify_html: false,
          powerpaste_html_import: 'merge',
          forced_root_block: 'p',
          paste_retain_style_properties: 'border-spacing,border-collapse',
          style_formats: [
            { title: 'Абзац', block: 'p' },
            { title: 'Заголовок', block: 'h2' },
            { title: 'Підзаголовок', block: 'h3' },
          ],
          templates: customTemplates ? customTemplates : templates,
          table_class_list: [
            { title: 'По дефолту', value: ' ' },
            { title: 'Суцільна межа', value: 'table-solid-borders' },
            { title: 'Без меж', value: 'table-no-borders' },
          ],
          table_cell_class_list: [
            { title: 'По дефолту', value: ' ' },
            { title: 'Суцільна межа', value: 'table-solid-borders' },
            { title: 'Без меж', value: 'table-no-borders' },
          ],
          table_default_styles: {
            width: '100%',
          },
          color_map: [
            '#BFEDD2',
            'Light Green',
            '#FBEEB8',
            'Light Yellow',
            '#F8CAC6',
            'Light Red',
            '#ECCAFA',
            'Light Purple',
            '#C2E0F4',
            'Light Blue',

            '#2DC26B',
            'Green',
            '#F1C40F',
            'Yellow',
            '#E03E2D',
            'Red',
            '#B96AD9',
            'Purple',
            '#3598DB',
            'Blue',

            '#169179',
            'Dark Turquoise',
            '#E67E23',
            'Orange',
            '#BA372A',
            'Dark Red',
            '#843FA1',
            'Dark Purple',
            '#236FA1',
            'Dark Blue',

            '#ECF0F1',
            'Light Gray',
            '#CED4D9',
            'Medium Gray',
            '#95A5A6',
            'Gray',
            '#7E8C8D',
            'Dark Gray',
            '#34495E',
            'Navy Blue',

            '#000000',
            'Black',
            '#ffffff',
            'White',
            'FFF3ED',
            'Yellow',
          ],
          custom_colors: false,
          content_style: `
            .mce-content-body {
              overflow: ${calendarDescriptionEditor ? 'hidden' : 'auto'};
              overflow-y: auto;
              min-height: 480px;
              max-width: 800px;
              margin: 0 auto;
              padding: 40px;
              font: normal 16px / 1.42857143 "Proxima Nova Rg Inner",Arial,Helvetica,sans-serif;
            }
            .mce-item-table:not([border]), .mce-item-table:not([border]) caption, .mce-item-table:not([border]) td, .mce-item-table:not([border]) th, .mce-item-table[border="0"], .mce-item-table[border="0"] caption, .mce-item-table[border="0"] td, .mce-item-table[border="0"] th, table[style*="border-width: 0px"], table[style*="border-width: 0px"] caption, table[style*="border-width: 0px"] td, table[style*="border-width: 0px"] th {
              border: solid 1px black;   
            }
            td {
              vertical-align: initial;
            }
            a {
              color: #0047b3;
              text-decoration: none;
              cursor: pointer;
              transition: color .3s,border-color .3s;
            }
            h2,h3 {
              font-weight: 600;
              letter-spacing: -0.005em;
              color: #252525;   
            }
            h1,h2,h3,h4,h5,p,div {
              position: relative;
            }
            li::marker {
              display: inline-block;
              vertical-align: baseline;
              min-width: 18px;
              margin-left: -18px;
              font-size: 13px;
              line-height: 13px;
              content: "\\2014\\a0";
              position: relative;
            }
            .link-download {
              color: #0047b3;
              text-decoration: none;
              cursor: pointer;
            }
            .link-type-export:after {
              display: inline;
              white-space: nowrap;
              text-transform: uppercase;
              color: #fff;
              font-size: 10px;
              padding: 2px;
              border-left: 3px solid #fff;
            }
            .file-png:after {
              content: ".png";
              background-color: #ff9800;
            }
            .file-jpg:after {
              content: ".jpg";
              background-color: #ff9800;
            }
            .file-doc:after {
              content: ".doc";
              background-color: #0085ff;
            }
            .file-pptx:after {
              content: ".pptx";
              background-color: #dd5f1a;
            }
            .file-xls:after {
              content: ".xls";
              background-color: #2e9b00;
            }
            .file-pdf:after {
              content: ".pdf";
              background-color: #d3032afc;
            }
            .anchor:before {
              pointer-events: all;
              transform: translateY(-1px);
              position: absolute;
              left: -30px;
              transition: transform .3s;
              will-change: transform;
              cursor: pointer;
              content: url(${URI.trim()}/images/img-icon/3006691.svg);
            }
            .myCopied {
              border: 1px solid black;
              border-block-end-color: gray;
              border-right-color: gray;
              background-color: white;
              color: black;
              text-align: center;
              padding: 5px;
              position: absolute;
              z-index: 1;
              font-size: small;
              font-weight: 400;
              left: 0;
              top: 0;
            }
            .table-no-borders,
            .table-no-borders td, 
            .table-no-borders th {
              border: none !important;
            }

            .table-solid-borders,
            .table-solid-borders td,
            .table-solid-borders th {
              border: 1px solid #000 !important;
            }

            .cell-background-color {
              background: rgba(252, 243, 237, 1);
            }
            table{
              max-width:1100px;
            }
          `,
          paste_preprocess: pastePreprocess({ maxLength }),
          images_upload_handler: imagesUpload({ id, siteId, categoryId, configSites }),
          setup: (editor) => {
            function changeStatus(status: string) {
              return () => {
                const node = editor.selection.getNode();
                let element = null as null | Element;
                if (node.parentElement?.className.includes('templates-2__header-status')) {
                  element = node.parentElement;
                } else {
                  element = node.querySelector('.templates-2__header-status');
                }
                if (element) {
                  const node = document.createRange().createContextualFragment(status);
                  element.replaceWith(node);
                }
              };
            }
            function redrawNumForTemplates() {
              editor
                .$('.templates-2__wrapper--example')
                .find('.templates-2__header-num')
                .each((i, node) => {
                  node.textContent = String(i + 1);
                });
              editor
                .$('.templates-2__case-decision')
                .find('.templates-2__header-num')
                .each((i, node) => {
                  node.textContent = String(i + 1) + '.';
                });
              editor
                .$('.templates-2__wrapper--definition')
                .find('.templates-2__header-num')
                .each((i, node) => {
                  node.textContent = String(i + 1);
                });
            }
            function redrawNumForCases() {
              editor
                .$('.templates-2__case')
                .find('.templates-2__wrapper--case')
                .find('.templates-2__header-name')
                .each((i, node) => {
                  node.textContent = `${String(i + 1)}. Кейс`;
                });
            }
            function changeElement(element: Element) {
              if (!element) return null;
              const parent = editor.$(element).parent().find('.templates-2__body-hidden');
              if (element.classList.contains('active')) {
                parent.hide();
                parent.removeAttr('data-mce-style');
              } else {
                parent.removeAttr('data-mce-style');
                parent.attr('style', 'display: block');
              }
              editor.$(element).toggleClass('active');
            }
            editor.on('BeforeSetContent', function (e) {
              const div = document.createElement('div');
              div.insertAdjacentHTML('afterbegin', e.content || '');
              div.querySelectorAll('*').forEach((el) => {
                //@ts-ignore
                el?.style?.removeProperty('font-size');
              });
              e.content = div.innerHTML;
              if (e.content?.includes('<div contenteditable="false" class="tiny-pageembed"><iframe')) {
                const content = e.content.replace(
                  '<div contenteditable="false" class="tiny-pageembed"><iframe',
                  `<div style="width: 100%;" class="tiny-pageembed"><div><iframe style="width: 100%;"`
                );
                e.content = content + '</div>';
              }
              if (e.content === '') {
                e.content = `<p id="${uuid()}"><br></p>`;
              }

              const content = e.content;
              const tempElement = document.createElement('div');
              tempElement.innerHTML = content;
              if (categoryId !== CATEGORIES.CATALOGUES) {
                const tdElements = tempElement.querySelectorAll('td');
                tdElements.forEach((tdElement) => {
                  const pElement = tdElement.querySelector('p');
                  if (!pElement || !pElement.id) {
                    const tdContent = tdElement.textContent;
                    if (tdContent !== null) {
                      const trimmedContent = tdContent.trim();

                      if (trimmedContent) {
                        const pElement = document.createElement('p');
                        pElement.id = uuid();
                        pElement.innerHTML = tdElement.innerHTML;
                        tdElement.innerHTML = pElement.outerHTML;
                      }
                    }
                  }
                });
              }

              // For all headings and lists that do not have an ID, we add an ID
              const elements = tempElement.querySelectorAll('.toggle__title, li');
              elements.forEach((titleElement) => {
                if (!titleElement.id) {
                  titleElement.id = uuid();
                }
              });
              e.content = tempElement.innerHTML;
            });
            editor.on('SetContent', (e) => {
              const body = editor.getBody();
              Array.from(body.children).forEach((child) => {
                if (!child.getAttribute('id')) {
                  child.setAttribute('id', uuid());
                }
              });
              redrawNumForTemplates();
              redrawNumForCases();
              handlerPointsLiveSample(editor);
            });
            editor.on('keyup', (e) => {
              const elem = editor.selection.getNode();
              if (!elem.getAttribute('id')) {
                elem.setAttribute('id', uuid());
              }
            });
            editor.on('click', (node) => {
              if (
                node.target?.classList?.contains('templates-2__body-toggle-icon') &&
                node.target?.parentElement?.classList?.contains('templates-2__body-toggle')
              ) {
                changeElement(node.target?.parentElement);
              }
              if (
                node.target?.parentElement?.classList?.contains('templates-2__body-toggle-icon') &&
                node.target?.parentElement?.parentElement?.classList?.contains('templates-2__body-toggle')
              ) {
                changeElement(node.target?.parentElement?.parentElement);
              }
              if (node.target?.classList?.contains('anchor') && node.offsetX < 0 && docId) {
                const id = node.target.id || editor.selection.getNode().id;
                navigator.clipboard.writeText(`${configSites[siteId].url}/${categoryName[categoryId]}/${docId}#${id}`);
                const el = editor.dom.create('span', { class: 'myCopied' }, 'Скопійовано');
                node.target.append(el);
                let timeout = setTimeout(() => {
                  el.remove();
                  clearTimeout(timeout);
                }, 1000);
              }
            });
            editor.on('keydown', (node) => {
              if (maxLength) {
                const text = editor.getContent({ format: 'text' });

                if (text.length > maxLength && node.code !== 'Backspace') {
                  node.preventDefault();
                  return false;
                }
              }

              const elem = editor.selection.getNode();
              const renge = editor.selection.getRng();

              if (node.code === 'Space') {
                if (
                  elem.classList &&
                  ['definition-icon'].find((item) => elem.classList.contains(item)) &&
                  elem.parentElement?.parentElement?.parentElement
                ) {
                  editor.$(elem.parentElement.parentElement).after('&nbsp;');
                  editor.selection.select(elem.parentElement.parentElement.parentElement);
                  editor.selection.collapse(false);
                  editor.focus();
                }

                if (
                  elem.parentElement &&
                  ['definition-icon'].find((item) => elem.parentElement?.classList.contains(item)) &&
                  elem.parentElement?.parentElement?.parentElement?.parentElement
                ) {
                  editor.$(elem.parentElement.parentElement.parentElement).after('&nbsp;');
                  editor.selection.select(elem.parentElement.parentElement.parentElement.parentElement);
                  editor.selection.collapse(false);
                  editor.focus();
                }
              }

              if (elem && elem.classList) {
                if (
                  [
                    'templates-2__header-num',
                    'templates-2__header-name',
                    'definition',
                    'def-ico-block',
                    'definition-icon',
                  ].find((item) => elem.classList.contains(item)) ||
                  ['templates-2__header-status', 'definition', 'def-ico-block', 'definition-icon'].find((item) =>
                    elem.parentElement?.classList?.contains(item)
                  ) ||
                  elem.className === 'templates-2__header-icon-name'
                ) {
                  node.preventDefault();
                  return false;
                }
                if (node.key === 'Enter') {
                  if (
                    [
                      'toggle__subtitle',
                      'toggle__title',
                      'templates-2__body-title',
                      'templates-2__header-name',
                      'templates-2__user-name',
                      'templates-2__user-pos',
                    ].find((item) => elem.classList.contains(item))
                  ) {
                    node.preventDefault();
                    return false;
                  }
                }

                if (node.key === 'Delete' || node.key === 'Backspace') {
                  try {
                    if (
                      [
                        'toggle__subtitle',
                        'toggle__title',
                        'templates-2__body-title',
                        'templates-2__header-name',
                        'templates-2__user-name',
                        'templates-2__user-pos',
                        'definition',
                        'definition-icon',
                      ].find((item) => elem.classList.contains(item))
                    ) {
                      if (node.key === 'Delete' || renge.endOffset === 0) {
                        node.preventDefault();
                        return false;
                      }
                      if (elem?.textContent?.length === 0) {
                        node.preventDefault();
                        return false;
                      }
                    }

                    if (
                      elem.parentNode &&
                      [
                        'toggle__body',
                        'templates-2__body-hidden',
                        'templates-2__footer',
                        'definition',
                        'definition-icon',
                      ].find((item) => elem.parentElement?.classList.contains(item))
                    ) {
                      if (node.key === 'Delete') {
                        node.preventDefault();
                        return false;
                      }
                      if (elem?.parentNode?.children.length === 1 && renge.endOffset === 0) {
                        node.preventDefault();
                        return false;
                      }
                      if (elem?.parentNode?.children.length === 1 && elem?.textContent?.length === 0) {
                        node.preventDefault();
                        return false;
                      }
                    }

                    if (
                      ['toggle', 'templates-2__global-wrapper'].find((item) =>
                        // @ts-ignore
                        elem.previousSibling?.classList?.contains(item)
                      )
                    ) {
                      const legthText = elem?.textContent?.length || 0;
                      if (legthText < 2) {
                        node.preventDefault();
                        return false;
                      }
                    }

                    if (
                      ['toggle', 'templates-2__global-wrapper'].find(
                        (item) =>
                          // @ts-ignore
                          elem?.previousSibling?.classList?.contains(item) &&
                          // @ts-ignore
                          elem?.nextSibling?.classList?.contains(item)
                      )
                    ) {
                      const legthText = elem?.textContent?.length || 0;
                      if (legthText < 2) {
                        node.preventDefault();
                        return false;
                      }
                    }
                  } catch (err) {
                    console.log(err);
                  }
                }
              }
            });

            editor.on('click', () => {
              const content = editor.getContent();
              if (!showActiveQuotes && content.includes('<mark>')) {
                editor.setContent(backlight(false, content));
              }
              if (showActiveQuotes) {
                setShowActiveQuotes(false);
              }
            });

            editor.on('click', trackDefinitionOnClick);

            editor.ui.registry.addContextToolbar('textselection', {
              predicate: function (node) {
                return (
                  !editor.selection.isCollapsed() &&
                  !['templates-iframe', 'templates-live-sample', 'tiny-pageembed'].find((item) =>
                    node.classList.contains(item)
                  )
                );
              },
              items: 'copy cut quicklink cellBackgroundColor',
              position: 'node',
            });
            editor.ui.registry.addContextToolbar('contextformtogglebuttonactualstatus', {
              predicate: (node) => {
                return node.classList.contains('templates-2__header-status');
              },
              items: 'actualstatus | notactualstatus',
              position: 'selection',
            });
            editor.ui.registry.addContextToolbar('contextformtogglebutton', {
              predicate: (node) => {
                return node.nodeName.toLowerCase() === 'a' && node.classList.contains('link-download');
              },
              items: `uploadcare | reviewfile | downloadfile ${
                customTemplates ? ' | editFileName' : ''
              } | copyTemplateFile | deleteTemplateFile`,
              position: 'selection',
            });
            editor.ui.registry.addContextToolbar('contextiframebutton', {
              predicate: (node) => {
                return node.classList.contains('templates-iframe');
              },
              items: 'editiframe | copyIframe | deleteiframe',
              position: 'selection',
            });
            editor.ui.registry.addContextToolbar('contextiframebuttoncustomEmbed', {
              predicate: (node) => {
                return node.classList.contains('tiny-pageembed');
              },
              items: 'editCustomEmbed | copyIframe | deleteiframe',
              position: 'selection',
            });
            editor.ui.registry.addContextToolbar('contexttemplatelivesample', {
              predicate: (node) => {
                return node.classList.contains('templates-live-sample');
              },
              items: 'editLiveSample | copyLiveSample | deleteLiveSample',
              position: 'selection',
            });
            editor.ui.registry.addContextToolbar('contexttemplate', {
              predicate: (node) => {
                return node.classList.contains('toggle') || node.classList.contains('templates-2__global-wrapper');
              },
              items: 'copyTemplate | deleteTemplate',
              position: 'node',
            });

            editor.ui.registry.addButton('actualstatus', {
              text: 'Актуально',
              onAction: changeStatus(ACTUAL),
            });
            editor.ui.registry.addButton('notactualstatus', {
              text: 'На актуалізаціі',
              onAction: changeStatus(NOT_ACTUAL),
            });
            editor.ui.registry.addButton('alignmentRight', {
              icon: 'chevron-right',
              onAction: changePositionTable(editor, 'right'),
            });
            editor.ui.registry.addButton('alignmentLeft', {
              icon: 'chevron-left',
              onAction: changePositionTable(editor, 'left'),
            });

            editor.ui.registry.addButton('cellBackgroundColor', {
              icon: 'cell-background-color',
              onAction: changeCellBackgroundColor(editor),
            });

            editor.ui.registry.addButton('editiframe', {
              text: 'Редагувати',
              onAction: editIframe(editor),
            });
            editor.ui.registry.addButton('copyIframe', {
              text: 'Копіювати',
              onAction: copyIframe(editor),
            });
            editor.ui.registry.addButton('deleteiframe', {
              text: 'Видалити',
              onAction: deleteIframe(editor),
            });

            editor.ui.registry.addIcon('anchorIcon', anchorIcon);
            editor.ui.registry.addButton('anchorbutton', {
              icon: 'anchorIcon',
              onAction: showAnchor(editor),
            });

            editor.ui.registry.addMenuItem('definition', {
              text: 'Визначення',
              icon: 'info',
              onAction: (e) => {
                const selectedNode = editor.selection.getContent();
                if (!selectedNode) return;

                setDefinitionModal({
                  type: 'new',
                  wordName: getTextContent(selectedNode)?.replaceAll('i', ''),
                  id: undefined,
                });
              },
            });

            editor.ui.registry.addButton('uploadcare', {
              text: 'Завантажити',
              onAction: uploadcare(editor, { id, siteId, categoryId, isCustom: !!customTemplates }),
            });
            editor.ui.registry.addButton('reviewfile', {
              text: 'Переглянути',
              onAction: reviewfile(editor),
            });
            editor.ui.registry.addButton('downloadfile', {
              text: 'Скачати',
              onAction: downloadfile(editor),
            });
            editor.ui.registry.addButton('editFileName', {
              text: 'Редагувати',
              onAction: editFileName(editor),
            });
            editor.ui.registry.addButton('copyTemplateFile', {
              text: 'Копіювати',
              onAction: copyTemplateFile(editor),
            });
            editor.ui.registry.addButton('deleteTemplateFile', {
              text: 'Видалити',
              onAction: deleteTemplateFile(editor),
            });

            editor.ui.registry.addButton('customEmbed', {
              tooltip: 'Вбудувати iframe',
              icon: 'embed-page',
              onAction: editIframeWithOptions(editor),
            });
            editor.ui.registry.addButton('editCustomEmbed', {
              text: 'Редагувати',
              onAction: editIframeWithOptions(editor),
            });

            editor.ui.registry.addButton('deleteLiveSample', {
              text: 'Видалити',
              onAction: deleteLiveSample(editor),
            });
            editor.ui.registry.addButton('editLiveSample', {
              text: 'Редагувати',
              onAction: editLiveSample(editor, setLiveSample),
            });
            editor.ui.registry.addButton('copyLiveSample', {
              text: 'Копіювати',
              onAction: copyLiveSample(editor),
            });

            editor.ui.registry.addButton('deleteTemplate', {
              text: 'Видалити',
              onAction: deleteTemplate(editor, () => {
                redrawNumForTemplates();
                redrawNumForCases();
              }),
            });
            editor.ui.registry.addButton('copyTemplate', {
              text: 'Копіювати',
              onAction: copyTemplate(editor),
            });
          },
        }}
      />

      {liveSample ? (
        <ModalLiveSample
          siteId={siteId}
          categoryId={categoryId}
          publicationId={id}
          open={!!liveSample}
          data={liveSample}
          handleConfirm={(value) => {
            changeLiveSample(value, liveSample.node, handleCloseModalLiveSample);
            handlerPointsLiveSample(editorRef?.current);
          }}
          handleClose={() => handleCloseModalLiveSample(liveSample.node)}
        />
      ) : null}

      <DefinitionModal
        title='Вставити/редагувати визначення'
        data={definitionModal}
        handleClose={() => setDefinitionModal(null)}
        handleConfirm={(value) => {
          const editor = editorRef?.current;
          if (!editor) return null;

          if (value.type === 'new') {
            const uniqId = uuid();
            const defIcon = `<span class="def-ico-block" style="display: inline-block;" id="def.${value.id}"><span class="definition-icon">i</span></span>`;
            const modifiedContent = `<span class="definition" id="${
              value.id
            }.${uniqId}">${value.wordName?.trim()}${defIcon.trimRight()}</span>`;

            editor.selection.setContent(modifiedContent.replaceAll('&nbsp;', '') + '&#xFEFF;');
          } else if (value.type === 'edit') {
            const editorBody = editor.getBody();
            const spanElement = editorBody.querySelector(`[id="${value.prevId}"]`);
            if (spanElement && spanElement.firstChild && value.wordName) {
              spanElement.id = String(value.id);
              spanElement.firstChild.textContent = value.wordName;
            }
          } else if (value.type === 'delete') {
            const editorBody = editor.getBody();
            const spanElement = editorBody.querySelector(`[id="${value.prevId}"]`);
            if (spanElement?.textContent) {
              const wordName = spanElement.textContent.replace('i', '').trim() || '';
              spanElement.replaceWith(wordName);
            }
          }
          setDefinitionModal(null);
        }}
        open={!!definitionModal}
        type={definitionModal?.type || 'new'}
      />
    </div>
  );
};
