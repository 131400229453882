export const findNearestAncestorWithClassName = (element: Element, className: string[]) => {
  let el: Element | null = element;

  // eslint-disable-next-line
  while (el) {
    // eslint-disable-next-line
    if (!el) return null;

    // eslint-disable-next-line
    if (className.find((item) => el && el.classList.contains(item))) {
      return el;
    }

    el = el.parentElement;
  }

  return null;
};
