import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { IAdditionalWidgets, IMainWidgets } from './types';

export const setWidgets = (payload: TypeGetWidgetesS) => action(ActionTypes.SET_WIDGETES, payload);

export interface TypeGetWidgetesR extends TDefRequest {
  data: {
    site_id: number;
  };
}

const GetWidgetesAction = new Actions('GET_WIDGETES', ActionTypes);

export interface TypeGetWidgetesS {
  additionalWidgets: IAdditionalWidgets[];
  mainWidgets: IMainWidgets[];
}

export const GetWidgetes = {
  request: (payload: TypeGetWidgetesR) => GetWidgetesAction.request(payload),
  success: (payload: TypeGetWidgetesS) => GetWidgetesAction.success(payload || {}),
  error: (message: string) => GetWidgetesAction.error(message),
};

const UpdateMainWidgetAction = new Actions('UPDATE_MAIN_WIDGET', ActionTypes);

export interface TypeUpdateMainWidgetR extends TDefRequest {
  data: IMainWidgets;
  id: number;
}

export const UpdateMainWidget = {
  request: (payload: TypeUpdateMainWidgetR) => UpdateMainWidgetAction.request(payload),
  success: (payload: IMainWidgets) => UpdateMainWidgetAction.success(payload || {}),
  error: (message: string) => UpdateMainWidgetAction.error(message),
};

const UpdateAdditionalWidgetAction = new Actions('UPDATE_ADDITION_WIDGET', ActionTypes);

export interface TypeUpdateAdditionalWidgetR extends TDefRequest {
  data: IAdditionalWidgets;
  id: number;
}

export const UpdateAdditionalWidget = {
  request: (payload: TypeUpdateAdditionalWidgetR) => UpdateAdditionalWidgetAction.request(payload),
  success: (payload: IAdditionalWidgets) => UpdateAdditionalWidgetAction.success(payload || {}),
  error: (message: string) => UpdateAdditionalWidgetAction.error(message),
};

export const UpdateMainWidgetsPositionsAction = new Actions('UPDATE_MAIN_WIDGET_POSITIONS', ActionTypes);

interface IPosition {
  id: number | string;
  position: number;
}

export interface TypeUpdatePositionsWidgetR extends TDefRequest {
  data: {
    positions: IPosition[];
    site_id: number;
  };
}

export const UpdateMainWidgetsPosition = {
  request: (payload: TypeUpdatePositionsWidgetR) => UpdateMainWidgetsPositionsAction.request(payload),
  success: (payload: IMainWidgets[]) => UpdateMainWidgetsPositionsAction.success(payload),
  error: (message: string) => UpdateMainWidgetsPositionsAction.error(message),
};

export const UpdateAdditionalWidgetsPositionsAction = new Actions('UPDATE_ADDITIONAL_WIDGET_POSITIONS', ActionTypes);

export const UpdateAdditionalWidgetsPosition = {
  request: (payload: TypeUpdatePositionsWidgetR) => UpdateAdditionalWidgetsPositionsAction.request(payload),
  success: (payload: IAdditionalWidgets[]) => UpdateAdditionalWidgetsPositionsAction.success(payload),
  error: (message: string) => UpdateAdditionalWidgetsPositionsAction.error(message),
};

export const CreateAdditionalWidgetAction = new Actions('CREATE_ADDITIONAL_WIDGET', ActionTypes);

export interface TypeCreateAdditionalWidgetR extends TDefRequest {
  data: {
    title: string;
    url: string;
    site_id: number;
  };
}

export const CreateAdditionalWidget = {
  request: (payload: TypeCreateAdditionalWidgetR) => CreateAdditionalWidgetAction.request(payload),
  success: (payload: IAdditionalWidgets[]) => CreateAdditionalWidgetAction.success(payload),
  error: (message: string) => CreateAdditionalWidgetAction.error(message),
};
