import { IAuthor } from '../authors/types';

export interface TMainMonthState {
  error: string | null;
  loading: boolean;
  mainInMonth: Record<string, IMainInMonthYears[]> | null;
  current: IMainInMonthYears | null;
  template: ITemplate | null;
  block: IMonthMainBlock | null;
}
export interface IMainInMonthYears {
  id: number;
  site_id: number;
  position: number;
  title: string;
  url: string;
  month: number;
  year: number;
  is_public: 1 | 0;
  is_open_publish: 1 | 0;
  publication_at?: Date;
  month_main_templates_details?: ITemplate[];
  templates: IMonthMainTemplate[];
  preview_hash: string;
}

export interface IMonthMainTemplate {
  title: string;
  is_public: number;
  id: number;
  position: number;
  path: string;
  template_detail_id: number;
}

export interface ITemplate {
  id: number;
  month_main_id: number;
  template_id: number;
  is_public: number;
  position: number;
  created_at: Date;
  updated_at: Date;
  month_main_template: {
    id: number;
    template_detail_id: number;
    title: string;
    path: string;
    max_length: number;
    created_at: Date;
    updated_at: Date;
  };
  month_main_blocks?: IMonthMainBlock[];
}

export interface IMonthMainBlock {
  id: number;
  month_main_templates_detail_id: number;
  category_id: number;
  publication_id: number;
  description: string;
  author_id: number;
  title: string;
  position: number;
  width: number;
  height: number;
  link: string;
  image: string;
  renewal_at: Date;
  created_at: Date;
  updated_at: Date;
  author?: IAuthor;
}

enum ActionTypes {
  SET_TEMPLATE = '@@mainInMonth/SET_TEMPLATE',

  GET_MAIN_IN_MONTH_R = '@@mainInMonth/GET_MAIN_IN_MONTH_R',
  GET_MAIN_IN_MONTH_S = '@@mainInMonth/GET_MAIN_IN_MONTH_S',
  GET_MAIN_IN_MONTH_E = '@@mainInMonth/GET_MAIN_IN_MONTH_E',

  GET_ONE_MAIN_IN_MONTH_R = '@@mainInMonth/GET_ONE_MAIN_IN_MONTH_R',
  GET_ONE_MAIN_IN_MONTH_S = '@@mainInMonth/GET_ONE_MAIN_IN_MONTH_S',
  GET_ONE_MAIN_IN_MONTH_E = '@@mainInMonth/GET_ONE_MAIN_IN_MONTH_E',

  CREATE_MAIN_IN_MONTH_R = '@@mainInMonth/CREATE_MAIN_IN_MONTH_R',
  CREATE_MAIN_IN_MONTH_S = '@@mainInMonth/CREATE_MAIN_IN_MONTH_S',
  CREATE_MAIN_IN_MONTH_E = '@@mainInMonth/CREATE_MAIN_IN_MONTH_E',

  DELETE_MAIN_IN_MONTH_R = '@@mainInMonth/DELETE_MAIN_IN_MONTH_R',
  DELETE_MAIN_IN_MONTH_S = '@@mainInMonth/DELETE_MAIN_IN_MONTH_S',
  DELETE_MAIN_IN_MONTH_E = '@@mainInMonth/DELETE_MAIN_IN_MONTH_E',

  UPDATE_MAIN_IN_MONTH_R = '@@mainInMonth/UPDATE_MAIN_IN_MONTH_R',
  UPDATE_MAIN_IN_MONTH_S = '@@mainInMonth/UPDATE_MAIN_IN_MONTH_S',
  UPDATE_MAIN_IN_MONTH_E = '@@mainInMonth/UPDATE_MAIN_IN_MONTH_E',

  GET_TEMPLATE_R = '@@mainInMonth/GET_TEMPLATE_R',
  GET_TEMPLATE_S = '@@mainInMonth/GET_TEMPLATE_S',
  GET_TEMPLATE_E = '@@mainInMonth/GET_TEMPLATE_E',

  UPDATE_TEMPLATE_R = '@@mainInMonth/UPDATE_TEMPLATE_R',
  UPDATE_TEMPLATE_S = '@@mainInMonth/UPDATE_TEMPLATE_S',
  UPDATE_TEMPLATE_E = '@@mainInMonth/UPDATE_TEMPLATE_E',

  CREATE_TEMPLATE_R = '@@mainInMonth/CREATE_TEMPLATE_R',
  CREATE_TEMPLATE_S = '@@mainInMonth/CREATE_TEMPLATE_S',
  CREATE_TEMPLATE_E = '@@mainInMonth/CREATE_TEMPLATE_E',

  DELETE_TEMPLATE_R = '@@mainInMonth/DELETE_TEMPLATE_R',
  DELETE_TEMPLATE_S = '@@mainInMonth/DELETE_TEMPLATE_S',
  DELETE_TEMPLATE_E = '@@mainInMonth/DELETE_TEMPLATE_E',

  GET_BLOCK_R = '@@mainInMonth/GET_BLOCK_R',
  GET_BLOCK_S = '@@mainInMonth/GET_BLOCK_S',
  GET_BLOCK_E = '@@mainInMonth/GET_BLOCK_E',

  CREATE_BLOCK_R = '@@mainInMonth/CREATE_BLOCK_R',
  CREATE_BLOCK_S = '@@mainInMonth/CREATE_BLOCK_S',
  CREATE_BLOCK_E = '@@mainInMonth/CREATE_BLOCK_E',

  UPDATE_BLOCK_R = '@@mainInMonth/UPDATE_BLOCK_R',
  UPDATE_BLOCK_S = '@@mainInMonth/UPDATE_BLOCK_S',
  UPDATE_BLOCK_E = '@@mainInMonth/UPDATE_BLOCK_E',

  DELETE_BLOCK_R = '@@mainInMonth/DELETE_BLOCK_R',
  DELETE_BLOCK_S = '@@mainInMonth/DELETE_BLOCK_S',
  DELETE_BLOCK_E = '@@mainInMonth/DELETE_BLOCK_E',
}

export default ActionTypes;
