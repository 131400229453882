import { makeStyles } from '@mui/styles';

export const StylesDetails = makeStyles(() => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0 15px 0 7px',
    gap: '7px',
  },
  rowBeetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap:'5px'
  },
  textFieldTitle: {
    color: '#838587',
    margin:'0px',
    padding:'0px',
    marginLeft:'5px'
  },
  pubId:{
    width:'35%'
  },
  date:{
    width:'17%'
  },
  userIdInput:{
    color: '#838587',
  }
}));
