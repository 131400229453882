import React from 'react';
import { Box, Button, CircularProgress, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import { ROUTES } from '../../utils/routes';
import { AppStore } from '../../store/applicationState';
import { CreateMainInMonth, DeleteMainInMonth, GetMainInMonth } from '../../store/mainInMonth/actions';
import { BaseModal, WarningModal } from '../../components/modals';
import { useNavigate } from 'react-router-dom';
import { BasicDatePicker } from '../../components/ui';
import { AlertMessage } from '../../components/AlertMessage';
import { IMainInMonthYears } from '../../store/mainInMonth/types';

interface Column {
  id: 'url' | 'name' | 'coppy' | 'edit' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: 'Назва', align: 'center', width: '20%' },
  { id: 'url', label: 'URL', align: 'center', width: '50%' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '10%',
  },
  { id: 'coppy', label: 'Копіювати', align: 'center', width: '10%' },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '10%',
  },
];

interface PropsType {}

export const MonthMain: React.FC<PropsType> = () => {
  const [items, setItems] = React.useState<{ title: string; blocks: IMainInMonthYears[] }[]>([]);
  const [month, setMonth] = React.useState<Date | null>(null);
  const [dataMonth, setDataMonth] = React.useState<{ type: 'new' | 'coppy'; title?: string; id?: number } | null>(null);
  const [expend, setExpand] = React.useState<Record<string, string>>({});
  const [deleteModal, setDeleteModal] = React.useState<{ id: number; title: string } | null>(null);
  const [error, setError] = React.useState('');

  const { Configurations, MainInMonth } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(
      GetMainInMonth.request({
        data: {
          site_id: Configurations.siteId,
        },
      })
    );
  }, [dispatch, Configurations]);

  React.useEffect(() => {
    if (MainInMonth.mainInMonth) {
      const data = Object.entries(MainInMonth.mainInMonth)
        .sort((a, b) => Number(b[0]) - Number(a[0]))
        .map((items) => {
          return {
            title: items[0],
            blocks: items[1]?.sort((a, b) => a.position - b.position) || [],
          };
        });
      setItems(data);

      if (data[0]) {
        setExpand({ [data[0].title]: data[0].title });
      }
    }
  }, [MainInMonth.mainInMonth]);

  const hanldeClearDataMonth = () => {
    setDataMonth(null);
    setMonth(null);
    setError('');
  };

  const handleExpand = (id: string) => () => {
    const obj = { ...expend };

    if (obj[id]) {
      delete obj[id];
      setExpand(obj);
    } else {
      Object.assign(obj, { [id]: id });
      setExpand(obj);
    }
  };

  const handleCreate = () => {
    if (MainInMonth.loading || !dataMonth || !month) return null;
    dispatch(
      CreateMainInMonth.request({
        data: {
          site_id: Configurations.siteId,
          month: month.getMonth() + 1,
          year: month.getFullYear(),
          id: dataMonth.id,
        },
        callBack: (success, data) => {
          if (success && data) {
            hanldeClearDataMonth();
            handleChangeRoute(data.id)();
          }
          if (!success && typeof data === 'string') {
            setError(data);
          }
        },
      })
    );
  };

  const handleChangeRoute = (id: number) => () => {
    navigate(ROUTES.routeChange(`edit/${id}?site_id=${Configurations.siteId}`));
  };

  const handleDeleteCard = (id: number) => {
    dispatch(DeleteMainInMonth.request({ id }));
  };

  const handleCopyLink = (link: string) => () => {
    navigator.clipboard.writeText(link);
  };

  return (
    <Box maxWidth={'1000px'} position={'relative'}>
      {MainInMonth.loading && (
        <Box
          position={'absolute'}
          zIndex={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          height={'100%'}
        >
          <CircularProgress color='secondary' />
        </Box>
      )}
      <Box mb={2}>
        <Box>
          <Button variant='contained' color='primary' onClick={() => setDataMonth({ type: 'new' })}>
            Створити головне
          </Button>
        </Box>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ width: column?.width || '', borderBottom: 'none' }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
      {items.map((item, index) => (
        <Box key={index}>
          <Box display={'flex'} alignItems={'center'} mt={'10px'}>
            <Box>
              <IconButton onClick={handleExpand(item.title)}>
                {expend[item.title] ? <RemoveIcon fontSize='medium' /> : <AddIcon fontSize='medium' />}
              </IconButton>
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={1}
              p={'5px 10px'}
              border={'1px solid black'}
              borderRadius={'4px'}
              sx={{ width: '100%' }}
            >
              {expend[item.title] ? (
                <FolderOpenOutlinedIcon color='primary' fontSize='small' />
              ) : (
                <FolderOutlinedIcon color='primary' fontSize='small' />
              )}

              <Typography component='h6' variant='h6'>
                {item.title}
              </Typography>
            </Box>
          </Box>
          {expend[item.title] &&
            item.blocks.map((item) => (
              <Box key={item.id} p={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Box pl={5} display={'flex'}>
                  <Box width={'170px'}>{item.title}</Box>
                  <Box display={'flex'} alignItems={'center'} gap={1}>
                    <IconButton onClick={handleCopyLink(item.url)}>
                      <ContentCopyIcon fontSize='small' color='primary' />
                    </IconButton>
                    <a style={{ textDecoration: 'none' }} href={item.url} rel='noreferrer' target='_blank'>
                      {item.url}
                    </a>
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-around'}
                  width={'100%'}
                  maxWidth={'300px'}
                >
                  <IconButton onClick={handleChangeRoute(item.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => setDataMonth({ type: 'coppy', id: item.id, title: `Копіювати "${item.title}"` })}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton onClick={() => setDeleteModal({ id: item.id, title: item.title })}>
                    <DeleteIcon color='error' />
                  </IconButton>
                </Box>
              </Box>
            ))}
        </Box>
      ))}
      {deleteModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleDeleteCard(deleteModal.id);
            setDeleteModal(null);
          }}
          type='delete'
          value={` "${deleteModal.title}" `}
          open={!!deleteModal}
        />
      ) : null}

      <BaseModal
        open={!!dataMonth}
        title={dataMonth?.title || 'Створити головне у місяці'}
        onClose={hanldeClearDataMonth}
        onCancel={hanldeClearDataMonth}
        disabled={!month || MainInMonth.loading}
        onConfirm={handleCreate}
      >
        <>
          <BasicDatePicker
            format='MM.yyyy'
            id='monthMain'
            views={['year', 'month']}
            required
            label={'Місяць-Рік'}
            value={month}
            onChange={(value) => {
              setMonth(value);
              setError('');
            }}
          />
          {error && <AlertMessage type='error' message={error} />}
        </>
      </BaseModal>
    </Box>
  );
};
