import { makeStyles } from '@mui/styles';

export const StylesTable: any = makeStyles(() => ({
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    marginTop: '10px',
    padding: '0 7px 0 7px',
    maxHeight: 'calc(100vh - 290px)',
  },
  table: {
    tableLayout: 'fixed',
  },
  row: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '10px',
  },
  searchField: {
    marginRight: '10px !important',
    marginBottom: '10px !important',
  },
  errMesg: {
    position: 'absolute',
    top: '57px',
    color: 'red',
    zIndex: 1000,
    fontSize: '15px',
  },
  linkField: {
    width: '90%',
  },
  summaField: {
    width: '150px',
  },
  monthSelect: {
    width: '200px',
  },
  inflationIndex: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    width: '100%',
  },
}));
