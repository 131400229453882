import { Reducer } from 'redux';
import ActionTypes, { TProductRatingsState } from './types';

const initialState: TProductRatingsState = {
  loading: false,
  error: undefined,
  productRatings: [],
  data: null,
  pages: 25,
  total: 0,
};

const reducer: Reducer<TProductRatingsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_PRODUCT_RATINGS: {
      return {
        ...state,
        data: action.payload,
        commentators: action.payload.commentators,
        ratings: action.payload.ratings,
        loading: false,
        errors: '',
      };
    }

    case ActionTypes.RESET_PRODUCT_RATINGS:
      return {
        ...state,
        ratings: state.data ? state.data.productRatings : [],
      };

    case ActionTypes.SEARCH_PRODUCT_RATINGS_R:
    case ActionTypes.GET_PRODUCT_RATINGS_R: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }

    case ActionTypes.GET_PRODUCT_RATINGS_S: {
      return {
        ...state,
        loading: false,
        error: undefined,
        productRatings: action.payload.productRatings,
        data: action.payload,
        pages: action.payload.pages,
        total: action.payload.total,
      };
    }
    case ActionTypes.SEARCH_PRODUCT_RATINGS_S:
      return {
        ...state,
        productRatings: action.payload.productRatings,
        loading: false,
        errors: undefined,
        pages: action.payload.pages,
        total: action.payload.total,
      };

    case ActionTypes.GET_PRODUCT_RATINGS_E:
    case ActionTypes.SEARCH_PRODUCT_RATINGS_E: {
      return { ...state, loading: false, error: action.payload };
    }

    default:
      return state;
  }
};

export { reducer as ProductRatingsReducer };
