import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { StylesTable } from '../../components/Stylestable';
import { TextField } from '@mui/material';
import { SelectOutline } from '../../components/ui';
import { GetWidgetes, UpdateMainWidget, UpdateMainWidgetsPosition } from '../../store/widgets/actions';
import { monthsOptions } from '../../utils/consts';
import { IMainWidgets } from '../../store/widgets/types';
import { isValidJSONString } from '../../utils/isValidJSONString';

interface Column {
  id: 'name' | 'isPublic' | 'edit' | 'url';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: 'Назва', align: 'left', width: '10%' },
  { id: 'isPublic', label: 'Публікція', align: 'center', width: '5%' },
  { id: 'url', label: 'URL', align: 'center', width: '10%' },
  {
    id: 'edit',
    label: 'Значення',
    align: 'center',
    width: '10%',
  },
];

type PropsType = {};

export const Widgets: React.FC<PropsType> = () => {
  const { Widgetes, Configurations } = useSelector((store: AppStore) => store) as unknown as AppStore;
  const [mainWidgets, setMainWidgets] = useState<IMainWidgets[]>([]);
  const [timeout, setTimeoutState] = useState<null | NodeJS.Timeout | undefined>(null);
  const [monthIndex, setMonthIndex] = useState('');
  const [indexSumma, setIndexSumma] = useState('');
  const dispatch = useDispatch();
  const classes = StylesTable();

  useEffect(() => {
    dispatch(
      GetWidgetes.request({
        data: {
          site_id: Configurations.siteId,
        },
        callBack: (success, data) => {
          if (success && data.widgetsMain.length) {
            setMainWidgets(
              data.widgetsMain.map((widget: any) => {
                if (widget.title === 'Індекс інфляції') {
                  const parseMonth: string[] = isValidJSONString(widget.month + '')
                    ? JSON.parse(widget.month + '')
                    : [];
                  return { ...widget, month: parseMonth };
                }
                return widget;
              })
            );
          }
        },
      })
    );
  }, [dispatch, Configurations.siteId]);

  useEffect(() => {
    const indexInflWidg = Widgetes?.mainWidgets?.find((widg) => widg.title === 'Індекс інфляції');
    if (indexInflWidg) {
      
      const currentMonth = indexInflWidg?.month?.find(
        (month) => month.month === monthsOptions[indexInflWidg.selected_month || 0]?.label
      );

      if (!indexSumma) setIndexSumma(currentMonth?.summa || '');
      if (!monthIndex) setMonthIndex(currentMonth?.month || '');
    }
  }, [Widgetes, indexSumma, monthIndex]);

  const onDragEnd = (result: DropResult, event: any) => {
    if (!result.destination) {
      return;
    }

    const item = mainWidgets[result.source.index];

    const payload = [
      { id: result.draggableId, position: result.destination.index },
      { id: mainWidgets[result.destination.index]?.id, position: result.source.index },
    ];

    if (mainWidgets) {
      mainWidgets.splice(result.source.index, 1);
      mainWidgets.splice(result.destination.index, 0, item);

      dispatch(
        UpdateMainWidgetsPosition.request({
          data: {
            positions: payload,
            site_id: Configurations.siteId,
          },
          callBack: (success, data) => {
            if (success) {
              setMainWidgets(data);
            }
          },
        })
      );
    }
  };

  const debounce = (callback: (data: string) => void, delay: number) => {
    return (value: string) => {
      if (timeout) clearTimeout(timeout);
      setTimeoutState(null);

      setTimeoutState(
        setTimeout(() => {
          callback(value);
        }, delay)
      );
    };
  };
  // eslint-disable-next-line
  const debounceSave = React.useCallback(
    debounce((data) => {
      const parseData = JSON.parse(String(data));
      const item = mainWidgets?.find((el) => el.id === parseData.id);
      const keyValue = Object.keys(parseData)[0];
      if (item) {
        dispatch(
          UpdateMainWidget.request({
            data: {
              ...item,
              [keyValue]: parseData[keyValue],
              site_id: +parseData.site_id,
              ...(parseData.month ? { month: parseData.month } : {}),
              ...(parseData.selected_month ? { selected_month: parseData.selected_month } : {}),
              ...(parseData.link ? { link: parseData.link } : {}),
              ...(parseData.summa ? { summa: parseData.summa } : {}),
            },
            id: item.id,
          })
        );
      }
    }, 600),
    [mainWidgets, dispatch, timeout]
  );

  const handleChange = (data: any) => {
    const item = mainWidgets?.find((el) => el.id === data.id);
    const keyValue = Object.keys(data)[1];

    setMainWidgets(
      mainWidgets.map((el) => {
        if (el.id === data.id) {
          return {
            ...el,
            [keyValue]: data[keyValue],
            ...(data.month ? { month: data.month } : {}),
            ...(data.selected_month ? { selected_month: data.selected_month } : {}),
            ...(data.link ? { link: data.link } : {}),
            ...(data.summa ? { summa: data.summa } : {}),
          };
        }
        return el;
      })
    );

    if (item) {
      debounceSave(
        JSON.stringify({
          [keyValue]: data[keyValue],
          id: data.id,
          site_id: Configurations.siteId,
          ...(data.month ? { month: data.month } : {}),
          ...(data.selected_month ? { selected_month: data.selected_month } : {}),
          ...(data.link ? { link: data.link } : {}),
          ...(data.summa ? { summa: data.summa } : {}),
        })
      );
    }
  };

  return (
    <>
      <TableContainer className={classes.container}>
        <Table className={classes.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='documents-list'>
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps} style={{ width: '100%' }}>
                  {mainWidgets
                    .sort((a, b) => a.position - b.position)
                    ?.map((row, i) =>
                      row.title === 'Індекс інфляції' ? (
                        <Draggable draggableId={`${row.id}`} index={i} key={`${i}-${row.id}`}>
                          {(provided, snapshot) => (
                            <TableRow
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                cursor: 'grab',
                                backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white',
                                ...provided.draggableProps.style,
                              }}
                              hover
                              key={`${i}-${row.id}`}
                              ref={provided.innerRef}
                            >
                              <TableCell align='left'>{row.title}</TableCell>
                              <TableCell align='center' className={classes.row}>
                                <IconButton
                                  aria-label='edit'
                                  onClick={() => {
                                    const item = mainWidgets?.find((el) => el.id === row.id);
                                    handleChange({ id: item?.id, is_public: !item?.is_public });
                                  }}
                                >
                                  {row.is_public ? <PlayArrowIcon /> : <PauseIcon />}
                                </IconButton>
                              </TableCell>
                              <TableCell align='center' className={classes.row}>
                                {
                                  <TextField
                                    size='small'
                                    id='outlined-basic'
                                    label='Посилання'
                                    variant='outlined'
                                    value={row.link}
                                    className={classes.linkField}
                                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                                      handleChange({ id: row.id, link: e.target.value });
                                    }}
                                  />
                                }
                              </TableCell>
                              <TableCell>
                                <div className={classes.inflationIndex}>
                                  <SelectOutline
                                    required
                                    id='outlined-basic-status'
                                    title='Оберіть місяць'
                                    value={monthIndex}
                                    error={false}
                                    className={classes.monthSelect}
                                    options={monthsOptions}
                                    handleChange={(value) => {
                                      setMonthIndex(value);
                                      setIndexSumma(
                                        mainWidgets
                                          ?.find((widg) => widg.title === 'Індекс інфляції')
                                          ?.month?.find((month) => month.month === value)?.summa || ''
                                      );

                                      handleChange({
                                        id: row.id,
                                        selected_month: monthsOptions.findIndex((el) => el.label === value),
                                      });
                                    }}
                                  />
                                  <TextField
                                    className={classes.summaField}
                                    size='small'
                                    id='outlined-basic'
                                    label={'Число %'}
                                    variant='outlined'
                                    value={indexSumma}
                                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                                      if (mainWidgets) {
                                        const updatedMonths = mainWidgets
                                          ?.find((widg) => widg.title === 'Індекс інфляції')
                                          ?.month?.map((m) => {
                                            if (m.month === monthIndex) {
                                              return { ...m, summa: e.target.value };
                                            }
                                            return m;
                                          });

                                        if (updatedMonths) {
                                          handleChange({ id: row.id, summa: e.target.value, month: updatedMonths });
                                          setIndexSumma(e.target.value);
                                        }
                                      }
                                    }}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ) : row.title === 'Курс валют' ? (
                        <Draggable draggableId={`${row.id}`} index={i} key={`${i}-${row.id}`}>
                          {(provided, snapshot) => (
                            <TableRow
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                cursor: 'grab',
                                backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white',
                                ...provided.draggableProps.style,
                              }}
                              hover
                              key={`${i}-${row.id}`}
                              ref={provided.innerRef}
                            >
                              <TableCell align='left'> {` ${row.title}  станом на ${row.exchangedate}`}</TableCell>
                              <TableCell align='center' className={classes.row}>
                                <IconButton
                                  aria-label='edit'
                                  onClick={() => {
                                    const forex = mainWidgets?.find((el) => el.title === 'Курс валют');
                                    handleChange({ id: forex?.id, is_public: !forex?.is_public });
                                  }}
                                >
                                  {row.is_public ? <PlayArrowIcon /> : <PauseIcon />}
                                </IconButton>
                              </TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell>
                                <div className={classes.inflationIndex}>
                                  <TextField
                                    className={classes.summaField}
                                    size='small'
                                    id='outlined-basic'
                                    label={'Число'}
                                    variant='outlined'
                                    value={`USD  ${row.usd?.toFixed(2)}`}
                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
                                    disabled
                                    sx={{
                                      '& input': {
                                        fontWeight: 'bold', // Изменение жирности шрифта
                                      },
                                    }}
                                  />
                                  <TextField
                                    sx={{
                                      '& input': {
                                        fontWeight: 'bold', // Изменение жирности шрифта
                                      },
                                    }}
                                    disabled
                                    className={classes.summaField}
                                    size='small'
                                    id='outlined-basic'
                                    label={'Число'}
                                    variant='outlined'
                                    value={`EUR  ${row.eur?.toFixed(2)}`}
                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ) : (
                        <Draggable draggableId={`${row.id}`} index={i} key={`${i}-${row.id}`}>
                          {(provided, snapshot) => (
                            <TableRow
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                cursor: 'grab',
                                backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white',
                                ...provided.draggableProps.style,
                              }}
                              hover
                              key={`${i}-${row.id}`}
                              ref={provided.innerRef}
                            >
                              <TableCell align='left'>{row.title}</TableCell>
                              <TableCell align='center' className={classes.row}>
                                <IconButton
                                  aria-label='edit'
                                  onClick={() => {
                                    const item = mainWidgets?.find((el) => el.id === row.id);
                                    handleChange({ id: item?.id, is_public: !item?.is_public });
                                  }}
                                >
                                  {row.is_public ? <PlayArrowIcon /> : <PauseIcon />}
                                </IconButton>
                              </TableCell>
                              <TableCell align='center' className={classes.row}>
                                {
                                  <TextField
                                    size='small'
                                    id='outlined-basic'
                                    label='Посилання'
                                    variant='outlined'
                                    value={row.link}
                                    className={classes.linkField}
                                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                                      handleChange({ id: row.id, link: e.target.value });
                                    }}
                                  />
                                }
                              </TableCell>
                              <TableCell align='left'>
                                {
                                  <TextField
                                    className={classes.summaField}
                                    size='small'
                                    id='outlined-basic'
                                    label={i !== 1 ? 'Введіть суму *' : 'Число, %'}
                                    variant='outlined'
                                    value={row.summa}
                                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                                      handleChange({ id: row.id, summa: e.target.value });
                                    }}
                                  />
                                }
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      )
                    )}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    </>
  );
};
