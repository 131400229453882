import React from 'react';
import { TypeOptions } from '../ui/SelectPopup';
import { SelectSearchReq } from '../ui';
import { IDataOpt } from '../ui/SelectSearchReq';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSearchPublishers } from '../../hooks/useSearchPublishers';
import { BaseModal } from './BaseModal';

interface Props {
  values?: TypeOptions[];
  open: boolean;
  handleApprove: (approved: boolean, value: TypeOptions[]) => void;
}

export const PublishersModal: React.FC<Props> = ({ values, open, handleApprove }) => {
  const [publishers, setPublishers] = React.useState<IDataOpt[]>([{ id: 0, label: '' }]);
  const { options, handleSearch } = useSearchPublishers();

  React.useEffect(() => {
    if (values && values.length > 0) {
      const pub = values?.map((it) => ({ id: it.id, label: it.name }));
      setPublishers(pub);
    }
  }, [values]);

  const handleClose = (approved: boolean) => () => {
    handleApprove(
      approved,
      publishers.filter((it) => it.id && it.label).map((it) => ({ id: it.id, name: it.label }))
    );
  };

  const handleDelete = (idx: number) => {
    setPublishers((prev) => prev?.filter((it, i) => i !== idx));
  };

  const handleChange = (idx: number | null, value?: IDataOpt | null, isDelete?: boolean) => {
    setPublishers((prev) => {
      if (isDelete) {
        return prev?.filter((it, i) => i !== idx);
      }
      if (idx !== null) {
        return prev?.map((it, i) => {
          if (i === idx) {
            return value ? value : { id: 0, label: '' };
          }
          return it;
        });
      }
      if (prev) return [...prev, { id: 0, label: '' }];
      return [{ id: 0, label: '' }];
    });
  };

  return (
    <BaseModal
      open={open}
      onClose={handleClose(true)}
      title='Видавники'
      onConfirm={handleClose(true)}
      btnNameConfirm='Закрити'
    >
      <>
        {publishers?.map((it, i) => (
          <div key={i} style={{ display: 'flex', width: '100%', paddingTop: '10px' }}>
            <SelectSearchReq
              style={{ marginRight: '10px' }}
              title='Видавник'
              data={it}
              onChange={(dta) => handleChange(i, dta)}
              options={options}
              handleRequest={handleSearch}
            />
            <IconButton
              aria-label='add'
              style={{ marginRight: '10px', color: '#3f51b5' }}
              onClick={() => handleChange(null)}
            >
              <AddIcon />
            </IconButton>
            {publishers.length > 1 && (
              <IconButton aria-label='delete' style={{ color: '#e75d5d' }} onClick={() => handleDelete(i)}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        ))}
      </>
    </BaseModal>
  );
};
