import React from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { StylesEdit } from '../../components/StylesEdit';
import { BasicDatePicker } from '../../components/ui';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { WarningModal } from '../../components/modals';
import { StylesTable } from '../../components/Stylestable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import {
  CreateMainInMonthTemplate,
  DeleteMainInMonthTemplate,
  GetOneMainInMonth,
  MainInMonthActions,
  TypeUpdateMainInMonth,
  UpdateMainInMonth,
} from '../../store/mainInMonth/actions';
import { AppStore } from '../../store/applicationState';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import { IMonthMainTemplate } from '../../store/mainInMonth/types';
import { ROUTES } from '../../utils/routes';
import { configSites } from '../../config';

interface Column {
  id: 'name' | 'edit' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: 'Назва блоку (шаблону)', align: 'left' },
  { id: 'edit', label: 'Редагувати', align: 'center' },
  { id: 'delete', label: 'Видалити', align: 'center' },
];

interface Props {}

export const EditMonthMain: React.FC<Props> = () => {
  const [comeBackModal, setComeBackModal] = React.useState<{ id: number } | null>(null);
  const [templates, setTemplates] = React.useState<IMonthMainTemplate[]>([]);
  const [isPublic, setIsPublic] = React.useState(0);
  const [isOpenPublish, setIsOpenPublish] = React.useState(0);
  const [month, setMonth] = React.useState<Date | null>(null);
  const [publicationAt, setPublicationAt] = React.useState<Date | null>(null);
  const [errors, setErrors] = React.useState('');
  const [isEdit, setIsEdit] = React.useState(true);
  const [deleteTextField, setDeleteTextField] = React.useState<{ id: number; title: string } | null>(null);

  const { MainInMonth } = useSelector((store: AppStore) => store);
  const prevRef = React.useRef<Record<string, string>>({});

  const dispatch = useDispatch();
  const { id } = useParams();

  React.useEffect(() => {
    if (id)
      dispatch(
        GetOneMainInMonth.request({
          id: +id,
        })
      );
  }, [dispatch, id]);

  React.useEffect(() => {
    if (MainInMonth.current) {
      const month = new Date(MainInMonth.current.year, MainInMonth.current.month - 1);
      const publication_at = MainInMonth.current.publication_at || null;
      const templates = MainInMonth.current.templates.sort((a, b) => a.position - b.position);

      setMonth(month);
      setPublicationAt(publication_at);
      setIsPublic(MainInMonth.current.is_public);
      setIsOpenPublish(MainInMonth.current.is_open_publish);
      setTemplates(templates);

      prevRef.current[1] = month.toLocaleString('uk', { month: 'numeric', year: 'numeric' });
      prevRef.current[2] = publication_at
        ? new Date(publication_at).toLocaleString('uk', { day: 'numeric', month: 'numeric', year: 'numeric' })
        : '';
      prevRef.current[3] = String(MainInMonth.current.is_public);
      prevRef.current[4] = String(MainInMonth.current.is_open_publish);
      prevRef.current[5] = JSON.stringify(
        templates.map((item) => ({ id: item.template_detail_id, title: item.title, is_public: item.is_public }))
      );
    }
    // eslint-disable-next-line
  }, [MainInMonth.current]);

  React.useEffect(() => {
    const currentMonth = month?.toLocaleString('uk', { month: 'numeric', year: 'numeric' }) || '';
    const currentPublicationAt = publicationAt
      ? new Date(publicationAt).toLocaleString('uk', { day: 'numeric', month: 'numeric', year: 'numeric' })
      : '';
    const currentIsPublic = String(isPublic);
    const currentIsOpenPublish = String(isOpenPublish);
    const currentTemplate = JSON.stringify(
      templates.map((item) => ({ id: item.template_detail_id, title: item.title, is_public: item.is_public }))
    );

    if (
      prevRef.current[1] !== currentMonth ||
      prevRef.current[2] !== currentPublicationAt ||
      prevRef.current[3] !== currentIsPublic ||
      prevRef.current[4] !== currentIsOpenPublish ||
      prevRef.current[5] !== currentTemplate
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [month, publicationAt, isPublic, isOpenPublish, templates]);

  const navigate = useNavigate();
  const classes = StylesEdit();
  const classesTable = StylesTable();
  const { Configurations } = useSelector((store: AppStore) => store);

  const onSubmit = () => {
    if (MainInMonth.loading) return null;
    if (!MainInMonth.current) return null;

    if (!month) return setErrors(`Заповнення поля 'Місяць' є обов'язковим.`);
    if (!publicationAt) return setErrors(`Заповнення поля 'Дата публікації' є обов'язковим.`);

    setErrors('');
    const options: TypeUpdateMainInMonth['data'] = {
      site_id: Configurations.siteId,
      templates,
      month: month.getMonth() + 1,
      year: month.getFullYear(),
      is_public: isPublic ? 1 : 0,
      is_open_publish: isOpenPublish ? 1 : 0,
      publication_at: publicationAt || undefined,
    };

    dispatch(
      UpdateMainInMonth.request({
        data: options,
        id: MainInMonth.current.id,
        callBack: (success, err) => {
          if (!success && err) {
            setErrors(err);
          } else {
            handleCancel();
          }
        },
      })
    );
  };

  const handleDeleteTextField = (isApprove: boolean) => {
    if (isApprove && deleteTextField && !MainInMonth.loading) {
      dispatch(DeleteMainInMonthTemplate.request({ id: deleteTextField.id }));
    }
    setDeleteTextField(null);
  };

  const handleCancel = () => {
    navigate(ROUTES.routeChange(''));
  };

  const handleChangeIsPublic = () => {
    setIsPublic(isPublic ? 0 : 1);
  };

  const handleChangeIsOpenPublish = () => {
    setIsOpenPublish(isOpenPublish ? 0 : 1);
  };

  const handleChange = (template: IMonthMainTemplate) => () => {
    if (templates) {
      setTemplates((prev) => {
        return prev.map((item) => {
          const isId = !!item.template_detail_id;
          const isCheck = isId ? item.template_detail_id === template.template_detail_id : item.id === template.id;
          if (isCheck) {
            return { ...item, is_public: item.is_public ? 0 : 1 };
          }
          return item;
        });
      });
    }
  };

  const handleOpenTemplate = (template: IMonthMainTemplate) => () => {
    dispatch(MainInMonthActions.setTemplate(null));
    if (template.template_detail_id) {
      navigate(`${template.path}/${template.template_detail_id}`);
    } else {
      addTextField(template.id);
    }
  };

  const addTextField = (templateId = 11) => {
    if (MainInMonth.loading || !MainInMonth.current) return null;
    dispatch(MainInMonthActions.setTemplate(null));
    dispatch(
      CreateMainInMonthTemplate.request({
        data: {
          month_main_id: MainInMonth.current.id,
          template_id: templateId,
        },
        callBack: (success, data) => {
          if (success && data) {
            navigate(`${data.month_main_template?.path}/${data.id}`);
          }
        },
      })
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return null;

    setTemplates((prev) => {
      if (prev && result.destination) {
        const arr = [...prev];
        const item = arr[result.source.index];
        arr.splice(result.source.index, 1);
        arr.splice(result.destination.index, 0, item);
        return arr.map((item, index) => ({ ...item, position: index }));
      }
      return prev;
    });
  };

  const handleReturnBack = () => {
    if (isEdit) {
      return setComeBackModal({ id: Math.random() + 1 });
    }

    handleCancel();
  };

  const handlePreview = () => {
    if (isEdit || !MainInMonth.current) return null;

    const { month, year, preview_hash } = MainInMonth.current;
    const date = `${month < 10 ? `0${month}` : month}${year}`;

    window.open(
      `${configSites[Configurations.siteId].url}/main/preview-0${Configurations.siteId}${99}-${date}-${preview_hash}`
    );
  };

  if (!MainInMonth.current)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );

  return (
    <Box position={'relative'}>
      <div className={classes.paper}>
        {errors ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>{errors}</AlertTitle>
          </Alert>
        ) : null}
        {MainInMonth.loading && (
          <Box
            position={'absolute'}
            zIndex={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            width={'100%'}
            height={'100%'}
          >
            <CircularProgress color='secondary' />
          </Box>
        )}
        <Box className={classes.header}>
          <Typography component='h1' variant='h5'>
            Редагувати нове у місяці
          </Typography>
        </Box>
        <Box
          marginTop={4}
          width={'100%'}
          marginBottom={2}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} gap={2}>
            <BasicDatePicker
              disabled={true}
              format='MM.yyyy'
              style={{ width: '200px' }}
              id='monthMain'
              views={['year', 'month']}
              required
              label={'Місяць-Рік'}
              value={month}
              error={!!errors && !month}
              onChange={setMonth}
            />

            <BasicDatePicker
              id='updatedAt'
              required
              error={!!errors && !publicationAt}
              style={{ width: '200px' }}
              label='Дата публікації'
              value={publicationAt}
              onChange={setPublicationAt}
            />
          </Box>

          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Button disabled={MainInMonth.loading} variant='contained' color='primary' onClick={onSubmit}>
              Зберегти
            </Button>
            <Button variant='contained' color='inherit' onClick={handleReturnBack}>
              Повернутися
            </Button>
          </Box>
        </Box>
        <Box display={'flex'} marginBottom={2} alignItems={'center'} gap={8}>
          <FormControlLabel
            control={<Checkbox checked={!!isPublic} onChange={handleChangeIsPublic} />}
            label='Публікувати'
            value={isPublic}
          />
          <FormControlLabel
            control={<Checkbox checked={!!isOpenPublish} onChange={handleChangeIsOpenPublish} />}
            label='Відкрита публікація'
            value={isOpenPublish}
          />
          <IconButton disabled={isEdit} onClick={handlePreview}>
            <RemoveRedEyeOutlinedIcon color={isEdit ? 'inherit' : 'primary'} />
          </IconButton>

          <Button disabled={MainInMonth.loading} variant='contained' color='primary' onClick={() => addTextField()}>
            Додати текстове поле
          </Button>
        </Box>
        <TableContainer className={classesTable.container} style={{ maxHeight: 'calc(100vh - 330px)' }}>
          <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='documents-list'>
                {(provided) => (
                  <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                    {templates.map((template, i) => (
                      <Draggable draggableId={`${i}-${template.id}`} index={i} key={`${i}-${template.id}`}>
                        {(provided, snapshot) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              cursor: 'grab',
                              backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white',
                              ...provided.draggableProps.style,
                            }}
                            hover
                            key={`${i}-${template.id}`}
                          >
                            <TableCell>
                              <Checkbox onChange={handleChange(template)} checked={template.is_public ? true : false} />
                              {template.title}
                            </TableCell>
                            <TableCell align='center'>
                              <IconButton onClick={handleOpenTemplate(template)}>
                                <EditIcon color='primary' />
                              </IconButton>
                            </TableCell>
                            <TableCell align='center'>
                              {template.id === 11 ? (
                                <IconButton
                                  onClick={() =>
                                    setDeleteTextField({ id: template.template_detail_id, title: template.title })
                                  }
                                >
                                  <DeleteIcon color='error' />
                                </IconButton>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
        {comeBackModal ? (
          <WarningModal
            handleApprove={(isApprove) => {
              isApprove && handleCancel();
              setComeBackModal(null);
            }}
            value={`Ви впевнені, що бажаєте повернутись? Всі незбережені зміни будуть втрачені!`}
            open={!!comeBackModal}
            type='comeback'
          />
        ) : null}
        {deleteTextField ? (
          <WarningModal
            handleApprove={handleDeleteTextField}
            type='delete'
            value={` "${deleteTextField.title}" `}
            open={!!deleteTextField}
          />
        ) : null}
      </div>
    </Box>
  );
};
