import { Editor } from '@tinymce/tinymce-react';

export const deleteIframe = (editor: Editor['editor']) => () => {
  if (!editor) return null;

  const node = editor.selection.getNode();
  node?.remove();

  document.querySelector('.tox-pop')?.remove();
};
