import { makeStyles } from '@mui/styles';

export const StylesRatings = makeStyles(() => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '10px',
  },
  total:{
    marginLeft:'20px',
    fontWeight:'500'
  },
  downloadButton: {
    width: '80px',
  },
  authorSearch: {
    width: '190px',
  },
  rateSelect: {
    width: '200px',
  },
  idField: {
    width: '130px',
  },
  typeSelect: {
    width: '160px',
  },
  iconDetail: {
    color: 'inherit',
    cursor: 'pointer',
    transition: 'color 0.3s', // добавляем плавный переход цвета
    '&:hover': {
      color: '#0076e0', // изменяем цвет при наведении
    },
  },
  download: {
    backgroundColor: '#3c54b4',
    padding: '5px 10px',
    borderRadius:'4px',
    color:'white',
    display:'flex',
    justifyContent:'center',
    alignItems: 'center',
    fontWeigth:'700',
    transition: 'background-color 0.3s',
    cursor:'pointer',
    '&:hover':{
      backgroundColor:'#2c3c7c'
    }
  },
  link:{
    color:'white',
    textDecoration:'none'
  }
}));
