import { IAuthor } from '../authors/types';
import { IDocumentVisitor } from '../consultations/types';
import { RubricType } from '../rubrics/types';

export interface TCataloguesState {
  data: { catalogues: ICatalog[]; total: number; pages: number } | null;
  catalogues: ICatalog[] | null;
  visitors: IDocumentVisitor[] | null;
  current: ICatalog | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
  pages: number;
  total: number;
}

export interface ICatalog {
  id: number;
  site_id: number;
  rubric_id: number;
  author_id: number;
  title: string;
  description: string;
  body: string;
  unpublished_at: Date;
  is_public: number;
  is_parse_link: number;
  is_in_cart: number;
  is_open_publish: boolean;
  open_publication_at: Date;
  search_tags: string;
  adate: Date;
  update: Date;
  publication_at: Date;
  created_at?: Date;
  updated_at?: Date;
  rubric?: RubricType;
  author?: IAuthor;
  preview_hash?:string;
  catalog_rubric_details: {
    rubric_id: number;
    catalog_id: number;
    rubric: RubricType;
  }[];
  authors: IAuthor[];
}

enum ActionTypes {
  SET_CATALOGUES = '@@catalogues/SET_CATALOGUES',
  RESET_CATALOGUES = '@@catalogues/RESET_CATALOGUES',

  GET_CATALOGUES_R = '@@catalogues/GET_CATALOGUES_R',
  GET_CATALOGUES_S = '@@catalogues/GET_CATALOGUES_S',
  GET_CATALOGUES_E = '@@catalogues/GET_CATALOGUES_E',

  GET_CATALOG_R = '@@catalogues/GET_CATALOG_R',
  GET_CATALOG_S = '@@catalogues/GET_CATALOG_S',
  GET_CATALOG_E = '@@catalogues/GET_CATALOG_E',

  SEARCH_CATALOGUES_R = '@@catalogues/SEARCH_CATALOGUES_R',
  SEARCH_CATALOGUES_S = '@@catalogues/SEARCH_CATALOGUES_S',
  SEARCH_CATALOGUES_E = '@@catalogues/SEARCH_CATALOGUES_E',

  CREATE_CATALOG_R = '@@catalogues/CREATE_CATALOG_R',
  CREATE_CATALOG_S = '@@catalogues/CREATE_CATALOG_S',
  CREATE_CATALOG_E = '@@catalogues/CREATE_CATALOG_E',

  UPDATE_CATALOG_R = '@@catalogues/UPDATE_CATALOG_R',
  UPDATE_CATALOG_S = '@@catalogues/UPDATE_CATALOG_S',
  UPDATE_CATALOG_E = '@@catalogues/UPDATE_CATALOG_E',

  DELETE_CATALOG_R = '@@catalogues/DELETE_CATALOG_R',
  DELETE_CATALOG_S = '@@catalogues/DELETE_CATALOG_S',
  DELETE_CATALOG_E = '@@catalogues/DELETE_CATALOG_E',

  CHECK_CATALOGUES_VISITOR_R = '@@catalogues/CHECK_CATALOGUES_VISITOR_R',
  CHECK_CATALOGUES_VISITOR_S = '@@catalogues/CHECK_CATALOGUES_VISITOR_S',
  CHECK_CATALOGUES_VISITOR_E = '@@catalogues/CHECK_CATALOGUES_VISITOR_E',

  DELETE_CATALOGUES_VISITOR_R = '@@catalogues/DELETE_CATALOGUES_VISITOR_R',
  DELETE_CATALOGUES_VISITOR_S = '@@catalogues/DELETE_CATALOGUES_VISITOR_S',
  DELETE_CATALOGUES_VISITOR_E = '@@catalogues/DELETE_CATALOGUES_VISITOR_E',
}

export default ActionTypes;
