import { Reducer } from 'redux';
import ActionTypes, { TDefinitionsState } from './types';

export const initialState: TDefinitionsState = {
  data: null,
  definitions: null,
  pages: 1,
  current: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TDefinitionsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_DEFINITIONS: {
      return {
        ...state,
        data: action.payload,
        definitions: action.payload.definitions,
        loading: false,
        errors: '',
      };
    }

    case ActionTypes.GET_DEFINITIONS_S:
      return {
        ...state,
        definitions: action.payload.definitions,
        pages: action.payload.pages,
        data: action.payload,
        loading: false,
        errors: '',
      };

    case ActionTypes.RESET_DEFINITIONS:
      return {
        ...state,
        definitions: state.data ? state.data.definitions : null,
      };

    case ActionTypes.GET_DEFINITIONS_R:
    case ActionTypes.SEARCH_DEFINITIONS_R:
    case ActionTypes.CREATE_DEFINITION_R:
    case ActionTypes.UPDATE_DEFINITION_R:
    case ActionTypes.DELETE_DEFINITION_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.SEARCH_DEFINITIONS_S: {
      return {
        ...state,
        definitions: action.payload.definitions,
        pages: action.payload.pages,
        loading: false,
        errors: '',
      };
    }
    case ActionTypes.GET_DEFINITION_R:
      return {
        ...state,
        current: null,
      };

    case ActionTypes.GET_DEFINITION_S:
      return {
        ...state,
        loading: false,
        errors: '',
        current: action.payload,
      };

    case ActionTypes.CREATE_DEFINITION_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? { ...state.data, definitions: [action.payload, ...state.data.definitions] }
          : { definitions: [action.payload], total: 1 },
        definitions: state.definitions ? [action.payload, ...state.definitions] : [action.payload],
      };

    case ActionTypes.UPDATE_DEFINITION_S: {
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? {
              ...state.data,
              definitions: state.data.definitions.map((it) => (it.id === action.payload.id ? action.payload : it)),
            }
          : state.data,
        definitions: state.definitions
          ? state.definitions.map((it) => (it.id === action.payload.id ? action.payload : it))
          : state.definitions,
        current: action.payload,
      };
    }
    case ActionTypes.DELETE_DEFINITION_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? {
              ...state.data,
              definitions: state.data.definitions.filter((it) => it.id !== action.payload),
            }
          : state.data,
        definitions: state.definitions?.filter((it) => it.id !== action.payload),
      };

    case ActionTypes.GET_DEFINITIONS_E:
    case ActionTypes.SEARCH_DEFINITIONS_E:
    case ActionTypes.CREATE_DEFINITION_E:
    case ActionTypes.UPDATE_DEFINITION_E:
    case ActionTypes.DELETE_DEFINITION_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as DefinitionsReducer };
