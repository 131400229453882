import React from 'react';
import { Box, Alert, AlertTitle } from '@mui/material';

interface Props {
  type: 'success' | 'error';
  message: string;
}
export const AlertMessage: React.FC<Props> = ({ type, message }) => {
  return (
    <Box mt={2}>
      <Alert severity={type}>
        <AlertTitle>{message}</AlertTitle>
      </Alert>
    </Box>
  );
};
