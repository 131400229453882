import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../store/applicationState';
import { CheckOffDocVisitors, DeleteOffDocVisitors, GetDoc, setEditDoc } from '../store/officialDoc/actions';
import CircularProgress from '@mui/material/CircularProgress';
import { EditOfficial } from '../components/OfficialDoc/EditOfficial';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { AlertModal } from '../components/modals/AlertModal';

export const EditDocument: React.FC = () => {
  const { OfficialDoc } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();

  React.useEffect(() => {
    const docId = Number(id);
    if (!Number.isNaN(docId) && !OfficialDoc.editData) {
      dispatch(
        GetDoc.request({
          id: docId,
          callBack: (success, data) => {
            if (success && data) {
              dispatch(setEditDoc({ type: 'edit', data: { ...data, docId: data.id } }));
            }
          },
        })
      );
    }
  }, [dispatch, id, OfficialDoc.editData]);

  React.useEffect(() => {
    if (id)
      dispatch(
        CheckOffDocVisitors.request({
          id: +id,
        })
      );
  }, [id, dispatch]);

  React.useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      dispatch(DeleteOffDocVisitors.request({ id: !isNaN(Number(id)) ? Number(id) : 0 }));
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      dispatch(DeleteOffDocVisitors.request({ id: !isNaN(Number(id)) ? Number(id) : 0 }));
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate, dispatch, id]);

  if (!OfficialDoc.editData) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );
  }

  return (
    <>
      <AlertModal visitors={OfficialDoc.visitors} />
      <EditOfficial
        title='документ'
        type={OfficialDoc.editData.type}
        data={OfficialDoc.editData.data}
        handleCancel={() => {
          dispatch(DeleteOffDocVisitors.request({ id: !isNaN(Number(id)) ? Number(id) : 0 }));
          navigate('/');
        }}
      />
    </>
  );
};
