import { Reducer } from 'redux';
import ActionTypes, { TOfficialDocBlocksState } from './types';

export const initialState: TOfficialDocBlocksState = {
  data: null,
  editData: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TOfficialDocBlocksState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BLOCK_R:
    case ActionTypes.UPDATE_BLOCK_R:
    case ActionTypes.UPDATE_BLOCK_MONTH_R:
    case ActionTypes.UPDATE_BLOCK_PUBLIC_R:
    case ActionTypes.ACTUALIZATION_BLOCK_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.GET_BLOCK_E:
    case ActionTypes.UPDATE_BLOCK_E:
    case ActionTypes.DELETE_SUB_BLOCK_E:
    case ActionTypes.UPDATE_BLOCK_MONTH_E:
    case ActionTypes.UPDATE_BLOCK_PUBLIC_E:
    case ActionTypes.ACTUALIZATION_BLOCK_E:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.SET_EDIT_DATA:
      return { ...state, editData: action.payload };

    case ActionTypes.GET_BLOCK_S:
      return { ...state, loading: false, data: action.payload };

    case ActionTypes.ACTUALIZATION_BLOCK_S:
    case ActionTypes.UPDATE_BLOCK_S:
    case ActionTypes.UPDATE_BLOCK_MONTH_S:
    case ActionTypes.UPDATE_BLOCK_PUBLIC_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? state.data.map((block) => (block.id === action.payload.id ? action.payload : block))
          : [action.payload],
        editData: state.editData ? (state.editData.id === action.payload.id ? action.payload : null) : null,
      };

    case ActionTypes.DELETE_SUB_BLOCK_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? state.data.map((block) => {
              if (block.id === action.payload.blockId) {
                const subBlocks = block.subBlocks.filter((sub) => sub.id !== action.payload.id);
                return { ...block, subBlocks };
              }
              return block;
            })
          : state.data,
      };

    default:
      return state;
  }
};

export { reducer as OfficialDocBlocksReducer };
