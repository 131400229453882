import { Reducer } from 'redux';
import ActionTypes, { TCatalogSelectionState } from './types';

export const initialState: TCatalogSelectionState = {
  selections: null,
  titleIdsPairs: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TCatalogSelectionState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SEARCH_FREE_IDS_R:
    case ActionTypes.GET_FREE_IDS_R:
    case ActionTypes.GET_SELECTIONS_R:
    case ActionTypes.CREATE_SELECTION_R:
    case ActionTypes.UPDATE_SELECTION_R:
    case ActionTypes.UPDATE_SELECTION_POSITIONS_R:
    case ActionTypes.DELETE_SELECTION_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.GET_SELECTIONS_S:
    case ActionTypes.UPDATE_SELECTION_POSITIONS_S: {
      return { ...state, loading: false, selections: action.payload };
    }

    case ActionTypes.CREATE_SELECTION_S:
      return {
        ...state,
        loading: false,
        selections: state.selections ? [...state.selections, action.payload] : action.payload,
      };

    case ActionTypes.UPDATE_SELECTION_S:
      return {
        ...state,
        loading: false,
        selections: state.selections?.map((it) => (it.id === action.payload.id ? action.payload : it)),
      };

    case ActionTypes.DELETE_SUB_SELECTION_S: {
      return {
        ...state,
        loading: false,
        selections: state.selections?.map((it) =>
          it.id === action.payload.selectionId
            ? { ...it, subBlocks: it.subBlocks.filter((sub) => sub.id !== action.payload.id) }
            : it
        ),
      };
    }

    case ActionTypes.DELETE_SELECTION_S:
      return { ...state, loading: false, selections: state.selections?.filter((it) => it.id !== action.payload.id) };
    
    case ActionTypes.SEARCH_FREE_IDS_S:
    case ActionTypes.GET_FREE_IDS_S:
      return { ...state, loading: false, errors: undefined, titleIdsPairs: action.payload };
 
  

    case ActionTypes.SEARCH_FREE_IDS_E:
    case ActionTypes.GET_FREE_IDS_E:
    case ActionTypes.GET_SELECTIONS_E:
    case ActionTypes.CREATE_SELECTION_E:
    case ActionTypes.UPDATE_SELECTION_E:
    case ActionTypes.DELETE_SUB_SELECTION_E:
    case ActionTypes.UPDATE_SELECTION_POSITIONS_E:
    case ActionTypes.DELETE_SELECTION_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as SelectionsReducer };
