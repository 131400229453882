import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const sxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '4px',
  minWidth: '600px',
  padding: '20px',
};

interface Props {
  open: boolean;
  title?: string;
  disabled?: boolean;
  children?: React.ReactElement;
  btnNameCancel?: string;
  btnNameConfirm?: string;
  style?: React.CSSProperties;
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const BaseModal: React.FC<Props> = ({
  open,
  title,
  disabled,
  btnNameCancel,
  btnNameConfirm,
  onClose,
  onCancel,
  onConfirm,
  children,
  style,
}) => {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Box style={style} sx={sxStyle} display={'flex'} flexDirection={'column'} gap={2}>
        {title ? (
          <Typography component='h1' variant='h5'>
            {title}
          </Typography>
        ) : null}
        {children}
        <Box display={onCancel || onConfirm ? 'flex' : 'none'} justifyContent={'space-between'}>
          {onCancel ? (
            <Button color='inherit' variant='contained' onClick={onCancel}>
              {btnNameCancel || 'Cкасувати'}
            </Button>
          ) : null}
          {onConfirm ? (
            <Button disabled={disabled} color='primary' variant='contained' onClick={onConfirm}>
              {btnNameConfirm || 'Зберегти'}
            </Button>
          ) : null}
        </Box>
      </Box>
    </Modal>
  );
};
