import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Rubrics } from '../Rubrics';
import { Box, Button } from '@mui/material';
import { ROUTES } from '../../utils/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { Roles } from '../../utils/consts';

type PropsType = {
  element: JSX.Element;
};

const menu = [
  { name: 'Рубрикатор', path: '' },
  { name: 'Список сервісів', path: 'list' },
  { name: 'Головна', path: 'main' },
  { name: 'Калькулятори', path: 'calculators' },
  { name: 'Навігатори', path: 'navigators' },
];

export const ServiceWorkspace: React.FC<PropsType> = ({ element }) => {
  const [tab, setTab] = React.useState(0);

  const { Configurations, User } = useSelector((store: AppStore) => store);
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const index = menu.findIndex((item) => item.path && location.pathname.includes(item.path));
    setTab(index < 0 ? 0 : index);
  }, [location.pathname, Configurations.siteId]);

  const handleChangeRoute =
    (path = '') =>
    () => {
      navigate(ROUTES.routeChange(path));
    };

  const renderRoutePage = () => {
    if (tab === 0) {
      return <Rubrics />;
    }
    return element;
  };

  if (![Roles.HEAD, Roles.ADMIN].includes(User.data?.role || 0) || !User.data?.is_service) {
    return null;
  }

  return (
    <>
      <Box display={'flex'} mb={2} gap={1}>
        {menu.map((item, idx) => (
          <Button
            key={idx}
            variant={tab === idx ? 'contained' : 'outlined'}
            color={tab === idx ? 'primary' : 'inherit'}
            onClick={handleChangeRoute(item.path)}
          >
            {item.name}
          </Button>
        ))}
      </Box>
      {renderRoutePage()}
    </>
  );
};
