import { Editor } from '@tinymce/tinymce-react';
import { findNearestAncestorWithClassName } from '../template/helpers/findNearestAncestorWithClassName';

export const deleteTemplateFile = (editor: Editor['editor']) => () => {
  if (!editor) return null;

  const node = editor.selection.getNode();
  if (!node) return null;

  const parent = findNearestAncestorWithClassName(node, ['link-download']);
  if (!parent) return null;

  if (parent.parentElement) parent.parentElement.innerHTML = '&nbsp;';

  parent.remove();

  document.querySelector('.tox-pop')?.remove();
};
