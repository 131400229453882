interface IData {
  imgSrc: string;
  items: { vertically: number | null; horizontally: number | null; description: string | null }[];
}

export const changeLiveSample = (value: IData, node: Element | null, callback: (node: Element) => void) => {
  const children: HTMLDivElement[] = [];

  if (!node || !node.classList.contains('templates-live-sample')) return null;

  const container = document.createElement('div');
  container.classList.add('templates-live-sample');
  container.setAttribute('contenteditable', 'false');
  container.id = node.id;

  const body = document.createElement('div');
  body.classList.add('templates-live-sample-body');

  const img = document.createElement('img');
  img.src = value.imgSrc;
  img.classList.add('templates-live-sample-image');

  value.items.forEach((item) => {
    if (!item.description || item.horizontally === null || item.vertically === null) return null;

    const div = document.createElement('div');
    div.classList.add('templates-live-sample-item');
    div.style.left = `${item.horizontally}%`;
    div.style.top = `${item.vertically}%`;

    const pointDiv = document.createElement('div');
    pointDiv.classList.add('templates-live-sample-item-point');

    const pointCenterDiv = document.createElement('div');
    pointCenterDiv.classList.add('templates-live-sample-item-point-center');

    const descriptionDiv = document.createElement('div');
    descriptionDiv.classList.add('templates-live-sample-item-description');
    descriptionDiv.textContent = item.description;

    pointDiv.appendChild(pointCenterDiv);

    div.append(pointDiv, descriptionDiv);

    children.push(div);
  });

  body.append(img, ...children);
  container.append(body);

  node.replaceWith(container);
  callback(container);
};
