import { Editor } from '@tinymce/tinymce-react';

export const downloadfile = (editor: Editor['editor']) => async () => {
  if (!editor) return null;

  try {
    const node = editor.$('a[data-mce-selected="inline-boundary"]')[0];
    if (!node || node.nodeName.toLowerCase() !== 'a') return null;

    const url = editor.$(node).attr('href');
    if (url) {
      const response = await fetch(url);
      const blobImage = await response.blob();
      const href = URL.createObjectURL(blobImage);
      const anchorElement = document.createElement('a');
      anchorElement.href = href;

      const filename = url.split('/').pop() || '';
      anchorElement.download = decodeURI(filename);

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    }
  } catch (err) {
    console.log(err);
  }
};
