import React from 'react';
import { useSelector } from 'react-redux';
import { Button, CircularProgress, CssBaseline, TextField, Typography } from '@mui/material';
import { AppStore } from '../../store/applicationState';
import TextFieldTime from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { IVideo } from '../../store/video/types';
import { TypeCreateVideoR } from '../../store/video/actions';
import { SelectRubrics, InputTime, BasicDatePicker, SelectPopup } from '../ui';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { StylesEdit } from '../StylesEdit';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { EditorDefault, EditorFeatured } from '../Editors';
import { IDataOpt, SelectSearchReq } from '../ui/SelectSearchReq';
import { useSearchAuthors } from '../../hooks/useSearchAuthors';
import { CATEGORIES } from '../../utils/consts';
import { AlertMessage } from '../AlertMessage';
import { RubricsModal } from '../modals';

interface PropsType {
  type: 'edit' | 'new';
  data?: IVideo | null;
  handleSubmit: (data: TypeCreateVideoR['data'], callBack: (err?: { name: string; message: string }) => void) => void;
  handleCancel: () => void;
}

export const VideoForm: React.FC<PropsType> = ({ type, data, handleSubmit, handleCancel }) => {
  const [searchTags, setSearchTags] = React.useState<string>(data?.search_tags || '');
  const [title, setTitle] = React.useState<string>(data?.title || '');
  const [authorFirst, setAuthorFirst] = React.useState<IDataOpt | null>(null);
  const [authorSecond, setAuthorSecond] = React.useState<IDataOpt | null>(null);
  const [rubricId, setRubricId] = React.useState<string>(data ? String(data.rubric_id) : '');
  const [rubricIds, setRubricIds] = React.useState<{ id: number; name: string }[]>([]);
  const [baseUrl, setBaseUrl] = React.useState<string>(data?.base_url || '');
  const [publicationAt, setPublicationAt] = React.useState<Date | null>(data?.publication_at || null);
  const [urls, setUrls] = React.useState<{ name: string; url: string; time: string }[]>(
    data?.urls && data?.urls.length > 0 ? data.urls : [{ name: '', url: '', time: '' }]
  );
  const [isPopular, setIsPopular] = React.useState(data?.is_popular || 0);
  const [isRecommendation, setIsRecommendation] = React.useState(data?.is_recommendation || 0);
  const [isPublic, setIsPublic] = React.useState(data?.is_public || 0);
  const [error, setError] = React.useState<Record<string, string>>({});

  const editorDefRef = React.useRef<any | null>(null);
  const { Video, Rubrics, Configurations } = useSelector((store: AppStore) => store);
  const { options, handleSearchAuthors } = useSearchAuthors();
  const classes = StylesEdit();
  const presentationEditorRef = React.useRef<any | null>(null);

  React.useEffect(() => {
    if (data) {
      if (data.authors[0]) setAuthorFirst({ id: data.authors[0].id, label: data.authors[0].fio });
      if (data.authors[1]) setAuthorSecond({ id: data.authors[1].id, label: data.authors[1].fio });

      if (data.video_rubric_details.length > 0) {
        setRubricIds(data.video_rubric_details.map((it) => ({ id: it.rubric.id, name: it.rubric.name })));
      }
    }
  }, [data]);

  const handleChange = (name: string, callBack: (value: any) => void) => (value: any) => {
    const obj = { ...error };
    delete obj[name];
    setError(obj);
    callBack(value);
  };

  const onSubmit = () => {
    if (!title) return setError({ title: 'Заголовок має бути заповненим' });
    if (!publicationAt) return setError({ publicationAt: 'Дата дата публікації має бути заповнена.' });
    if (!Number(rubricId)) return setError({ rubricId: 'Рубрика має бути заповнена' });
    setError({});

    const description = editorDefRef.current?.getContent();
    const body = presentationEditorRef.current?.getContent();

    const authorsIds = [authorFirst, authorSecond]
      .map((it) => Number(it?.id))
      .filter((num) => !!num && !Number.isNaN(num));

    handleSubmit(
      {
        body,
        isPublic,
        publication_at: publicationAt,
        authorsIds,
        title,
        description,
        baseUrl,
        isPopular,
        isRecommendation,
        siteId: data ? data.site_id : Configurations.siteId,
        rubricId: Number(rubricId),
        searchTags,
        is_in_cart: data?.is_in_cart || 0,
        rubricsIds: rubricIds.map((it) => it.id),
        urls: urls
          ?.filter((it) => it.name && it.time && it.url)
          .map((it) => {
            if (it.time.length < 4) {
              const num = 4 - it.time.length;
              return {
                ...it,
                time: it.time + Array(num).fill(0).join(''),
              };
            }
            return it;
          }),
      },
      (err) => {
        if (err) {
          setError({ [err.name]: err.message });
        }
      }
    );
  };

  const handleChangeUrls = (idx: number | null, type: 'url' | 'time' | 'name' | 'delete', value: string) => {
    setUrls((prev) => {
      if (type === 'delete') {
        return prev?.filter((it, i) => i !== idx);
      }
      if (idx !== null) {
        return prev?.map((it, i) => {
          if (i === idx) {
            const obj: any = {};
            obj[type] = value;
            return { ...it, ...obj };
          }
          return it;
        });
      }
      if (prev) return [...prev, { url: '', time: '' }];
      return [{ url: '', time: '' }];
    });
  };

  const downloadTemplate = {
    title: 'Посилання на скачування',
    description: 'Посилання на скачування у форматах .docx, .xlsx, .pdf, .png, .jpeg .pptx',
    content: `<a class="link-download" href="#/" download><strong>Завантажте презентацію >></strong></a>`,
  };

  return (
    <Box>
      <CssBaseline />
      <div className={classes.paper}>
        <Box className={classes.header}>
          <Typography component='h1' variant='h5'>
            {type === 'new' ? 'Створити відео' : 'Редагувати відео'}
          </Typography>
          {data && (
            <Box style={{ display: 'flex' }}>
              <Box className={classes.headerItem}>Дата створення {moment(data?.adate).format('DD.MM.YYYY HH:mm')}</Box>
              <Box>Дата редагування {moment(data?.update).format('DD.MM.YYYY HH:mm')}</Box>
            </Box>
          )}
        </Box>
        <FormControlLabel
          style={{ marginTop: '7px' }}
          control={<Checkbox checked={!!isPublic} />}
          onChange={() => setIsPublic(isPublic ? 0 : 1)}
          label='Публікувати'
        />
        <FormControlLabel
          style={{ marginTop: '7px' }}
          control={<Checkbox checked={!!isPopular} />}
          onChange={() => setIsPopular(isPopular ? 0 : 1)}
          label='Популярне'
        />
        <FormControlLabel
          style={{ marginTop: '7px' }}
          control={<Checkbox checked={!!isRecommendation} />}
          onChange={() => setIsRecommendation(isRecommendation ? 0 : 1)}
          label='Рекомендація'
        />
        <TextField
          style={{ marginTop: '7px' }}
          size='small'
          id='outlined-basic'
          label='Теги'
          variant='outlined'
          value={searchTags}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setSearchTags(event.target.value as string);
          }}
        />
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Заголовок'
          variant='outlined'
          value={title}
          error={!!error['title']}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            handleChange('title', setTitle)(event.target.value);
          }}
        />
        <div className={classes.textField} style={{ display: 'flex' }}>
          <BasicDatePicker
            id='publicationAt'
            required
            label='Дата публікації'
            value={publicationAt}
            error={!!error['publicationAt']}
            onChange={handleChange('publicationAt', setPublicationAt)}
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <EditorDefault placeholder='Опис' editorRef={editorDefRef} initialValue={data?.description} />
        </div>
        <SelectRubrics
          required
          title='Рубрикатор'
          value={rubricId}
          error={!!error['rubricId']}
          className={classes.textField}
          options={Rubrics.rubrics && Rubrics.rubrics[CATEGORIES.VIDEO]}
          onChange={handleChange('rubricId', setRubricId)}
        />
        <SelectPopup
          title='Додатк. Рубрикатор'
          value={rubricIds}
          className={classes.textField}
          options={Rubrics.rubrics && Rubrics.rubrics[CATEGORIES.VIDEO]}
          onChange={setRubricIds}
          limit={3}
          PropsModal={RubricsModal}
        />
        <SelectSearchReq
          title='Автор'
          data={authorFirst}
          className={classes.textField}
          onChange={setAuthorFirst}
          options={options}
          handleRequest={handleSearchAuthors}
        />
        <SelectSearchReq
          title='Автор'
          data={authorSecond}
          className={classes.textField}
          onChange={setAuthorSecond}
          options={options}
          handleRequest={handleSearchAuthors}
        />
        <TextField
          size='small'
          id='outlined-basic'
          label='Базовий URL'
          variant='outlined'
          value={baseUrl}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setBaseUrl(event.target.value as string);
          }}
        />
        <Typography style={{ marginTop: '10px', marginBottom: '10px' }} component='h1' variant='h5'>
          Презентація
        </Typography>

        <EditorFeatured
          customTemplates={[downloadTemplate]}
          categoryId={CATEGORIES.VIDEO}
          editorRef={presentationEditorRef}
          initialValue={data?.body}
          siteId={Configurations.siteId}
        />

        <Typography style={{ marginTop: '10px' }} component='h1' variant='h5'>
          Фрагменти
        </Typography>
        {urls
          ? urls.map((it, i) => (
              <div key={i} style={{ display: 'flex', width: '100%', paddingTop: '10px' }}>
                <TextField
                  size='small'
                  id='outlined-basic'
                  label='Назва'
                  variant='outlined'
                  value={it.name}
                  style={{ marginRight: '20px', minWidth: '375px', width: '30%' }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    handleChangeUrls(i, 'name', event.target.value as string);
                  }}
                />
                <TextField
                  size='small'
                  id='outlined-basic'
                  label='URL'
                  variant='outlined'
                  value={it.url}
                  style={{ marginRight: '20px', minWidth: '375px', width: '31%' }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    handleChangeUrls(i, 'url', event.target.value as string);
                  }}
                />
                <TextFieldTime
                  size='small'
                  name='numberformat'
                  id='formatted-numberformat-input'
                  label='Тривалість'
                  variant='outlined'
                  value={it.time}
                  InputProps={{
                    inputComponent: InputTime as any,
                  }}
                  style={{ marginRight: '20px', width: '110px' }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    handleChangeUrls(i, 'time', event.target.value as string);
                  }}
                />
                <IconButton
                  aria-label='add'
                  style={{ marginRight: '20px', color: '#3f51b5' }}
                  onClick={() => handleChangeUrls(null, 'time', '')}
                >
                  <AddIcon />
                </IconButton>
                {urls.length > 1 && (
                  <IconButton
                    aria-label='delete'
                    style={{ color: '#e75d5d' }}
                    onClick={() => handleChangeUrls(i, 'delete', '')}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ))
          : null}
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={Video.loading} onClick={onSubmit}>
            {Video.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {Object.keys(error).length ? <AlertMessage type='error' message={Object.entries(error)[0][1]} /> : null}
      </div>
    </Box>
  );
};
