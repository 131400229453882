import React from 'react';
import { MainMonthBlockForm } from '../../../../components/MainInMonth/MainMonthBlockForm';

export default function Form({ title, type }: { title?: string; type: 'new' | 'edit' }) {
  return (
    <MainMonthBlockForm
      type={type}
      stepBack={3}
      name={`${type === 'new' ? 'Створити' : 'Редагувати'} блок шаблону ${title || 'Банер'}`}
      fields={{
        image: { require: true },
        link: { require: false },
      }}
    />
  );
}
