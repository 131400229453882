import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { AlertMessage } from '../AlertMessage';
import { collectionValidate } from '../../utils/collectionValidate';

interface PropsType {
  handleClose?: () => void;
  handleSubmit: (values: string, callback: (success: boolean) => void) => void;
}

export const FieldNewPasswd: React.FC<PropsType> = ({ handleSubmit, handleClose }) => {
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleChangePasswd = () => {
    if (!password) return setError('Обов’язкове поле');
    if (!collectionValidate['password'].checkValue(password)) {
      return setError(collectionValidate['password'].error);
    }
    setLoading(true);
    handleSubmit(password, (success) => {
      if (!success) setError('Сталася помилка');
      if (success && handleClose) {
        handleClose();
      }
      setLoading(false);
    });
  };

  return (
    <Box width={'900px'} mt={2}>
      <Box display={'flex'}>
        <TextField
          fullWidth
          size='small'
          id='outlined-basic-password'
          label='Пароль'
          variant='outlined'
          value={password}
          onChange={(event) => {
            if (!collectionValidate['password'].checkValue(password)) {
              setError(collectionValidate['password'].error);
            } else {
              setError('');
            }
            setPassword(event.target.value);
          }}
        />
        <Button
          variant='contained'
          color='primary'
          disabled={loading}
          onClick={handleChangePasswd}
          style={{ marginLeft: '30px' }}
        >
          {loading ? <CircularProgress size={15} /> : 'Зберегти'}
        </Button>
      </Box>
      {error ? <AlertMessage type='error' message={error} /> : null}
    </Box>
  );
};
