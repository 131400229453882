import { Editor } from '@tinymce/tinymce-react';
import { replaceQuotes } from '../../../../utils/replaceQuotesParser';

const recursiveReplaceQuotes = (element: Element | ChildNode) => {
  if (!element) return null;

  for (let i = 0; i < element.childNodes.length; i++) {
    const node = element.childNodes[i];
    if (node.nodeName === '#text' && node.textContent) {
      node.textContent = replaceQuotes(node.textContent);
    } else {
      recursiveReplaceQuotes(node);
    }
  }
};

export const changeQuotes = (editor?: Editor['editor'] | null) => {
  if (!editor) return;

  const selectedBlocks = editor.selection.getSelectedBlocks();
  selectedBlocks.forEach(recursiveReplaceQuotes);

  const newContent = editor.getContent();

  return editor.setContent(
    newContent.replaceAll('&raquo;', '<mark>&raquo;</mark>').replaceAll('&laquo;', '<mark>&laquo;</mark>')
  );
};
