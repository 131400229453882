import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { StylesTable } from '../../components/Stylestable';
import { EditUniversalWidgets } from '../../components/Widgets/EditUniversalWidgets';
import { AppStore } from '../../store/applicationState';
import { IEditData } from '../../components/Widgets/WidgetsWorkSpace';
import { GetWidgetes, UpdateAdditionalWidget, UpdateAdditionalWidgetsPosition } from '../../store/widgets/actions';

interface Column {
  id: 'name' | 'isPublic' | 'edit';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: 'Назва', align: 'left', width: '10%' },
  { id: 'isPublic', label: 'Публікція', align: 'center', width: '5%' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '5%',
  },
];

type PropsType = {
  editData?: IEditData | undefined | null;
  setEditData?: (data: IEditData | null | undefined) => void;
};

export const Universal: React.FC<PropsType> = ({ editData, setEditData }) => {
  const { Widgetes, Configurations } = useSelector((store: AppStore) => store);
  const [widgets, setWidgets] = useState(
    Widgetes.additionalWidgets.filter((el) => el.type_id === 1).sort((a, b) => a.position - b.position)
  );

  const dispatch = useDispatch();
  const classes = StylesTable();
  useEffect(() => {
    dispatch(
      GetWidgetes.request({
        data: {
          site_id: Configurations.siteId,
        },
      })
    );
  }, [dispatch, Configurations]);
  const onPublic = (id: number, value: 1 | 0) => {
    const item = widgets.find((el) => el.id === id);

    if (item) {
      dispatch(
        UpdateAdditionalWidget.request({
          data: {
            ...item,
            is_public: value,
            site_id: Configurations.siteId,
          },
          id,
        })
      );
    }
  };

  useEffect(() => {
    setWidgets(Widgetes.additionalWidgets.filter((el) => el.type_id === 1).sort((a, b) => a.position - b.position));
  }, [Widgetes]);

  const onDragEnd = (result: DropResult, event: any) => {
    if (!result.destination) {
      return;
    }
    const item = widgets[result.source.index];

    const payload = [
      { id: result.draggableId, position: result.destination.index },
      { id: widgets[result.destination.index]?.id, position: result.source.index },
    ];

    widgets.splice(result.source.index, 1);
    widgets.splice(result.destination.index, 0, item);

    setWidgets(widgets);

    dispatch(
      UpdateAdditionalWidgetsPosition.request({
        data: {
          positions: payload,
          site_id: Configurations.siteId,
        },
      })
    );
  };

  if (editData) {
    return (
      <EditUniversalWidgets
        type={editData.type}
        data={editData.data}
        handleCancel={() => setEditData && setEditData(null)}
      />
    );
  }

  return (
    <>
      <TableContainer className={classes.container}>
        <Table className={classes.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='documents-list'>
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps} style={{ width: '100%' }}>
                  {widgets
                    .sort((a: any, b: any) => {
                      return Number(a.position) - Number(b.position);
                    })
                    .map((row, i) => {
                      return (
                        <Draggable draggableId={`${row.id}`} index={i} key={`${i}-${row.id}`}>
                          {(provided, snapshot) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                cursor: 'grab',
                                backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white',
                                ...provided.draggableProps.style,
                              }}
                              hover
                              key={`${i}-${row.id}`}
                            >
                              <TableCell align='left'>{row.title}</TableCell>
                              <TableCell align='center' className={classes.row}>
                                <IconButton
                                  aria-label='edit'
                                  onClick={() => {
                                    onPublic(row.id, row.is_public === 1 ? 0 : 1);
                                  }}
                                >
                                  {row.is_public ? <PlayArrowIcon /> : <PauseIcon />}
                                </IconButton>
                              </TableCell>
                              <TableCell align='center'>
                                <IconButton
                                  aria-label='edit'
                                  onClick={() => setEditData && setEditData({ data: row, type: 'edit' })}
                                >
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      );
                    })}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    </>
  );
};
