import React from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import Box from '@mui/material/Box';
import { DeleteFile } from '../../../store/officialDocFiles/actions';
import { IOfficialDocFile } from '../../../store/officialDocFiles/types';
import { StylesTable } from '../../Stylestable';
import { EditFile } from './EditFile';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { WarningModal } from '../WarningModal';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { API_URL } from '../../../config';
import moment from 'moment';
import combinedSort from '../../../utils/combineSort';

interface Column {
  id: 'id' | 'name' | 'fileName' | 'udate' | 'download' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: '№', align: 'center', width: '5%' },
  { id: 'name', label: 'Назва', align: 'left' },
  {
    id: 'fileName',
    label: 'Назва файлу',
    align: 'left',
  },
  {
    id: 'udate',
    label: 'Дата змін',
    align: 'center',
    width: '16%',
  },
  {
    id: 'download',
    label: 'Скачати',
    align: 'center',
    width: '9%',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '10%',
  },
];

interface PropsType {
  docId: number;
  urlLink?: string;
  type?: number;
}

export const ListFile: React.FC<PropsType> = ({ docId, urlLink, type }) => {
  const [url] = React.useState<string>(urlLink || '/officialdoc-files');
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const [editData, setEditData] = React.useState<{ type: 'edit' | 'new'; data?: IOfficialDocFile } | null>(null);
  const { OfficialDocFiles } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classesTable = StylesTable();

  const handleRemove = (id: number) => {
    dispatch(DeleteFile.request({ id, url }));
  };

  const handleDownload = (file: IOfficialDocFile) => {
    const link = document.createElement('a');
    link.download = file.file_name;
    link.href = `${API_URL}${url}/download/${file.id}`;
    document.body.appendChild(link);
    link.click();
    link.parentNode && link.parentNode.removeChild(link);
  };

  if (editData) {
    return <EditFile docId={docId} handleCancel={() => setEditData(null)} url={url} type={type} />;
  }

  return (
    <>
      <Box className={classesTable.header}>
        <Button
          className={classesTable.searchField}
          variant='contained'
          color='primary'
          onClick={() => {
            setEditData({ type: 'new' });
          }}
        >
          Додати файли
        </Button>
      </Box>
      <TableContainer className={classesTable.container} style={{ height: '400px' }}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {combinedSort(OfficialDocFiles?.data?.slice())?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>{row.id}</TableCell>
                  <TableCell className={classesTable.row}>{row.name}</TableCell>
                  <TableCell className={classesTable.row}>{row.file_name}</TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    {moment(row.udate).format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    <IconButton aria-label='edit' onClick={() => handleDownload(row)}>
                      <FileDownloadIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton aria-label='delete' onClick={() => setIsModal({ id: row.id })}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
