import { Reducer } from 'redux';
import { TCalendarState } from './types';
import ActionTypes from './types';

export const initialState: TCalendarState = {
  loading: false,
  error: undefined,
  calendar: null,
  current: null,
  data: null,
};

const reducer: Reducer<TCalendarState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SEARCH_CALENDAR_R:
    case ActionTypes.GET_EVENT_R:
    case ActionTypes.UPDATE_EVENT_R:
    case ActionTypes.DELETE_EVENT_R:
    case ActionTypes.CREATE_EVENT_R:
    case ActionTypes.GET_CALENDAR_R:
    case ActionTypes.UPDATE_CALENDAR_POSITIONS_R:
    case ActionTypes.UPDATE_EVENT_POSITIONS_R:
      return { ...state, loading: true, error: undefined };

    case ActionTypes.UPDATE_EVENT_S:
      return {
        ...state,
        loading: false,
        error: undefined,
        calendar: action.payload.calendars,
        current: action.payload.current,
      };

    case ActionTypes.GET_CALENDAR_S: {
      return { ...state, loading: false, error: undefined, calendar: action.payload, data: action.payload };
    }

    case ActionTypes.CLONE_MONTH_EVENTS_S:{
      return {
        ...state,
        loading: false,
        error: undefined,
        calendar: action.payload,
      };
    }
    case ActionTypes.DELETE_EVENT_S: {
      if (!action.payload[0]?.children?.length) {
        return {
          ...state,
          loading: false,
          error: undefined,
          calendar: state.calendar?.filter((el) => el.id !== action.payload.id),
        };
      }
      return {
        ...state,
        loading: false,
        error: undefined,
        calendar: state.calendar?.map((el) => {
          if (el.id === action.payload[0].id) {
            return action.payload[0];
          }
          return el;
        }),
      };
    }

    case ActionTypes.UPDATE_EVENT_POSITIONS_S: {
      return {
        ...state,
        loading: false,
        error: undefined,
        calendar: state.calendar?.map((el) => {
          if (el.id === action.payload[0].id) {
            return action.payload[0];
          }
          return el;
        }),
      };
    }
    case ActionTypes.UPDATE_CALENDAR_POSITIONS_S:
      return {
        ...state,
        loading: false,
        error: undefined,
        calendar: state.calendar?.map((el) => {
          if (el.id === action.payload[0].id) {
            return action.payload[0];
          } else if (el.id === action.payload[1].id) {
            return action.payload[1];
          }
          return el;
        }),
      };

    case ActionTypes.CREATE_EVENT_S: {
      return {
        ...state,
        loading: false,
        error: undefined,
        calendar: state.calendar?.map((el) => {
          if (el.id === action.payload[0].id) {
            return action.payload[0];
          }
          return el;
        }),
      };
    }

    case ActionTypes.GET_EVENT_S:
      return {
        ...state,
        loading: false,
        error: undefined,
        current: action.payload,
      };

    case ActionTypes.SEARCH_CALENDAR_E:
    case ActionTypes.GET_EVENT_E:
    case ActionTypes.UPDATE_EVENT_E:
    case ActionTypes.DELETE_EVENT_E:
    case ActionTypes.CREATE_EVENT_E:
    case ActionTypes.GET_CALENDAR_E:
    case ActionTypes.UPDATE_CALENDAR_POSITIONS_E:
    case ActionTypes.UPDATE_EVENT_POSITIONS_E:
      return { ...state, loading: false, error: action.payload };
  }
  return state;
};

export { reducer as CalendarReducer };
