import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppStore } from '../../store/applicationState';
import { ROUTES } from '../../utils/routes';
import { Box, Button } from '@mui/material';
import { IAdditionalWidgets } from '../../store/widgets/types';
import { Roles } from '../../utils/consts';

type PropsType = {
  element: JSX.Element;
};

export interface IEditData {
  type: 'new' | 'edit';
  data: IAdditionalWidgets | null;
}

export const RatingWorkSpace: React.FC<PropsType> = ({ element }) => {
  const [tab, setTab] = React.useState(1);
  const { Configurations, User } = useSelector((store: AppStore) => store);
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (location.pathname.includes('product-list')) {
      setTab(2);
    } else {
      setTab(1);
    }
  }, [location.pathname, Configurations.siteId]);

  const handleChangeRoute =
    (path = '') =>
    () => {
      navigate(ROUTES.routeChange(path));
    };

  if (![Roles.HEAD, Roles.ADMIN].includes(User.data?.role || 0) || !User.data?.is_raiting) {
    return null;
  }

  return (
    <>
      <Box display={'flex'} mb={2} gap={1} width={500} flexWrap={'wrap'}>
        <Button
          variant={tab === 1 ? 'contained' : 'outlined'}
          color={tab === 1 ? 'primary' : 'inherit'}
          onClick={handleChangeRoute('list')}
        >
          Оцінка публікацій
        </Button>
        <Button
          variant={tab === 2 ? 'contained' : 'outlined'}
          color={tab === 2 ? 'primary' : 'inherit'}
          onClick={handleChangeRoute('product-list')}
        >
          Оцінка продукту
        </Button>
      </Box>
      {element}
    </>
  );
};
