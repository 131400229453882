import { IAuthor } from '../authors/types';
import { IDocumentVisitor } from '../consultations/types';
import { RubricType } from '../rubrics/types';

export interface TVideoState {
  data: { video: IVideo[]; total: number; page: number; pages: number } | null;
  pages: number;
  total: number;
  visitors: IDocumentVisitor[] | null;
  video: IVideo[] | null;
  current: IVideo | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IVideo {
  id: number;
  site_id: number;
  rubric_id: number;
  author_id: number;
  unpublished_at: Date;
  title: string;
  description: string;
  base_url: string;
  urls: { url: string; name: string; time: string }[] | null;
  is_popular: number;
  is_recommendation: number;
  is_public: number;
  is_parse_link: number;
  is_sitemap: number;
  body: string;
  is_in_cart: number;
  is_open_publish: boolean;
  open_publication_at: Date;
  search_tags: string;
  adate: Date;
  publication_at: Date;
  update: Date;
  is_parse_link_law: number;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  rubric?: RubricType;
  authorDetails: IAuthor[];
  url: string;
  authors: IAuthor[];
  author?: IAuthor;
  video_rubric_details: {
    rubric_id: number;
    video_id: number;
    rubric: RubricType;
  }[];
}

enum ActionTypes {
  SET_VIDEO = '@@video/SET_VIDEO',
  RESET_VIDEO = '@@video/RESET_VIDEO',

  GET_VIDEO_R = '@@video/GET_VIDEO_R',
  GET_VIDEO_S = '@@video/GET_VIDEO_S',
  GET_VIDEO_E = '@@video/GET_VIDEO_E',

  GET_VIDEO_BY_ID_R = '@@video/GET_VIDEO_BY_ID_R',
  GET_VIDEO_BY_ID_S = '@@video/GET_VIDEO_BY_ID_S',
  GET_VIDEO_BY_ID_E = '@@video/GET_VIDEO_BY_ID_E',

  SEARCH_VIDEO_R = '@@video/SEARCH_VIDEO_R',
  SEARCH_VIDEO_S = '@@video/SEARCH_VIDEO_S',
  SEARCH_VIDEO_E = '@@video/SEARCH_VIDEO_E',

  CREATE_VIDEO_R = '@@video/CREATE_VIDEO_R',
  CREATE_VIDEO_S = '@@video/CREATE_VIDEO_S',
  CREATE_VIDEO_E = '@@video/CREATE_VIDEO_E',

  UPDATE_VIDEO_R = '@@video/UPDATE_VIDEO_R',
  UPDATE_VIDEO_S = '@@video/UPDATE_VIDEO_S',
  UPDATE_VIDEO_E = '@@video/UPDATE_VIDEO_E',

  DELETE_VIDEO_R = '@@video/DELETE_VIDEO_R',
  DELETE_VIDEO_S = '@@video/DELETE_VIDEO_S',
  DELETE_VIDEO_E = '@@video/DELETE_VIDEO_E',

  CHECK_VIDEO_VISITOR_R = '@@video/CHECK_VIDEO_VISITOR_R',
  CHECK_VIDEO_VISITOR_S = '@@video/CHECK_VIDEO_VISITOR_S',
  CHECK_VIDEO_VISITOR_E = '@@video/CHECK_VIDEO_VISITOR_E',

  DELETE_VIDEO_VISITOR_R = '@@video/DELETE_VIDEO_VISITOR_R',
  DELETE_VIDEO_VISITOR_S = '@@video/DELETE_VIDEO_VISITOR_S',
  DELETE_VIDEO_VISITOR_E = '@@video/DELETE_VIDEO_VISITOR_E',
}

export default ActionTypes;
