import React from 'react';
import NumberFormat from 'react-number-format';

function limit(val: string, max: number) {
  if (val.length === 1 && val[0] > String(max)[0]) {
    val = '0' + val;
  }

  if (val.length === 2) {
    if (Number(val) > max) {
      val = String(max);
    }
  }

  return val;
}

function formatTime(val: string) {
  let minute = limit(val.substring(0, 2), 59);
  let seconds = limit(val.substring(2, 4), 59);

  return minute + (minute.length ? ':' + seconds : '');
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
  inputRef: any;
}

export const InputTime = React.forwardRef<NumberFormat<any>, CustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  delete other.inputRef;

  return (
    <NumberFormat
      {...other}
      style={{ textAlign: 'center' }}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={formatTime}
      placeholder="__:__"
      mask={['_', '_', '_', '_']}
    />
  );
});
