const ERROR_EMAIL = 'Неправильно зазначено пошту';
const ERROR_PASSW = 'Латиниця і цифри, без пробілів, мінімум 4 символи';

const collectionValidate = {
  email: {
    error: ERROR_EMAIL,
    checkValue: (value: string) => {
      const indexDog = value.indexOf('@');
      const indexDot = value.lastIndexOf('.');
      const afterDog =
        indexDog > 0
          ? // eslint-disable-next-line
            value.substring(indexDog + 1, indexDot).replace(/[0-9`~!#$@.%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/g, '')
          : '';
      const strEnd =
        // eslint-disable-next-line
        indexDot > 0 ? value.substring(indexDot + 1).replace(/[0-9`~!#$@.%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/g, '') : '';
      if (
        indexDot > 0 &&
        indexDog > 0 &&
        indexDog < indexDot &&
        afterDog.length > 0 &&
        strEnd.length >= 2 &&
        !value.includes(' ') &&
        // eslint-disable-next-line
        !RegExp(/[`~!#$@.%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/).test(value[0]) &&
        // eslint-disable-next-line
        !RegExp(/[`~!#$%^&*()|+=?;:'",<>\{\}\[\]\\\/]/).test(value)
      ) {
        return true;
      }
      return false;
    },
  },
  password: {
    error: ERROR_PASSW,
    checkValue: (value: string) => {
      const str = value?.replace(/[0-9]/g, '');
      const num = value?.replace(/[^\d.]/g, '');
      if (/^[a-zA-Z]+$/.test(str) && num.length > 0 && value.length >= 4 && !value.includes(' ')) {
        return true;
      }
      return false;
    },
  },
};

export { collectionValidate };
