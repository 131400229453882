import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { makeStyles } from '@mui/styles';
import Pagination from '@mui/material/Pagination';
import { configSites, URI } from '../../config';
import { IMeaning } from './EditorDocum/entities/EditorTypes';
import { HEIGHT } from './EditorDocum/entities/consts';
import {
  defineScrollDirection,
  handleScrollMeaning,
  handleSelectedMeaning,
  parseHTML,
} from './EditorDocum/entities/utils';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 128px)',
  },
  editor: {
    position: 'relative',
  },
  pagination: {
    position: 'absolute',
    bottom: '5px',
    left: '33%',
    width: '380px',
    display: 'flex',
    justifyContent: 'center',
  },
  item: {
    cursor: 'pointer',
    color: 'gray',
    '&:hover': {
      color: '#3f51b5',
    },
  },

  meaning: {
    maxHeight: 'calc(100vh - 189px)',
    overflow: 'auto',
    width: '200px',
    marginRight: '20px',
    listStyleType: 'none',
    padding: 0,
  },
  itemPage: {
    fontSize: '15px',
    fontWeight: 400,
    cursor: 'pointer',
    '&:hover': {
      color: '#3f51b5',
    },
  },
  itemUl: {},
  itemIi: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: 'grey',
    padding: '2px 0',
    cursor: 'pointer',
    '&:hover': {
      color: '#3f51b5',
    },
  },
}));

const defineMeaning = (text = '', tag = 'h2') => {
  const meaning = [] as { id: string; level: number; text: string }[];
  text.split(`<${tag}`).forEach((item, i) => {
    if (i === 0 && item && tag === 'h2') {
      const level2 = defineMeaning(item, 'h3');
      meaning.push(...level2);
    }
    if (i === 0) return null;
    const arr = item.split(`</${tag}>`);
    const title = arr[0];
    const dataId = item.split('id="')[1];
    let id = '';
    if (dataId) {
      id = dataId?.substring(0, dataId.indexOf('"'));
    }
    if (title) {
      meaning.push({
        id,
        level: tag === 'h2' ? 1 : 2,
        text: title.substring(title.indexOf('>') + 1),
      });
    }
    if (tag === 'h3') return null;
    const level2 = defineMeaning(arr[1], 'h3');
    meaning.push(...level2);
  });
  return meaning;
};

interface EditorProps {
  siteId: number;
  docId: number;
  initialValue?: string;
}

export const EditorDocumRead: React.FC<EditorProps> = ({ siteId, docId, initialValue }) => {
  const [pages, setPages] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [isScroll, setIsScroll] = React.useState(false);
  const [paragraphId, setParagraphId] = React.useState<string>('');
  const [selectedMeaningId, setSelectedMeaningId] = React.useState<string>('');
  const [prevScrollY, setPrevScrollY] = React.useState<number>(0);
  const [scrollDirection, setScrollDirection] = React.useState<'down' | 'up'>('down');

  const meaningRef = React.useRef<IMeaning[]>([]);
  const editorRef = React.useRef<any | null>(null);

  const classes = useStyles();

  React.useEffect(() => {
    if (initialValue) {
      const data = defineMeaning(initialValue);
      meaningRef.current = data;
    }
  }, [initialValue]);

  React.useEffect(() => {
    setSelectedMeaningId(paragraphId);
  }, [paragraphId, editorRef]);

  React.useEffect(() => {
    if (page && isScroll) {
      const top = (page - 1) * 870;
      //@ts-ignore
      editorRef.current?.getWin().scrollTo({ top });
      setIsScroll(false);
    }
  }, [page, isScroll, editorRef]);

  const handleChangePage = (page: number) => {
    setPage(page);
    setIsScroll(true);
  };

  const defineScrollDirectionHandler = defineScrollDirection(prevScrollY, setPrevScrollY, setScrollDirection);

  React.useEffect(() => {
    editorRef?.current?.on('ScrollContent', defineScrollDirectionHandler);
    return () => {
      editorRef?.current?.off('ScrollContent', defineScrollDirectionHandler);
    };
  }, [defineScrollDirectionHandler, editorRef]);

  return (
    <div className={classes.root}>
      <Meaning
        meaning={meaningRef.current}
        setParagraphId={setParagraphId}
        selectedMeaning={selectedMeaningId}
        scrollDirection={scrollDirection}
        editorRef={editorRef}
      />
      <div className={classes.editor}>
        <Editor
          //@ts-ignore
          onInit={(evt, editor) => editorRef && (editorRef.current = editor)}
          apiKey='uzm54hzb90l1dcm92ia4tfjp6aj0krmgjak7eof1jhyd17pv'
          init={{
            language: 'uk',
            height: '100%',
            width: '900px',
            readonly: true,
            menubar: false,
            toolbar: false,
            content_style: `
            .mce-item-table:not([border]), 
            .mce-item-table:not([border]) caption, 
            .mce-item-table:not([border]) td, 
            .mce-item-table:not([border]) th {
              border: 1px solid black;
            }
            table {
              width: 100%;
              float: none !important;       
            }
            .mce-content-body {
              margin: 0 1rem 0 2rem;
            }
            h1,h2,h3,h4,h5,p,div {
              position: relative;
            }
            .head-doc {
              display: block;
              text-align: center;
              font-size: 24px;
              font-weight: bold;
              line-height: 1.8;
            }
            .notes {
              display: block;
              font-size: 14px;
              margin: 40px 0;
            }
            .anchor:before {
              pointer-events: all;
              transform: translateY(2px);
              position: absolute;
              left: -26px;
              transition: transform .3s;
              will-change: transform;
              cursor: pointer;
              content: url(${URI.trim()}/images/img-icon/3006691.svg);
            }
            .myCopied {
              border: 1px solid black;
              border-block-end-color: gray;
              border-right-color: gray;
              background-color: white;
              color: black;
              text-align: center;
              padding: 5px;
              position: absolute;
              z-index: 1;
              font-size: small;
              font-weight: 400;
              left: 0;
              top: 0;
            }
            h1, h2, h3, h4 {
              text-align: center;
            }
              `,
            setup: (editor) => {
              editor.on('click', (node) => {
                if (node.target?.classList?.contains('anchor') && node.x < 25) {
                  const id = node.target.id || editor.selection.getNode().id;
                  navigator.clipboard.writeText(`${configSites[siteId].url}/law/${docId}#${id}`);
                  const el = editor.dom.create('span', { class: 'myCopied' }, 'Скопійовано');
                  node.target.append(el);
                  let timeout = setTimeout(() => {
                    el.remove();
                    clearTimeout(timeout);
                  }, 1000);
                }
              });

              editor.on('ScrollContent', (e) => {
                if (e?.currentTarget?.scrollY) {
                  const _page = Math.ceil(e.currentTarget.scrollY / (HEIGHT - 10));
                  setPage(_page);
                }
                handleScrollMeaning(e, meaningRef, editorRef, selectedMeaningId, setSelectedMeaningId);
              });
              editor.on('SetContent', (e) => {
                const body = editor.getBody();
                editor.dom.addClass(body.childNodes as unknown as Node[], 'anchor');
                const pages = Math.ceil(body.offsetHeight / HEIGHT);
                setPages(pages);
              });
            },
          }}
          initialValue={initialValue}
        />
        <Pagination
          className={classes.pagination}
          count={pages}
          page={page}
          onChange={(ev, _page) => handleChangePage(_page)}
        />
      </div>
    </div>
  );
};

interface IMeaningProps {
  meaning: IMeaning[];
  setParagraphId: (key: string) => void;
  selectedMeaning: string;
  scrollDirection: 'down' | 'up';
  editorRef: any;
}

const Meaning: React.FC<IMeaningProps> = React.memo(
  ({ meaning, setParagraphId, selectedMeaning, scrollDirection, editorRef }) => {
    const classes = useStyles();
    const containerMeaningScrollRef = React.useRef<HTMLUListElement>(null);

    React.useEffect(() => {
      handleSelectedMeaning(selectedMeaning, meaning, containerMeaningScrollRef, scrollDirection);
    }, [selectedMeaning, scrollDirection, meaning]);

    return (
      <div>
        <h2>Зміст</h2>
        <ul className={classes.meaning} ref={containerMeaningScrollRef}>
          {meaning.map(({ id, level, text }, idx) => {
            const plainText = parseHTML(text);

            return (
              <li
                id={id + '.' + idx}
                key={`${idx}-${id}`}
                className={classes.itemIi}
                style={{
                  paddingLeft: level !== 1 ? '10px' : '',
                  color: selectedMeaning === id || (!selectedMeaning && idx === 0) ? '#ffffff' : '',
                  backgroundColor: selectedMeaning === id || (!selectedMeaning && idx === 0) ? '#3f51b5' : '',
                  borderRadius: '2px',
                }}
                onClick={() => {
                  const element = editorRef.current?.dom?.get(id);
                  if (element) element.scrollIntoView({ behavior: 'instant' });
                  setParagraphId(id);
                }}
                dangerouslySetInnerHTML={{ __html: plainText }}
              />
            );
          })}
        </ul>
      </div>
    );
  }
);
