import { Editor } from '@tinymce/tinymce-react';

export const changeCellBackgroundColor = (editor: Editor['editor']) => () => {
  if (!editor) return null;

  let hasClass = false;

  const elements = editor.$('td[data-mce-selected="1"]');
  elements.each((i, value) => {
    if (editor.$(value).hasClass('cell-background-color')) {
      hasClass = true;
    } else {
      hasClass = false;
    }
  });

  if (hasClass) {
    elements.removeClass('cell-background-color');
  } else {
    elements.addClass('cell-background-color');
  }
};
