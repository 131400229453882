export interface TUsersState {
  data: IUser[] | null;
  current: IUser | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IUser {
  id: number;
  username: string;
  password: string;
  fio: string;
  created_at: Date;
  updated_at: Date;
  role: number;
  is_raiting: number;
  is_service: number;
  rights?: { site_id: number; site?: { id: number; name: string } }[];
}

enum ActionTypes {
  GET_USERS_R = '@@users/GET_USERS_R',
  GET_USERS_S = '@@users/GET_USERS_S',
  GET_USERS_E = '@@users/GET_USERS_E',

  GET_USER_R = '@@users/GET_USER_R',
  GET_USER_S = '@@users/GET_USER_S',
  GET_USER_E = '@@users/GET_USER_E',

  CREATE_USER_R = '@@users/CREATE_USER_R',
  CREATE_USER_S = '@@users/CREATE_USER_S',
  CREATE_USER_E = '@@users/CREATE_USER_E',

  UPDATE_USER_R = '@@users/UPDATE_USER_R',
  UPDATE_USER_S = '@@users/UPDATE_USER_S',
  UPDATE_USER_E = '@@users/UPDATE_USER_E',

  DELETE_USER_R = '@@users/DELETE_USER_R',
  DELETE_USER_S = '@@users/DELETE_USER_S',
  DELETE_USER_E = '@@users/DELETE_USER_E',

  UPDATE_PASSWORD_R = '@@users/UPDATE_PASSWORD_R',
  UPDATE_PASSWORD_S = '@@users/UPDATE_PASSWORD_S',
  UPDATE_PASSWORD_E = '@@users/UPDATE_PASSWORD_E',
}

export default ActionTypes;
