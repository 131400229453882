import React from 'react';
import { MainMonthListBlocks } from '../../../../components/MainInMonth/ListMainMonthBlocks';

export default function List() {
  return (
    <MainMonthListBlocks
      columns={[
        {
          id: 'id',
          label: '№',
        },
        {
          id: 'title',
          label: 'Заголовок',
        },
        {
          id: 'link',
          label: 'Посилання на публікацію',
          align: 'center',
        },
        {
          id: 'image',
          label: 'Зображення',
          align: 'center',
        },
      ]}
    />
  );
}
