import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { GetBlocks } from '../../store/blocks/actions';
import { GetMainBlocks } from '../../store/main/actions';
import { AppStore } from '../../store/applicationState';
import { CheckFormsVisitor, DeleteFormsVisitors, GetForm, UpdateForms } from '../../store/forms/actions';
import { FormsForm } from '../../components/FormsForm';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ConfigurationsActions } from '../../store/configurations/actions';
import { IDocumentVisitor } from '../../store/consultations/types';
import { AlertModal } from '../../components/modals/AlertModal';

export const EditForm: React.FC = () => {
  const { Forms } = useSelector((store: AppStore) => store);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visitors, setVisitors] = React.useState<IDocumentVisitor[] | null>();

  React.useEffect(() => {
    if (id) {
      dispatch(GetForm.request({ id: Number(id) }));
      dispatch(
        CheckFormsVisitor.request({
          id: +id,
        })
      );
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      dispatch(DeleteFormsVisitors.request({ id: !isNaN(Number(id)) ? Number(id) : 0 }));
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      dispatch(DeleteFormsVisitors.request({ id: !isNaN(Number(id)) ? Number(id) : 0 }));
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate, dispatch, id]);

  React.useEffect(() => {
    setVisitors(Forms.visitors);
  }, [Forms]);

  React.useEffect(() => {
    if (Forms.current) {
      dispatch(ConfigurationsActions.setSiteId(Forms.current.site_id));
    }
  }, [dispatch, Forms]);

  const handleUpdate = (data: FormData, callBack: (err?: { name: string; message: string }) => void) => {
    if (!Forms.current) return null;
    dispatch(
      CheckFormsVisitor.request({
        id: Forms.current.id,
      })
    );
    dispatch(
      UpdateForms.request({
        data,
        id: Forms.current.id,
        callBack: (success, dta) => {
          if (!success && dta) {
            if (dta.status === 409) {
              callBack({ name: 'title', message: 'Такий заголовок вже використовується' });
            } else if (dta.status === 410) {
              callBack({ name: 'error', message: 'Публікацію видалено' });
            } else {
              callBack({ name: 'error', message: 'Сталася помилка' });
            }
          }
          const isPublic = data.get('isPublic') || 0;
          if (success && Forms.current?.is_public === 1 && Number(isPublic) === 0) {
            dispatch(GetBlocks.request({ id: Forms.current.site_id }));
            dispatch(GetMainBlocks.request({ id: Forms.current.site_id }));
          }
        },
      })
    );
  };

  if (!Forms.current)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );

  return (
    <>
      <AlertModal visitors={visitors} />
      <FormsForm
        type='edit'
        data={Forms.current}
        handleSubmit={handleUpdate}
        handleCancel={() => {
          navigate(ROUTES.routeChange('list'));
          dispatch(DeleteFormsVisitors.request({ id: !isNaN(Number(id)) ? Number(id) : 0 }));
        }}
      />
    </>
  );
};
