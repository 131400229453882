import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import IconButton from '@mui/material/IconButton/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { AppStore } from '../../store/applicationState';
import { ROUTES } from '../../utils/routes';
import { StylesTable } from '../../components/Stylestable';
import { WarningModal } from '../../components/modals';
import { DeleteOfficialDocDrafts, GetOfficialDocDrafts } from '../../store/officialDocDrafts/actions';
import { useParams } from 'react-router-dom';
import { Roles } from '../../utils/consts';
import { IOfficialDocDraft } from '../../store/officialDocDrafts/types';

interface Column {
  id: 'id' | 'create_at' | 'title' | 'author' | 'cretor' | 'edit' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: '№', align: 'center', width: '6%' },
  { id: 'create_at', label: 'Дата створення', align: 'left' },
  { id: 'title', label: 'Назва', align: 'left' },
  { id: 'author', label: 'Автор', align: 'left' },
  { id: 'cretor', label: 'Власник', align: 'left' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '8%',
  },
];

type PropsType = {};

export const ListOfficialDocDrafts: React.FC<PropsType> = () => {
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);

  const { OfficialDocDrafts, User } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classesTable = StylesTable();
  const { group_id } = useParams();

  React.useEffect(() => {
    if (group_id && !Number.isNaN(Number(group_id))) {
      dispatch(
        GetOfficialDocDrafts.request({
          group_id: Number(group_id),
        })
      );
    }
  }, [dispatch, group_id]);

  const handleChangeRoute =
    (path = '') =>
    () => {
      window.open(`${ROUTES.drafts}/${path}`);
    };

  const handleRemove = (isApprove: boolean) => {
    setIsModal(null);
    if (isApprove && isModal) {
      dispatch(
        DeleteOfficialDocDrafts.request({
          id: isModal.id,
        })
      );
    }
  };

  const renderBtn = (doc: IOfficialDocDraft) => {
    if (
      (User.data && [Roles.HEAD, Roles.ADMIN].includes(User.data.role)) ||
      (User.data && User.data.id === doc.creator_id)
    ) {
      return (
        <>
          <TableCell align='center'>
            <IconButton aria-label='delete' onClick={handleChangeRoute(String(doc.id))}>
              <EditIcon />
            </IconButton>
          </TableCell>
          <TableCell align='center'>
            <IconButton aria-label='delete' onClick={() => setIsModal({ id: doc.id })}>
              {OfficialDocDrafts.loading ? <CircularProgress size={25} /> : <DeleteIcon />}
            </IconButton>
          </TableCell>
        </>
      );
    }

    return (
      <>
        <TableCell />
        <TableCell />
      </>
    );
  };

  if (OfficialDocDrafts.loading && !OfficialDocDrafts.data) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );
  }

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {OfficialDocDrafts.data?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>{row.id}</TableCell>
                  <TableCell className={classesTable.row}>{new Date(row.created_at).toLocaleString()}</TableCell>
                  <TableCell className={classesTable.row}>{row.title}</TableCell>
                  <TableCell className={classesTable.row}>{row.author?.fio || ''}</TableCell>
                  <TableCell className={classesTable.row}>{row.creator?.fio || ''}</TableCell>
                  {renderBtn(row)}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isModal ? <WarningModal handleApprove={handleRemove} value={`№ ${isModal.id}`} open={!!isModal} /> : null}
    </>
  );
};
