import { Reducer } from 'redux';
import ActionTypes, { TOfficialDocDraftsState } from './types';

export const initialState: TOfficialDocDraftsState = {
  data: null,
  current: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TOfficialDocDraftsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_OFFICIAL_DOC_DRAFTS_R:
    case ActionTypes.UPDATE_OFFICIAL_DOC_DRAFTS_R:
    case ActionTypes.DELETE_OFFICIAL_DOC_DRAFTS_R:
    case ActionTypes.CHANGE_OWNER_R:
    case ActionTypes.UPDATE_REDACTION_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.GET_OFFICIAL_DOC_DRAFTS_R:
      return {
        ...state,
        data: null,
        loading: true,
        errors: '',
      };

    case ActionTypes.GET_OFFICIAL_DOC_DRAFT_R:
      return {
        ...state,
        current: null,
        loading: true,
        errors: '',
      };

    case ActionTypes.GET_OFFICIAL_DOC_DRAFTS_E:
    case ActionTypes.GET_OFFICIAL_DOC_DRAFT_E:
    case ActionTypes.CREATE_OFFICIAL_DOC_DRAFTS_E:
    case ActionTypes.UPDATE_OFFICIAL_DOC_DRAFTS_E:
    case ActionTypes.DELETE_OFFICIAL_DOC_DRAFTS_E:
    case ActionTypes.CHANGE_OWNER_E:
    case ActionTypes.UPDATE_REDACTION_E:
    case ActionTypes.SET_ERROR:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.CREATE_OFFICIAL_DOC_DRAFTS_S:
    case ActionTypes.UPDATE_REDACTION_S:
      return { ...state, loading: false, errors: '' };

    case ActionTypes.GET_OFFICIAL_DOC_DRAFTS_S:
      return {
        ...state,
        data: action.payload,
        loading: false,
        errors: '',
      };

    case ActionTypes.GET_OFFICIAL_DOC_DRAFT_S:
    case ActionTypes.UPDATE_OFFICIAL_DOC_DRAFTS_S:
      return {
        ...state,
        loading: false,
        errors: '',
        current: action.payload,
      };

    case ActionTypes.DELETE_OFFICIAL_DOC_DRAFTS_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data ? state.data.filter((it) => it.id !== action.payload.id) : state.data,
      };

    case ActionTypes.CHANGE_OWNER_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data ? state.data.map((it) => (it.id === action.payload.id ? action.payload : it)) : state.data,
      };

    default:
      return state;
  }
};

export { reducer as OfficialDocDraftsReducer };
