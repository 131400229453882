import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Button, CircularProgress, CssBaseline, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { IConsultation } from '../../store/consultations/types';
import { TypeCreateConsultationR } from '../../store/consultations/actions';
import { SelectRubrics, SelectPopup, SelectSearchReq, BasicDatePicker } from '../ui';
import { EditorDefault, EditorFeatured } from '../Editors';
import { StylesEdit } from '../StylesEdit';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSearchAuthors } from '../../hooks/useSearchAuthors';
import { IDataOpt } from '../ui/SelectSearchReq';
import { RubricsModal } from '../modals';
import { Editor } from '@tinymce/tinymce-react';
import { backlight, uuid } from '../Editors/EditorDocum/entities/utils';
import { CATEGORIES, defaultUsavedFlags } from '../../utils/consts';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { areArraysEqual } from '../../utils/areArraysEqual';
import { configSites } from '../../config';
import { AlertMessage } from '../AlertMessage';
import { hideAnchor } from '../Editors/commands';

interface PropsType {
  type: 'edit' | 'new';
  data?: IConsultation | null;
  handleSubmit: (
    data: TypeCreateConsultationR['data'],
    callBack: (err?: { name: string; message: string }) => void
  ) => void;
  handleCancel: () => void;
}

export interface UnsavedFlags {
  unsavedTitle: boolean;
  unsavedBody: boolean;
  unsavedDesc: boolean;
  unsavedPublicationAt: boolean;
  unsavedAuthorFirst: boolean;
  unsavedAuthorSec: boolean;
  unsavedAuthorThir: boolean;
  unsavedIsNews?: boolean;
  unsavedMainRubric: boolean;
  unsavedRubrics: boolean;
}

export const ConsultationForm: React.FC<PropsType> = ({ type, data, handleSubmit, handleCancel }) => {
  const [searchTags, setSearchTags] = React.useState<string>(data?.search_tags || '');
  const [title, setTitle] = React.useState<string>(data?.title || '');
  const [publicationAt, setPublicationAt] = React.useState<Date | null>(data?.publication_at || null);
  const [expireDateAt, setExpireDateAt] = React.useState<Date | null>(data?.expire_at || null);
  const [authorFirst, setAuthorFirst] = React.useState<IDataOpt | null>(null);
  const [authorSecond, setAuthorSecond] = React.useState<IDataOpt | null>(null);
  const [authorThird, setAuthorThird] = React.useState<IDataOpt | null>(null);
  const [isPublic, setIsPublic] = React.useState(data?.is_public || 0);
  const [rubricId, setRubricId] = React.useState<string>(data ? String(data.rubric_id) : '');
  const [rubricIds, setRubricIds] = React.useState<{ id: number; name: string }[]>([]);
  const [isNews, setIsNews] = React.useState<number>(data?.is_news || 0);
  const [error, setError] = React.useState<Record<string, string>>({});

  const [isDisablePreview, setIsdisablePreview] = React.useState(false);
  const [unsavedData, setUnsavedData] = React.useState<UnsavedFlags>(defaultUsavedFlags);
  const [editorContent, setEditorContent] = React.useState('');

  const editorDefRef = React.useRef<Editor['editor'] | null>(null);
  const editorRef = React.useRef<Editor['editor'] | null>(null);

  const { Consultations, Rubrics, Configurations } = useSelector((store: AppStore) => store);

  const { options, handleSearchAuthors } = useSearchAuthors();
  const classes = StylesEdit();

  React.useEffect(() => {
    if (data && data.expire_at && data.is_news) {
      setExpireDateAt(data.expire_at);
    }
  }, [data]);

  React.useEffect(() => {
    if (publicationAt && !expireDateAt) {
      const newExpireDate = new Date(publicationAt);
      newExpireDate.setMonth(newExpireDate.getMonth() + 6);
      setExpireDateAt(newExpireDate);
    }
  }, [publicationAt, data, isNews, expireDateAt]);

  React.useEffect(() => {
    if (data) {
      if (data.authors[0]) setAuthorFirst({ id: data.authors[0].id, label: data.authors[0].fio });
      if (data.authors[1]) setAuthorSecond({ id: data.authors[1].id, label: data.authors[1].fio });
      if (data.authors[2]) setAuthorThird({ id: data.authors[2].id, label: data.authors[2].fio });

      if (data && data.consultation_rubric_details.length > 0) {
        setRubricIds(data.consultation_rubric_details.map((it) => ({ id: it.rubric.id, name: it.rubric.name })));
      }
    }
  }, [data]);

  React.useEffect(() => {
    const unsavedAuthorFirst = authorFirst?.id !== data?.authors[0]?.id;
    const unsavedAuthorSec = authorSecond?.id !== data?.authors[1]?.id;
    const unsavedAuthorThir = authorThird?.id !== data?.authors[2]?.id;
    const unsavedIsNews = isNews !== data?.is_news;
    const unsavedTitle = title !== data?.title;
    const unsavedRubrics = !areArraysEqual(
      data?.consultation_rubric_details.map((it) => it.rubric_id),
      rubricIds.map((it) => it.id)
    );

    const publicationAtLoc = new Date(publicationAt || new Date());
    const dataPublicationAt = new Date(data?.publication_at || new Date());

    const publicationAtUTC = new Date(publicationAtLoc.toUTCString());
    const dataPublicationAtUTC = new Date(dataPublicationAt.toUTCString());

    const unsavedPublicationAt = publicationAtUTC.getTime() !== dataPublicationAtUTC.getTime();

    const unsavedMainRubric = +rubricId !== data?.rubric_id;

    setUnsavedData({
      ...unsavedData,
      unsavedAuthorFirst,
      unsavedAuthorSec,
      unsavedAuthorThir,
      unsavedTitle,
      unsavedPublicationAt,
      unsavedMainRubric,
      unsavedIsNews,
      unsavedRubrics,
    });

    //eslint-disable-next-line
  }, [
    title,
    isNews,
    authorFirst,
    authorSecond,
    authorThird,
    rubricId,
    data?.title,
    data?.is_news,
    data?.rubric_id,
    data?.authors,
    data?.publication_at,
    publicationAt,
    rubricIds,
  ]);

  React.useEffect(() => {
    setIsdisablePreview(Object.values(unsavedData).some((flag) => flag));
  }, [unsavedData]);

  const handleChange = (name: string, callBack: (value: any) => void) => (value: any) => {
    const obj = { ...error };
    delete obj[name];
    setError(obj);
    callBack(value);
  };

  const onSubmit = () => {
    if (!title) return setError({ title: 'Заголовок має бути заповненим' });
    if (!publicationAt) return setError({ publicationAt: 'Дата дата публікації має бути заповнена.' });

    if (publicationAt && expireDateAt) {
      const expDateF = new Date(expireDateAt);
      const pubDateF = new Date(publicationAt);
      if (expDateF < pubDateF && isNews) {
        setError({ expireDateAt: 'Дата закриття не може бути меншою за дату публікації.' });
        return;
      }
    }

    if (!Number(rubricId)) return setError({ rubricId: 'Рубрика має бути заповнена' });

    hideAnchor(editorRef.current);

    const description = editorDefRef.current?.getContent();
    const content = editorRef.current?.getContent();
    setEditorContent(content || '');
    const tempElement = document.createElement('html');
    tempElement.innerHTML = backlight(false, content || '');

    Array.from(tempElement.getElementsByTagName('p')).forEach((item) => {
      if (!item.hasAttribute('id')) {
        item.setAttribute('id', uuid());
      }
    });

    const tdElements = tempElement.querySelectorAll('td');

    tdElements.forEach((tdElement) => {
      const pElement = tdElement.querySelector('p');

      if (!pElement || !pElement.id) {
        const tdContent = tdElement.textContent;
        if (tdContent !== null) {
          const trimmedContent = tdContent.trim();

          if (trimmedContent) {
            const pElement = document.createElement('p');
            pElement.id = uuid();
            pElement.innerHTML = tdElement.innerHTML;
            tdElement.innerHTML = pElement.outerHTML;
          }
        }
      }
    });
    const body = tempElement.innerHTML;

    setError({});
    const authorsIds = [authorFirst, authorSecond, authorThird]
      .map((it) => Number(it?.id))
      .filter((num) => !!num && !Number.isNaN(num));

    handleSubmit(
      {
        isPublic,
        authorsIds,
        title,
        publication_at: publicationAt,
        description,
        body,
        siteId: data ? data.site_id : Configurations.siteId,
        rubricId: Number(rubricId),
        searchTags,
        rubricsIds: rubricIds.map((it) => it.id),
        is_news: isNews,
        expire_at: expireDateAt && new Date(expireDateAt),
        is_in_cart: data?.is_in_cart || 0,
      },
      (err) => {
        setUnsavedData(defaultUsavedFlags);
        if (err) {
          setError({ [err.name]: err.message });
        }
      }
    );
  };

  return (
    <Box>
      <CssBaseline />
      <div className={classes.paper}>
        <Box className={classes.header}>
          <Typography component='h1' variant='h5'>
            {type === 'new' ? 'Створити консультацію' : 'Редагувати консультацію'}
          </Typography>
          {data && (
            <Box style={{ display: 'flex' }}>
              <Box className={classes.headerItem}>Дата створення {moment(data?.adate).format('DD.MM.YYYY HH:mm')}</Box>
              <Box>Дата редагування {moment(data?.update).format('DD.MM.YYYY HH:mm')}</Box>
            </Box>
          )}
        </Box>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '900px' }}>
          <Box>
            <FormControlLabel
              style={{ marginTop: '7px' }}
              control={<Checkbox checked={!!isPublic} />}
              onChange={() => {
                setIsPublic(isPublic ? 0 : 1);
              }}
              label='Публікувати'
            />
            <FormControlLabel
              style={{ marginTop: '7px' }}
              control={<Checkbox checked={!!isNews} />}
              onChange={() => {
                setIsNews(isNews ? 0 : 1);
                if (!isNews) setExpireDateAt(null);
              }}
              label='Новина'
            />
          </Box>

          <div
            title={isDisablePreview ? 'У вас є незбережені зміни.' : ''}
            style={{ cursor: isDisablePreview ? 'not-allowed' : 'pointer' }}
            onClick={() => {
              if (isDisablePreview) return;
              window.open(
                `${configSites[Configurations.siteId].url}/recommendations/preview-${Configurations.siteId}-1-${
                  data?.id
                }-${data?.preview_hash}`
              );
            }}
          >
            <RemoveRedEyeOutlinedIcon color={isDisablePreview ? 'disabled' : 'info'} />
          </div>
        </div>

        <TextField
          size='small'
          id='outlined-basic'
          label='Теги'
          variant='outlined'
          value={searchTags}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setSearchTags(event.target.value as string);
          }}
        />
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Заголовок'
          variant='outlined'
          value={title}
          error={!!error['title']}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            handleChange('title', setTitle)(event.target.value);
          }}
        />
        <div className={classes.textField} style={{ display: 'flex' }}>
          <BasicDatePicker
            id='publicationAt'
            required
            label='Дата публікації'
            value={publicationAt}
            error={!!error['publicationAt']}
            onChange={handleChange('publicationAt', setPublicationAt)}
          />
        </div>
        <div className={classes.textField} style={{ display: isNews ? 'flex' : 'none' }}>
          <BasicDatePicker
            id='expireAt'
            label='Дата закриття'
            value={expireDateAt}
            error={!!error['expireDateAt']}
            onChange={handleChange('expireDateAt', setExpireDateAt)}
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <EditorDefault
            setUnsavedChanges={setUnsavedData}
            unsavedChanges={unsavedData}
            placeholder='Опис'
            editorRef={editorDefRef}
            initialValue={data?.description}
          />
        </div>
        <SelectRubrics
          required
          title='Рубрикатор'
          value={rubricId}
          error={!!error['rubricId']}
          className={classes.textField}
          options={Rubrics.rubrics && Rubrics.rubrics[CATEGORIES.CONSULTATIONS]}
          onChange={handleChange('rubricId', setRubricId)}
        />
        <SelectPopup
          title='Додатк. Рубрикатор'
          value={rubricIds}
          className={classes.textField}
          options={Rubrics.rubrics && Rubrics.rubrics[CATEGORIES.CONSULTATIONS]}
          onChange={setRubricIds}
          PropsModal={RubricsModal}
        />
        <SelectSearchReq
          title='Автор'
          data={authorFirst}
          className={classes.textField}
          onChange={setAuthorFirst}
          options={options}
          handleRequest={handleSearchAuthors}
        />
        <SelectSearchReq
          title='Автор'
          data={authorSecond}
          className={classes.textField}
          onChange={setAuthorSecond}
          options={options}
          handleRequest={handleSearchAuthors}
        />
        <SelectSearchReq
          title='Автор'
          data={authorThird}
          className={classes.textField}
          onChange={setAuthorThird}
          options={options}
          handleRequest={handleSearchAuthors}
        />
        <div style={{ marginTop: '20px', display: 'flex' }}>
          <EditorFeatured
            unsavedChanges={unsavedData}
            setUnsavedChanges={setUnsavedData}
            categoryId={CATEGORIES.CONSULTATIONS}
            editorRef={editorRef}
            initialValue={data?.body}
            siteId={Configurations.siteId}
            docId={data?.id}
            setEditorContent={setEditorContent}
            editorContent={editorContent}
          />
          <div style={{ padding: '130px 10px 0px' }}>Ширина 768px</div>
        </div>
        <Box display={'flex'} mt={2} justifyContent={'space-between'} width={900}>
          <Box display={'flex'} gap={2}>
            <Button variant='contained' color='primary' disabled={Consultations.loading} onClick={onSubmit}>
              {Consultations.loading ? <CircularProgress size={15} /> : 'Зберегти'}
            </Button>
            <Button variant='contained' color='inherit' onClick={handleCancel}>
              Повернутися
            </Button>
          </Box>

          <Button
            style={{ cursor: isDisablePreview ? 'not-allowed' : 'pointer' }}
            disabled={isDisablePreview}
            variant='contained'
            color='inherit'
            onClick={() => {
              window.open(
                `${configSites[Configurations.siteId].url}/recommendations/preview-${Configurations.siteId}-1-${
                  data?.id
                }-${data?.preview_hash}`
              );
            }}
          >
            ПЕРЕГЛЯНУТИ
          </Button>
        </Box>
        {Object.keys(error).length ? <AlertMessage type='error' message={Object.entries(error)[0][1]} /> : null}
      </div>
    </Box>
  );
};
