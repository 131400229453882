import { callApi } from '../../../../utils/callApi';

interface Props {
  siteId: number;
  categoryId: number;
  id?: string;
  configSites: Record<string, { url: string }>;
}

export const imagesUpload =
  ({ siteId, categoryId, id, configSites }: Props) =>
  async (
    blobInfo: { blob: () => Blob; filename: () => string },
    success: (url: string) => void,
    failure: (err: string, options?: any | undefined) => void
  ) => {
    try {
      const formData = new FormData();
      if (blobInfo) {
        formData.append('file', blobInfo.blob(), blobInfo.filename());
        formData.append('siteId', String(siteId));
        formData.append('categoryId', String(categoryId));
        formData.append('id', id || '');

        const path = await callApi({
          method: 'post',
          data: formData,
          isFormData: true,
          path: '/upload/file',
        }).catch((err) => failure('error'));

        if (path) {
          success(`${configSites[siteId].url}/${path}`);
        } else {
          failure('Сталася помилка');
        }
      } else {
        failure('Сталася помилка');
      }
    } catch (err) {
      console.log(err);
      failure('Сталася помилка');
    }
  };
