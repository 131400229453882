import { makeStyles } from '@mui/styles';

export const StylesEdit = makeStyles(() => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0 15px 0 7px',
  },
  textField: {
    marginTop: '20px !important',
    width: '900px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '900px',
    alignItems: 'center',
  },
  headerItem: {
    marginRight: '30px',
  },
  wrappPhoto: {
    maxWidth: '300px',
  },
  photo: {
    color: ' -webkit-link',
    cursor: 'pointer',
    textDecoration: 'underline',
    overflow: 'hidden',
    maxWidth: '75px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  dropZone: {
    width: '100%',
    height: '150px',
    borderRadius: '8px',
    border: 'dashed 1px #4caf50',
    padding: '50px 20px',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '12px',
    cursor: 'pointer',
    backgroundColor: '#f6f8f6',
    zIndex: 30,
    '&:hover': {
      border: 'dashed 1px #28772b',
    },
  },
  listFile: {
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  itemFile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  fixedBtns: {
    position: 'fixed',
    top: '70px',
    zIndex: 1005,
    margin: 'auto',
    padding: '20px',
    left: '55%',
  },
}));
