import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import IconButton from '@mui/material/IconButton/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { AppStore } from '../../store/applicationState';
import { StylesTable } from '../Stylestable';
import { SearchDocs, setEditDoc, CreateGroup, setSearchData } from '../../store/officialDoc/actions';
import { SelectOutline, SelectSearch } from '../ui';
import moment from 'moment';
import { colorsStatus } from '../../utils/consts';
import { useSearchAny } from '../../hooks/useSearchAny';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { Pagination } from '@mui/material';

interface Column {
  id: 'id' | 'name' | 'type' | 'approved' | 'update' | 'author' | 'redactions' | 'edit';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '5%' },
  { id: 'name', label: 'Назва', align: 'left' },
  { id: 'type', label: 'Тип', align: 'center', width: '10%' },
  { id: 'approved', label: 'Статус', align: 'center', width: '10%' },
  {
    id: 'update',
    label: 'Дата змін',
    align: 'center',
    width: '10%',
  },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '9%',
  },
  {
    id: 'redactions',
    label: 'Редакції',
    align: 'center',
    width: '13%',
  },
  {
    id: 'author',
    label: 'Створено/Редаговано',
    width: '12%',
  },
];

type PropsType = {};

const marksOptions: { label: string; value: string }[] = [
  { value: '', label: 'Усі' },
  { value: '2', label: 'Чинний' },
  { value: '1', label: 'Не набрав чинності' },
  { value: '0', label: 'Втратив чинність' },
  { value: '3', label: 'Частково втратив чинність' },
];

export const OfficialDocList: React.FC<PropsType> = () => {
  const [id, setId] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [typeId, setTypeId] = React.useState('');
  const [approved, setApproved] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [docNum, setDocNum] = React.useState('');
  const [error, setError] = React.useState(false);
  const { OfficialDoc, Sidebar } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classesTable = StylesTable();
  const { loading, handleSearch } = useSearchAny({
    SearchData: SearchDocs,
  });
  const navigate = useNavigate();

  const obj = {
    title,
    typeId,
    approved,
    status,
    docNum,
  };

  React.useEffect(() => {
    if (OfficialDoc.data) {
      dispatch(setSearchData(OfficialDoc.data));
    }
  }, [dispatch, OfficialDoc.data]);

  React.useEffect(() => {
    if (Sidebar.page.type === 'main' || Sidebar.page.type === 'list') {
      dispatch(setEditDoc(null));
    }
  }, [dispatch, Sidebar.page]);

  const handleChangePage = (groupId: number) => () => {
    navigate(`${ROUTES.documents}/${groupId}`);
  };

  const createDoc = () => {
    if (OfficialDoc.loading) return null;
    dispatch(
      CreateGroup.request({
        callBack: (success, id) => {
          if (!success) {
            setError(true);
            const timeId = setTimeout(() => {
              setError(false);
              clearTimeout(timeId);
            }, 1500);
          }
          if (success && !Number.isNaN(Number(id))) {
            window.open(`/document/${id}`);
          }
        },
      })
    );
  };

  const handleChangeCurrentPage = (event: unknown, newPage: number) => {
    handleSearch(String(newPage), 'page', obj);
  };

  return (
    <>
      <Box className={classesTable.header}>
        <div style={{ position: 'relative' }}>
          <Button
            disabled={OfficialDoc.loading}
            className={classesTable.searchField}
            variant='contained'
            color='primary'
            onClick={createDoc}
          >
            Створити документ
          </Button>
          {error ? <div className={classesTable.errMesg}>Виникла помилка</div> : ''}
        </div>
        <TextField
          size='small'
          id='outlined-basic-id'
          label='Пошук по ID'
          variant='outlined'
          value={id}
          className={classesTable.searchField}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            const num = event.target.value.replace(/[^0-9.]/g, '');
            setId(num);
            handleSearch(num, 'id', obj);
          }}
        />
        <TextField
          className={classesTable.searchField}
          size='small'
          id='outlined-basic-title'
          label='Назва'
          variant='outlined'
          value={title}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setTitle(event.target.value);
            handleSearch(event.target.value, 'title', obj);
          }}
        />
        <SelectSearch
          style={{ minWidth: '165px', maxWidth: '210px' }}
          title='Тип документа'
          value={typeId}
          className={classesTable.searchField}
          options={OfficialDoc.typesDoc?.map((it) => ({ value: String(it.id), label: it.name })) || []}
          onChange={(value) => {
            setTypeId(value);
            handleSearch(value, 'typeId', obj);
          }}
        />
        <SelectSearch
          style={{ width: '210px' }}
          title='Статус'
          value={approved}
          className={classesTable.searchField}
          options={[
            { id: '', name: 'Усі' },
            { id: 1, name: 'Створено' },
            { id: 2, name: 'Опублікований' },
            { id: 0, name: 'Видалений' },
          ].map((it) => ({ value: String(it.id), label: it.name }))}
          onChange={(value) => {
            setApproved(value);
            handleSearch(value, 'approved', obj);
          }}
        />
        <SelectOutline
          style={{ width: '210px' }}
          title='Юридичний сила'
          value={status}
          className={classesTable.searchField}
          options={marksOptions}
          handleChange={(value) => {
            setStatus(value);
            handleSearch(value, 'status', obj);
          }}
        />
        <TextField
          className={classesTable.searchField}
          style={{ marginRight: '20rem' }}
          size='small'
          id='outlined-basic-title'
          label='Номер'
          variant='outlined'
          value={docNum}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setDocNum(event.target.value);
            handleSearch(event.target.value, 'docNum', obj);
          }}
        />
        <div>Усього: {OfficialDoc.searchData?.total || 0}</div>
        {OfficialDoc.loading || loading ? <CircularProgress size={25} /> : null}
      </Box>
      <TableContainer className={classesTable.container} style={{ maxHeight: 'calc(100vh - 280px)' }}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {OfficialDoc.searchData?.officialDoc?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>{row.id}</TableCell>
                  <TableCell className={classesTable.row}>{row.title}</TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    {row.type?.name || ''}
                  </TableCell>
                  <TableCell className={classesTable.row} align='center' style={{ color: colorsStatus[row.approved] }}>
                    {OfficialDoc.status.approved[row.approved]}
                  </TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    {moment(row.update).format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton
                      aria-label='edit'
                      onClick={() => {
                        window.open(`/document/${row.id}`);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align='center'>
                    <Button variant='contained' color='primary' onClick={handleChangePage(row.group_id)}>
                      Переглянути
                    </Button>
                  </TableCell>
                  <TableCell className={classesTable.row}>
                    {row.creator?.fio || ''}
                    <br />
                    {row.author?.fio || ''}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classesTable.pagination}
        count={OfficialDoc.searchData?.pages || 1}
        page={OfficialDoc.searchData?.currentPage || 1}
        onChange={handleChangeCurrentPage}
      />
    </>
  );
};
