import React, { useEffect, useState } from 'react';
import { ConsultationForm } from '../../components/ConsultationForm';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckConsultationVisitor,
  DeleteVisitors,
  GetConsultation,
  TypeUpdateConsultationR,
  UpdateConsultation,
} from '../../store/consultations/actions';
import { GetBlocks } from '../../store/blocks/actions';
import { GetMainBlocks } from '../../store/main/actions';
import { AppStore } from '../../store/applicationState';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ConfigurationsActions } from '../../store/configurations/actions';
import { IDocumentVisitor } from '../../store/consultations/types';
import { AlertModal } from '../../components/modals/AlertModal';

export const EditConsultation: React.FC = () => {
  const { Consultations } = useSelector((store: AppStore) => store);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visitors, setVisitors] = useState<IDocumentVisitor[] | null>();

  React.useEffect(() => {
    if (id) {
      dispatch(GetConsultation.request({ id: Number(id) }));
      dispatch(
        CheckConsultationVisitor.request({
          id: +id,
        })
      );
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    if (Consultations.current) {
      dispatch(ConfigurationsActions.setSiteId(Consultations.current.site_id));
    }
  }, [dispatch, Consultations]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      dispatch(DeleteVisitors.request({ id: !isNaN(Number(id)) ? Number(id) : 0 }));
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      dispatch(
        DeleteVisitors.request({
          id: !isNaN(Number(id)) ? Number(id) : 0,
        })
      );
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate, dispatch, id]);

  useEffect(() => {
    setVisitors(Consultations.visitors);
  }, [Consultations]);

  const handleUpdate = (
    data: TypeUpdateConsultationR['data'],
    callBack: (err?: { name: string; message: string }) => void
  ) => {
    if (!Consultations.current) return null;
    dispatch(
      CheckConsultationVisitor.request({
        id: Consultations.current.id,
      })
    );
    dispatch(
      UpdateConsultation.request({
        data,
        id: Consultations.current.id,
        callBack: (success, dta) => {
          if (!success && dta) {
            if (dta.status === 409) {
              callBack({ name: 'title', message: 'Такий заголовок вже використовується' });
            } else if (dta.status === 410) {
              callBack({ name: 'error', message: 'Публікацію видалено' });
            } else {
              callBack({ name: 'error', message: 'Сталася помилка' });
            }
          } else {
            callBack();
          }
          if (success && Consultations.current?.is_public === 1 && data.isPublic === 0) {
            dispatch(GetBlocks.request({ id: Consultations.current.site_id }));
            dispatch(GetMainBlocks.request({ id: Consultations.current.site_id }));
          }
        },
      })
    );
  };

  if (!Consultations.current)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );

  return (
    <>
      <AlertModal visitors={visitors} />
      <ConsultationForm
        type='edit'
        data={Consultations.current}
        handleSubmit={handleUpdate}
        handleCancel={() => {
          navigate(ROUTES.routeChange('list'));
          dispatch(DeleteVisitors.request({ id: !isNaN(Number(id)) ? Number(id) : 0 }));
        }}
      />
    </>
  );
};
