import { Editor } from '@tinymce/tinymce-react';

export const deleteLiveSample = (editor: Editor['editor']) => () => {
  if (!editor) return null;

  const node = editor.selection.getNode();
  if (!node || !node.classList.contains('templates-live-sample')) return null;

  node.remove();

  document.querySelector('.tox-pop')?.remove();
};
