import React from 'react';
import { useSelector } from 'react-redux';
import { Alert, AlertTitle, Button, CircularProgress, CssBaseline, TextField, Typography } from '@mui/material';
import { AppStore } from '../../store/applicationState';
import Box from '@mui/material/Box';
import { SelectPopup, SelectRubrics } from '../ui';
import { StylesEdit } from '../StylesEdit';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { EditorDefault } from '../Editors';
import { CATEGORIES } from '../../utils/consts';
import { IService } from '../../store/services/types';
import { TypeCreateServiceR } from '../../store/services/actions';
import { Editor } from '@tinymce/tinymce-react';
import { RubricsModal } from '../modals';

interface PropsType {
  type: 'edit' | 'new';
  data?: IService | null;
  handleSubmit: (data: TypeCreateServiceR['data'], callBack: (err?: string) => void) => void;
  handleCancel: () => void;
}

export const ServiceForm: React.FC<PropsType> = ({ type, data, handleSubmit, handleCancel }) => {
  const [title, setTitle] = React.useState<string>(data?.title || '');
  const [rubricId, setRubricId] = React.useState<string>(data?.rubric_id ? String(data.rubric_id) : '');
  const [url, setUrl] = React.useState<string>(data?.url || '');
  const [isPublic, setIsPublic] = React.useState(data?.is_public || 0);
  const [rubricIds, setRubricIds] = React.useState<{ id: number; name: string }[]>([]);
  const [error, setError] = React.useState(false);
  const [errors, setErrors] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const editorDefRef = React.useRef<Editor['editor'] | null>(null);
  const { Rubrics, Configurations } = useSelector((store: AppStore) => store);
  const classes = StylesEdit();

  React.useEffect(() => {
    if (data?.description && editorDefRef.current) {
      editorDefRef.current.setContent(data.description);
    }
    if (data && data.service_rubric_details.length > 0) {
      setRubricIds(data.service_rubric_details.map((it) => ({ id: it.rubric.id, name: it.rubric.name })));
    }
  }, [data]);

  const onSubmit = () => {
    const description = editorDefRef.current?.getContent();

    if (!title || !url) return setError(true);

    setError(false);
    setErrors('');
    setLoading(true);

    handleSubmit(
      {
        is_public: isPublic,
        title,
        description,
        url,
        site_id: data ? data.site_id : Configurations.siteId,
        rubric_id: Number(rubricId),
        rubricsIds: rubricIds.map((it) => it.id),
      },
      (err) => {
        if (err) {
          setErrors(err);
        }
        setLoading(false);
      }
    );
  };

  return (
    <Box>
      <CssBaseline />
      <div className={classes.paper}>
        <Box className={classes.header}>
          <Typography component='h1' variant='h5'>
            {type === 'new' ? 'Додати сервіс' : 'Редагувати сервіс'}
          </Typography>
          {data && (
            <Box style={{ display: 'flex' }}>
              <Box className={classes.headerItem}>Дата створення {moment(data?.adate).format('DD.MM.YYYY HH:mm')}</Box>
              <Box>Дата редагування {moment(data?.update).format('DD.MM.YYYY HH:mm')}</Box>
            </Box>
          )}
        </Box>
        <FormControlLabel
          style={{ marginTop: '7px' }}
          control={<Checkbox checked={!!isPublic} />}
          onChange={() => setIsPublic(isPublic ? 0 : 1)}
          label='Публікувати'
        />
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Заголовок'
          variant='outlined'
          value={title}
          error={error && !title}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setTitle(event.target.value as string);
          }}
        />
        <SelectRubrics
          title='Рубрикатор'
          value={rubricId}
          className={classes.textField}
          options={Rubrics.rubrics && Rubrics.rubrics[CATEGORIES.SERVICES]}
          onChange={setRubricId}
        />
        <SelectPopup
          title='Додатк. Рубрикатор'
          value={rubricIds}
          className={classes.textField}
          options={Rubrics.rubrics && Rubrics.rubrics[CATEGORIES.SERVICES]}
          onChange={setRubricIds}
          PropsModal={RubricsModal}
        />
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='URL'
          variant='outlined'
          value={url}
          error={error && !url}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setUrl(event.target.value as string);
          }}
        />
        <div style={{ marginTop: '20px' }}>
          <EditorDefault placeholder='Опис' editorRef={editorDefRef} initialValue={data?.description} />
        </div>
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={loading} onClick={onSubmit}>
            {loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' sx={{ mt: 2, width: '100%' }}>
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {errors ? (
          <Alert severity='error' sx={{ mt: 2, width: '100%' }}>
            <AlertTitle>{errors}</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
