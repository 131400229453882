import { Reducer } from 'redux';
import ActionTypes, { TUsersState } from './types';

export const initialState: TUsersState = {
  data: null,
  current: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TUsersState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_USERS_R:
    case ActionTypes.CREATE_USER_R:
    case ActionTypes.UPDATE_USER_R:
    case ActionTypes.UPDATE_PASSWORD_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.GET_USERS_E:
    case ActionTypes.CREATE_USER_E:
    case ActionTypes.UPDATE_USER_E:
    case ActionTypes.UPDATE_PASSWORD_E:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.GET_USERS_S:
      return {
        ...state,
        data: action.payload,
        loading: false,
        errors: '',
      };

    case ActionTypes.GET_USER_R:
      return {
        ...state,
        current: null,
      };

    case ActionTypes.GET_USER_S:
      return {
        ...state,
        loading: false,
        errors: '',
        current: action.payload,
      };

    case ActionTypes.CREATE_USER_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data ? [action.payload, ...state.data] : [action.payload],
      };

    case ActionTypes.UPDATE_USER_S: {
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data ? state.data.map((it) => (it.id === action.payload.id ? action.payload : it)) : state.data,
        current: action.payload,
      };
    }
    case ActionTypes.DELETE_USER_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data ? state.data.filter((it) => it.id !== action.payload.id) : state.data,
      };

    default:
      return state;
  }
};

export { reducer as UsersReducer };
