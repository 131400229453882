import React from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import './BoxModal.scss';
import { useStyles } from './styles';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { GroupAccordion, SelectOutline } from '../ui';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { DownloadValueOfDoc, GetValueOfDoc } from '../../store/officialDoc/actions';

interface Props {
  id: number;
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const ValueOfDocModal: React.FC<Props> = ({ id, open, handleClose, handleConfirm }) => {
  const [siteId, setSiteId] = React.useState('1');
  const [expanded, setExpanded] = React.useState<string | boolean>(false);
  const [loading, setLoading] = React.useState(false);

  const { OfficialDoc } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classes = useStyles();

  const { catalogues = {}, consultations = {}, forms = {}, videos = {} } = OfficialDoc.valueOfDoc || {};

  React.useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(
        GetValueOfDoc.request({
          id,
          siteId: Number(siteId),
          callBack: () => setLoading(false),
        })
      );
    }
  }, [dispatch, id, siteId]);

  const handleChangeDirection = (value: string) => () => {
    setExpanded(expanded === value ? false : value);
  };

  const downloadXlsx = () => {
    if (loading) return null;
    setLoading(true);
    dispatch(
      DownloadValueOfDoc.request({
        id,
        callBack: (success, res?: { data: Buffer }) => {
          if (success && res?.data) {
            const buff = new Uint8Array(res.data).buffer;
            const url = window.URL.createObjectURL(
              new Blob([buff], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `doc_value_${id}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode && link.parentNode.removeChild(link);
          }
          setLoading(false);
        },
      })
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <div className='boxModal-inner'>
        <Box className='boxBody'>
          <Box mb={2} display={'flex'} justifyContent={'space-between'}>
            <Typography variant={'h6'}>Ціність документа</Typography>
            <Button color='primary' variant='contained' onClick={downloadXlsx}>
              {loading ? <CircularProgress color='inherit' size={25} /> : 'Скачати'}
            </Button>
          </Box>
          <SelectOutline
            style={{ marginBottom: '16px' }}
            title='Система'
            value={siteId}
            options={[
              { value: '1', label: 'Головбух' },
              { value: '2', label: 'Охорона праці' },
              { value: '3', label: 'Кадри' },
              { value: '4', label: 'Держзакупівлі' },
              { value: '5', label: 'Медзаклад' },
            ]}
            handleChange={setSiteId}
          />
          <Box>
            <GroupAccordion
              title={`Робочі ситуації ${consultations[siteId]?.length || 0}`}
              expanded={expanded === '1'}
              handleChange={handleChangeDirection('1')}
              children={
                <Box display={'flex'} flexDirection={'column'} maxHeight={'150px'} overflow={'auto'}>
                  {consultations[siteId]?.map((item, i) => (
                    <Link key={`1-${i}`} href={item.url} target='_blank'>
                      {item.url}
                    </Link>
                  ))}
                </Box>
              }
            />
            <GroupAccordion
              title={`Форми ${forms[siteId]?.length || 0}`}
              expanded={expanded === '2'}
              handleChange={handleChangeDirection('2')}
              children={
                <Box display={'flex'} flexDirection={'column'} maxHeight={'150px'} overflow={'auto'}>
                  {forms[siteId]?.map((item, i) => (
                    <Link key={`2-${i}`} href={item.url} target='_blank'>
                      {item.url}
                    </Link>
                  ))}
                </Box>
              }
            />
            <GroupAccordion
              title={`Довідники ${catalogues[siteId]?.length || 0}`}
              expanded={expanded === '3'}
              handleChange={handleChangeDirection('3')}
              children={
                <Box display={'flex'} flexDirection={'column'} maxHeight={'150px'} overflow={'auto'}>
                  {catalogues[siteId]?.map((item, i) => (
                    <Link key={`3-${i}`} href={item.url} target='_blank'>
                      {item.url}
                    </Link>
                  ))}
                </Box>
              }
            />
            <GroupAccordion
              title={`Вебінари ${videos[siteId]?.length || 0}`}
              expanded={expanded === '4'}
              handleChange={handleChangeDirection('4')}
              children={
                <Box display={'flex'} flexDirection={'column'} maxHeight={'150px'} overflow={'auto'}>
                  {videos[siteId]?.map((item, i) => (
                    <Link key={`link-${i}`} href={item.url} target='_blank'>
                      {item.url}
                    </Link>
                  ))}
                </Box>
              }
            />
          </Box>
        </Box>
        <div className='boxFooter'>
          <Button disabled={OfficialDoc.loading} color='primary' variant='contained' onClick={handleConfirm}>
            {OfficialDoc.loading ? <CircularProgress size={15} /> : 'підтвердити'}
          </Button>
          <Button color='inherit' variant='contained' onClick={handleClose}>
            скасувати
          </Button>
        </div>
      </div>
    </Modal>
  );
};
