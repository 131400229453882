import { action } from 'typesafe-actions';
import { ActionTypes, TUserState } from './types';
import { Actions, TDefRequest } from '../Actions';

export const setAuth = (payload: boolean) => action(ActionTypes.SET_IS_AUTH, payload);

const GetUserAction = new Actions('GET_USER', ActionTypes);
export interface TypeGetUserR extends TDefRequest {
  id: number;
}
export const GetUser = {
  request: (payload: TypeGetUserR) => GetUserAction.request(payload),
  success: (payload: TUserState['data']) => GetUserAction.success(payload || {}),
  error: (message: string) => GetUserAction.error(message),
};
