import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TFactoidsState, IFactoids, IFactoidBlock } from './types';
import { TypeSearchData } from '../../hooks/useSearchAny';

export const setFactoids = (payload: TFactoidsState['factoids']) => action(ActionTypes.SET_FACTOIDS, payload);
export const resetFactoids = () => action(ActionTypes.RESET_FACTOIDS, {});
export const setEditData = (payload: TFactoidsState['editData']) => action(ActionTypes.SET_EDIT_FACTOID, payload);
export const setEditBlockData = (payload: TFactoidsState['editBlockData']) =>
  action(ActionTypes.SET_EDIT_BLOCK_FACTOID, payload);
export const setListData = (payload: TFactoidsState['listBlockData']) =>
  action(ActionTypes.GET_LIST_FACTOID_BLOCKS, payload);

const GetFactoidsAction = new Actions('GET_FACTOIDS', ActionTypes);

export interface TypeGetFactoidsR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    limit?: number;
    created_at?: Date | null;
  };
}

export const GetFactoids = {
  request: (payload: TypeGetFactoidsR) => GetFactoidsAction.request(payload),
  success: (payload: TFactoidsState['data']) => GetFactoidsAction.success(payload || {}),
  error: (message: string) => GetFactoidsAction.error(message),
};

const GetFactoidBlocksAction = new Actions('GET_FACTOID_BLOCKS', ActionTypes);

export interface TypeGetFactoidBlocksR extends TDefRequest {
  data: {
    factoid_id?: number;
    id?: number;
    title?: string;
    body?: string;
    position?: number;
    approved?: number;
    created_at?: Date | null;
    updated_at?: Date | null;
  };
}

export const GetFactoidBlocks = {
  request: (payload: TypeGetFactoidBlocksR) => GetFactoidBlocksAction.request(payload),
  success: (payload: TFactoidsState['dataBlocks']) => GetFactoidBlocksAction.success(payload || {}),
  error: (message: string) => GetFactoidBlocksAction.error(message),
};

const SearchFactoidsAction = new Actions('SEARCH_FACTOIDS', ActionTypes);

export interface TypeSearchFactoidsR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    limit?: number;
    created_at?: Date | null
  };
}

export const SearchFactoids = {
  request: (payload: TypeSearchFactoidsR | TypeSearchData) => SearchFactoidsAction.request(payload),
  success: (payload: IFactoids[]) => SearchFactoidsAction.success(payload || {}),
  error: (message: string) => SearchFactoidsAction.error(message),
};

const CreateFactoidsAction = new Actions('CREATE_FACTOIDS', ActionTypes);

export interface TypeCreateFactoidsR extends TDefRequest {
  data: FormData;
}

export const CreateFactoids = {
  request: (payload: TypeCreateFactoidsR) => CreateFactoidsAction.request(payload),
  success: (payload: IFactoids) => CreateFactoidsAction.success(payload || {}),
  error: (message: string) => CreateFactoidsAction.error(message),
};

const CreateFactoidBlocksAction = new Actions('CREATE_FACTOID_BLOCKS', ActionTypes);

export interface TypeCreateFactoidBlocksR extends TDefRequest {
  data: FormData;
}

export const CreateFactoidBlocks = {
  request: (payload: TypeCreateFactoidBlocksR) => CreateFactoidBlocksAction.request(payload),
  success: (payload: IFactoidBlock) => CreateFactoidBlocksAction.success(payload || {}),
  error: (message: string) => CreateFactoidBlocksAction.error(message),
};

const UpdateFactoidsAction = new Actions('UPDATE_FACTOIDS', ActionTypes);

export interface TypeUpdateFactoidsR extends TDefRequest {
  data: FormData;
  id: number;
}

export const UpdateFactoids = {
  request: (payload: TypeUpdateFactoidsR) => UpdateFactoidsAction.request(payload),
  success: (payload: IFactoids) => UpdateFactoidsAction.success(payload || {}),
  error: (message: string) => UpdateFactoidsAction.error(message),
};

const UpdateFactoidBlocksAction = new Actions('UPDATE_FACTOID_BLOCKS', ActionTypes);

export interface TypeUpdateFactoidBlocksR extends TDefRequest {
  data: FormData;
  id: number;
}

export const UpdateFactoidBlocks = {
  request: (payload: TypeUpdateFactoidBlocksR) => UpdateFactoidBlocksAction.request(payload),
  success: (payload: IFactoidBlock) => UpdateFactoidBlocksAction.success(payload || {}),
  error: (message: string) => UpdateFactoidBlocksAction.error(message),
};

const DeleteFactoidsAction = new Actions('DELETE_FACTOIDS', ActionTypes);

export interface TypeDeleteFactoidsR extends TDefRequest {
  id: number;
}

export const DeleteFactoids = {
  request: (payload: TypeDeleteFactoidsR) => DeleteFactoidsAction.request(payload),
  success: (payload: IFactoids) => DeleteFactoidsAction.success(payload || {}),
  error: (message: string) => DeleteFactoidsAction.error(message),
};

const DeleteFactoidBlocksAction = new Actions('DELETE_FACTOID_BLOCKS', ActionTypes);

export interface TypeDeleteFactoidBlocksR extends TDefRequest {
  id: number;
}

export const DeleteFactoidBlocks = {
  request: (payload: TypeDeleteFactoidBlocksR) => DeleteFactoidBlocksAction.request(payload),
  success: (payload: IFactoidBlock) => DeleteFactoidBlocksAction.success(payload || {}),
  error: (message: string) => DeleteFactoidBlocksAction.error(message),
};
