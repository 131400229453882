import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppStore } from '../../store/applicationState';
import { DeleteForms, GetForms, SearchForms } from '../../store/forms/actions';
import { IForms } from '../../store/forms/types';
import { GetCart } from '../../store/cart/actions';
import { StylesTable } from '../../components/Stylestable';
import { WarningModal } from '../../components/modals';
import { ROUTES } from '../../utils/routes';
import { useSearchAny } from '../../hooks/useSearchAny';
import { Pagination } from '@mui/material';

interface Column {
  id: 'id' | 'title' | 'rubric' | 'template' | 'sample' | 'isPopular' | 'isMonth' | 'delete' | 'isPublic' | 'author';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: '№', align: 'center', width: '6%' },
  { id: 'title', label: 'Заголовок', align: 'left' },
  {
    id: 'isPublic',
    label: 'Публікція',
    align: 'center',
  },
  {
    id: 'isPopular',
    label: 'Популярне',
    align: 'center',
  },
  {
    id: 'isMonth',
    label: 'Форма місяця',
    align: 'center',
  },
  {
    id: 'author',
    label: 'Редактор',
    align: 'left',
  },
  {
    id: 'template',
    label: 'Шаблони',
    align: 'left',
  },
  {
    id: 'sample',
    label: 'Зразки',
    align: 'left',
  },
  {
    id: 'rubric',
    label: 'Рубрика',
    align: 'left',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '8%',
  },
];

type PropsType = {};

export const ListForms: React.FC<PropsType> = () => {
  const [isModal, setIsModal] = useState<{ id: number } | null>(null);
  const [forms, setForms] = useState<IForms[]>([]);

  const { Forms, Configurations } = useSelector((store: AppStore) => store);

  const classesTable = StylesTable();
  const dispatch = useDispatch();

  const [id, setId] = useState<any>('');
  const [title, setTitle] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Forms.data?.pages || 1);

  const resetState = () => {
    setId('');
    setTitle('');
    setPage(1);

    dispatch(
      GetForms.request({
        data: {
          siteId: Configurations.siteId,
          page: 1,
        },
      })
    );
  };

  const { loading, handleSearch } = useSearchAny({
    SearchData: SearchForms,
  });

  useEffect(() => {
    dispatch(
      GetForms.request({
        data: {
          siteId: Configurations.siteId,
          page,
          title,
          id,
        },
        callBack: (success, data) => {
          if (success && data?.forms) {
            setForms(data.forms);
            setTotalPages(data.pages);
          }
        },
      })
    );
    //eslint-disable-next-line
  }, [page, Configurations, dispatch]);

  const handleChangeRoute =
    (path = '' as string | number) =>
    () => {
      window.open(ROUTES.routeChange(`list/${path}?site_id=${Configurations.siteId}`));
    };

  const handleRemove = (id: number) => {
    dispatch(
      DeleteForms.request({
        id,
        callBack: (success) => {
          if (success) {
            dispatch(
              GetCart.request({
                data: {
                  siteId: Configurations.siteId,
                },
              })
            );
            dispatch(
              GetForms.request({
                data: {
                  siteId: Configurations.siteId,
                },
              })
            );
          }
        },
      })
    );
  };

  const defineForm = (appId: number) => {
    if (appId === 5) return 'форму або зразок';
    if (appId === 2 || appId === 3) return 'зразок';
    return 'форму';
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (Forms?.forms) {
      setForms(Forms?.forms || Forms.data?.forms);
      setTotalPages(Forms?.pages);
    }
  }, [Forms]);

  return (
    <>
      <Box className={classesTable.header}>
        <Button
          className={classesTable.searchField}
          variant='contained'
          color='primary'
          onClick={handleChangeRoute('new')}
        >
          Створити {defineForm(Configurations.siteId)}
        </Button>
        <TextField
          size='small'
          id='outlined-basic-id'
          label='Пошук по ID'
          variant='outlined'
          value={id}
          className={classesTable.searchField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const value = event.target.value as string;
            const data: any = {};
            if (page) data['page'] = 1;
            data['siteId'] = Configurations.siteId;
            handleSearch(value, 'id', data);
            setPage(1);
            setId(value);
          }}
        />
        <TextField
          className={classesTable.searchField}
          size='small'
          id='outlined-basic-title'
          label='Пошук по заголовку'
          variant='outlined'
          value={title}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const value = event.target.value as string;
            const data: any = {};
            if (page) data['page'] = 1;
            data['siteId'] = Configurations.siteId;
            handleSearch(value, 'title', data);
            setPage(1);
            setTitle(value);
          }}
        />
        <Button className={classesTable.searchField} variant='contained' color='primary' onClick={resetState}>
          {loading ? <CircularProgress color='inherit' size={25} /> : 'Очистити фільтри'}
        </Button>
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {forms?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>
                    <Link sx={{ cursor: 'pointer' }} onClick={handleChangeRoute(row.id)}>
                      {row.id}
                    </Link>
                  </TableCell>
                  <TableCell className={classesTable.row}>{row.title}</TableCell>
                  <TableCell align='center' className={classesTable.row}>
                    {row.is_public ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell align='center' className={classesTable.row}>
                    {row.is_popular ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell align='center' className={classesTable.row}>
                    {row.is_month ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell className={classesTable.row}>{row.author ? row.author.fio : ''}</TableCell>
                  <TableCell className={classesTable.row}>
                    {row.files.filter((file) => file.type === 1).length || 0 ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell className={classesTable.row}>
                    {row.files.filter((file) => file.type === 2).length || 0 ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell className={classesTable.row}>{row.rubric?.name || ''}</TableCell>
                  <TableCell align='center'>
                    <IconButton aria-label='delete' onClick={() => setIsModal({ id: row.id })}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination className={classesTable.pagination} count={totalPages} page={page} onChange={handleChangePage} />
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
