import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { StylesTable } from '../Stylestable';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { DeleteConsultation } from '../../store/consultations/actions';
import { DeleteForms } from '../../store/forms/actions';
import { WarningModal } from '../modals';
import { DeleteVideo } from '../../store/video/actions';
import { GetCart, SearchCart } from '../../store/cart/actions';
import { DeleteCatalog } from '../../store/catalogues/actions';
import { useSearchAny } from '../../hooks/useSearchAny';
import Select from 'react-select';
import { CATEGORIES } from '../../utils/consts';

interface Column {
  id: 'id' | 'name' | 'category' | 'open_publication_at' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '10%' },
  { id: 'name', label: 'Заголовок', align: 'left' },
  { id: 'category', label: 'Рубрика', align: 'left' },
  { id: 'open_publication_at', label: 'Розділ', align: 'center' },
  {
    id: 'delete',
    label: '',
    align: 'center',
    width: '25%',
  },
];

const marksOptions: { label: string; value: string }[] = [
  { label: 'Консультаії', value: String(CATEGORIES.CONSULTATIONS) },
  { label: 'Відео', value: String(CATEGORIES.FORMS) },
  { label: 'Форми', value: String(CATEGORIES.VIDEO) },
  { label: 'Довідники', value: String(CATEGORIES.CATALOGUES) },
];

type PropsType = {};

export const PublicationCart: React.FC<PropsType> = () => {
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const { Cart, Configurations } = useSelector((store: AppStore) => store);
  const [publications, setPublications] = useState<any[] | null>([]);

  const dispatch = useDispatch();
  const classesTable = StylesTable();

  const [id, setId] = useState<any>('');
  const [title, setTitle] = useState('');
  const [type, setType] = useState<{ label: string; value: string }[] | null>(null);

  const { loading, handleSearch } = useSearchAny({
    SearchData: SearchCart,
  });

  const restorePublication = (id: number) => {
    const publication = publications?.find((el) => el.id === id) || null;

    if (!publication) return;

    const actions: Record<
      string,
      {
        deleteAction: {
          request: (payload: { id: number; callBack: (success: boolean) => void }) => {
            type: any;
            payload: Object;
          };
        };
      }
    > = {
      Консультація: {
        deleteAction: DeleteConsultation,
      },
      Форма: {
        deleteAction: DeleteForms,
      },
      Відео: {
        deleteAction: DeleteVideo,
      },
      Довідник: {
        deleteAction: DeleteCatalog,
      },
    };

    const { deleteAction } = actions[publication.type];

    if (deleteAction) {
      dispatch(
        deleteAction.request({
          id,
          callBack: (success: boolean) => {
            if (success) {
              dispatch(
                GetCart.request({
                  data: {
                    siteId: Configurations.siteId,
                  },
                })
              );
            }
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      GetCart.request({
        data: {
          siteId: Configurations.siteId,
          title,
          id,
        },
      })
    );
    //eslint-disable-next-line
  }, [Configurations, dispatch]);

  useEffect(() => {
    setPublications(Cart.publications);
  }, [Cart]);

  const resetState = () => {
    setId('');
    setTitle('');

    dispatch(
      GetCart.request({
        data: {
          siteId: Configurations.siteId,
        },
      })
    );
  };

  return (
    <>
      <Box className={classesTable.header}>
        <TextField
          size='small'
          id='outlined-basic-id'
          label='Пошук по ID'
          variant='outlined'
          value={id}
          className={classesTable.searchField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const value = event.target.value as string;
            const data: any = {};
            data['siteId'] = Configurations.siteId;
            handleSearch(value, 'id', data);
            setId(value);
          }}
        />
        <TextField
          className={classesTable.searchField}
          size='small'
          id='outlined-basic-title'
          label='Пошук по заголовку'
          variant='outlined'
          value={title}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const value = event.target.value as string;
            const data: any = {};
            data['siteId'] = Configurations.siteId;
            handleSearch(value, 'title', data);
            if (type) data['type'] = JSON.stringify(type.map((el) => el.value));
            setTitle(value);
          }}
        />
        <div style={{ zIndex: 10000, marginTop: '-10px', marginRight: '10px' }}>
          <Select
            isMulti
            placeholder='Розділи'
            name='ratings'
            options={marksOptions}
            value={type}
            className='basic-multi-select'
            classNamePrefix='select'
            onChange={(value) => {
              const data: any = {};
              data['siteId'] = Configurations.siteId;
              data['title'] = title;
              setType(value.map((el) => ({ value: el.value, label: el.label })));
              handleSearch(JSON.stringify(value.map((el) => el.value)), 'type', data);
            }}
          />
        </div>
        <Button className={classesTable.searchField} variant='contained' color='primary' onClick={resetState}>
          {loading ? <CircularProgress color='inherit' size={25} /> : 'Очистити фільтри'}
        </Button>
      </Box>
      {
        <TableContainer className={classesTable.container}>
          <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!Cart.loading && publications?.length ? (
                publications?.map((row, i) => {
                  return (
                    <TableRow
                      style={{ maxHeight: 50 }}
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={`${i}-${row.id}-${row.id}`}
                    >
                      <TableCell align='center' className={classesTable.row}>
                        {row.id}
                      </TableCell>
                      <TableCell className={classesTable.row}>{row.title}</TableCell>
                      <TableCell className={classesTable.row}>{row.rubric?.name || ''}</TableCell>
                      <TableCell align='center' className={classesTable.row}>
                        {row.type}
                      </TableCell>
                      <TableCell align='center'>
                        <Button
                          style={{ margin: '0 0 10px 0' }}
                          variant='contained'
                          color='primary'
                          onClick={() => setIsModal({ id: row.id })}
                        >
                          Відновити
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : Cart.loading ? (
                <Box
                  overflow={'hidden'}
                  width={'1200px'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  height={'400px'}
                >
                  <CircularProgress color='secondary' />
                </Box>
              ) : (
                []
              )}
            </TableBody>
          </Table>
        </TableContainer>
      }

      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && restorePublication(isModal.id);
            setIsModal(null);
          }}
          type='restore'
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
