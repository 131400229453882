import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {
  ChangeOwner,
  GetOfficialDocDraft,
  UpdateOfficialDocDrafts,
  UpdateRedaction,
  setError,
} from '../../store/officialDocDrafts/actions';
import { Alert, AlertTitle } from '@mui/lab';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { SelectOutline } from '../../components/ui';
import { StylesEdit } from '../../components/StylesEdit';
import { EditorDocum } from '../../components/Editors';
import { backlight } from '../../components/Editors/EditorDocum/entities/utils';
import { BaseModal } from '../../components/modals';
import { Roles } from '../../utils/consts';
import { GetUsers } from '../../store/users/actions';
import { hideAnchor } from '../../components/Editors/commands';

export const EditOfficialDocDraft: React.FC = () => {
  const [isEmpty, setIsEmpty] = React.useState(false);
  const [isOwner, setIsOwner] = React.useState(false);
  const [userId, setUserId] = React.useState('');

  const editorRef = React.useRef<any | null>(null);
  const ref = React.useRef<HTMLDivElement | null>(null);

  const { OfficialDocDrafts, OfficialDoc, User, Users } = useSelector((store: AppStore) => store);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = StylesEdit();

  React.useEffect(() => {
    if (id && !Number.isNaN(Number(id))) {
      dispatch(GetOfficialDocDraft.request({ id: Number(id) }));
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    if (User.data && [Roles.HEAD, Roles.ADMIN].includes(User.data.role)) {
      const roles = [Roles.REDACTOR, Roles.HEAD];
      if (User.data.role === Roles.ADMIN) roles.push(Roles.ADMIN);

      dispatch(GetUsers.request({ data: { site_id: 6, role: roles.join(',') } }));
    }
  }, [dispatch, User.data]);

  React.useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  React.useEffect(() => {
    if (OfficialDocDrafts.errors) {
      let timeId: string | number | NodeJS.Timeout | undefined;

      timeId = setTimeout(() => {
        dispatch(setError(''));
        clearTimeout(timeId);
      }, 1500);
    }
  }, [dispatch, OfficialDocDrafts.errors]);

  const handleScroll = (e: Event) => {
    //@ts-ignore
    if (e.target?.scrollingElement?.scrollTop > 60 && ref.current) {
      ref.current.style.top = '0';
    } else if (ref.current) {
      ref.current.style.top = '70px';
    }
  };

  const handleSave = () => {
    if (!editorRef.current || !OfficialDocDrafts.current) return null;

    hideAnchor(editorRef.current);

    const body = backlight(false, editorRef.current.getContent());

    dispatch(
      UpdateOfficialDocDrafts.request({
        data: {
          body,
        },
        id: OfficialDocDrafts.current.id,
      })
    );
  };

  const handleCancel = () => {
    navigate(ROUTES.stepBack());
  };

  const handleUpdateRedaction = () => {
    if (!OfficialDocDrafts.current) return null;

    dispatch(
      UpdateRedaction.request({
        id: OfficialDocDrafts.current.id,
      })
    );
  };

  const handleChangeOwner = () => {
    if (!OfficialDocDrafts.current || !userId) return null;
    setIsOwner(false);
    setUserId('');

    dispatch(
      ChangeOwner.request({
        data: {
          user_id: Number(userId),
        },
        id: OfficialDocDrafts.current.id,
      })
    );
  };

  if (!OfficialDocDrafts.current)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );

  return (
    <Box>
      <CssBaseline />
      <div className={classes.paper} style={{ alignItems: 'center' }}>
        <Box className={classes.header} pt={9}>
          <Typography component='h1' variant='h5'>
            Редагувати документ
          </Typography>
          <Button onClick={() => setIsEmpty(!isEmpty)} variant='text' color='primary' size='small'>
            {isEmpty ? 'розгорнути' : 'згорнути'}
          </Button>
          {User.data && [Roles.HEAD, Roles.ADMIN].includes(User.data.role) ? (
            <Button onClick={() => setIsOwner(true)}>Змінити власника</Button>
          ) : null}
        </Box>
        <div style={{ display: isEmpty ? 'none' : 'contents' }}>
          <TextField
            disabled
            size='small'
            label='Назва'
            value={OfficialDocDrafts.current.title || ''}
            className={classes.textField}
          />
          <Box className={classes.textField} display={'flex'} gap={1}>
            <TextField
              disabled
              size='small'
              fullWidth
              label='Дата старту'
              value={OfficialDocDrafts.current.start_date || ''}
            />
            <TextField
              disabled
              size='small'
              fullWidth
              label='Дата закінчення'
              value={OfficialDocDrafts.current.end_date || ''}
            />
          </Box>
          <TextField
            disabled
            size='small'
            label='Теги'
            value={OfficialDocDrafts.current.search_tags || ''}
            className={classes.textField}
          />
          <TextField
            disabled
            size='small'
            label='Юр. Статус'
            value={OfficialDoc.status.lowStatus[OfficialDocDrafts.current.status]}
            className={classes.textField}
          />
          <TextField
            disabled
            size='small'
            label='Тип'
            value={OfficialDocDrafts.current.type.name}
            className={classes.textField}
          />
          <TextField
            disabled
            size='small'
            label='Видавники'
            value={OfficialDocDrafts.current.publishers.map((item) => item.name).join('; ')}
            className={classes.textField}
          />
          <Box className={classes.textField} display={'flex'} gap={1}>
            <TextField
              disabled
              size='small'
              fullWidth
              label='Дата документа'
              value={OfficialDocDrafts.current.doc_date || ''}
            />
            <TextField disabled size='small' fullWidth label='Номер' value={OfficialDocDrafts.current.doc_num || ''} />
          </Box>
          <Box className={classes.textField} display={'flex'} gap={1}>
            <TextField
              disabled
              size='small'
              fullWidth
              label='Дата реєстрації в Мін’юсті'
              value={OfficialDocDrafts.current.regist_date || ''}
            />
            <TextField
              disabled
              size='small'
              fullWidth
              label='Номер'
              value={OfficialDocDrafts.current.regist_num || ''}
            />
          </Box>
          <TextField
            disabled
            size='small'
            className={classes.textField}
            label='Додатки'
            value={OfficialDocDrafts.current.attachments.map((item) => item.name).join('; ')}
          />
        </div>
        <div style={{ marginTop: '20px', transform: 'translateX(-10%)' }}>
          <EditorDocum
            docId={OfficialDocDrafts.current.id}
            initialValue={OfficialDocDrafts.current.body}
            editorRef={editorRef}
          />
        </div>
        <div className={classes.fixedBtns} ref={ref}>
          <Box display={'flex'} gap={2}>
            <Button variant='contained' color='primary' disabled={OfficialDocDrafts.loading} onClick={handleSave}>
              {OfficialDocDrafts.loading ? <CircularProgress color='inherit' size={25} /> : 'Зберегти'}
            </Button>
            <Button variant='contained' color='inherit' onClick={handleCancel}>
              Повернутися
            </Button>
            <Button
              disabled={OfficialDocDrafts.loading}
              variant='contained'
              color='primary'
              onClick={handleUpdateRedaction}
            >
              {OfficialDocDrafts.loading ? <CircularProgress color='inherit' size={25} /> : 'Оновити редакцію'}
            </Button>
          </Box>
          {OfficialDocDrafts.errors ? (
            <Alert severity='error' sx={{ width: '100%', marginTop: '10px', position: 'absolute' }}>
              <AlertTitle>{OfficialDocDrafts.errors}</AlertTitle>
            </Alert>
          ) : null}
        </div>
      </div>

      <BaseModal
        open={isOwner}
        title={'Зміна власника'}
        onClose={() => setIsOwner(false)}
        onCancel={() => setIsOwner(false)}
        disabled={!userId}
        onConfirm={handleChangeOwner}
      >
        <SelectOutline
          title='Власник'
          value={userId}
          options={Users.data?.map((item) => ({ value: String(item.id), label: item.fio })) || []}
          handleChange={setUserId}
        />
      </BaseModal>
    </Box>
  );
};
