import React from 'react';
import {
  Button,
  CircularProgress,
  CssBaseline,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { StylesEdit } from '../StylesEdit';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { IUser } from '../../store/users/types';
import { FieldNewPasswd, SelectOutline } from '../ui';
import { Positions, Roles } from '../../utils/consts';
import { TypeCreateUserR } from '../../store/users/actions';
import { AlertMessage } from '../AlertMessage';
import { collectionValidate } from '../../utils/collectionValidate';

interface IData {
  [x: string]: { id: number; name: string };
}

interface PropsType {
  type: 'edit' | 'new';
  data?: IUser | null;
  loading?: boolean;
  error?: string;
  systems?: { id: number; name: string }[] | null;
  handleSubmit: (data: TypeCreateUserR['data'], callBack?: (err?: string) => void) => void;
  handleChangePassw?: (value: string, callBack: (value: boolean) => void) => void;
  handleCancel: () => void;
}

export const UserForm: React.FC<PropsType> = ({
  loading,
  error: errors,
  type,
  data,
  systems,
  handleSubmit,
  handleCancel,
  handleChangePassw,
}) => {
  const [username, setUsername] = React.useState(data?.username || '');
  const [password, setPassword] = React.useState('');
  const [fio, setFio] = React.useState(data?.fio || '');
  const [role, setRole] = React.useState(data?.role || 1);
  const [isService, setIsService] = React.useState(data?.is_service || 0);
  const [isRaiting, setIsRaiting] = React.useState(data?.is_raiting || 0);
  const [error, setError] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState('');
  const [errorPassword, setErrorPassword] = React.useState('');
  const [sites, setSites] = React.useState<string[]>([]);
  const [objSites, setObjSites] = React.useState<IData>({});
  const [isNewPassw, setIsNewPassw] = React.useState(false);
  const [apps, setApps] = React.useState<{ id: number; name: string }[]>([]);

  const classes = StylesEdit();

  React.useEffect(() => {
    if (data?.rights && systems) {
      const sites: IData = {};
      const apps: IData = {};
      const names =
        data?.rights?.map((item) => {
          if (item.site) {
            sites[item.site_id] = item.site;
            apps[item.site_id] = item.site;
          }

          return item.site?.name || '';
        }) || [];

      systems.forEach((item) => {
        apps[item.id] = item;
      });

      setObjSites(sites);
      setSites(names);
      setApps(Object.values(apps));
    } else if (systems) {
      setApps(systems);
    }
  }, [data, systems]);

  const handleChange = (event: SelectChangeEvent<typeof sites>) => {
    const {
      target: { value },
    } = event;
    const names = typeof value === 'string' ? value.split(',') : value;
    setSites(names);
    if (names.length > 0 && apps) {
      const objSites = {} as IData;
      apps.forEach((item) => {
        const checked = names.includes(item.name);
        if (checked) {
          objSites[item.id] = item;
        }
      });

      setObjSites(objSites);
    } else {
      setObjSites({});
    }
  };

  const handleChangeRole = (value: string) => {
    const role = Number(value);
    setRole(role);
    const isValue = [Roles.HEAD, Roles.ADMIN].includes(role) ? 1 : 0;
    setIsService(isValue);
    setIsRaiting(isValue);
  };

  const onSubmit = () => {
    if (type === 'new') {
      if (!collectionValidate['email'].checkValue(username)) {
        return setErrorEmail(collectionValidate['email'].error);
      }
      if (!collectionValidate['password'].checkValue(password)) {
        return setErrorPassword(collectionValidate['password'].error);
      }
    }
    const rights = Object.values(objSites)
      .filter((item) => !!Number(item.id))
      .map((item) => ({ site_id: Number(item.id) }));

    if (!fio || !role || !rights.length) return setError(true);

    setError(false);

    handleSubmit({
      username,
      password,
      fio,
      role,
      rights,
      is_service: isService,
      is_raiting: isRaiting,
    });
  };

  const renderFiled = () => {
    if (!data)
      return (
        <>
          <TextField
            required
            size='small'
            id='outlined-basic'
            label='Пароль'
            variant='outlined'
            value={password}
            error={!!errorPassword}
            className={classes.textField}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setPassword(event.target.value as string);
              setErrorPassword('');
            }}
          />
          {errorPassword ? <AlertMessage type='error' message={errorPassword} /> : null}
        </>
      );
    if (isNewPassw && handleChangePassw)
      return <FieldNewPasswd handleClose={() => setIsNewPassw(false)} handleSubmit={handleChangePassw} />;
    return (
      <Stack direction={'row'} spacing={2} mt={2}>
        <Button variant='text' onClick={() => setIsNewPassw(true)}>
          Змінити пароль
        </Button>
      </Stack>
    );
  };

  return (
    <Box>
      <CssBaseline />
      <div className={classes.paper}>
        <Box className={classes.header}>
          <Typography component='h1' variant='h5'>
            {type === 'new' ? 'Створити користувача' : 'Редагувати користувача'}
          </Typography>
          {data && (
            <Box style={{ display: 'flex' }}>
              <Box className={classes.headerItem}>
                Дата створення {moment(data?.created_at).format('DD.MM.YYYY HH:mm')}
              </Box>
              <Box>Дата редагування {moment(data?.updated_at).format('DD.MM.YYYY HH:mm')}</Box>
            </Box>
          )}
        </Box>
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='ПІБ'
          variant='outlined'
          value={fio}
          error={error && !fio}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setFio(event.target.value as string);
          }}
        />
        <TextField
          required
          disabled={type === 'edit'}
          size='small'
          id='outlined-basic'
          label='Пошта'
          variant='outlined'
          value={username}
          error={!!errorEmail}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setUsername(event.target.value as string);
            setErrorEmail('');
          }}
        />
        {errorEmail ? <AlertMessage type='error' message={errorEmail} /> : null}
        {renderFiled()}
        <SelectOutline
          required
          id='outlined-basic'
          title='Роль'
          value={String(role)}
          error={error && !role}
          className={classes.textField}
          options={Object.entries(Positions)
            .filter((items) => {
              if (data?.role) {
                const condition = data.role > Roles.HEAD ? data.role : Roles.HEAD;
                return Number(items[0]) <= condition;
              }

              return Number(items[0]) <= Roles.HEAD;
            })
            .map((items) => ({ value: items[0], label: items[1] }))}
          handleChange={handleChangeRole}
        />
        <Box display={'flex'} alignItems={'center'} height={60}>
          <Typography component='h1' variant='h5' mr={2}>
            Функції:
          </Typography>
          <FormControlLabel
            control={<Checkbox checked={!!isRaiting} />}
            onChange={() => {
              setIsRaiting(isRaiting || ![Roles.HEAD, Roles.ADMIN].includes(role) ? 0 : 1);
            }}
            label='Оцінки'
          />
          <FormControlLabel
            control={<Checkbox checked={!!isService} />}
            onChange={() => {
              setIsService(isService || ![Roles.HEAD, Roles.ADMIN].includes(role) ? 0 : 1);
            }}
            label='Сервіси'
          />
        </Box>
        <FormControl sx={{ mt: 1, width: '900px' }}>
          <InputLabel size={'small'} id='demo-multiple-checkbox-label'>
            Системи
          </InputLabel>
          <Select
            size={'small'}
            labelId='demo-multiple-checkbox-label'
            id='demo-multiple-checkbox-label'
            label='Напрямки'
            name='demo-multiple-checkbox-label'
            multiple
            value={sites}
            onChange={handleChange}
            input={<OutlinedInput sx={{ color: '#000' }} label='Системи' />}
            renderValue={(selected) => selected.join(', ')}
            error={error && sites.length === 0}
          >
            {apps?.map((item) => (
              <MenuItem key={item.id} value={item.name}>
                <Checkbox checked={!!sites.find((it) => it === item.name)} />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={loading} onClick={onSubmit}>
            {loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {error ? <AlertMessage type='error' message={'Заповніть поля'} /> : null}
        {errors && typeof errors === 'string' ? <AlertMessage type='error' message={errors} /> : null}
      </div>
    </Box>
  );
};
