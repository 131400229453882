import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { StylesTable } from '../../Stylestable';
import { ITypeDoc } from '../../../store/officialDoc/types';
import { EditTypeDoc } from './EditTypeDoc';
import { colorsStatus } from '../../../utils/consts';

interface Column {
  id: 'id' | 'name' | 'shortName' | 'delete' | 'approved' | 'docCount' | 'edit';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '3%' },
  { id: 'name', label: 'Назва', align: 'left' },
  { id: 'shortName', label: 'Скорочена назва', align: 'left' },
  { id: 'docCount', label: 'К-сть документів', align: 'center' },
  { id: 'approved', label: 'Статус', align: 'left' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '9%',
  },
];

type PropsType = {};

export const TypeDocList: React.FC<PropsType> = () => {
  const [editData, setEditData] = React.useState<{ type: 'edit' | 'new'; data?: ITypeDoc } | null>(null);

  const { OfficialDoc, Sidebar } = useSelector((store: AppStore) => store);
  const classesTable = StylesTable();

  React.useEffect(() => {
    if (Sidebar.page.type === 'main' || Sidebar.page.type === 'list') {
      setEditData(null);
    }
  }, [Sidebar.page]);

  if (editData) {
    return <EditTypeDoc type={editData.type} data={editData.data} handleCancel={() => setEditData(null)} />;
  }

  return (
    <Box>
      <Box className={classesTable.header}>
        <Button
          style={{ marginRight: '20px' }}
          variant='contained'
          color='primary'
          onClick={() => {
            setEditData({ type: 'new' });
          }}
        >
          Створити тип
        </Button>
      </Box>
      <TableContainer className={classesTable.container} style={{ maxHeight: 'calc(100vh - 240px)' }}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {OfficialDoc.typesDoc?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>{row.id}</TableCell>
                  <TableCell className={classesTable.row}>{row.name}</TableCell>
                  <TableCell className={classesTable.row}>{row.shortName}</TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    {row.docCount}
                  </TableCell>
                  <TableCell className={classesTable.row} style={{ color: colorsStatus[row.approved] }}>
                    {OfficialDoc.status.approved[row.approved]}
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton aria-label='edit' onClick={() => setEditData({ type: 'edit', data: row })}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
