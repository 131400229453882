import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OptionType } from '../components/ManageBlocks/ManageBlocks';
import { IDataOpt } from '../components/ui/SelectSearchReq';
import { TDefRequest } from '../store/Actions';
import { AppStore } from '../store/applicationState';
import { ISubBlock, ITitleIdsPairs } from '../store/main/types';

interface IBlock {
  id: number;
  subBlocks: ISubBlock[];
}

export type ChangeType =
  | 'categoryId'
  | 'itemId'
  | 'title'
  | 'image'
  | 'label'
  | 'position'
  | 'viewBlockId'
  | 'add'
  | 'delete'
  | 'anchor'
  | 'description'
  | 'author_id';

export interface IOptions {
  index: number | null;
  type: ChangeType;
  value: string;
  categoryId?: number;
  id?: number;
}
const initBlock: ISubBlock = {
  id: 0,
  blockId: 0,
  categoryId: 0,
  itemId: 0,
  title: '',
  label: '',
  image: null,
  position: 1,
  viewBlockId: 0,
  isChange: true,
  anchor: '',
};

interface TypeDeleteData extends TDefRequest {
  id: number;
}

interface TypeSearchFreeIdData extends TDefRequest {
  data: {
    siteId: number;
    categoryId: number;
    itemId: number;
  };
}

interface TypeResetItemIdR extends TDefRequest {
  id: number;
}

interface PropsType {
  DeleteData: {
    request: (payload: TypeDeleteData) => void;
  };
  SearchFreeIds: {
    request: (payload: TypeSearchFreeIdData) => void;
  };
  ResetItemId: {
    request: (payload: TypeResetItemIdR) => void;
  };
  blocks: IBlock[] | null | undefined;
  currentId?: number;
  ids: ITitleIdsPairs | null;
}

export const useSelectedBlocks = ({ DeleteData, SearchFreeIds, ResetItemId, blocks, ids, currentId }: PropsType) => {
  const [subBlocks, setSubBlocks] = React.useState<ISubBlock[]>([initBlock]);
  const [options, setOptions] = React.useState<OptionType>({});
  const [selectedId, setSelectedId] = React.useState<{ [x: string]: boolean }>({});

  const { Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const refSelectedId = React.useRef<{ [x: string]: boolean }>({});
  const refOptions = React.useRef<OptionType>({});

  React.useEffect(() => {
    if (ids) {
      const obj: { [x: string]: IDataOpt[] } = {};
      for (const key in ids) {
        obj[key] = ids[key]
          .filter((it) => !selectedId[`${key}.${it.id}`])
          .map((val) => ({
            id: val.id,
            label: String(val.id),
            title: val.title,
          }));
      }
      refOptions.current = obj;
      setOptions(obj);
    }
  }, [ids, selectedId]);

  React.useEffect(() => {
    if (blocks && currentId) {
      const block = blocks.find((it) => it.id === currentId);
      if (block && block.subBlocks?.length > 0) {
        setSubBlocks(block.subBlocks);
      }
    }
  }, [blocks, currentId]);

  const handleSearch = React.useCallback(
    (categoryId: number, itemId: string, callBack?: (resData: IDataOpt[]) => void) => {
      const blockId = Number(itemId);
      if (itemId && !Number.isNaN(blockId) && categoryId) {
        dispatch(
          SearchFreeIds.request({
            data: {
              siteId: Configurations.siteId,
              categoryId: categoryId,
              itemId: blockId,
            },
            callBack: (success, data) => {
              if (success && data) {
                const arr = data[categoryId];
                if (Array.isArray(arr)) {
                  const opt = arr
                    .filter((it) => !refSelectedId.current[`${categoryId}.${it.id}`])
                    .map((it) => ({ id: it, label: String(it.id), title: it.title }));
                  callBack && callBack(opt);
                } else if (callBack) {
                  callBack(refOptions.current[categoryId]);
                }
              } else {
                callBack && callBack(refOptions.current[categoryId]);
              }
            },
          })
        );
      } else {
        callBack && callBack(refOptions.current[categoryId]);
      }
    },
    [dispatch, Configurations.siteId, SearchFreeIds]
  );

  const deleteSubBlock = React.useCallback(
    (id: number) => {
      dispatch(
        DeleteData.request({
          id,
        })
      );
    },
    [dispatch, DeleteData]
  );

  const resetItemId = React.useCallback(
    (id: number) => {
      dispatch(
        ResetItemId.request({
          id,
        })
      );
    },
    [dispatch, ResetItemId]
  );

  const updateSelectedId = React.useCallback((subBlocks: ISubBlock[]) => {
    const obj: { [x: string]: boolean } = {};
    subBlocks.forEach((it) => (obj[`${it.categoryId}.${it.itemId}`] = true));
    refSelectedId.current = obj;
    setSelectedId(obj);
  }, []);

  const handleChange = React.useCallback(
    ({ index, type, value, categoryId, id }: IOptions) => {
      setSubBlocks((prev) => {
        let subBlocksL = [] as ISubBlock[];
        if (type === 'add') {
          subBlocksL = [...prev, { ...initBlock }];
        }
        if (type === 'delete') {
          if (id) {
            const subBlock = prev.find((sub) => sub.id === id);
            if (subBlock) {
              deleteSubBlock(subBlock.id);
            }
          }
          subBlocksL = prev?.filter((it, i) => i !== index);
        }
        if (index !== null && type !== 'delete') {
          subBlocksL = prev?.map((it, i) => {
            if (i === index) {
              const obj: any = {};
              obj[type] = value;
              if (type === 'categoryId') {
                obj['itemId'] = '';
                if (!it.isChange) {
                  obj['isChange'] = true;
                  resetItemId(it.id);
                }
              }
              if (type === 'itemId' && !it.isChange) {
                obj['isChange'] = true;
                resetItemId(it.id);
              }
              return { ...it, ...obj };
            }
            return it;
          });
        }
        if (type === 'itemId' || type === 'categoryId') {
          updateSelectedId(subBlocksL);
        }
        return subBlocksL;
      });
    },
    [deleteSubBlock, resetItemId, updateSelectedId]
  );

  return {
    subBlocks,
    options,
    handleSearch,
    handleChange,
    setSubBlocks,
  };
};
