import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppStore } from '../../store/applicationState';
import { DeleteUser, GetUsers } from '../../store/users/actions';
import { StylesTable } from '../../components/Stylestable';
import { WarningModal } from '../../components/modals';
import { ROUTES } from '../../utils/routes';
import { useSearchAny } from '../../hooks/useSearchAny';
import { SelectOutline } from '../../components/ui';
import { Positions, Roles } from '../../utils/consts';
import { IUser } from '../../store/users/types';

interface Column {
  id: 'id' | 'fio' | 'username' | 'role' | 'rights' | 'fun' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: '№', align: 'center', width: '6%' },
  { id: 'fio', label: 'ПІБ', align: 'left' },
  { id: 'username', label: 'E-mail', align: 'left' },
  { id: 'role', label: 'Роль', align: 'center' },
  { id: 'rights', label: 'Системи', align: 'center' },
  { id: 'fun', label: 'Функції', align: 'center' },
  { id: 'delete', label: 'Видалити', align: 'center', width: '8%' },
];

type PropsType = {};

export const ListUsers: React.FC<PropsType> = () => {
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const { Users, User, Configurations } = useSelector((store: AppStore) => store);

  const classesTable = StylesTable();
  const dispatch = useDispatch();

  const [fio, setFio] = React.useState('');
  const [role, setRole] = React.useState('');
  const [username, setUsername] = React.useState('');

  const { loading, handleSearch } = useSearchAny({
    SearchData: GetUsers,
  });

  React.useEffect(() => {
    dispatch(GetUsers.request({ data: {} }));
  }, [dispatch]);

  const handleChangeRoute =
    (path = '' as string | number) =>
    () => {
      window.open(ROUTES.routeChange(`list/${path}?site_id=${Configurations.siteId}`));
    };

  const handleRemove = (id: number) => {
    dispatch(DeleteUser.request({ id }));
  };

  const resetState = () => {
    setFio('');
    setRole('');
    setUsername('');
    dispatch(GetUsers.request({ data: {} }));
  };

  if (User.data?.role !== Roles.ADMIN) return null;

  return (
    <>
      <Box className={classesTable.header}>
        <Button
          className={classesTable.searchField}
          variant='contained'
          color='primary'
          onClick={handleChangeRoute('new')}
        >
          Додати користувача
        </Button>
        <TextField
          size='small'
          id='outlined-basic-id'
          label='Пошук по ПІБ'
          variant='outlined'
          value={fio}
          className={classesTable.searchField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const value = event.target.value as string;
            handleSearch(value, 'fio', { username, role });
            setFio(value);
          }}
        />
        <TextField
          size='small'
          id='outlined-basic-id'
          label='E-mail'
          variant='outlined'
          value={username}
          className={classesTable.searchField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const value = event.target.value as string;
            handleSearch(value, 'username', { fio, role });
            setUsername(value);
          }}
        />
        <SelectOutline
          style={{ width: '210px' }}
          id='outlined-basic'
          title='Роль'
          value={role}
          className={classesTable.searchField}
          options={[
            { value: '', label: 'Всі' },
            ...Object.entries(Positions).map((items) => ({ value: items[0], label: items[1] })),
          ]}
          handleChange={(value) => {
            handleSearch(value, 'role', { fio, username });
            setRole(value);
          }}
        />
        <Button className={classesTable.searchField} variant='contained' color='primary' onClick={resetState}>
          {loading ? <CircularProgress color='inherit' size={25} /> : 'Очистити фільтри'}
        </Button>
      </Box>
      <TableContainer className={classesTable.container} style={{ maxHeight: 'calc(100vh - 200px)' }}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Users.data?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  {columns.map((column) => {
                    const value = row[column.id as keyof IUser];
                    if (column.id === 'id') {
                      return (
                        <TableCell align='center' key={`${i}-${column.id}-${row.id}`}>
                          <Link sx={{ cursor: 'pointer' }} onClick={handleChangeRoute(row.id)}>
                            {row.id}
                          </Link>
                        </TableCell>
                      );
                    }
                    if (column.id === 'role') {
                      return (
                        <TableCell align='center' className={classesTable.row} key={`${i}-${column.id}-${row.id}`}>
                          {Positions[row.role]}
                        </TableCell>
                      );
                    }
                    if (column.id === 'rights') {
                      return (
                        <TableCell
                          key={`${i}-${column.id}-${row.id}`}
                          align='center'
                          className={classesTable.row}
                          dangerouslySetInnerHTML={{
                            __html: row.rights?.map((item) => item.site?.name || '').join('<br>') || '',
                          }}
                        />
                      );
                    }
                    if (value) {
                      return (
                        <TableCell key={`${i}-${column.id}-${row.id}`} className={classesTable.row}>
                          {String(value)}
                        </TableCell>
                      );
                    }

                    return null;
                  })}
                  <TableCell align='center' className={classesTable.row}>
                    {row.is_raiting ? 'Оцінки' : ''}
                    <br></br>
                    {row.is_service ? 'Сервіси' : ''}
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton aria-label='delete' onClick={() => setIsModal({ id: row.id })}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
