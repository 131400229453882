import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { CreateVideo, TypeCreateVideoR } from '../../store/video/actions';
import { useDispatch } from 'react-redux';
import { VideoForm } from '../../components/VideoForm';
import { ConfigurationsActions } from '../../store/configurations/actions';

export const CreateVideoPage: React.FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const query = new URLSearchParams(search);
    const siteId = query.get('site_id');
    if (siteId) {
      dispatch(ConfigurationsActions.setSiteId(Number(siteId)));
    }
  }, [dispatch, search]);

  const handleCreate = (
    data: TypeCreateVideoR['data'],
    callBack: (err?: { name: string; message: string }) => void
  ) => {
    dispatch(
      CreateVideo.request({
        data,
        callBack: (success, dta) => {
          if (!success && dta) {
            if (dta.status === 409) callBack({ name: 'title', message: 'Такий заголовок вже використовується' });
            else callBack({ name: '1', message: 'Сталася помилка' });
          } else {
            callBack();
            navigate(ROUTES.routeChange(`list/${dta?.id || ''}`));
          }
        },
      })
    );
  };

  return <VideoForm type='new' handleSubmit={handleCreate} handleCancel={() => navigate(ROUTES.routeChange('list'))} />;
};
