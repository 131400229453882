import { IUser } from '../users/types';

export interface TUserState {
  readonly data: IUser | null;
  readonly loading: boolean;
  isAuth: boolean;
  readonly errors?: string | undefined;
}

export enum ActionTypes {
  GET_USER_R = '@@user/GET_USER_R',
  GET_USER_S = '@@user/GET_USER_S',
  GET_USER_E = '@@user/GET_USER_E',

  SET_IS_AUTH = '@@user/SET_IS_AUTH',
  USER_CLEAN_UP = '@@user/USER_CLEAN_UP',
}
