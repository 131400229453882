import { Editor } from '@tinymce/tinymce-react';

interface IResponseData {
  imgSrc: string;
  items: { vertically: number | null; horizontally: number | null; description: string | null }[];
  node: Element | null;
}

export const editLiveSample = (editor: Editor['editor'], callback: (value: IResponseData) => void) => () => {
  const resp: IResponseData = {
    imgSrc: '',
    items: [],
    node: null,
  };

  if (!editor) return null;

  const node = editor.selection.getNode();
  if (!node || !node.classList.contains('templates-live-sample')) return null;

  resp.node = node;

  const image = node.querySelector('.templates-live-sample-image');
  const imgSrc = image?.getAttribute('src') || '';
  if (imgSrc) resp.imgSrc = imgSrc;

  const items = node.querySelectorAll('.templates-live-sample-item');
  items.forEach((item) => {
    const description = item.textContent;

    //@ts-ignore
    const top = Number(item.style?.top?.replace(/[^0-9.]/g, ''));
    //@ts-ignore
    const left = Number(item.style?.left?.replace(/[^0-9.]/g, ''));

    resp.items.push({
      description: description || '',
      vertically: !Number.isNaN(top) ? top : null,
      horizontally: !Number.isNaN(left) ? left : null,
    });
  });

  callback(resp);
};
