import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { StylesTable } from '../../components/Stylestable';
import {
  CreateOpenPublication,
  DeleteOpenPublication,
  GetOpenPublicationIdsByCategory,
  GetOpenPublications,
} from '../../store/publishers/actions';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { SelectSearch, SelectSearchReq } from '../../components/ui';
import { WarningModal } from '../../components/modals';
import moment from 'moment';
import { IDataOpt } from '../../components/ui/SelectSearchReq';
import CircularProgress from '@mui/material/CircularProgress';

interface Column {
  id: 'id' | 'name' | 'category' | 'open_publication_at' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '6%' },
  { id: 'name', label: 'Назва', align: 'left' },
  { id: 'category', label: 'Категорія', align: 'left' },
  { id: 'open_publication_at', label: 'Дата відкриття', align: 'left' },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '8%',
  },
];

type PropsType = {};

export const OpenPublicationsList: React.FC<PropsType> = () => {
  const [publication, setPublication] = React.useState<IDataOpt | null>(null);
  const [category, setCategory] = React.useState('');
  const [isModal, setIsModal] = React.useState<{ id: number; category: number } | null>(null);
  const { Publishers, Configurations } = useSelector((store: AppStore) => store);
  const [loading, setLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState<{ id: number; category: number } | null>(null);
  const [loadingIds, setLoadingIds] = useState(false);

  const classesTable = StylesTable();
  const dispatch = useDispatch();

  const handleRemove = (id: number, category_id: number) => {
    if (deleteLoading) return null;

    setDeleteLoading({ id, category: category_id });
    dispatch(
      DeleteOpenPublication.request({
        id: id,
        category_id: category_id,
        callBack: () => {
          dispatch(
            GetOpenPublications.request({
              data: {
                site_id: Configurations.siteId,
              },
              callBack: () => {
                setDeleteLoading(null);
              },
            })
          );
        },
      })
    );
  };

  const handleGetIdsByCategoryAndId = React.useCallback(
    (category_id: number, id = 0) => {
      if (!category) return null;
      setLoadingIds(true);
      dispatch(
        GetOpenPublicationIdsByCategory.request({
          category_id: category_id,
          site_id: Configurations.siteId,
          id,
          callBack: () => {
            setLoadingIds(false);
          },
        })
      );
    },
    [category, dispatch, Configurations.siteId]
  );

  const handleCreate = () => {
    if (!publication || !category || loading) return null;
    setLoading(true);
    dispatch(
      CreateOpenPublication.request({
        id: publication.id,
        category_id: Number(category),
        callBack: () => {
          dispatch(
            GetOpenPublications.request({
              data: {
                site_id: Configurations.siteId,
              },
              callBack: () => {
                setPublication(null);
                setLoading(false);
              },
            })
          );
        },
      })
    );
  };

  React.useEffect(() => {
    if (Configurations.siteId) {
      dispatch(
        GetOpenPublications.request({
          data: {
            site_id: Configurations.siteId,
          },
        })
      );
    }
  }, [dispatch, Configurations.siteId]);

  useEffect(() => {
    handleGetIdsByCategoryAndId(Number(category));
  }, [category, handleGetIdsByCategoryAndId]);

  return (
    <>
      <Box className={classesTable.header}>
        <TextField
          style={{ width: '68%' }}
          className={classesTable.searchField}
          size='small'
          id='outlined-basic-title'
          label='Назва'
          variant='outlined'
          value={publication?.title || ''}
          disabled={true}
        />
        <SelectSearch
          style={{ width: '210px' }}
          title='Категорія'
          value={category}
          className={classesTable.searchField}
          options={
            Publishers.dataOpenPublications?.categories.map((it) => ({ value: String(it.id), label: it.name })) ||
            undefined
          }
          onChange={(category_id) => {
            setPublication(null);
            setCategory(category_id);
          }}
        />
        <SelectSearchReq
          loadingOuter={loadingIds}
          style={{ margin: '0 10px 10px 0', width: '210px' }}
          title='ID'
          data={publication}
          className={classesTable.textField}
          onChange={setPublication}
          options={Publishers.ids?.map((it) => ({ id: it.id, label: String(it.id), title: it.title }))}
          handleRequest={(value) => {
            if (typeof value !== 'string') return null;
            handleGetIdsByCategoryAndId(Number(category), Number(value.replace(/[^0-9.]/g, '')));
          }}
        />
        <Button
          className={classesTable.searchField}
          style={{ marginRight: '20px' }}
          variant='contained'
          color='primary'
          onClick={handleCreate}
        >
          {loading ? <CircularProgress color='inherit' size={24} /> : 'Відкрити'}
        </Button>
      </Box>
      <TableContainer className={classesTable.container} style={{ maxHeight: 'calc(100vh - 190px)' }}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Publishers.dataOpenPublications?.publishers
              .sort((a, b) => new Date(b.open_publication_at).getTime() - new Date(a.open_publication_at).getTime())
              .map((row, i) => {
                return (
                  <TableRow
                    style={{ maxHeight: 50 }}
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={`${i}-${row.id}-${row.category_id}`}
                  >
                    <TableCell>{row.id}</TableCell>
                    <TableCell className={classesTable.row}>
                      <a href={row.url} target='_blank' rel='noreferrer'>
                        {row.title}
                      </a>
                    </TableCell>
                    <TableCell>{row.category}</TableCell>
                    <TableCell className={classesTable.row}>
                      {moment(row.open_publication_at).format('DD.MM.YYYY HH:mm')}
                    </TableCell>
                    <TableCell align='center'>
                      <IconButton
                        disabled={!!deleteLoading}
                        aria-label='delete'
                        onClick={() => setIsModal({ id: row.id, category: row.category_id })}
                      >
                        {deleteLoading?.id === row.id && deleteLoading.category === row.category_id ? (
                          <CircularProgress size={24} />
                        ) : (
                          <DeleteIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            if (isApprove) {
              handleRemove(isModal.id, isModal.category);
            }
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
