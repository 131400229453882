import React from 'react';
import Button from '@mui/material/Button';
import { OfficialDocList } from './OfficialDocList';
import { setSideBar } from '../../store/sidebar/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { GetDocs } from '../../store/officialDoc/actions';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';

type PropsType = {
  element?: React.ReactElement;
};

export const OfficialDoc: React.FC<PropsType> = ({ element }) => {
  const { Sidebar } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = (e: Event) => {
    //@ts-ignore
    if (e.target?.scrollingElement?.scrollTop > 60 && ref.current) {
      ref.current.style.top = '0';
    } else if (ref.current) {
      ref.current.style.top = '70px';
    }
  };

  const checkActive = (path: string) => {
    if (path === '/') {
      return window.location.pathname === path;
    } else {
      return window.location.pathname.includes(path);
    }
  };

  return (
    <>
      <div
        style={{
          position: 'fixed',
          zIndex: 1001,
          top: 70,
          backgroundColor: 'white',
          paddingTop: '20px',
        }}
        ref={ref}
      >
        <Button
          style={{ marginRight: '15px' }}
          variant={checkActive('/') ? 'contained' : 'outlined'}
          color={checkActive('/') ? 'primary' : 'inherit'}
          onClick={() => {
            dispatch(setSideBar({ value: Sidebar.page.value, name: Sidebar.page.name, type: 'list' }));
            dispatch(GetDocs.request({ data: {} }));
            navigate('/');
          }}
        >
          Список документів
        </Button>
        <Button
          style={{ marginRight: '15px' }}
          variant={checkActive(ROUTES.publishers) ? 'contained' : 'outlined'}
          color={checkActive(ROUTES.publishers) ? 'primary' : 'inherit'}
          onClick={() => {
            dispatch(setSideBar({ value: Sidebar.page.value, name: Sidebar.page.name, type: 'list' }));
            navigate(`${ROUTES.documents}/${ROUTES.publishers}`);
          }}
        >
          Список видавників
        </Button>
        <Button
          variant={checkActive(ROUTES.types) ? 'contained' : 'outlined'}
          color={checkActive(ROUTES.types) ? 'primary' : 'inherit'}
          onClick={() => {
            dispatch(setSideBar({ value: Sidebar.page.value, name: Sidebar.page.name, type: 'list' }));
            navigate(`${ROUTES.documents}/${ROUTES.types}`);
          }}
        >
          Список типів
        </Button>
        <Button
          style={{ marginLeft: '15px' }}
          variant={checkActive(ROUTES.statistics) ? 'contained' : 'outlined'}
          color={checkActive(ROUTES.statistics) ? 'primary' : 'inherit'}
          onClick={() => {
            dispatch(setSideBar({ value: Sidebar.page.value, name: Sidebar.page.name, type: 'list' }));
            navigate(`${ROUTES.documents}/${ROUTES.statistics}`);
          }}
        >
          Статистика
        </Button>
      </div>
      <div style={{ paddingTop: 50 }}>{element || <OfficialDocList />}</div>
    </>
  );
};
