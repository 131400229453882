import { all, call, put, takeEvery, fork } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import {
  CreateAdditionalWidget,
  GetWidgetes,
  TypeCreateAdditionalWidgetR,
  TypeGetWidgetesR,
  TypeGetWidgetesS,
  TypeUpdateAdditionalWidgetR,
  TypeUpdateMainWidgetR,
  UpdateAdditionalWidget,
  UpdateAdditionalWidgetsPosition,
  UpdateMainWidget,
  UpdateMainWidgetsPosition,
} from './actions';
import { callApi } from '../../utils/callApi';
import ActionTypes, { IAdditionalWidgets, IMainWidgets } from './types';
import { TypeUpdatePositionsBlockR } from '../blocks/actions';

function* getWidgetsWorker(action: ReturnType<typeof GetWidgetes.request>): Generator {
  const { data, callBack } = action.payload as TypeGetWidgetesR;

  let success = true;
  const query = buildQuery(data);
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/widgets?${query}`,
    })) as TypeGetWidgetesS;
    yield put(GetWidgetes.success(resp));
  } catch (e) {
    success = false;
    yield put(GetWidgetes.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updateMainWidgetWorker(action: ReturnType<typeof UpdateMainWidget.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateMainWidgetR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data: data,
      path: `/widgets/main/${id}`,
    })) as IMainWidgets;
    yield put(UpdateMainWidget.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(UpdateMainWidget.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* updateAdditionalWidgetWorker(action: ReturnType<typeof UpdateAdditionalWidget.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateAdditionalWidgetR;
  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data: data,
      path: `/widgets/additional/${id}`,
    })) as IAdditionalWidgets;
    yield put(UpdateAdditionalWidget.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(UpdateAdditionalWidget.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* updateMainWidgetPositionsWorker(action: ReturnType<typeof UpdateMainWidgetsPosition.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdatePositionsBlockR;

  let success = true;
  let resp: IMainWidgets[] = [];
  try {
    resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/widgets/positionsMain`,
    })) as IMainWidgets[];
    yield put(UpdateMainWidgetsPosition.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateMainWidgetsPosition.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updateAdditionalWidgetPositionsWorker(
  action: ReturnType<typeof UpdateAdditionalWidgetsPosition.request>
): Generator {
  const { data, callBack } = action.payload as TypeUpdatePositionsBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/widgets/positionsAdditional`,
    })) as IAdditionalWidgets[];
    yield put(UpdateAdditionalWidgetsPosition.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateAdditionalWidgetsPosition.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createAdditionalWidgetWorker(action: ReturnType<typeof CreateAdditionalWidget.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateAdditionalWidgetR;

  let success = true;
  let resp = null;

  try {
    resp = (yield call(callApi, {
      method: 'post',
      data: data,
      path: '/widgets/additional',
    })) as IAdditionalWidgets[];
    yield put(CreateAdditionalWidget.success(resp));
  } catch (e: any) {
    success = false;
    resp = e;
    yield put(CreateAdditionalWidget.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_WIDGETES_R, getWidgetsWorker);
  yield takeEvery(ActionTypes.UPDATE_MAIN_WIDGET_R, updateMainWidgetWorker);
  yield takeEvery(ActionTypes.UPDATE_ADDITION_WIDGET_R, updateAdditionalWidgetWorker);
  yield takeEvery(ActionTypes.UPDATE_MAIN_WIDGET_POSITIONS_R, updateMainWidgetPositionsWorker);
  yield takeEvery(ActionTypes.UPDATE_ADDITIONAL_WIDGET_POSITIONS_R, updateAdditionalWidgetPositionsWorker);
  yield takeEvery(ActionTypes.CREATE_ADDITIONAL_WIDGET_R, createAdditionalWidgetWorker);
}

export default function* widgetesSaga() {
  yield all([fork(watchFetchRequest)]);
}
