import React from 'react';
import { MainMonthBlockForm } from '../../../../components/MainInMonth/MainMonthBlockForm';

export default function Form({ type }: { type: 'new' | 'edit' }) {
  return (
    <MainMonthBlockForm
      type={type}
      name={`${type === 'new' ? 'Створити' : 'Редагувати'} блок шаблону Пам’ятки`}
      fields={{
        title: { require: true },
        description: { require: false, maxLength: 120 },
        link: { require: false, label: 'Посилання на публікацію' },
        image: { require: true },
      }}
    />
  );
}
