import React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import IconButton from '@mui/material/IconButton';
import { Styles } from './Styles';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import DeleteIcon from '@mui/icons-material/Delete';
import { URI } from '../../config';
import { callApi } from '../../utils/callApi';
import { replacePath } from '../../utils/replacePath';

const Input = styled('input')({
  display: 'none',
});

type PropsType = {
  id: string;
  title?: string;
  value?: File | string;
  required?: boolean;
  error?: boolean;
  accept?: string;
  style?: React.CSSProperties | undefined;
  className?: string;
  isDelete?: boolean;
  isImg?: boolean;
  hint?: string;
  onChange: (file: File | string) => void;
};

export const UploadFile: React.FC<PropsType> = ({
  style,
  className,
  id,
  title,
  required,
  error,
  value,
  accept,
  isDelete,
  isImg,
  hint,
  onChange,
}) => {
  const [preview, setPreview] = React.useState<string>('');
  const [isFocus, setIsFocus] = React.useState(false);
  const { ref } = useOutsideClick(() => setIsFocus(false));
  const classes = Styles();

  const fileName = typeof value === 'string' ? replacePath(value) : value ? value.name : '';

  React.useEffect(() => {
    if (value && typeof value === 'string') {
      setPreview(`${URI.trim()}/${value}`);
    }
  }, [value]);

  const setClass = () => {
    if (isFocus) return classes.placeholderFocus;
    if (!!value) return classes.placeholderInit;
  };

  const handleLeaveFocus = () => {
    setIsFocus(!isFocus);
  };

  const findTypeFile = (name: string) => {
    const types = ['docx', 'doc', 'xlsx', 'xls', 'pdf'];

    return types.find((tp) => tp.includes(name.substring(name.lastIndexOf('.') + 1)));
  };

  const renderPreviewImg = (file: File, callBack: (value: string) => void) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        callBack(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const renderPreviewPdf = (file: File, callBack: (value: string) => void) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result !== null) {
        const url = window.URL.createObjectURL(new Blob([reader.result], { type: 'application/pdf' }));
        callBack(url);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleChange = (file: File) => {
    if (file.type.includes('image') && isImg) {
      onChange(file);
      renderPreviewImg(file, setPreview);
    }
    if (!isImg) {
      const type = findTypeFile(file.name);
      if (type === 'pdf') {
        onChange(file);
        renderPreviewPdf(file, setPreview);
      } else if (type && file) {
        const fd = new FormData();
        fd.append('file', file);
        callApi({
          method: 'post',
          data: fd,
          isFormData: true,
          path: '/upload/preview',
        })
          .then((path) => {
            onChange(file);
            setPreview(`${URI.trim()}${path}`);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleDelete = () => {
    onChange('');
    setPreview('');
  };

  const openPreview = () => {
    if (isImg) {
      const iframe = "<iframe width='100%' height='100%' src='" + preview + "'></iframe>";
      const x = window.open();
      x?.document.write(iframe);
      x?.document.close();
    } else {
      const type = findTypeFile(fileName);
      if (type === 'pdf') window.open(preview);
      else window.open(`https://view.officeapps.live.com/op/embed.aspx?src=${preview}`);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={style} className={clsx(classes.root, className)} ref={ref}>
        {title && (
          <div className={clsx(classes.placeholder, setClass())} style={{ color: error && !isFocus ? 'red' : '' }}>
            {required ? `${title} *` : title}
          </div>
        )}
        <label htmlFor={id}>
          <Input
            accept={accept}
            style={{ display: 'none' }}
            id={id}
            type='file'
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                handleChange(e.target.files[0]);
              }
            }}
          />
          <div
            className={clsx(classes.wrappField, isFocus && classes.wrappFieldFocus)}
            style={{ borderColor: error && !isFocus ? 'red' : '', justifyContent: 'space-between' }}
            onClick={() => setIsFocus(true)}
          >
            <div className={classes.field}>{fileName}</div>
            <div className={clsx(classes.icon)} onClick={handleLeaveFocus}>
              <IconButton
                color='default'
                style={{ color: isFocus ? '#3f51b5' : error ? 'red' : '' }}
                aria-label='upload picture'
                component='span'
              >
                <UploadFileIcon />
              </IconButton>
            </div>
            {isDelete ? (
              <div className={clsx(classes.icon)} onClick={handleLeaveFocus}>
                <IconButton
                  aria-label='delete'
                  style={{ color: isFocus ? '#3f51b5' : error ? 'red' : '' }}
                  onClick={handleDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ) : null}
          </div>
        </label>
      </div>
      {preview || hint ? (
        <div className={classes.wrapHint}>
          {preview ? (
            <div className={classes.wrapLink} onClick={openPreview}>
              {fileName}
            </div>
          ) : null}
          {hint ? <div style={{ marginLeft: '10px' }}>{hint}</div> : null}
        </div>
      ) : null}
    </div>
  );
};
