import axios from 'axios';
import { API_URL } from '../config';
import { checkToken, ReturnType } from './checkToken';

export async function callApi(payload: {
  method: 'get' | 'post' | 'put' | 'delete' | 'patch';
  path: string;
  data?: Object | null;
  refreshToken?: string;
  isFormData?: boolean;
}) {
  const { method, path, data, refreshToken, isFormData } = payload;

  const mustCheckToken = path !== '/login' && !refreshToken;
  let userToken: ReturnType = {
    success: true,
    token: '',
  };

  if (mustCheckToken) {
    userToken = await checkToken();
  }

  try {
    const res = await axios.request({
      method,
      headers: {
        'Content-Type': isFormData ? 'multipart/form-data ' : 'application/json',
        Authorization: userToken.token,
      },
      url: API_URL + path,
      data: data && data,
    });
    return res.data;
  } catch (e: any) {
    console.log(e.response, 'Error Api Call');
    console.log('Error', e);
    let error = e;
    if (e.response && e.response.data && e.response.data.message) {
      error = e.response.data.message;
      if (e.response.status === 409 || e.response.status === 410) {
        error = {
          message: e.response.data.message,
          status: e.response.status,
        };
      }
    }
    throw error;
  }
}
