import { Editor } from '@tinymce/tinymce-react';

const coefficient = 0.743;

const getNumberFromValue = (value: string) => {
  return Number(value.replace(/[^0-9.]/g, ''));
};

const calculateHeight = (value: number) => {
  return Math.round(coefficient * value);
};

const calculateWidth = (value: number) => {
  return Math.round(value / coefficient);
};

export const editIframeWithOptions = (editor: Editor['editor']) => () => {
  if (!editor) return null;

  const initialData = {
    width: '768px',
    height: '500px',
    enablescrollbar: false,
    showborder: false,
    source: '',
    includeproportion: false,
  };

  const node = editor.selection.getNode();
  const iframe = node?.querySelector('iframe');
  if (iframe) {
    Object.assign(initialData, {
      source: iframe.src,
      width: iframe.width,
      height: iframe.height,
      showborder: iframe.getAttribute('frameborder') === '1' ? true : false,
      enablescrollbar: iframe.getAttribute('scrolling') === 'yes' ? true : false,
      includeproportion: false,
    });
  }

  editor.windowManager.open({
    title: 'Вставити/редагувати iframe',
    initialData,
    onSubmit: function (api: any) {
      const data = api.getData();
      if (!data.source) return api.close();

      const formatedHeight = getNumberFromValue(data.height);
      const heightValue = data.includeproportion ? Math.min(formatedHeight, 817) : formatedHeight;
      const height = heightValue + 'px';

      const width = Math.min(1100, getNumberFromValue(data.width)) + 'px';

      const container = document.createElement('div');
      container.classList.add('tiny-pageembed');
      container.setAttribute('contenteditable', 'false');

      const iframe = document.createElement('iframe');
      iframe.setAttribute('frameborder', data.showborder ? '1' : '0');
      iframe.setAttribute('scrolling', data.enablescrollbar ? 'yes' : 'no');
      iframe.setAttribute('src', data.source);
      iframe.setAttribute('width', width);
      iframe.setAttribute('height', height);

      const span = document.createElement('span');
      span.classList.add('mce-shim');
      span.setAttribute('data-mce-bogus', '1');

      container.append(iframe, span);

      editor.selection.setContent(container.outerHTML, { format: 'raw' });
      api.close();
    },
    onChange: (api, targetData) => {
      const data = api.getData();

      const width = calculateWidth(getNumberFromValue(data.height)) + 'px';
      const height = calculateHeight(getNumberFromValue(data.width)) + 'px';

      if (['includeproportion', 'width'].includes(targetData.name) && data.includeproportion) {
        api.setData({ height });
      }

      if (targetData.name === 'height' && data.includeproportion) {
        api.setData({ width });
      }
    },
    body: {
      type: 'panel',
      items: [
        {
          type: 'input',
          name: 'source',
          label: 'Джерело',
        },
        {
          type: 'input',
          name: 'height',
          label: 'Висота',
        },
        {
          type: 'input',
          name: 'width',
          label: 'Ширина',
        },
        {
          type: 'checkbox',
          name: 'includeproportion',
          label: 'Врахувати пропорції',
        },
        {
          type: 'checkbox',
          name: 'showborder',
          label: 'Показувати межі iframe',
        },
        {
          type: 'checkbox',
          name: 'enablescrollbar',
          label: 'Активувати скролер',
        },
      ],
    },
    buttons: [
      {
        type: 'cancel',
        text: 'Закрити',
      },
      {
        type: 'submit',
        text: 'Зберегти',
        primary: true,
      },
    ],
  });
};
