import { Editor } from '@tinymce/tinymce-react';

export const copyLiveSample = (editor: Editor['editor']) => () => {
  if (!editor) return null;

  const node = editor.selection.getNode();
  if (!node) return null;

  const type = 'text/html';
  const blob = new Blob([node.outerHTML], { type });
  const data = [new ClipboardItem({ [type]: blob })];

  navigator.clipboard.write(data);

  document.querySelector('.tox-pop')?.remove();
};
