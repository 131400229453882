import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { GetUser, TypeUpdateUserR, UpdateUser, UpdatePassword } from '../../store/users/actions';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ConfigurationsActions } from '../../store/configurations/actions';
import { UserForm } from '../../components/UserForm';
import { Roles } from '../../utils/consts';

export const EditUser: React.FC = () => {
  const { Users, User, Configurations } = useSelector((store: AppStore) => store);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();

  React.useEffect(() => {
    if (id) {
      dispatch(GetUser.request({ id: Number(id) }));
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    const query = new URLSearchParams(search);
    const siteId = query.get('site_id');
    if (siteId) {
      dispatch(ConfigurationsActions.setSiteId(Number(siteId)));
    }
  }, [dispatch, search]);

  const handleUpdate = (data: TypeUpdateUserR['data']) => {
    if (!Users.current) return null;
    dispatch(
      UpdateUser.request({
        data,
        id: Users.current.id,
      })
    );
  };

  const handleChangePassw = (value: string, callBack: (value: boolean) => void) => {
    if (!Users.current) return null;
    dispatch(
      UpdatePassword.request({
        id: Users.current.id,
        data: {
          password: value,
        },
        callBack,
      })
    );
  };

  if (User.data?.role !== Roles.ADMIN) return null;

  if (!Users.current)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );

  return (
    <UserForm
      loading={Users.loading}
      error={Users.errors}
      systems={Configurations.sites}
      data={Users.current}
      type='edit'
      handleSubmit={handleUpdate}
      handleCancel={() => navigate(ROUTES.routeChange('list'))}
      handleChangePassw={handleChangePassw}
    />
  );
};
