export function replaceQuotes(html: string) {
  let modifiedHtml = '';

  for (let i = 0; i < html.length; i++) {
    const currentChar = html[i];
    const prevChar = modifiedHtml[i - 1];
    const next = html[i + 1];

    if (currentChar === '"') {
      if (
        (prevChar === ' ' ||
          prevChar === '\n' ||
          !prevChar ||
          prevChar === '(' ||
          prevChar === '>' ||
          prevChar === ';' ||
          prevChar === '.' ||
          prevChar === ',') &&
        next !== ' ' &&
        next !== '<' &&
        next !== '»' &&
        next !== '\n' &&
        next !== '.'
      ) {
        modifiedHtml += '«';
        continue;
      } else {
        modifiedHtml += '»';
        continue;
      }
    }

    if (currentChar === '“') {
      modifiedHtml += '«';
      continue;
    }
    if (currentChar === '”') {
      modifiedHtml += '»';
      continue;
    }

    modifiedHtml += currentChar;
  }
  return modifiedHtml;
}
