import { Editor } from '@tinymce/tinymce-react';
import { findNearestAncestorWithClassName } from './helpers/findNearestAncestorWithClassName';

export const copyTemplate = (editor: Editor['editor']) => () => {
  if (!editor) return null;

  const node = editor.selection.getNode();
  if (!node) return null;

  const parent = findNearestAncestorWithClassName(node, ['toggle', 'templates-2__global-wrapper']);
  if (!parent) return null;

  const div = document.createElement('div');
  div.innerHTML = ` ${parent.outerHTML}<p>&nbsp;</p>`;

  const body = editor.getBody();
  body.append(div);

  editor.selection.select(div);
  editor.execCommand('copy');

  body.removeChild(div);

  document.querySelector('.tox-pop')?.remove();
};
