import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { IFactoids } from '../../store/factoids/types';
import { UpdateFactoids, CreateFactoids } from '../../store/factoids/actions';
import { EditorFeatured } from '../Editors';
import { StylesEdit } from '../StylesEdit';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CATEGORIES } from '../../utils/consts';
import { AlertMessage } from '../AlertMessage';
import { hideAnchor } from '../Editors/commands';

interface PropsType {
  type: 'edit' | 'new';
  data?: IFactoids | null;
  handleCancel: () => void;
}

export const EditFactoids: React.FC<PropsType> = ({ type, data, handleCancel }) => {
  const [tags, setTags] = React.useState<string>(data?.tags || '');
  const [title, setTitle] = React.useState<string>(data?.title || '');
  const [approved, setApproved] = React.useState(data?.approved ? 1 : 0);
  const [error, setError] = React.useState<Record<string, string>>({});

  const editorRef = React.useRef<any | null>(null);

  const { Factoids, Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classes = StylesEdit();

  const onSubmit = () => {
    if (!title) return setError({ title: 'Заголовок має бути заповненим' });
    setError({});

    hideAnchor(editorRef.current);

    const body = editorRef.current?.getContent();

    const fd = new FormData();
    fd.append('site_id', String(Configurations.siteId));
    fd.append('title', title);
    fd.append('body', body);
    fd.append('tags', tags);
    fd.append('approved', String(approved));

    if (type === 'new') {
      dispatch(
        CreateFactoids.request({
          data: fd,
          callBack: (success, dta) => {
            if (!success && dta) {
              if (dta.status === 409) setError({ title: 'Такий заголовок вже використовується' });
              else setError({ 1: 'Сталася помилка' });
            }
          },
        })
      );
    }
    if (data && type === 'edit') {
      dispatch(
        UpdateFactoids.request({
          data: fd,
          id: data.id,
          callBack: (success, dta) => {
            if (!success && dta) {
              if (dta.status === 409) setError({ title: 'Такий заголовок вже використовується' });
              else setError({ 1: 'Сталася помилка' });
            }
          },
        })
      );
    }
  };

  return (
    <Box>
      <CssBaseline />
      <div className={classes.paper}>
        <Box className={classes.header}>
          <Typography component='h1' variant='h5'></Typography>
          {data && (
            <Box style={{ display: 'flex' }}>
              <Box className={classes.headerItem}>
                Дата створення {moment(data?.created_at).format('DD.MM.YYYY HH:mm')}
              </Box>
              <Box>
                Дата редагування {data?.updated_at ? moment(data?.updated_at).format('DD.MM.YYYY HH:mm') : 'Відсутня'}
              </Box>
            </Box>
          )}
        </Box>
        <FormControlLabel
          style={{ marginTop: '7px' }}
          control={
            <Checkbox
              checked={!!approved}
              onChange={(e) => {
                setApproved(e.target.checked ? 1 : 0);
              }}
            />
          }
          label='Публікувати'
        />
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Заголовок'
          variant='outlined'
          value={title}
          error={!!error['title']}
          className={`${classes.textField} my-2`}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setTitle(event.target.value as string);
          }}
        />
        <TextField
          style={{ marginTop: '7px' }}
          size='small'
          id='outlined-basic'
          label='Теги'
          variant='outlined'
          value={tags}
          className={`${classes.textField} my-2`}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setTags(event.target.value as string);
          }}
        />
        <div style={{ marginTop: '20px' }}>
          <EditorFeatured
            categoryId={CATEGORIES.FACTOIDS}
            editorRef={editorRef}
            initialValue={data?.body}
            siteId={Configurations.siteId}
            docId={data?.id}
          />
        </div>
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={Factoids.loading} onClick={onSubmit}>
            {Factoids.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {Object.keys(error).length ? <AlertMessage type='error' message={Object.entries(error)[0][1]} /> : null}
      </div>
    </Box>
  );
};
