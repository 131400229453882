import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TUsersState } from './types';

const GetUsersAction = new Actions('GET_USERS', ActionTypes);
export interface TypeGetUsersR extends TDefRequest {
  data: {
    fio?: string;
    role?: string;
    site_id?: number;
  };
}
export const GetUsers = {
  request: (payload: TypeGetUsersR) => GetUsersAction.request(payload),
  success: (payload: TUsersState['data']) => GetUsersAction.success(payload || {}),
  error: (message: string) => GetUsersAction.error(message),
};

const GetUserAction = new Actions('GET_USER', ActionTypes);
export interface TypeGetUserR extends TDefRequest {
  id: number;
}
export const GetUser = {
  request: (payload: TypeGetUserR) => GetUserAction.request(payload),
  success: (payload: TUsersState['current']) => GetUserAction.success(payload || {}),
  error: (message: string) => GetUserAction.error(message),
};

const CreateUserAction = new Actions('CREATE_USER', ActionTypes);
export interface TypeCreateUserR extends TDefRequest {
  data: {
    username: string;
    password: string;
    fio: string;
    role: number;
    rights: { site_id: number }[];
    is_service: number;
    is_raiting: number;
  };
}
export const CreateUser = {
  request: (payload: TypeCreateUserR) => CreateUserAction.request(payload),
  success: (payload: TUsersState['current']) => CreateUserAction.success(payload || {}),
  error: (message: string) => CreateUserAction.error(message),
};

const UpdateUserAction = new Actions('UPDATE_USER', ActionTypes);
export interface TypeUpdateUserR extends TDefRequest {
  data: {
    fio: string;
    role: number;
    rights: { site_id: number }[];
    is_service: number;
    is_raiting: number;
  };
  id: number;
}
export const UpdateUser = {
  request: (payload: TypeUpdateUserR) => UpdateUserAction.request(payload),
  success: (payload: TUsersState['current']) => UpdateUserAction.success(payload || {}),
  error: (message: string) => UpdateUserAction.error(message),
};

const DeleteUserAction = new Actions('DELETE_USER', ActionTypes);
export interface TypeDeleteUserR extends TDefRequest {
  id: number;
}
export const DeleteUser = {
  request: (payload: TypeDeleteUserR) => DeleteUserAction.request(payload),
  success: (payload: TUsersState['current']) => DeleteUserAction.success(payload || {}),
  error: (message: string) => DeleteUserAction.error(message),
};

const UpdatePasswordAction = new Actions('UPDATE_PASSWORD', ActionTypes);
export interface TypeUpdatePasswordR extends TDefRequest {
  data: {
    password: string;
  };
  id: number;
}
export const UpdatePassword = {
  request: (payload: TypeUpdatePasswordR) => UpdatePasswordAction.request(payload),
  success: (payload: TUsersState['current']) => UpdatePasswordAction.success(payload || {}),
  error: (message: string) => UpdatePasswordAction.error(message),
};
