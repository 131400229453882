import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import { GetUser, TypeGetUserR } from './actions';
import { ActionTypes, TUserState } from './types';

function* getUserWorker(action: ReturnType<typeof GetUser.request>): Generator {
  const { id, callBack } = action.payload as TypeGetUserR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/users/user/${id}`,
    })) as TUserState['data'];
    yield put(GetUser.success(resp));
  } catch (e) {
    success = false;
    yield put(GetUser.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_USER_R, getUserWorker);
}

export default function* userSaga() {
  yield all([fork(watchFetchRequest)]);
}
