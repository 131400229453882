import { action } from 'typesafe-actions';
import { DeepPartial } from '../../utils/DeepPartial';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { IMainInMonthYears, IMonthMainBlock, ITemplate, TMainMonthState } from './types';

export const MainInMonthActions = {
  setTemplate: (payload: TMainMonthState['template']) => action(ActionTypes.SET_TEMPLATE, payload),
};

const GetMainInMonthAction = new Actions('GET_MAIN_IN_MONTH', ActionTypes);
export interface TypeGetMainInMonthR extends TDefRequest {
  data: {
    site_id: number;
  };
}
export const GetMainInMonth = {
  request: (payload: TypeGetMainInMonthR) => GetMainInMonthAction.request(payload),
  success: (payload: IMainInMonthYears[]) => GetMainInMonthAction.success(payload),
  error: (payload: string) => GetMainInMonthAction.error(payload),
};

const GetOneMainInMonthAction = new Actions('GET_ONE_MAIN_IN_MONTH', ActionTypes);
export interface TypeGetOneMainInMonthR extends TDefRequest {
  id: number;
}
export const GetOneMainInMonth = {
  request: (payload: TypeGetOneMainInMonthR) => GetOneMainInMonthAction.request(payload),
  success: (payload: IMainInMonthYears) => GetOneMainInMonthAction.success(payload),
  error: (payload: string) => GetOneMainInMonthAction.error(payload),
};

const CreateMainInMonthAction = new Actions('CREATE_MAIN_IN_MONTH', ActionTypes);
export interface TypeCreateMainInMonth extends TDefRequest {
  data: {
    site_id: number;
    year: number;
    month: number;
    id?: number;
  };
  id?: number;
}
export const CreateMainInMonth = {
  request: (payload: TypeCreateMainInMonth) => CreateMainInMonthAction.request(payload),
  success: (payload: IMainInMonthYears) => CreateMainInMonthAction.success(payload),
  error: (error: string) => CreateMainInMonthAction.error(error),
};

const DeleteMainInMonthAction = new Actions('DELETE_MAIN_IN_MONTH', ActionTypes);
export interface TypeDeleteMainInMonth extends TDefRequest {
  id: number;
}
export const DeleteMainInMonth = {
  request: (payload: TypeDeleteMainInMonth) => DeleteMainInMonthAction.request(payload),
  success: (id: number) => DeleteMainInMonthAction.success(id),
  error: (message: string) => DeleteMainInMonthAction.error(message),
};

const UpdateMainInMonthAction = new Actions('UPDATE_MAIN_IN_MONTH', ActionTypes);
export interface TypeUpdateMainInMonth extends TDefRequest {
  data: {
    site_id: number;
    templates: any[];
    month: number;
    is_public: 1 | 0;
    is_open_publish: 1 | 0;
    year: number;
    publication_at?: Date;
  };
  id?: number;
}
export const UpdateMainInMonth = {
  request: (payload: TypeUpdateMainInMonth) => UpdateMainInMonthAction.request(payload),
  success: (payload: IMainInMonthYears) => UpdateMainInMonthAction.success(payload),
  error: (message: string) => UpdateMainInMonthAction.error(message),
};

const GetMainInMonthTemplateAction = new Actions('GET_TEMPLATE', ActionTypes);
export interface TypeGetMainInMonthTemplateR extends TDefRequest {
  id: number;
}
export const GetMainInMonthTemplate = {
  request: (payload: TypeGetMainInMonthTemplateR) => GetMainInMonthTemplateAction.request(payload),
  success: (payload: TMainMonthState['template']) => GetMainInMonthTemplateAction.success(payload || {}),
  error: (message: string) => GetMainInMonthTemplateAction.error(message),
};

const CreateMainInMonthTemplateAction = new Actions('CREATE_TEMPLATE', ActionTypes);
export interface TypeCreateMainInMonthTemplateR extends TDefRequest {
  data: DeepPartial<ITemplate>;
}
export const CreateMainInMonthTemplate = {
  request: (payload: TypeCreateMainInMonthTemplateR) => CreateMainInMonthTemplateAction.request(payload),
  success: (payload: TMainMonthState['template']) => CreateMainInMonthTemplateAction.success(payload || {}),
  error: (message: string) => CreateMainInMonthTemplateAction.error(message),
};

const UpdateMainInMonthTemplateAction = new Actions('UPDATE_TEMPLATE', ActionTypes);
export interface TypeUpdateMainInMonthTemplateR extends TDefRequest {
  id: number;
  data: {
    template_detailes: IMonthMainBlock[];
  };
}
export const UpdateMainInMonthTemplate = {
  request: (payload: TypeUpdateMainInMonthTemplateR) => UpdateMainInMonthTemplateAction.request(payload),
  success: (payload: TMainMonthState['template']) => UpdateMainInMonthTemplateAction.success(payload || {}),
  error: (message: string) => UpdateMainInMonthTemplateAction.error(message),
};

const DeleteMainInMonthTemplateAction = new Actions('DELETE_TEMPLATE', ActionTypes);
export interface TypeDeleteMainInMonthTemplateR extends TDefRequest {
  id: number;
}
export const DeleteMainInMonthTemplate = {
  request: (payload: TypeDeleteMainInMonthTemplateR) => DeleteMainInMonthTemplateAction.request(payload),
  success: (payload: TMainMonthState['template']) => DeleteMainInMonthTemplateAction.success(payload || {}),
  error: (message: string) => DeleteMainInMonthTemplateAction.error(message),
};

const GetMainInMonthBlockAction = new Actions('GET_BLOCK', ActionTypes);
export interface TypeGetMainInMonthBlockR extends TDefRequest {
  id: number;
}
export const GetMainInMonthBlock = {
  request: (payload: TypeGetMainInMonthBlockR) => GetMainInMonthBlockAction.request(payload),
  success: (payload: TMainMonthState['block']) => GetMainInMonthBlockAction.success(payload || {}),
  error: (message: string) => GetMainInMonthBlockAction.error(message),
};

const CreateMainInMonthBlockAction = new Actions('CREATE_BLOCK', ActionTypes);
export interface TypeCreateMainInMonthBlockR extends TDefRequest {
  data: DeepPartial<IMonthMainBlock>;
}
export const CreateMainInMonthBlock = {
  request: (payload: TypeCreateMainInMonthBlockR) => CreateMainInMonthBlockAction.request(payload),
  success: (payload: TMainMonthState['block']) => CreateMainInMonthBlockAction.success(payload || {}),
  error: (message: string) => CreateMainInMonthBlockAction.error(message),
};

const UpdateMainInMonthBlockAction = new Actions('UPDATE_BLOCK', ActionTypes);
export interface TypeUpdateMainInMonthBlockR extends TDefRequest {
  id: number;
  data: DeepPartial<IMonthMainBlock>;
}
export const UpdateMainInMonthBlock = {
  request: (payload: TypeUpdateMainInMonthBlockR) => UpdateMainInMonthBlockAction.request(payload),
  success: (payload: TMainMonthState['block']) => UpdateMainInMonthBlockAction.success(payload || {}),
  error: (message: string) => UpdateMainInMonthBlockAction.error(message),
};

const DeleteMainInMonthBlockAction = new Actions('DELETE_BLOCK', ActionTypes);
export interface TypeDeleteMainInMonthBlockR extends TDefRequest {
  id: number;
}
export const DeleteMainInMonthBlock = {
  request: (payload: TypeDeleteMainInMonthBlockR) => DeleteMainInMonthBlockAction.request(payload),
  success: (payload: TMainMonthState['block']) => DeleteMainInMonthBlockAction.success(payload || {}),
  error: (message: string) => DeleteMainInMonthBlockAction.error(message),
};
