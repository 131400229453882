import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IRubric } from '../../store/rubrics/types';
import { AppStore } from '../../store/applicationState';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import SourceTwoToneIcon from '@mui/icons-material/SourceTwoTone';
import { format } from 'date-fns';
import { DeleteEvent, GetAmountEventsInMonth, UpdateEventPositions } from '../../store/calendar/actions';
import { ROUTES } from '../../utils/routes';
import { IEvent } from '../../store/calendar/types';
import { WarningModal } from '../modals';

const useStyles: any = makeStyles(() => ({
  root: {
    display: 'flex',
    marginTop: '5px',
  },
  wrappBody: {
    display: 'flex',
    borderRadius: ' 4px',
    border: 'solid 1px #3f51b5',
    marginTop: '5px',
  },
  expend: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    marginTop: '5px',
    cursor: 'pointer',
    minWidth: '30px',
  },
  item: {
    minWidth: '37px',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    cursor: 'pointer',
    fontSize: '16px',
    color: '#3f51b5',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    minWidth: '520px',
    maxWidth: '520px',
    overflowWrap: 'anywhere',
  },
  children: {
    paddingLeft: '20px',
  },
}));

const findChildren = (cards: any[] | null, ids: number[]): any[] | null => {
  if (!cards) return cards;
  const card = cards[ids[0]];
  if (card && card.children) {
    ids.splice(0, 1);
    return findChildren(card.children, ids);
  }
  return cards;
};

const setChildren = (cards: IRubric[] | null, ids: number[], newChildren: IRubric[] | null): IRubric[] | null => {
  if (!cards) return cards;
  let prev = null;
  for (const id of ids) {
    if (prev && prev[id]) {
      prev = prev[id].children;
    }
    if (!prev && cards[id]) {
      prev = cards[id].children;
    }
  }
  prev = newChildren;
  return cards;
};

type PropsType = {
  children: any[] | null;
  cards: any[] | null;
  chain?: string;
  level?: number;
  categoryId: number;
  setCards: (cards: IRubric[] | null) => void;
  setHaveSimillarEvents?: (data: boolean) => void;
  setAmountMonthEvents?: (value: number) => void;
};

function formatDateString(day: number, month: number, year: number) {
  const paddedDay = day.toString().padStart(2, '0');
  const paddedMonth = month.toString().padStart(2, '0');

  const formattedDate = `${paddedDay}.${paddedMonth}.${year}`;
  return formattedDate;
}

export const CalendarTree: React.FC<PropsType> = ({
  children,
  cards,
  categoryId,
  chain,
  level = 0,
  setCards,
  setHaveSimillarEvents,
  setAmountMonthEvents,
}) => {
  const [draggId, setDraggId] = React.useState('');
  const [overId, setOverId] = React.useState('');
  const [expend, setExpand] = React.useState<string[]>(
    children?.filter((el) => el.site_id).map((el, i) => i + '') || []
  );
  const [hoverId, setHoverId] = React.useState('');
  const [isModal, setIsModal] = React.useState<any>(false);

  useEffect(() => {
    if (children) setExpand(children?.filter((el) => el.site_id).map((el, i) => i + ''));
  }, [children]);

  const [isCopied, setIsCopied] = useState({ id: 0 });

  const { Configurations } = useSelector((store: AppStore) => store);

  const dispatch = useDispatch();

  const classes = useStyles();

  const moveCard = (dragIndex: number, hoverIndex: number, chain: string) => {
    if (!cards) return null;
    const ids = chain.split('-').map((str) => Number(str));
    ids.pop();
    const currentCards = findChildren(cards, ids);
    if (currentCards && currentCards[0].site_id) return;
    if (currentCards && currentCards[dragIndex]) {
      const card = currentCards[dragIndex];
      currentCards.splice(dragIndex, 1);
      currentCards.splice(hoverIndex, 0, card);

      // Якщо буде потрібно рухати дати
      if (currentCards[0].site_id) {
        // dispatch(UpdateCalendarPositions.request({ data: currentCards.map((it, i) => ({ id: it.id, position: i })) }));
      } else {
        dispatch(UpdateEventPositions.request({ data: currentCards.map((it, i) => ({ id: it.id, position: i })) }));
      }

      const ids = chain.split('-').map((str) => Number(str));
      const newCards = setChildren([...cards], ids, currentCards);
      setCards(newCards);
    }
  };

  const onDeleteCard = (event: any) => {
    let ids: number[] = event.children ? event.children.map((it: any) => it.id) : [event.id];
    const calendar_id = event.children ? event.id : event.calendar.id;

    dispatch(
      DeleteEvent.request({
        id: calendar_id,
        data: {
          calendar_id,
          site_id: Configurations.siteId,
          ids,
        },
      })
    );
    const year = event.children?.year;
    const month = event.children?.month;
    if (!year || !month) return;
    dispatch(
      GetAmountEventsInMonth.request({
        data: {
          year,
          month,
          site_id: Configurations.siteId,
        },
        callBack: (success, data) => {
          if (success) {
            typeof setAmountMonthEvents === 'function' && setAmountMonthEvents(data);
          }
        },
      })
    );
  };

  const handleChangeRoute =
    (path = '' as string | number) =>
    () => {
      window.open(ROUTES.routeChange(`edit/${path}?site_id=${Configurations.siteId}`));
    };

  const handleDraggEnd = (chainId: string) => {
    if (draggId !== overId) {
      const drId = draggId.split('-').pop();
      const chId = overId.split('-').pop();
      moveCard(Number(drId), Number(chId), chainId);
    }
    setDraggId('');
    setHoverId('');
  };

  const copyEvent = (event: IEvent) => {
    setIsCopied({ id: event.id });
    setTimeout(() => setIsCopied({ id: 0 }), 200);
    window.open(ROUTES.routeChange(`coppy/${event.id}?site_id=${Configurations.siteId}`));
  };

  return (
    <>
      {children
        ?.sort((a, b) => a.position - b.position)
        ?.map((event, index) => {
          const chainId = chain ? `${chain}-${index}` : String(index);
          return (
            <div
              key={chainId}
              onDragOver={() => {
                chainId.length !== expend.length && setHoverId(chainId);
              }}
              onDragLeave={() => setOverId(chainId)}
              onDrag={() => !draggId && setDraggId(chainId)}
              onDragEnd={() => handleDraggEnd(chainId)}
              draggable={event.site_id ? false : true}
            >
              <Box display={'flex'} mt={'5px'}>
                <div
                  className={classes.expend}
                  onClick={() =>
                    setExpand(
                      expend.includes(chainId) ? expend.filter((el, i) => el !== chainId) : [...expend, chainId]
                    )
                  }
                >
                  {event.children && event.children.length > 0 ? (
                    expend.includes(chainId) ? (
                      <RemoveIcon style={{ fontSize: '1rem' }} />
                    ) : (
                      <AddIcon style={{ fontSize: '1rem' }} />
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div
                  className={classes.wrappBody}
                  style={{ background: chainId === hoverId && !expend.includes(hoverId) ? '#554e4e70' : '' }}
                >
                  <div className={classes.item} style={{ color: '#3f51b5' }}>
                    {event.children && event.children.length > 0 ? (
                      <AccessTimeTwoToneIcon fontSize='small' />
                    ) : (
                      <SourceTwoToneIcon fontSize='small' />
                    )}
                  </div>
                  <div className={classes.title} style={{ cursor: event.site_id ? 'pointer' : 'move' }}>
                    {!event.site_id ? event.title : format(new Date(event.title), 'dd.MM.yyyy')}
                  </div>

                  {level > 0 && (
                    <IconButton className={classes.item} onClick={(e) => copyEvent(event)}>
                      <ContentCopyIcon
                        htmlColor={isCopied.id === event.id ? '#3F51B5' : 'black'}
                        style={{ fontSize: '1.1rem' }}
                      />
                    </IconButton>
                  )}

                  <IconButton
                    onClick={handleChangeRoute(event.id)}
                    style={{ display: !event.site_id ? 'flex' : 'none' }}
                    className={classes.item}
                  >
                    <EditIcon style={{ fontSize: '1.1rem' }} />
                  </IconButton>

                  <IconButton
                    className={classes.item}
                    onClick={() => (event.children ? setIsModal(event) : onDeleteCard(event))}
                  >
                    <DeleteIcon color={'error'} style={{ fontSize: '1.1rem' }} />
                  </IconButton>
                </div>
              </Box>

              {expend.includes(chainId) && (
                <div className={classes.children}>
                  <CalendarTree
                    setHaveSimillarEvents={setHaveSimillarEvents}
                    children={event.children}
                    cards={cards}
                    categoryId={categoryId}
                    chain={chainId}
                    level={level + 1}
                    setCards={setCards}
                  />
                </div>
              )}
            </div>
          );
        })}
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && onDeleteCard(isModal);
            setIsModal(null);
          }}
          value={'події ' + formatDateString(isModal.day, isModal.month, isModal.year)}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
