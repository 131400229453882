const setEmptyParagraph = (element: any) => {
  if (element.children) {
    const children = element.children.map(setEmptyParagraph);
    element = { ...element, children: children };
  }

  if (element.children?.length === 0) {
    const children = [
      {
        type: 'text',
        value: ' ',
      },
    ];
    element = { ...element, children: children };
  }

  return element;
};

const transformParagraph = (element: any) => {
  element = setEmptyParagraph(element);
  if (element.alignment === 'center') {
    return { ...element, styleId: 'Heading2' };
  } else if (
    (element.alignment !== 'center' && element.children[0]?.isBold) ||
    (element.alignment !== 'center' && element.children[1]?.isBold)
  ) {
    return { ...element, styleId: 'Heading3' };
  } else if (element.alignment === 'right') {
    return { ...element, styleId: 'ParagraphRight', styleName: 'ParagraphRight' };
  } else {
    return element;
  }
};

const transformTable = (element: any) => {
  if (element.children) {
    const children = element.children.map(transformTable);
    element = { ...element, children: children };
  }

  if (element.type === 'tableRow') {
    element = { ...element, isHeader: false };
  }

  if (element.type === 'paragraph') {
    element = setEmptyParagraph(element);
    if (element.alignment) {
      element = {
        ...element,
        styleName: `Paragraph${element.alignment[0].toUpperCase() + element.alignment.slice(1)}`,
      };
    }
  }

  return element;
};

const transformElement = (element: any) => {
  if (['table', 'tableRow', 'tableCell'].includes(element.type)) {
    element = transformTable(element);
  }
  if (element.type === 'paragraph') {
    element = transformParagraph(element);
  }

  return element;
};

export { transformElement };
