import { Editor } from '@tinymce/tinymce-react';

const isOnlySpaces = (str = '') => {
  return str && str.trim() === '';
};

const replaceDash = ({ value = '', to = '—', prevSymbol = '', nextSymbol = '' }) => {
  let respValue = value;
  if (!value) return respValue;

  return respValue
    .split('')
    .map((value, i) => {
      const prev = i === 0 ? prevSymbol : respValue[i - 1];
      const next = i === respValue.length - 1 ? nextSymbol : respValue[i + 1];

      if (['-', '‑'].includes(value) && isOnlySpaces(prev) && isOnlySpaces(next)) {
        return to;
      }

      return value;
    })
    .join('');
};

const recursiveReplaceDash = (element: Element | ChildNode, prevSymbol = '', nextSymbol = '') => {
  let lastSymbol = prevSymbol;

  if (!element) return null;

  for (let i = 0; i < element.childNodes.length; i++) {
    const node = element.childNodes[i];
    const nextNode = element.childNodes[i + 1];
    let firstSymbolNextNode = nextNode ? (nextNode.textContent ? nextNode.textContent[0] : nextSymbol) : nextSymbol;

    if (node.nodeName === '#text' && node.textContent) {
      node.textContent = replaceDash({
        value: node.textContent,
        prevSymbol: lastSymbol,
        nextSymbol: firstSymbolNextNode,
      });

      lastSymbol = node.textContent[node.textContent.length - 1] || '';
    } else {
      recursiveReplaceDash(node, lastSymbol, firstSymbolNextNode);
    }
  }
};

export const changeDash = (editor?: Editor['editor'] | null) => {
  if (!editor) return;

  const selectedBlocks = editor.selection.getSelectedBlocks();
  selectedBlocks.forEach((value) => recursiveReplaceDash(value));

  const newContent = editor.getContent();

  return editor.setContent(newContent.replaceAll('&mdash;', '<mark>&mdash;</mark>'));
};
