import { IAuthor } from '../authors/types';
import { ITypeDoc } from '../officialDoc/types';
import { IOfficialDocFile } from '../officialDocFiles/types';
import { IPublisherDoc } from '../publishers/types';

export interface TOfficialDocDraftsState {
  data: IOfficialDocDraft[] | null;
  current: IOfficialDocDraft | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IOfficialDocDraft {
  id: number;
  author_id: number;
  creator_id: number;
  type_id: number;
  doc_num: string;
  doc_date: Date;
  title: string;
  body: string;
  start_date: Date;
  end_date: Date;
  regist_date: Date;
  regist_num: string;
  search_tags: string;
  status: number;
  created_at: Date;
  updated_at?: Date;
  deleted_at?: Date;
  type: ITypeDoc;
  publishers: IPublisherDoc[];
  attachments: IOfficialDocFile[];
  author: IAuthor;
  creator: IAuthor;
}

enum ActionTypes {
  SET_ERROR = '@@officialDocDrafts/SET_ERROR',

  GET_OFFICIAL_DOC_DRAFTS_R = '@@officialDocDrafts/GET_OFFICIAL_DOC_DRAFTS_R',
  GET_OFFICIAL_DOC_DRAFTS_S = '@@officialDocDrafts/GET_OFFICIAL_DOC_DRAFTS_S',
  GET_OFFICIAL_DOC_DRAFTS_E = '@@officialDocDrafts/GET_OFFICIAL_DOC_DRAFTS_E',

  GET_OFFICIAL_DOC_DRAFT_R = '@@officialDocDrafts/GET_OFFICIAL_DOC_DRAFT_R',
  GET_OFFICIAL_DOC_DRAFT_S = '@@officialDocDrafts/GET_OFFICIAL_DOC_DRAFT_S',
  GET_OFFICIAL_DOC_DRAFT_E = '@@officialDocDrafts/GET_OFFICIAL_DOC_DRAFT_E',

  CREATE_OFFICIAL_DOC_DRAFTS_R = '@@officialDocDrafts/CREATE_OFFICIAL_DOC_DRAFTS_R',
  CREATE_OFFICIAL_DOC_DRAFTS_S = '@@officialDocDrafts/CREATE_OFFICIAL_DOC_DRAFTS_S',
  CREATE_OFFICIAL_DOC_DRAFTS_E = '@@officialDocDrafts/CREATE_OFFICIAL_DOC_DRAFTS_E',

  UPDATE_OFFICIAL_DOC_DRAFTS_R = '@@officialDocDrafts/UPDATE_OFFICIAL_DOC_DRAFTS_R',
  UPDATE_OFFICIAL_DOC_DRAFTS_S = '@@officialDocDrafts/UPDATE_OFFICIAL_DOC_DRAFTS_S',
  UPDATE_OFFICIAL_DOC_DRAFTS_E = '@@officialDocDrafts/UPDATE_OFFICIAL_DOC_DRAFTS_E',

  DELETE_OFFICIAL_DOC_DRAFTS_R = '@@officialDocDrafts/DELETE_OFFICIAL_DOC_DRAFTS_R',
  DELETE_OFFICIAL_DOC_DRAFTS_S = '@@officialDocDrafts/DELETE_OFFICIAL_DOC_DRAFTS_S',
  DELETE_OFFICIAL_DOC_DRAFTS_E = '@@officialDocDrafts/DELETE_OFFICIAL_DOC_DRAFTS_E',

  CHANGE_OWNER_R = '@@officialDocDrafts/CHANGE_OWNER_R',
  CHANGE_OWNER_S = '@@officialDocDrafts/CHANGE_OWNER_S',
  CHANGE_OWNER_E = '@@officialDocDrafts/CHANGE_OWNER_E',

  UPDATE_REDACTION_R = '@@officialDocDrafts/UPDATE_REDACTION_R',
  UPDATE_REDACTION_S = '@@officialDocDrafts/UPDATE_REDACTION_S',
  UPDATE_REDACTION_E = '@@officialDocDrafts/UPDATE_REDACTION_E',
}

export default ActionTypes;
