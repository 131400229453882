import React from 'react';
import { MainMonthBlockForm } from '../../../../components/MainInMonth/MainMonthBlockForm';
import { IDataOpt } from '../../../../components/ui/SelectSearchReq';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../../store/applicationState';
import { CATEGORIES } from '../../../../utils/consts';
import { GetVideo } from '../../../../store/video/actions';

export default function Form({ type }: { type: 'new' | 'edit' }) {
  const { Configurations, Video } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      GetVideo.request({
        data: {
          siteId: Configurations.siteId,
        },
      })
    );
  }, [dispatch, Configurations.siteId]);

  const handleSearchPublications = (value: string | IDataOpt, callback?: ((data: IDataOpt[]) => void) | undefined) => {
    let id = 0;
    if (typeof value !== 'string') {
      id = value.id;
    } else if (!Number.isNaN(Number(value))) {
      id = Number(value);
    }

    dispatch(
      GetVideo.request({
        data: {
          siteId: Configurations.siteId,
          id,
        },
        callBack: (success, data) => {
          if (success && data?.video && callback) {
            callback(
              data.video
                .filter((item: { isPublic: any }) => !!item.isPublic)
                .map((item: { id: any }) => ({
                  id: item.id,
                  label: String(item.id),
                }))
            );
          }
        },
      })
    );
  };

  return (
    <MainMonthBlockForm
      type={type}
      name={`${type === 'new' ? 'Створити' : 'Редагувати'} блок шаблону Вебінари`}
      category={CATEGORIES.VIDEO}
      fields={{
        publication_id: { require: true },
        title: { require: true },
        author_id: { require: false },
        image: { require: false, label: 'Заставка' },
        renewal_at: { require: true },
      }}
      handleSearchPublications={handleSearchPublications}
      publications={Video.video
        ?.filter((item) => !!item.is_public)
        .map((item) => ({
          id: item.id,
          label: String(item.id),
          title: item.title,
          author: item.authors && item.authors[0],
        }))}
    />
  );
}
