import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  CreateDefinition,
  DeleteDefinition,
  GetDefinition,
  GetDefinitions,
  SearchDefinitions,
  TypeCreateDefinitionR,
  TypeDeleteDefinitionR,
  TypeGetDefinitionR,
  TypeGetDefinitionsR,
  TypeSearchDefinitionsR,
  TypeUpdateDefinitionR,
  UpdateDefinition,
} from './actions';
import ActionTypes, { IDefinition, TDefinitionsState } from './types';

function* getDefinitionsWorker(action: ReturnType<typeof GetDefinitions.request>): Generator {
  const { data, callBack } = action.payload as TypeGetDefinitionsR;

  let success = true;
  const query = buildQuery(data);
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/definitions?${query}`,
    })) as TDefinitionsState['data'];
    yield put(GetDefinitions.success(resp));
  } catch (e) {
    success = false;
    yield put(GetDefinitions.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* getDefinitionWorker(action: ReturnType<typeof GetDefinition.request>): Generator {
  const { id, callBack } = action.payload as TypeGetDefinitionR;
  let success = true;
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/definitions/one/${id}`,
    })) as IDefinition;
    yield put(GetDefinition.success(resp));
  } catch (e) {
    success = false;
    yield put(GetDefinition.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* searchDefinitionsWorker(action: ReturnType<typeof SearchDefinitions.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchDefinitionsR;

  let success = true;
  const query = buildQuery(data);
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/definitions?${query}`,
    })) as TDefinitionsState['data'];
    yield put(SearchDefinitions.success(resp || {}));
  } catch (e) {
    success = false;
    yield put(SearchDefinitions.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* createDefinitionWorker(action: ReturnType<typeof CreateDefinition.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateDefinitionR;

  let success = true;
  let resp = null;

  try {
    resp = (yield call(callApi, {
      method: 'post',
      data: data,
      path: '/definitions/',
    })) as IDefinition;
    yield put(CreateDefinition.success(resp));
  } catch (e: any) {
    success = false;
    resp = e;
    yield put(CreateDefinition.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updateDefinitionWorker(action: ReturnType<typeof UpdateDefinition.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateDefinitionR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data: data,
      path: `/definitions/${id}`,
    })) as IDefinition;
    yield put(UpdateDefinition.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(UpdateDefinition.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* deleteDefinitionWorker(action: ReturnType<typeof DeleteDefinition.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteDefinitionR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/definitions/${id}`,
    })) as number;
    yield put(DeleteDefinition.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteDefinition.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_DEFINITIONS_R, getDefinitionsWorker);
  yield takeEvery(ActionTypes.GET_DEFINITION_R, getDefinitionWorker);
  yield takeEvery(ActionTypes.SEARCH_DEFINITIONS_R, searchDefinitionsWorker);
  yield takeEvery(ActionTypes.CREATE_DEFINITION_R, createDefinitionWorker);
  yield takeEvery(ActionTypes.UPDATE_DEFINITION_R, updateDefinitionWorker);
  yield takeEvery(ActionTypes.DELETE_DEFINITION_R, deleteDefinitionWorker);
}

export default function* definitionsSaga() {
  yield all([fork(watchFetchRequest)]);
}
