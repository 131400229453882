import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { Alert, AlertTitle } from '@mui/lab';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { StylesEdit } from '../../StylesEdit';
import { IPublisherDoc } from '../../../store/publishers/types';
import { CreatePublisher, DeletePublisher, UpdatePublisher } from '../../../store/publishers/actions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { APPROVED } from '../../../utils/consts';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { WarningModal } from '../../modals';

interface PropsType {
  type: 'edit' | 'new';
  data?: IPublisherDoc;
  handleCancel: () => void;
}

export const EditPublisher: React.FC<PropsType> = React.memo(({ type, data, handleCancel }) => {
  const [name, setName] = React.useState(data?.name || '');
  const [shortName, setShortName] = React.useState(data?.short_name || '');
  const [shortNameGenitive, setShortNameGenitive] = React.useState(data?.short_name_genitive || '');
  const [synonyms, setSynonyms] = React.useState(data?.synonyms || '');
  const [status, setStatus] = React.useState(data?.status || 0);
  const [approved, setApproved] = React.useState(data ? data.approved : APPROVED.PUBLISH);

  const [errors, setErrors] = React.useState('');
  const [isModal, setIsModal] = React.useState<{ id: number; value: string } | null>(null);

  const { OfficialDoc } = useSelector((store: AppStore) => store);

  const dispatch = useDispatch();
  const classes = StylesEdit();

  const handleRemove = (id: number) => {
    dispatch(
      DeletePublisher.request({
        id,
        callBack: (success) => {
          if (!success) {
            setErrors("Видалення видавника неможливе. Є зв'язані документи.");

            return false;
          }

          return success && handleCancel();
        },
      })
    );
  };

  const onSubmit = () => {
    setErrors('');

    if (!name || !shortName || !shortNameGenitive) return setErrors('Заповніть поля');

    const reqData = {
      id: 0,
      data: {
        name,
        shortName,
        shortNameGenitive,
        synonyms,
        status,
        approved,
      },
      callBack: (success: boolean) => {
        if (!success) {
          setErrors('Сталася помилка');
        } else {
          handleCancel();
        }
      },
    };

    if (data && type === 'edit') reqData['id'] = data.id;

    if (type === 'new') dispatch(CreatePublisher.request(reqData));
    if (data && type === 'edit') dispatch(UpdatePublisher.request(reqData));
  };

  return (
    <Box>
      {errors ? (
        <Alert severity='error' style={{ width: '100%' }}>
          <AlertTitle>{errors}</AlertTitle>
        </Alert>
      ) : null}
      <CssBaseline />
      <div className={classes.paper} style={{ alignItems: 'center' }}>
        <Box className={classes.header}>
          <Typography component='h1' variant='h5'>
            {type.includes('new') ? `Створити видавника` : `Редагувати видавника`}
          </Typography>
          <IconButton
            aria-label='delete'
            style={{ color: data?.approved ? 'red' : '' }}
            onClick={() => data?.approved && setIsModal({ id: data.id, value: 'видавника' })}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
        <Box className={classes.textField}>
          <FormControlLabel
            control={<Checkbox checked={approved === APPROVED.PUBLISH} />}
            onChange={() => setApproved(approved === APPROVED.PUBLISH ? APPROVED.CREATE : APPROVED.PUBLISH)}
            label='Публікувати'
          />
        </Box>
        <Box className={classes.textField} style={{ marginTop: 0 }}>
          <FormControlLabel
            control={<Checkbox checked={!!status} />}
            onChange={() => setStatus(status ? 0 : 1)}
            label='Чинний'
          />
        </Box>
        <TextField
          required
          style={{ marginTop: '7px' }}
          size='small'
          id='outlined-basic'
          label='Назва'
          variant='outlined'
          value={name}
          error={!!errors && !name}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setName(event.target.value);
          }}
        />
        <TextField
          required
          size='small'
          id='outlined-basic-shortName'
          label='Скорочена назва'
          variant='outlined'
          value={shortName}
          error={!!errors && !shortName}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setShortName(event.target.value);
          }}
        />
        <TextField
          required
          size='small'
          id='outlined-basic-shortNameGenitive'
          label='Скорочена назва у род.відмінку'
          variant='outlined'
          value={shortNameGenitive}
          error={!!errors && !shortNameGenitive}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setShortNameGenitive(event.target.value);
          }}
        />
        <TextField
          size='small'
          id='outlined-basic-synonyms'
          label='Синоніми назви'
          variant='outlined'
          value={synonyms}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setSynonyms(event.target.value);
          }}
        />
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={OfficialDoc.loading} onClick={onSubmit}>
            {OfficialDoc.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
      </div>
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={isModal.value}
          open={!!isModal}
        />
      ) : null}
    </Box>
  );
});
