import { IDocumentVisitor } from '../consultations/types';
import { IOfficialDocFile } from '../officialDocFiles/types';
import { IPublisherDoc } from '../publishers/types';
import { IUser } from '../users/types';

export interface TOfficialDocState {
  statistics: any | null;
  selectList: IOfficialDocSelect[] | null;
  officialDocUpdate: { data: IOfficialDocUpdate[]; pages: number; page: number } | null;
  visitors: IDocumentVisitor[] | null;
  data: {
    officialDoc: IOfficialDoc[];
    currentPage: number;
    pages: number;
  } | null;
  searchData: {
    officialDoc: IOfficialDoc[];
    currentPage: number;
    pages: number;
    total?: number;
  } | null;
  redactions: IOfficialDoc[] | null;
  editData: { type: 'edit' | 'new'; data: IOfficialDoc } | null;
  status: {
    lowStatus: { [x: string]: string };
    approved: { [x: string]: string };
  };
  valueOfDoc: {
    catalogues?: Record<string, ItemDoc[]>;
    consultations?: Record<string, ItemDoc[]>;
    videos?: Record<string, ItemDoc[]>;
    forms?: Record<string, ItemDoc[]>;
  } | null;
  publishers?: { publishers: IPublisherDoc[]; currentPage: number; pages: number } | null;
  typesDoc?: ITypeDoc[] | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

interface ItemDoc {
  id: number;
  site_id: number;
  title: string;
  url: string;
}

export interface ITypeDoc {
  id: number;
  name: string;
  shortName: string;
  approved: number;
  docCount: number;
}

export interface IOfficialDoc {
  id: number;
  author_id: number;
  creator_id: number;
  group_id: number;
  type_id: number;
  doc_num: string;
  doc_date: Date;
  title: string;
  body: string;
  start_date: Date;
  end_date: Date;
  regist_date: Date;
  regist_num: string;
  search_tags: string;
  status: number;
  update: Date;
  approved: number;
  issearch: number;
  is_redaction: number;
  is_sitemap: number;
  is_draft: number;
  created_at?: Date;
  updated_at?: Date;
  type?: ITypeDoc;
  publishers?: IPublisherDoc[];
  attachments?: IOfficialDocFile[];
  author?: IUser;
  creator?: IUser;
  warning?: string;
}

export interface IOfficialDocUpdate {
  id: number;
  doc_id?: number;
  title?: string;
  url?: string;
  publication_at?: Date;
}

export interface IOfficialDocSelect {
  id: number;
  title: string;
}

enum ActionTypes {
  RESET_DATA = '@@officialDoc/RESET_DATA',
  SET_EDIT_DOC = '@@officialDoc/SET_EDIT_DOC',
  SET_DOC = '@@officialDoc/SET_DOC',
  SET_SEARCH_DATA = '@@officialDoc/SET_SEARCH_DATA',
  GET_PUBLISHERS_R = '@@officialDoc/GET_PUBLISHERS_R',
  GET_PUBLISHERS_S = '@@officialDoc/GET_PUBLISHERS_S',
  GET_PUBLISHERS_E = '@@officialDoc/GET_PUBLISHERS_E',

  GET_DOCS_R = '@@officialDoc/GET_DOCS_R',
  GET_DOCS_S = '@@officialDoc/GET_DOCS_S',
  GET_DOCS_E = '@@officialDoc/GET_DOCS_E',

  SEARCH_DOCS_R = '@@officialDoc/SEARCH_DOCS_R',
  SEARCH_DOCS_S = '@@officialDoc/SEARCH_DOCS_S',
  SEARCH_DOCS_E = '@@officialDoc/SEARCH_DOCS_E',

  GET_DOCS_OF_SELECT_R = '@@officialDoc/GET_DOCS_OF_SELECT_R',
  GET_DOCS_OF_SELECT_S = '@@officialDoc/GET_DOCS_OF_SELECT_S',
  GET_DOCS_OF_SELECT_E = '@@officialDoc/GET_DOCS_OF_SELECT_E',

  GET_DOCS_STATISTICS_R = '@@officialDoc/GET_DOCS_STATISTICS_R',
  GET_DOCS_STATISTICS_S = '@@officialDoc/GET_DOCS_STATISTICS_S',
  GET_DOCS_STATISTICS_E = '@@officialDoc/GET_DOCS_STATISTICS_E',

  GET_OFFICIAL_DOCS_FROM_UPDATE_R = '@@officialDoc/GET_OFFICIAL_DOCS_FROM_UPDATE_R',
  GET_OFFICIAL_DOCS_FROM_UPDATE_S = '@@officialDoc/GET_OFFICIAL_DOCS_FROM_UPDATE_S',
  GET_OFFICIAL_DOCS_FROM_UPDATE_E = '@@officialDoc/GET_OFFICIAL_DOCS_FROM_UPDATE_E',

  GET_REDACTIONS_R = '@@officialDoc/GET_REDACTIONS_R',
  GET_REDACTIONS_S = '@@officialDoc/GET_REDACTIONS_S',
  GET_REDACTIONS_E = '@@officialDoc/GET_REDACTIONS_E',

  GET_DOC_R = '@@officialDoc/GET_DOC_R',
  GET_DOC_S = '@@officialDoc/GET_DOC_S',
  GET_DOC_E = '@@officialDoc/GET_DOC_E',

  CREATE_GROUP_R = '@@officialDoc/CREATE_GROUP_R',
  CREATE_GROUP_S = '@@officialDoc/CREATE_GROUP_S',
  CREATE_GROUP_E = '@@officialDoc/CREATE_GROUP_E',

  CREATE_DOC_R = '@@officialDoc/CREATE_DOC_R',
  CREATE_DOC_S = '@@officialDoc/CREATE_DOC_S',
  CREATE_DOC_E = '@@officialDoc/CREATE_DOC_E',

  CREATE_OFFICIAL_DOC_OF_UPDATE_R = '@@officialDoc/CREATE_OFFICIAL_DOC_OF_UPDATE_R',
  CREATE_OFFICIAL_DOC_OF_UPDATE_S = '@@officialDoc/CREATE_OFFICIAL_DOC_OF_UPDATE_S',
  CREATE_OFFICIAL_DOC_OF_UPDATE_E = '@@officialDoc/CREATE_OFFICIAL_DOC_OF_UPDATE_E',

  UPDATE_DOC_R = '@@officialDoc/UPDATE_DOC_R',
  UPDATE_DOC_S = '@@officialDoc/UPDATE_DOC_S',
  UPDATE_DOC_E = '@@officialDoc/UPDATE_DOC_E',

  DELETE_DOC_R = '@@officialDoc/DELETE_DOC_R',
  DELETE_DOC_S = '@@officialDoc/DELETE_DOC_S',
  DELETE_DOC_E = '@@officialDoc/DELETE_DOC_E',

  GET_TYPE_DOC_R = '@@officialDoc/GET_TYPE_DOC_R',
  GET_TYPE_DOC_S = '@@officialDoc/GET_TYPE_DOC_S',
  GET_TYPE_DOC_E = '@@officialDoc/GET_TYPE_DOC_E',

  CREATE_TYPE_DOC_R = '@@officialDoc/CREATE_TYPE_DOC_R',
  CREATE_TYPE_DOC_S = '@@officialDoc/CREATE_TYPE_DOC_S',
  CREATE_TYPE_DOC_E = '@@officialDoc/CREATE_TYPE_DOC_E',

  UPDATE_TYPE_DOC_R = '@@officialDoc/UPDATE_TYPE_DOC_R',
  UPDATE_TYPE_DOC_S = '@@officialDoc/UPDATE_TYPE_DOC_S',
  UPDATE_TYPE_DOC_E = '@@officialDoc/UPDATE_TYPE_DOC_E',

  DELETE_TYPE_DOC_R = '@@officialDoc/DELETE_TYPE_DOC_R',
  DELETE_TYPE_DOC_S = '@@officialDoc/DELETE_TYPE_DOC_S',
  DELETE_TYPE_DOC_E = '@@officialDoc/DELETE_TYPE_DOC_E',

  DELETE_OFFICIAL_DOCS_FROM_UPDATE_R = '@@officialDoc/DELETE_OFFICIAL_DOCS_FROM_UPDATE_R',
  DELETE_OFFICIAL_DOCS_FROM_UPDATE_S = '@@officialDoc/DELETE_OFFICIAL_DOCS_FROM_UPDATE_S',
  DELETE_OFFICIAL_DOCS_FROM_UPDATE_E = '@@officialDoc/DELETE_OFFICIAL_DOCS_FROM_UPDATE_E',

  UPDATE_OFFICIAL_DOCS_FROM_UPDATE_R = '@@officialDoc/UPDATE_OFFICIAL_DOCS_FROM_UPDATE_R',
  UPDATE_OFFICIAL_DOCS_FROM_UPDATE_S = '@@officialDoc/UPDATE_OFFICIAL_DOCS_FROM_UPDATE_S',
  UPDATE_OFFICIAL_DOCS_FROM_UPDATE_E = '@@officialDoc/UPDATE_OFFICIAL_DOCS_FROM_UPDATE_E',

  CHECK_OFFICIAL_DOCS_VISITOR_R = '@@officialDoc/CHECK_OFFICIAL_DOCS_VISITOR_R',
  CHECK_OFFICIAL_DOCS_VISITOR_S = '@@officialDoc/CHECK_OFFICIAL_DOCS_VISITOR_S',
  CHECK_OFFICIAL_DOCS_VISITOR_E = '@@officialDoc/CHECK_OFFICIAL_DOCS_VISITOR_E',

  DELETE_OFFICIAL_DOCS_VISITOR_R = '@@officialDoc/DELETE_OFFICIAL_DOCS_VISITOR_R',
  DELETE_OFFICIAL_DOCS_VISITOR_S = '@@officialDoc/DELETE_OFFICIAL_DOCS_VISITOR_S',
  DELETE_OFFICIAL_DOCS_VISITOR_E = '@@officialDoc/DELETE_OFFICIAL_DOCS_VISITOR_E',

  GET_VALUE_OF_DOC_R = '@@officialDoc/GET_VALUE_OF_DOC_R',
  GET_VALUE_OF_DOC_S = '@@officialDoc/GET_VALUE_OF_DOC_S',
  GET_VALUE_OF_DOC_E = '@@officialDoc/GET_VALUE_OF_DOC_E',

  DOWNLOAD_VALUE_OF_DOC_R = '@@officialDoc/DOWNLOAD_VALUE_OF_DOC_R',
  DOWNLOAD_VALUE_OF_DOC_S = '@@officialDoc/DOWNLOAD_VALUE_OF_DOC_S',
  DOWNLOAD_VALUE_OF_DOC_E = '@@officialDoc/DOWNLOAD_VALUE_OF_DOC_E',
}

export default ActionTypes;
