import { Reducer } from 'redux';
import ActionTypes, { TCataloguesState } from './types';

export const initialState: TCataloguesState = {
  data: null,
  pages:1,
  total:1,
  catalogues: null,
  visitors: null,
  current: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TCataloguesState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_CATALOGUES: {
      return {
        ...state,
        data: action.payload,
        catalogues: action.payload.catalogues,
        loading: false,
        errors: '',
      };
    }

    case ActionTypes.GET_CATALOGUES_S:
      return {
        ...state,
        catalogues: action.payload.catalogues,
        data: action.payload,
        loading: false,
        pages: action.payload.pages,
        total: action.payload.total,
        errors: '',
      };

    case ActionTypes.RESET_CATALOGUES:
      return {
        ...state,
        catalogues: state.data ? state.data.catalogues : null,
      };

    case ActionTypes.DELETE_CATALOGUES_VISITOR_R:
    case ActionTypes.CHECK_CATALOGUES_VISITOR_R:
    case ActionTypes.GET_CATALOGUES_R:
    case ActionTypes.SEARCH_CATALOGUES_R:
    case ActionTypes.CREATE_CATALOG_R:
    case ActionTypes.UPDATE_CATALOG_R:
    case ActionTypes.DELETE_CATALOG_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.SEARCH_CATALOGUES_S:
      return {
        ...state,
        catalogues: action.payload.catalogues,
        pages: action.payload.pages,
        total: action.payload.total,
        loading: false,
        errors: '',
      };

    case ActionTypes.GET_CATALOG_R:
      return {
        ...state,
        current: null,
      };

    case ActionTypes.GET_CATALOG_S: {
      return {
        ...state,
        loading: false,
        errors: '',
        current: action.payload,
      };
    }

    case ActionTypes.CREATE_CATALOG_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? { ...state.data, catalogues: [action.payload, ...state.data.catalogues], total: state.data.total + 1 }
          : { catalogues: [action.payload], total: 1 },
        catalogues: state.catalogues ? [action.payload, ...state.catalogues] : [action.payload],
      };

    case ActionTypes.UPDATE_CATALOG_S: {
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? {
              ...state.data,
              catalogues: state.data.catalogues.map((it) => (it.id === action.payload.id ? action.payload : it)),
            }
          : state.data,
        catalogues: state.catalogues
          ? state.catalogues.map((it) => (it.id === action.payload.id ? action.payload : it))
          : state.catalogues,
        current: action.payload,
      };
    }
    case ActionTypes.DELETE_CATALOG_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? {
              ...state.data,
              catalogues: state.data.catalogues.filter((it) => it.id !== action.payload.id),
              total: state.data.total - 1,
            }
          : state.data,
        catalogues: state.catalogues?.filter((it) => it.id !== action.payload.id),
      };

    case ActionTypes.CHECK_CATALOGUES_VISITOR_S: {
      return { ...state, visitors: action.payload };
    }
    case ActionTypes.DELETE_CATALOGUES_VISITOR_S: {
      return { ...state, visitors: state.visitors?.filter((el) => el.id !== action.payload.id) };
    }

    case ActionTypes.DELETE_CATALOGUES_VISITOR_E:
    case ActionTypes.CHECK_CATALOGUES_VISITOR_E:
    case ActionTypes.GET_CATALOGUES_E:
    case ActionTypes.SEARCH_CATALOGUES_E:
    case ActionTypes.CREATE_CATALOG_E:
    case ActionTypes.UPDATE_CATALOG_E:
    case ActionTypes.DELETE_CATALOG_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as CataloguesReducer };
