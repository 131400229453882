import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppStore } from '../../store/applicationState';
import { DeleteVideo, GetVideo, SearchVideo } from '../../store/video/actions';
import { GetCart } from '../../store/cart/actions';
import { useSearchAuthors } from '../../hooks/useSearchAuthors';
import { StylesTable } from '../../components/Stylestable';
import { ROUTES } from '../../utils/routes';
import { SelectSearchReq } from '../../components/ui';
import { WarningModal } from '../../components/modals';
import { useSearchAny } from '../../hooks/useSearchAny';
import { Pagination } from '@mui/material';

interface Column {
  id:
    | 'id'
    | 'title'
    | 'author'
    | 'authorFio'
    | 'baseUrl'
    | 'urls'
    | 'rubric'
    | 'isPopular'
    | 'isRecomm'
    | 'delete'
    | 'isPublic';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: '№', align: 'center', width: '6%' },
  { id: 'title', label: 'Заголовок', align: 'left' },
  { id: 'author', label: 'Автор', align: 'left', width: '10%' },
  {
    id: 'authorFio',
    label: 'Редактор',
    align: 'left',
  },
  { id: 'baseUrl', label: 'Базовий URL', align: 'left' },
  { id: 'urls', label: 'Фрагменти', align: 'center', width: '17%' },
  {
    id: 'isPublic',
    label: 'Публікція',
    align: 'center',
  },
  {
    id: 'isPopular',
    label: 'Популярне',
    align: 'center',
  },
  {
    id: 'isRecomm',
    label: 'Рекомендація',
    align: 'center',
  },
  {
    id: 'rubric',
    label: 'Рубрика',
    align: 'left',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '8%',
  },
];

type PropsType = {};

export const ListVideo: React.FC<PropsType> = () => {
  const [id, setId] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [author, setAuthor] = React.useState<{ label: string; id: string | number } | null | undefined>();
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const [page, setPage] = React.useState(1);

  const { Video, Configurations } = useSelector((store: AppStore) => store);
  const { options, handleSearchAuthors } = useSearchAuthors();
  const dispatch = useDispatch();
  const classesTable = StylesTable();

  const obj = {
    page: String(1),
    id,
    title,
    author: author ? String(author?.id) : '',
    siteId: String(Configurations.siteId),
  };

  const resetState = () => {
    setId('');
    setTitle('');
    setPage(1);
    setAuthor(null);
    dispatch(
      GetVideo.request({
        data: {
          siteId: Configurations.siteId,
          page: 1,
        },
      })
    );
  };

  const { loading, handleSearch } = useSearchAny({
    SearchData: SearchVideo,
  });

  React.useEffect(() => {
    if (Configurations.siteId) {
      dispatch(
        GetVideo.request({
          data: {
            siteId: Configurations.siteId,
          },
        })
      );
    }
  }, [Configurations.siteId, dispatch]);

  const handleChangeRoute =
    (path = '' as string | number) =>
    () => {
      window.open(ROUTES.routeChange(`list/${path}?site_id=${Configurations.siteId}`));
    };

  const handleRemove = (id: number) => {
    dispatch(
      DeleteVideo.request({
        id,
        callBack: (success) => {
          if (success) {
            dispatch(
              GetCart.request({
                data: {
                  siteId: Configurations.siteId,
                },
              })
            );
            dispatch(
              GetVideo.request({
                data: {
                  siteId: Configurations.siteId,
                },
              })
            );
          }
        },
      })
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(
      GetVideo.request({
        data: {
          page: newPage,
          siteId: Configurations.siteId,
          author: author ? String(author.id) : undefined,
          title,
          id: id ? Number(id) : undefined,
        },
      })
    );
  };

  return (
    <>
      <Box className={classesTable.header}>
        <Button
          className={classesTable.searchField}
          variant='contained'
          color='primary'
          onClick={handleChangeRoute('new')}
        >
          Створити відео
        </Button>
        <TextField
          size='small'
          id='outlined-basic-id'
          label='Пошук по ID'
          variant='outlined'
          value={id}
          className={classesTable.searchField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const value = event.target.value as string;
            setId(value);
            handleSearch(value, 'id', obj);
            setPage(1);
          }}
        />
        <TextField
          className={classesTable.searchField}
          size='small'
          id='outlined-basic-title'
          label='Пошук по заголовку'
          variant='outlined'
          value={title}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const value = event.target.value as string;
            setTitle(value);
            handleSearch(value, 'title', obj);
            setPage(1);
          }}
        />
        <SelectSearchReq
          style={{ maxWidth: '200px' }}
          title='Автор'
          className={classesTable.searchField}
          onChange={(value) => {
            setAuthor({ label: value?.label || '', id: value?.id || 0 });
            handleSearch(String(value?.id) || '', 'author', obj);
            setPage(1);
          }}
          options={options}
          handleRequest={handleSearchAuthors}
          value={author?.label}
        />
        <Button className={classesTable.searchField} variant='contained' color='primary' onClick={resetState}>
          {loading ? <CircularProgress color='inherit' size={25} /> : 'Очистити фільтри'}
        </Button>
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Video.video?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>
                    <Link sx={{ cursor: 'pointer' }} onClick={handleChangeRoute(row.id)}>
                      {row.id}
                    </Link>
                  </TableCell>
                  <TableCell className={classesTable.row}>{row.title}</TableCell>
                  <TableCell className={classesTable.row}>
                    {row.authors && row.authors.length > 0
                      ? row.authors.map((author) => (
                          <div key={`${i}-${author.id}`}>
                            <span>{author.fio}</span>
                            <br />
                          </div>
                        ))
                      : ''}
                  </TableCell>
                  <TableCell className={classesTable.row}>{row.author?.fio || ''}</TableCell>
                  <TableCell className={classesTable.row}>{row.base_url}</TableCell>
                  <TableCell align='center' className={classesTable.row}>
                    {row.urls?.length || 0}
                  </TableCell>
                  <TableCell align='center' className={classesTable.row}>
                    {row.is_public ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell align='center' className={classesTable.row}>
                    {row.is_popular ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell align='center' className={classesTable.row}>
                    {row.is_recommendation ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell className={classesTable.row}>{row.rubric?.name || ''}</TableCell>
                  <TableCell align='center'>
                    <IconButton aria-label='delete' onClick={() => setIsModal({ id: row.id })}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classesTable.pagination}
        count={Video.pages || 0}
        page={page}
        onChange={handleChangePage}
      />
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
