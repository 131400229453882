import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { ModalOfficialDoc, WarningModal } from '../modals';
import { IRubric } from '../../store/rubrics/types';
import { CreateRubric, DeleteRubric, UpdatePositions, UpdateRubric } from '../../store/rubrics/actions';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppStore } from '../../store/applicationState';
import { makeStyles } from '@mui/styles';
import { CATEGORIES } from '../../utils/consts';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    marginTop: '5px',
  },
  wrappBody: {
    display: 'flex',
    borderRadius: ' 4px',
    border: 'solid 1px #3f51b5',
    marginTop: '5px',
  },
  expend: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    marginTop: '5px',
    cursor: 'pointer',
    minWidth: '30px',
  },
  item: {
    minWidth: '37px',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    cursor: 'pointer',
    fontSize: '16px',
    color: '#3f51b5',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    minWidth: '520px',
    maxWidth: '520px',
    overflowWrap: 'anywhere',
  },
  children: {
    paddingLeft: '20px',
  },
}));

const findChildren = (cards: IRubric[] | null, ids: number[]): IRubric[] | null => {
  if (!cards) return cards;
  const card = cards[ids[0]];
  if (card && card.children) {
    ids.splice(0, 1);
    return findChildren(card.children, ids);
  }
  return cards;
};

const setChildren = (cards: IRubric[] | null, ids: number[], newChildren: IRubric[] | null): IRubric[] | null => {
  if (!cards) return cards;
  let prev = null;
  for (const id of ids) {
    if (prev && prev[id]) {
      prev = prev[id].children;
    }
    if (!prev && cards[id]) {
      prev = cards[id].children;
    }
  }
  prev = newChildren;
  return cards;
};

type IModal = 'add' | 'edit' | 'delete' | null;

type PropsType = {
  rubrics: IRubric[] | null;
  cards: IRubric[] | null;
  chain?: string;
  level?: number;
  categoryId: number;
  setCards: (cards: IRubric[] | null) => void;
};

const BindDoc: React.FC<PropsType> = ({ cards, rubrics, categoryId, chain, level = 0, setCards }) => {
  const [draggId, setDraggId] = React.useState('');
  const [overId, setOverId] = React.useState('');
  const [expend, setExpand] = React.useState('');
  const [currentId, setCurrentId] = React.useState('');
  const [hoverId, setHoverId] = React.useState('');
  const [isModal, setIsModal] = React.useState<IModal>(null);

  const dispatch = useDispatch();
  const classes = useStyles();

  const moveCard = (dragIndex: number, hoverIndex: number, chain: string) => {
    if (!cards) return null;
    const ids = chain.split('-').map((str) => Number(str));
    ids.pop();
    const currentCards = findChildren(cards, ids);
    if (currentCards && currentCards[dragIndex]) {
      const card = currentCards[dragIndex];
      currentCards.splice(dragIndex, 1);
      currentCards.splice(hoverIndex, 0, card);

      dispatch(UpdatePositions.request({ data: currentCards.map((it, i) => ({ id: it.id, position: i })) }));

      const ids = chain.split('-').map((str) => Number(str));
      const newCards = setChildren([...cards], ids, currentCards);
      setCards(newCards);
    }
  };

  const onAddDoc = (rubric: IRubric, { docId, name }: { docId: number; name: string }) => {
    dispatch(CreateRubric.request({ data: { docId, categoryId, name, mainId: rubric.id, siteId: rubric.siteId } }));
  };

  const updateDoc = (rubric: IRubric, { docId, name }: { docId: number; name: string }) => {
    dispatch(
      UpdateRubric.request({
        data: { docId, categoryId, name, mainId: rubric.mainId, siteId: rubric.siteId },
        id: rubric.id,
      })
    );
  };

  const onDeleteDoc = (id: number) => {
    dispatch(DeleteRubric.request({ id }));
  };

  const handleDraggEnd = (chainId: string) => {
    if (draggId !== overId) {
      const drId = draggId.split('-').pop();
      const chId = overId.split('-').pop();
      moveCard(Number(drId), Number(chId), chainId);
    }
    setDraggId('');
    setHoverId('');
  };

  const handleManage = (chainId: string, isModal: IModal) => {
    setCurrentId(chainId);
    setIsModal(isModal);
  };

  return (
    <>
      {rubrics?.map((rubric, index) => {
        const chainId = chain ? `${chain}-${index}` : String(index);
        const isDisabled = rubric.children ? rubric.children.length > 0 : false;
        return (
          <div
            key={chainId}
            onDragOver={() => {
              chainId.length !== expend.length && setHoverId(chainId);
            }}
            onDragLeave={() => setOverId(chainId)}
            onDrag={() => !draggId && setDraggId(chainId)}
            onDragEnd={() => handleDraggEnd(chainId)}
            draggable
          >
            <div className={classes.root}>
              <div className={classes.expend} onClick={() => setExpand(chainId === expend ? '' : chainId)}>
                {rubric.children && rubric.children.length > 0 ? (
                  expend === chainId ? (
                    <RemoveIcon style={{ fontSize: '1rem' }} />
                  ) : (
                    <AddIcon style={{ fontSize: '1rem' }} />
                  )
                ) : (
                  ''
                )}
              </div>
              <div
                className={classes.wrappBody}
                style={{ background: chainId === hoverId && expend !== hoverId ? '#554e4e70' : '' }}
              >
                <div className={classes.item} style={{ color: '#3f51b5' }}>
                  {!rubric.docId ? (
                    expend === chainId ? (
                      <FolderOpenOutlinedIcon fontSize='small' />
                    ) : (
                      <FolderOutlinedIcon fontSize='small' />
                    )
                  ) : (
                    <InsertDriveFileOutlinedIcon fontSize='small' />
                  )}
                </div>
                <div className={classes.title} style={{ cursor: 'move' }}>
                  {rubric.name}
                </div>

                {!rubric.children?.find((it) => !it.docId) && !rubric.docId ? (
                  <>
                    <div className={classes.item} />
                    <div className={classes.item} onClick={() => !rubric.docId && handleManage(chainId, 'add')}>
                      <AddBoxOutlinedIcon style={{ fontSize: '1.1rem' }} />
                    </div>
                  </>
                ) : rubric.docId ? (
                  <>
                    <div className={classes.item} />
                    <div className={classes.item} onClick={() => rubric.docId && handleManage(chainId, 'edit')}>
                      <EditIcon style={{ fontSize: '1.1rem' }} />
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.item} />
                    <div className={classes.item} />
                  </>
                )}

                <IconButton
                  disabled={isDisabled}
                  className={classes.item}
                  onClick={() => handleManage(chainId, 'delete')}
                >
                  <DeleteIcon color={isDisabled ? 'disabled' : 'error'} style={{ fontSize: '1.1rem' }} />
                </IconButton>
              </div>
            </div>

            {expend === chainId && (
              <div className={classes.children}>
                <BindDoc
                  rubrics={rubric.children}
                  cards={cards}
                  categoryId={categoryId}
                  chain={chainId}
                  level={level + 1}
                  setCards={setCards}
                />
              </div>
            )}

            {isModal === 'add' && currentId === chainId ? (
              <ModalOfficialDoc
                title="Прив'язати документ"
                handleClose={() => {
                  setIsModal(null);
                }}
                handleConfirm={(doc) => {
                  onAddDoc(rubric, doc);
                  setIsModal(null);
                }}
                open={!!isModal}
              />
            ) : null}
            {isModal === 'edit' && currentId === chainId ? (
              <ModalOfficialDoc
                title='Редагувати'
                initValue={rubric.name}
                handleClose={() => {
                  setIsModal(null);
                }}
                handleConfirm={(doc) => {
                  updateDoc(rubric, doc);
                  setIsModal(null);
                }}
                docum={rubric}
                open={!!isModal}
              />
            ) : null}
            {isModal === 'delete' && currentId === chainId ? (
              <WarningModal
                handleApprove={(isApprove) => {
                  isApprove && onDeleteDoc(rubric.id);
                  setIsModal(null);
                }}
                value={rubric.name}
                open={!!isModal}
              />
            ) : null}
          </div>
        );
      })}
    </>
  );
};

export const WrapBindDoc: React.FC<{}> = () => {
  const [cards, setCards] = React.useState<IRubric[] | null>(null);

  const { Rubrics: RRubrics } = useSelector((store: AppStore) => store);

  React.useEffect(() => {
    if (RRubrics.rubrics) {
      if (RRubrics.rubrics[CATEGORIES.NPD]) {
        setCards(RRubrics.rubrics[CATEGORIES.NPD]);
      } else {
        setCards(null);
      }
    }
  }, [RRubrics.rubrics]);

  return <BindDoc rubrics={cards} cards={cards} setCards={setCards} categoryId={5} />;
};
