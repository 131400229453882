export interface TRubricsState {
  rubrics: { [key: string]: IRubric[] } | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IRubric {
  id: number;
  siteId: number;
  mainId: number;
  docId: number;
  name: string;
  position: number;
  approved: number;
  children: IRubric[] | null;
}

export interface RubricType {
  id: number;
  site_id: number;
  main_id: number;
  parent_id: number;
  category_id: number;
  doc_id: number;
  name: string;
  position: number;
  approved: number;
  update: Date;
  created_at?: Date;
  updated_at?: Date;
}

export interface IRubricItem {
  id: number;
  categoryId: number;
  name: string;
  itemId: number;
}

enum ActionTypes {
  GET_RUBRICS_R = '@@rubrics/GET_RUBRICS_R',
  GET_RUBRICS_S = '@@rubrics/GET_RUBRICS_S',
  GET_RUBRICS_E = '@@rubrics/GET_RUBRICS_E',

  CREATE_RUBRIC_R = '@@rubrics/CREATE_RUBRIC_R',
  CREATE_RUBRIC_S = '@@rubrics/CREATE_RUBRIC_S',
  CREATE_RUBRIC_E = '@@rubrics/CREATE_RUBRIC_E',

  UPDATE_RUBRIC_R = '@@rubrics/UPDATE_RUBRIC_R',
  UPDATE_RUBRIC_S = '@@rubrics/UPDATE_RUBRIC_S',
  UPDATE_RUBRIC_E = '@@rubrics/UPDATE_RUBRIC_E',

  UPDATE_POSITIONS_R = '@@rubrics/UPDATE_POSITIONS_R',
  UPDATE_POSITIONS_S = '@@rubrics/UPDATE_POSITIONS_S',
  UPDATE_POSITIONS_E = '@@rubrics/UPDATE_POSITIONS_E',

  DELETE_RUBRIC_R = '@@rubrics/DELETE_RUBRIC_R',
  DELETE_RUBRIC_S = '@@rubrics/DELETE_RUBRIC_S',
  DELETE_RUBRIC_E = '@@rubrics/DELETE_RUBRIC_E',
}

export default ActionTypes;
