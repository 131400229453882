import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TOfficialDocDraftsState } from './types';

export const setError = (payload: string) => action(ActionTypes.SET_ERROR, payload);

const GetOfficialDocDraftsAction = new Actions('GET_OFFICIAL_DOC_DRAFTS', ActionTypes);
export interface TypeGetOfficialDocDraftsR extends TDefRequest {
  group_id: number;
}
export const GetOfficialDocDrafts = {
  request: (payload: TypeGetOfficialDocDraftsR) => GetOfficialDocDraftsAction.request(payload),
  success: (payload: TOfficialDocDraftsState['data']) => GetOfficialDocDraftsAction.success(payload || {}),
  error: (message: string) => GetOfficialDocDraftsAction.error(message),
};

const GetOfficialDocDraftAction = new Actions('GET_OFFICIAL_DOC_DRAFT', ActionTypes);
export interface TypeGetOfficialDocDraftR extends TDefRequest {
  id: number;
}
export const GetOfficialDocDraft = {
  request: (payload: TypeGetOfficialDocDraftR) => GetOfficialDocDraftAction.request(payload),
  success: (payload: TOfficialDocDraftsState['current']) => GetOfficialDocDraftAction.success(payload || {}),
  error: (message: string) => GetOfficialDocDraftAction.error(message),
};

const CreateOfficialDocDraftsAction = new Actions('CREATE_OFFICIAL_DOC_DRAFTS', ActionTypes);
export interface TypeCreateOfficialDocDraftsR extends TDefRequest {
  data: {
    doc_id: number;
  };
}
export const CreateOfficialDocDrafts = {
  request: (payload: TypeCreateOfficialDocDraftsR) => CreateOfficialDocDraftsAction.request(payload),
  success: (payload: TOfficialDocDraftsState['current']) => CreateOfficialDocDraftsAction.success(payload || {}),
  error: (message: string) => CreateOfficialDocDraftsAction.error(message),
};

const UpdateOfficialDocDraftsAction = new Actions('UPDATE_OFFICIAL_DOC_DRAFTS', ActionTypes);
export interface TypeUpdateOfficialDocDraftsR extends TDefRequest {
  data: {
    body: string;
  };
  id: number;
}
export const UpdateOfficialDocDrafts = {
  request: (payload: TypeUpdateOfficialDocDraftsR) => UpdateOfficialDocDraftsAction.request(payload),
  success: (payload: TOfficialDocDraftsState['current']) => UpdateOfficialDocDraftsAction.success(payload || {}),
  error: (message: string) => UpdateOfficialDocDraftsAction.error(message),
};

const DeleteOfficialDocDraftsAction = new Actions('DELETE_OFFICIAL_DOC_DRAFTS', ActionTypes);
export interface TypeDeleteOfficialDocDraftsR extends TDefRequest {
  id: number;
}
export const DeleteOfficialDocDrafts = {
  request: (payload: TypeDeleteOfficialDocDraftsR) => DeleteOfficialDocDraftsAction.request(payload),
  success: (payload: TOfficialDocDraftsState['current']) => DeleteOfficialDocDraftsAction.success(payload || {}),
  error: (message: string) => DeleteOfficialDocDraftsAction.error(message),
};

const ChangeOwnerAction = new Actions('CHANGE_OWNER', ActionTypes);
export interface TypeChangeOwnerR extends TDefRequest {
  data: {
    user_id: number;
  };
  id: number;
}
export const ChangeOwner = {
  request: (payload: TypeChangeOwnerR) => ChangeOwnerAction.request(payload),
  success: (payload: TOfficialDocDraftsState['current']) => ChangeOwnerAction.success(payload || {}),
  error: (message: string) => ChangeOwnerAction.error(message),
};

const UpdateRedactionAction = new Actions('UPDATE_REDACTION', ActionTypes);
export interface TypeUpdateRedactionR extends TDefRequest {
  id: number;
}
export const UpdateRedaction = {
  request: (payload: TypeUpdateRedactionR) => UpdateRedactionAction.request(payload),
  success: (payload: TOfficialDocDraftsState['current']) => UpdateRedactionAction.success(payload || {}),
  error: (message: string) => UpdateRedactionAction.error(message),
};
