import React from 'react';
import clsx from 'clsx';
import { Styles } from './Styles';
import { FilesModal } from '../modals';
import { IOfficialDocFile } from '../../store/officialDocFiles/types';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../store/officialDocFiles/actions';
import { AppStore } from '../../store/applicationState';

type SelectPopupProps = {
  docId: number;
  files: IOfficialDocFile[];
  title?: string;
  style?: React.CSSProperties | undefined;
  className?: string;
  url?: string;
  type?: number;
  handleClose?: () => void;
};

export const SelectPopupFile: React.FC<SelectPopupProps> = React.memo(({ title, style, files, className, docId, handleClose, url, type }) => {
  const [titleView, setTitleView] = React.useState<string>(title || 'Додатки');
  const [isFocus, setIsFocus] = React.useState(false);
  const classes = Styles();
  const { OfficialDocFiles } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const checkName = React.useCallback((count: number) => {
    if (count > 4) return 'файлів';
    if (count > 1) return 'файли';
    if (count === 1) return 'файл';
    return '';
  }, []);

  React.useEffect(() => {
    if (OfficialDocFiles.data) {
      const count = OfficialDocFiles.data.length;
      const sub = checkName(count);
      setTitleView(`Додатки ${count || ''} ${sub}`);
    }
  }, [OfficialDocFiles.data, checkName]);

  React.useEffect(() => {
    if (files) {
      dispatch(setData(files));
    }
  }, [dispatch, files]);

  const handleLeaveFocus = () => {
    setIsFocus(!isFocus);
  };

  const setClass = () => {
    if (isFocus) return classes.placeholderFocus;
  };

  return (
    <div style={style} className={clsx(classes.root, className)}>
      {titleView && <div className={clsx(classes.placeholder, setClass())}>{titleView}</div>}
      <div className={clsx(classes.wrappField, isFocus && classes.wrappFieldFocus)} onClick={handleLeaveFocus}>
        <div className={classes.field} />
      </div>
      {isFocus ? (
        <FilesModal
          open={isFocus}
          docId={docId}
          handleClose={() => {
            setIsFocus(false);
            handleClose && handleClose();
          }}
          urlLink={url}
          type={type}
        />
      ) : null}
    </div>
  );
});
