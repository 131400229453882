import React from 'react';
import { BaseModal, WarningModal } from '../../modals';
import { UploadImage } from '../../ui';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { callApi } from '../../../utils/callApi';
import { configSites } from '../../../config';

interface Item {
  vertically: number | string | null;
  horizontally: number | string | null;
  description: string | null;
}

interface IResponseItem {
  vertically: number | null;
  horizontally: number | null;
  description: string | null;
}

interface Props {
  siteId?: number;
  categoryId?: number;
  publicationId?: number | string;
  open: boolean;
  data?: {
    imgSrc: string;
    items: { vertically: number | null; horizontally: number | null; description: string | null }[];
  } | null;
  handleConfirm: (value: { imgSrc: string; items: IResponseItem[] }) => void;
  handleClose: () => void;
}

const WIDTH_IMAGE = 800;

export const ModalLiveSample: React.FC<Props> = ({
  categoryId,
  publicationId,
  siteId,
  open,
  data,
  handleConfirm,
  handleClose,
}) => {
  const [dragIndex, setDragIndex] = React.useState<number | null>(null);
  const [loading, setloading] = React.useState(false);
  const [deleteIndex, setDeleteIndex] = React.useState<number | null>(null);
  const [widthImage, setWidthImage] = React.useState(WIDTH_IMAGE);
  const [preview, setPreview] = React.useState(data?.imgSrc || '');
  const [file, setFile] = React.useState<File | string>(data?.imgSrc || '');
  const [points, setPoints] = React.useState<Item[]>(
    data && data.items.length ? data.items : [{ description: '', horizontally: '50', vertically: '50' }]
  );

  const ref = React.useRef<HTMLImageElement | null>(null);
  const refPoints = React.useRef<Record<string, HTMLDivElement | null>>({});

  const handleChangeFile = (file: File | string) => {
    setFile(file);
    if (typeof file === 'string') {
      setPreview(file);
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    try {
      let imgSrc = '';
      if (!file) return null;

      if (typeof file !== 'string' && siteId && categoryId) {
        setloading(true);

        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('siteId', String(siteId));
        formData.append('categoryId', String(categoryId));
        if (publicationId) {
          formData.append('id', String(publicationId));
        }

        const path = await callApi({
          method: 'post',
          data: formData,
          isFormData: true,
          path: '/upload/file',
        }).catch((err) => console.log(err));

        setloading(false);

        if (path) {
          imgSrc = `${configSites[siteId].url}/${path}`;
        }
      } else if (typeof file === 'string') {
        imgSrc = file;
      }

      handleConfirm({
        imgSrc,
        items: points.map((item) => ({
          ...item,
          horizontally: Number(item.horizontally),
          vertically: Number(item.vertically),
        })),
      });
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  const handleChangePoint =
    (key: keyof Item, index: number) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const arr = [...points];

      if (arr[index] && key === 'description') {
        arr[index][key] = event.target.value;
      }
      if ((arr[index] && key === 'horizontally') || (arr[index] && key === 'vertically')) {
        const value = Math.abs(Number(event.target.value));
        if (!Number.isNaN(value) && value <= 100) {
          arr[index][key] = event.target.value;
        }
        if (!event.target.value) {
          arr[index][key] = '';
        }
      }

      setPoints(arr);
    };

  const handleDeletePoint = (isApprove: boolean) => {
    if (typeof deleteIndex === 'number' && isApprove) {
      const arr = [...points];
      arr.splice(deleteIndex, 1);
      setPoints(arr);
    }
    setDeleteIndex(null);
  };

  const handleAddPoint = () => {
    setPoints([...points, { description: '', horizontally: '50', vertically: '50' }]);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (typeof dragIndex !== 'number' || !ref.current) return null;
    const point = refPoints.current[dragIndex];
    if (!point) return null;

    const rect = ref.current.getBoundingClientRect();

    const centerY = rect.height / 2;

    const pointX = point.clientWidth / 2;
    const pointY = e.clientY > centerY ? point.clientHeight : point.clientHeight / 2;

    const x = ((e.clientX - rect.left - pointX) / rect.width) * 100;
    const y = ((e.clientY - rect.top - pointY) / rect.height) * 100;

    point.style.left = x.toFixed(2) + '%';
    point.style.top = y.toFixed(2) + '%';
  };

  const handleDrag = React.useCallback((value: number) => {
    return () => {
      setDragIndex(value);
    };
  }, []);

  const handleDrop = React.useCallback(() => {
    if (typeof dragIndex === 'number') {
      const point = refPoints.current[dragIndex];
      if (points[dragIndex] && point) {
        const arr = [...points];

        const x = Number(point.style.left.replace('%', ''));
        const y = Number(point.style.top.replace('%', ''));

        if (!Number.isNaN(x)) {
          const value = x > 97 ? 97 : x < 0 ? 0 : x;
          arr[dragIndex].horizontally = String(value);
          point.style.left = value + '%';
        }

        if (!Number.isNaN(y)) {
          const value = y > 97 ? 97 : y < 0 ? 0 : y;
          arr[dragIndex].vertically = String(value);
          point.style.top = value + '%';
        }

        setPoints(arr);
      }
    }

    setDragIndex(null);
  }, [points, dragIndex]);

  React.useEffect(() => {
    document.addEventListener('mouseup', handleDrop);
    return () => document.removeEventListener('mouseup', handleDrop);
  }, [handleDrop]);

  return (
    <>
      <BaseModal
        open={open}
        disabled={!preview || loading}
        onConfirm={handleSave}
        onCancel={handleClose}
        onClose={handleClose}
        children={
          <Box display={'flex'}>
            <Box width='400px' height={'calc(100vh - 200px)'} overflow='auto' pr={2}>
              {points.map((item, i) => {
                return (
                  <Box key={i}>
                    <Typography component='h1' variant='h5'>
                      №{i + 1} Підказка
                    </Typography>
                    <Box my={2} display={'flex'} flexDirection={'column'} gap={2}>
                      <TextField
                        id='description'
                        fullWidth
                        size='small'
                        label='Опис'
                        value={item.description}
                        onChange={handleChangePoint('description', i)}
                      />
                      <Box display={'flex'} gap={2}>
                        <TextField
                          id='horizontally'
                          fullWidth
                          size='small'
                          label='Горизонтально'
                          value={item.horizontally}
                          onChange={handleChangePoint('horizontally', i)}
                        />
                        <TextField
                          id='vertically'
                          fullWidth
                          size='small'
                          label='Вертикально'
                          value={item.vertically}
                          onChange={handleChangePoint('vertically', i)}
                        />
                        <IconButton onClick={() => setDeleteIndex(i)}>
                          <DeleteIcon color='error' />
                        </IconButton>
                      </Box>
                    </Box>
                    {points.length !== i + 1 ? <hr /> : null}
                  </Box>
                );
              })}
              <Button sx={{ mt: 2 }} variant='contained' color='primary' onClick={handleAddPoint}>
                Додати
              </Button>
            </Box>
            <hr />
            <Box pl={2} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
              <div onMouseMove={handleMouseMove}>
                <Box position={'relative'} mb={1} display={'inline-block'}>
                  <img
                    ref={ref}
                    style={{
                      maxWidth: '100vh',
                      maxHeight: 'calc(100vh - 200px)',
                      boxShadow: '0px 0px 16px 0px #00000029',
                      pointerEvents: 'none',
                      userSelect: 'none',
                    }}
                    onLoad={() => {
                      if (ref.current?.clientWidth) {
                        setWidthImage(ref.current.clientWidth);
                      }
                    }}
                    src={preview}
                    alt=''
                  />
                  {preview &&
                    points.map((item, i) => (
                      <Point
                        ref={(e) => (refPoints.current[i] = e)}
                        key={i}
                        position={i + 1}
                        description={item.description}
                        horizontally={item.horizontally}
                        vertically={item.vertically}
                        widthImage={widthImage}
                        isDrag={typeof dragIndex === 'number'}
                        onDrag={handleDrag(i)}
                        onDrop={handleDrop}
                      />
                    ))}
                </Box>
              </div>
              <UploadImage isDelete label='Зображення' value={file} onChange={handleChangeFile} />
            </Box>
          </Box>
        }
      />
      {typeof deleteIndex === 'number' ? (
        <WarningModal
          handleApprove={handleDeletePoint}
          value={`№ ${deleteIndex + 1} підказку`}
          open={typeof deleteIndex === 'number'}
        />
      ) : null}
    </>
  );
};

interface PointProps {
  isDrag: boolean;
  onDrag: () => void;
  onDrop: () => void;
  widthImage: number;
  position: number;
  description?: string | null;
  vertically?: string | number | null;
  horizontally?: string | number | null;
}

const Point = React.forwardRef<HTMLDivElement | null, PointProps>(
  ({ isDrag, widthImage, position, description, horizontally, vertically, onDrag, onDrop }, ref) => {
    const refPoint = React.useRef<HTMLDivElement | null>(null);
    const refPopup = React.useRef<HTMLDivElement | null>(null);

    const koef = widthImage / WIDTH_IMAGE;
    const size = koef * 22;

    const hidePopup = () => {
      if (!refPopup.current) return null;
      refPopup.current.style.display = 'none';
      refPopup.current.style.top = '0';
      refPopup.current.style.left = '0';
    };

    const enterPoint = () => {
      if (!refPoint.current || !refPopup.current || isDrag) return null;
      refPopup.current.style.display = 'block';

      let x = refPoint.current.offsetLeft + refPoint.current.clientWidth;
      let y = refPoint.current.offsetTop + refPoint.current.clientHeight;

      const centerX = refPoint.current.parentElement ? refPoint.current.parentElement?.clientWidth / 2 : 0;
      const centerY = refPoint.current.parentElement ? refPoint.current.parentElement?.clientHeight / 2 : 0;

      if (refPoint.current.offsetLeft > centerX) {
        x = refPoint.current.offsetLeft - refPopup.current.clientWidth;
      }
      if (refPoint.current.offsetTop > centerY && refPoint.current.parentElement) {
        y = refPoint.current.offsetTop - refPopup.current.clientHeight;
      }

      refPopup.current.style.top = `${y}px`;
      refPopup.current.style.left = `${x}px`;
    };

    const onMouseDown = () => {
      onDrag();
      hidePopup();
    };

    return (
      <>
        <div
          ref={(e) => {
            if (typeof ref === 'function') {
              ref(e);
            }
            refPoint.current = e;
          }}
          className='templates-live-sample-item'
          onMouseEnter={enterPoint}
          onMouseLeave={hidePopup}
          onMouseDown={onMouseDown}
          onMouseUp={onDrop}
          style={{
            color: 'red',
            position: 'absolute',
            left: horizontally ? `${Number(horizontally) - 0.3}%` : undefined,
            top: vertically ? `${Number(vertically) - 0.3}%` : undefined,
          }}
        >
          <div style={{ position: 'relative' }}>
            <div
              style={{
                position: 'absolute',
                top: '-4px',
                right: '25px',
                background: '#fff',
                color: ' red',
                padding: '0 3px',
                fontSize: '14px',
                userSelect: 'none',
              }}
            >
              {' '}
              №{position}
            </div>
          </div>
          <div
            className='templates-live-sample-item-point'
            style={{
              width: `${size}px`,
              height: `${size}px`,
            }}
          >
            <div className='templates-live-sample-item-point-center'></div>
          </div>
        </div>
        <div ref={refPopup} className='templates-live-sample-item-description'>
          <span>{description}</span>
        </div>
      </>
    );
  }
);
