import { IRubricItem } from "../rubrics/types";

export interface TDefinitionsState {
  data: { definitions: IDefinition[]; pages: number } | null;
  definitions: IDefinition[] | null;
  pages: number;
  current: IDefinition | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IDefinition {
  id: number;
  rubric_id: number;
  rubricName: string;
  redactor: string;
  site_id: number;
  title: string;
  description: string;
  rubrics: IRubricItem[];
  is_public: number;
}

enum ActionTypes {
  SET_DEFINITIONS = '@@definitions/SET_DEFINITIONS',
  RESET_DEFINITIONS = '@@definitions/RESET_DEFINITIONS',

  GET_DEFINITIONS_R = '@@definitions/GET_DEFINITIONS_R',
  GET_DEFINITIONS_S = '@@definitions/GET_DEFINITIONS_S',
  GET_DEFINITIONS_E = '@@definitions/GET_DEFINITIONS_E',

  GET_DEFINITION_R = '@@definitions/GET_DEFINITION_R',
  GET_DEFINITION_S = '@@definitions/GET_DEFINITION_S',
  GET_DEFINITION_E = '@@definitions/GET_DEFINITION_E',

  SEARCH_DEFINITIONS_R = '@@definitions/SEARCH_DEFINITIONS_R',
  SEARCH_DEFINITIONS_S = '@@definitions/SEARCH_DEFINITIONS_S',
  SEARCH_DEFINITIONS_E = '@@definitions/SEARCH_DEFINITIONS_E',

  CREATE_DEFINITION_R = '@@definitions/CREATE_DEFINITION_R',
  CREATE_DEFINITION_S = '@@definitions/CREATE_DEFINITION_S',
  CREATE_DEFINITION_E = '@@definitions/CREATE_DEFINITION_E',

  UPDATE_DEFINITION_R = '@@definitions/UPDATE_DEFINITION_R',
  UPDATE_DEFINITION_S = '@@definitions/UPDATE_DEFINITION_S',
  UPDATE_DEFINITION_E = '@@definitions/UPDATE_DEFINITION_E',

  DELETE_DEFINITION_R = '@@definitions/DELETE_DEFINITION_R',
  DELETE_DEFINITION_S = '@@definitions/DELETE_DEFINITION_S',
  DELETE_DEFINITION_E = '@@definitions/DELETE_DEFINITION_E',
}

export default ActionTypes;
