import { backlight } from '../components/Editors/EditorDocum/entities/utils';
import { replaceQuotes } from './replaceQuotesParser';
import { Editor } from '@tinymce/tinymce-react';

export const convertSymbols = (editor: Editor['editor'], from: string, to: string) => {
  if (!editor) return;

  let selectedContent = editor.selection.getContent();
  let content = editor.getContent();
  let textContent = editor.selection.getContent({ format: 'text' });
  const firstWord = findFirstWord(textContent) || '';
  const reversedTextContent = reverseSentence(textContent);
  const endWord = findFirstWord(reversedTextContent) || '';
  const lastIndexEnd = selectedContent.lastIndexOf(endWord);
  const start = editor
    .getContent()
    .indexOf(selectedContent.substring(selectedContent.indexOf(firstWord), lastIndexEnd + endWord.length));

  if (start < 0) return;
  const end = selectedContent.includes('<ul>')
    ? start + selectedContent.substring(selectedContent.indexOf(firstWord), lastIndexEnd + endWord.length).length + 10
    : start + selectedContent.substring(selectedContent.indexOf(firstWord), lastIndexEnd + endWord.length).length;

  const substr = content.substring(start, end);

  if (from === '"') {
    const parser = new DOMParser();
    const document = parser.parseFromString(substr, 'text/html');

    document.querySelectorAll('*').forEach(function (paragraph) {
      for (let i = 0; i < paragraph.childNodes.length; i++) {
        const node = paragraph.childNodes[i];
        if (node.nodeName === '#text') {
          node.textContent = replaceQuotes(node.textContent || '');
        }
      }
    });

    const newContent = content.replaceAll(substr, backlight(true, document.body.innerHTML));

    return editor.setContent(
      newContent.replaceAll('&raquo;', '<mark>&raquo;</mark>').replaceAll('&laquo;', '<mark>&laquo;</mark>')
    );
  }

  content =
    from === ' &ndash; '
      ? content.replaceAll(
          substr,
          replaceDash(substr, to).replaceAll(from, ` <mark>${to}</mark> `).replaceAll('&ndash;', ` <mark>${to}</mark> `)
        )
      : content.replaceAll(substr, substr.replaceAll(from, `<mark>${to}</mark>`));

  if (from === "'") {
    return editor.setContent(content.replaceAll('&rsquo;', '<mark>&rsquo;</mark>'));
  }
  if (from === ' &ndash; ') {
    return editor.setContent(content.replaceAll(' &mdash; ', ' <mark>&mdash;</mark> '));
  }
  return editor.setContent(content);
};

const replaceDash = (value = '', to = '&mdash;') => {
  let respValue = value;
  if (!value) return respValue;

  ['-', '‑'].forEach((item) => {
    respValue = respValue
      .replaceAll(`&nbsp;${item} `, ` <mark>${to}</mark> `)
      .replaceAll(`&nbsp;${item}&nbsp;`, ` <mark>${to}</mark> `)
      .replaceAll(` ${item}&nbsp;`, ` <mark>${to}</mark> `)
      .replaceAll(` ${item} `, ` <mark>${to}</mark> `)
      .replaceAll(` ${item}`, ` <mark>${to}</mark> `)
      .replaceAll(`${item} `, ` <mark>${to}</mark> `);
  });

  return respValue;
};

export function findFirstWord(sentence: string) {
  // Удалить начальные пробелы и знаки препинания
  // eslint-disable-next-line
  const cleanedSentence = sentence.replace(/^[.,!?;:(){}\[\]\s]+/, '');

  // Разделить предложение на слова
  const words = cleanedSentence.split(/\s+/);

  // Вернуть первое слово
  if (words.length > 0) {
    return words[0]
      .replaceAll('«', '&laquo;')
      .replaceAll('»', '&raquo;')
      .replaceAll('’', '&rsquo;')
      .replaceAll('—', '&mdash;')
      .replaceAll('“', '&ldquo;')
      .replaceAll('”', '&rdquo;');
  } else {
    return null; // Если предложение пустое
  }
}

export function reverseSentence(sentence: string) {
  // Разделить предложение на слова
  const words = sentence.split(/\s+/);

  // Инвертировать порядок слов
  const reversedWords = words.reverse();

  // Собрать инвертированное предложение
  const reversedSentence = reversedWords.join(' ');

  return reversedSentence;
}
