import React from 'react';
import clsx from 'clsx';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from '@mui/material/MenuItem';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { Styles } from './Styles';
import Paper from '@mui/material/Paper';

const StyleSelect = {
  width: '100%',
  minWidth: '300px',
  maxHeight: '300px',
  overflowX: 'hidden',
  position: 'absolute',
  zIndex: 5,
  boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
};

type PropsType = {
  title?: string;
  value?: string;
  required?: boolean;
  options?: { value: string; label: string }[];
  error?: boolean;
  style?: React.CSSProperties | undefined;
  className?: string;
  onChange: (value: string) => void;
};

export const SelectSearch: React.FC<PropsType> = React.memo(
  ({ style, className, title, required, error, value, options, onChange }) => {
    const [search, setSearch] = React.useState('');
    const [myValue, setMyValue] = React.useState('');
    const [items, setItems] = React.useState<{ value: string; label: string }[] | undefined>([]);
    const [isFocus, setIsFocus] = React.useState(false);
    const { ref } = useOutsideClick(() => setIsFocus(false));
    const classes = Styles();

    React.useEffect(() => {
      if (options) {
        setItems(options);
      }
    }, [options]);

    React.useEffect(() => {
      if (options && value) {
        const item = options.find((it) => it.value === value);
        if (item) {
          setSearch(item.label);
          setMyValue(item.label);
        } else {
          setSearch(value);
          setMyValue(value);
        }
      } else {
        setSearch('');
        setMyValue('');
      }
    }, [options, value]);

    const handleLeaveFocus = () => {
      setIsFocus(!isFocus);
    };

    const setClass = () => {
      if (isFocus) return classes.placeholderFocus;
      if (!!value) return classes.placeholderInit;
    };

    const searchUser = (search: string) => {
      if (!search) {
        onChange(search);
        setMyValue(search);
      }
      setSearch(search);
      if (options) {
        const items = options
          .filter((item) => {
            if (item.label.toLowerCase().includes(search.toLowerCase())) {
              return true;
            }
            return false;
          })
          .sort((a) => (a.label.toLowerCase().includes(search.toLowerCase()) ? -1 : 1));
        setItems(items);
      }
    };

    const handleSelect = (element: { value: string; label: string }) => () => {
      onChange(element.value);
      handleLeaveFocus();
    };

    return (
      <div style={style} className={clsx(classes.root, className)} ref={ref}>
        {title && (
          <div className={clsx(classes.placeholder, setClass())} style={{ color: error && !isFocus ? 'red' : '' }}>
            {required ? `${title} *` : title}
          </div>
        )}
        <div
          className={clsx(classes.wrappField, isFocus && classes.wrappFieldFocus)}
          style={{ borderColor: error && !isFocus ? 'red' : '' }}
          onClick={() => setIsFocus(true)}
        >
          <div className={classes.field}>
            {isFocus ? (
              <input autoFocus className={classes.input} value={search} onChange={(e) => searchUser(e.target.value)} />
            ) : (
              myValue
            )}
          </div>
          <div className={clsx(classes.icon, isFocus && classes.iconFocus)} onClick={handleLeaveFocus}>
            <ArrowDropDownIcon />
          </div>
        </div>
        {isFocus ? (
          <Paper sx={StyleSelect}>
            {items?.map((item) => (
              <MenuItem
                sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line', textAlign: 'left' }}
                key={item.value}
                value={item.value}
                onClick={handleSelect(item)}
              >
                {item.label}
              </MenuItem>
            ))}
          </Paper>
        ) : null}
      </div>
    );
  }
);
