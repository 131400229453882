import { action } from 'typesafe-actions';
import ActionTypes from './types';
import { IProductRatings, TProductRatingsState } from './types';
import { Actions, TDefRequest } from '../Actions';
import { TypeSearchData } from '../../hooks/useSearchAny';

export const setProductRatings = (payload: TProductRatingsState['data']) =>
  action(ActionTypes.SET_PRODUCT_RATINGS, payload);
export const resetProductRatings = () => action(ActionTypes.RESET_PRODUCT_RATINGS, {});

const GetProductRatingsAction = new Actions('GET_PRODUCT_RATINGS', ActionTypes);

export interface TypeGetProductRatingsR extends TDefRequest {
  data: {
    siteId?: number;
    date_at?: string;
    date_to?: string;
    rate?: string;
    page?: string;
    version?: string;
  };
}

export const GetProductRatings = {
  request: (payload: TypeGetProductRatingsR) => GetProductRatingsAction.request(payload),
  success: (payload: TProductRatingsState['data']) => GetProductRatingsAction.success(payload || {}),
  error: (message: string) => GetProductRatingsAction.error(message),
};

const SearchProductRatingsAction = new Actions('SEARCH_PRODUCT_RATINGS', ActionTypes);

export interface TypeSearchProductRatingsS {
  ratings: IProductRatings[];
  pages: number;
}

export interface TypeSearchProductRatingsR extends TDefRequest {
  data: {
    siteId?: number;
    date_at?: string;
    date_to?: string;
    rate?: string;
    page?: string;
    version?: string;
    limit?: number;
  };
}

export const SearchProductRatings = {
  request: (payload: TypeSearchProductRatingsR | TypeSearchData) => SearchProductRatingsAction.request(payload),
  success: (payload: TypeSearchProductRatingsS | null) => SearchProductRatingsAction.success(payload || {}),
  error: (message: string) => SearchProductRatingsAction.error(message),
};
