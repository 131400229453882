import { Box, Button, CircularProgress, CssBaseline, TextField, TextareaAutosize, Typography } from '@mui/material';
import { StylesDetails } from '../../components/Ratings/StylesDetails';
import { blue } from '@mui/material/colors';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { AppStore } from '../../store/applicationState';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { SearchProductRatings } from '../../store/productRatings/actions';

export const RatingProductDetail: React.FC = () => {
  const classes: any = StylesDetails();
  const { Configurations } = useSelector((store: AppStore) => store);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [publicationDetail, setPublicationDetail] = useState<any>(null);

  const location = useLocation();

  const handleNavigate = () => {
    const params = new URLSearchParams(location.search);
    const queryParams = Object.fromEntries(params.entries());

    const queryString: string = Object.keys(queryParams).reduce((acc, key, index) => {
      const separator = index === 0 ? '?' : '&';
      return `${acc}${separator}${key}=${queryParams[key]}`;
    }, '');

    navigate(ROUTES.routeChange(`product-list/${queryString}`));
  };

  useEffect(() => {
    if (id) {
      dispatch(
        SearchProductRatings.request({
          data: {
            id: Number(id),
            siteId: Configurations.siteId,
          },
          callBack: (success, detail) => {
            if (success) setPublicationDetail(detail.productRatings[0]);
          },
        })
      );
    }
  }, [Configurations.siteId, id, dispatch]);

  if (!publicationDetail) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );
  }

  return (
    <Box>
      <CssBaseline />
      <div className={classes.paper}>
        <Box className={classes.rowBeetween}>
          <Typography component='h2' variant='h6'>
            ОЦІНКА ПРОДУКТУ
          </Typography>
          <Button variant='contained' color='inherit' onClick={handleNavigate}>
            Повернутися
          </Button>
        </Box>

        <div className={classes.rowBeetween}>
          <div className={classes.pubId}>
            <div className={classes.row}>
              <h4 className={classes.textFieldTitle}>Оцінка</h4>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                value={publicationDetail?.rate}
                className={classes.textField}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
                disabled
              />
            </div>
          </div>
          <div className={classes.date}>
            <div className={classes.row}>
              <h4 className={classes.textFieldTitle}>Дата</h4>
              <TextField
                size='small'
                InputProps={{
                  style: { color: blue[700] },
                }}
                id='outlined-basic'
                variant='outlined'
                value={format(new Date(publicationDetail?.created_at), 'dd.MM.yyyy')}
                disabled
                className={classes.textField}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
              />
            </div>
          </div>
        </div>

        <div className={classes.row}>
          <h4 className={classes.textFieldTitle}>Відгук</h4>
          <TextareaAutosize
            value={publicationDetail?.comment}
            aria-label='maximum height'
            maxRows={255}
            style={{
              width: '100%',
              minHeight: '40px',
              borderRadius: '3px',
              padding: '8.5px 14px',
              fontSize: '1rem',
              fontFamily: 'inherit',
            }}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
            disabled
          />
        </div>

        <div className={classes.rowBeetween}>
          <div className={classes.pubId}>
            <div className={classes.row}>
              <h4 className={classes.textFieldTitle}>ID користувача</h4>
              <TextField
                size='small'
                InputProps={{
                  style: { color: blue[700], caretColor: 'transparent', outline: 'none' },
                }}
                id='outlined-basic'
                variant='outlined'
                value={publicationDetail?.user_id}
                className={classes.textField}
                disabled
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
              />
            </div>
          </div>
          <div className={classes.date}>
            <div className={classes.row}>
              <h4 className={classes.textFieldTitle}>Версія продукту</h4>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                value={
                  publicationDetail?.version === 0
                    ? 'Standart'
                    : publicationDetail?.version === 2
                    ? 'Plus'
                    : publicationDetail?.version === 1
                    ? 'VIP'
                    : ''
                }
                className={classes.textField}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
                disabled
              />
            </div>
          </div>
        </div>

        <div className={classes.row}>
          <h4 className={classes.textFieldTitle}>ПІБ користувача</h4>
          <TextField
            size='small'
            InputProps={{
              className: classes.userIdInput,
            }}
            id='outlined-basic'
            variant='outlined'
            disabled
            value={publicationDetail?.lname + ' ' + publicationDetail?.fname + ' ' + publicationDetail?.mname}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
          />
        </div>
      </div>
    </Box>
  );
};
