import { put, call, takeEvery, all, fork } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import {
  CreateMainInMonth,
  CreateMainInMonthBlock,
  CreateMainInMonthTemplate,
  DeleteMainInMonth,
  DeleteMainInMonthBlock,
  DeleteMainInMonthTemplate,
  GetMainInMonth,
  GetMainInMonthBlock,
  GetMainInMonthTemplate,
  GetOneMainInMonth,
  TypeCreateMainInMonth,
  TypeCreateMainInMonthBlockR,
  TypeCreateMainInMonthTemplateR,
  TypeDeleteMainInMonth,
  TypeDeleteMainInMonthBlockR,
  TypeDeleteMainInMonthTemplateR,
  TypeGetMainInMonthBlockR,
  TypeGetMainInMonthR,
  TypeGetMainInMonthTemplateR,
  TypeGetOneMainInMonthR,
  TypeUpdateMainInMonthBlockR,
  TypeUpdateMainInMonthTemplateR,
  UpdateMainInMonth,
  UpdateMainInMonthBlock,
  UpdateMainInMonthTemplate,
} from './actions';
import { callApi } from '../../utils/callApi';
import ActionTypes, { IMainInMonthYears, TMainMonthState } from './types';

function* getMainInMonthWorker(action: ReturnType<typeof GetMainInMonth.request>): Generator {
  const { callBack, data } = action.payload as TypeGetMainInMonthR;
  let success = false;
  const query = buildQuery(data);
  try {
    let res: IMainInMonthYears[] | null = null;
    res = (yield call(callApi, {
      method: 'get',
      data,
      path: `/mainInMonth?${query}`,
    })) as IMainInMonthYears[];

    yield put(GetMainInMonth.success(res));
  } catch (e) {
    success = false;
    yield put(GetMainInMonth.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getOneMainInMonthWorker(action: ReturnType<typeof GetOneMainInMonth.request>): Generator {
  const { id, callBack } = action.payload as TypeGetOneMainInMonthR;

  let success = true;
  let resp: IMainInMonthYears | null = null;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: '/mainInMonth/one/' + id,
    })) as IMainInMonthYears;
    yield put(GetOneMainInMonth.success(resp));
  } catch (error) {
    yield put(GetOneMainInMonth.error(error as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* deleteMainInMonthWorker(action: ReturnType<typeof DeleteMainInMonth.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteMainInMonth;

  let success = true;
  let resp: number | null = null;
  try {
    resp = (yield call(callApi, {
      method: 'delete',
      path: '/mainInMonth/one/' + id,
    })) as number;
    yield put(DeleteMainInMonth.success(resp));
  } catch (error) {
    yield put(DeleteMainInMonth.error(error as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* createMainInMonthWorker(action: ReturnType<typeof CreateMainInMonth.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateMainInMonth;

  let success = true;
  let resp = null;

  try {
    resp = (yield call(callApi, {
      method: 'post',
      data: data,
      path: '/mainInMonth/',
    })) as IMainInMonthYears;
    yield put(CreateMainInMonth.success(resp));
  } catch (e: any) {
    success = false;
    resp = e;
    yield put(CreateMainInMonth.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updateMainInMonthWorker(action: ReturnType<typeof UpdateMainInMonth.request>): Generator {
  const { data, callBack, id } = action.payload as TypeCreateMainInMonth;

  let success = true;
  let resp = null;

  try {
    resp = (yield call(callApi, {
      method: 'put',
      data: data,
      path: '/mainInMonth/one/' + id,
    })) as IMainInMonthYears;
    yield put(UpdateMainInMonth.success(resp));
  } catch (e: any) {
    success = false;
    resp = e;
    yield put(UpdateMainInMonth.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* getMainInMonthTemplateWorker(action: ReturnType<typeof GetMainInMonthTemplate.request>): Generator {
  const { id, callBack } = action.payload as TypeGetMainInMonthTemplateR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/mainInMonth/template/${id}`,
    })) as TMainMonthState['template'];
    yield put(GetMainInMonthTemplate.success(resp));
  } catch (e) {
    success = false;
    yield put(GetMainInMonthTemplate.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createMainInMonthTemplateWorker(action: ReturnType<typeof CreateMainInMonthTemplate.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateMainInMonthTemplateR;

  let success = true;
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/mainInMonth/template`,
    })) as TMainMonthState['template'];
    yield put(CreateMainInMonthTemplate.success(resp));
  } catch (e) {
    success = false;
    yield put(CreateMainInMonthTemplate.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updateMainInMonthTemplateWorker(action: ReturnType<typeof UpdateMainInMonthTemplate.request>): Generator {
  const { id, data, callBack } = action.payload as TypeUpdateMainInMonthTemplateR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      data,
      path: `/mainInMonth/template/${id}`,
    })) as TMainMonthState['template'];
    yield put(UpdateMainInMonthTemplate.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateMainInMonthTemplate.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteMainInMonthTemplateWorker(action: ReturnType<typeof DeleteMainInMonthTemplate.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteMainInMonthTemplateR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/mainInMonth/template/${id}`,
    })) as TMainMonthState['template'];
    yield put(DeleteMainInMonthTemplate.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteMainInMonthTemplate.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getMainInMonthBlockWorker(action: ReturnType<typeof GetMainInMonthBlock.request>): Generator {
  const { id, callBack } = action.payload as TypeGetMainInMonthBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/mainInMonth/block/${id}`,
    })) as TMainMonthState['block'];
    yield put(GetMainInMonthBlock.success(resp));
  } catch (e) {
    success = false;
    yield put(GetMainInMonthBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createMainInMonthBlockWorker(action: ReturnType<typeof CreateMainInMonthBlock.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateMainInMonthBlockR;

  let success = true;
  let error = '';
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/mainInMonth/block`,
    })) as TMainMonthState['block'];
    yield put(CreateMainInMonthBlock.success(resp));
  } catch (e) {
    success = false;
    error = e as string;
    yield put(CreateMainInMonthBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* updateMainInMonthBlockWorker(action: ReturnType<typeof UpdateMainInMonthBlock.request>): Generator {
  const { id, data, callBack } = action.payload as TypeUpdateMainInMonthBlockR;

  let success = true;
  let error = '';
  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      data,
      path: `/mainInMonth/block/${id}`,
    })) as TMainMonthState['block'];
    yield put(UpdateMainInMonthBlock.success(resp));
  } catch (e) {
    success = false;
    error = e as string;
    yield put(UpdateMainInMonthBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* deleteMainInMonthBlockWorker(action: ReturnType<typeof DeleteMainInMonthBlock.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteMainInMonthBlockR;

  let success = true;
  let error = '';
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/mainInMonth/block/${id}`,
    })) as TMainMonthState['block'];
    yield put(DeleteMainInMonthBlock.success(resp));
  } catch (e) {
    success = false;
    error = e as string;
    yield put(DeleteMainInMonthBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_MAIN_IN_MONTH_R, getMainInMonthWorker);
  yield takeEvery(ActionTypes.GET_ONE_MAIN_IN_MONTH_R, getOneMainInMonthWorker);
  yield takeEvery(ActionTypes.CREATE_MAIN_IN_MONTH_R, createMainInMonthWorker);
  yield takeEvery(ActionTypes.DELETE_MAIN_IN_MONTH_R, deleteMainInMonthWorker);
  yield takeEvery(ActionTypes.UPDATE_MAIN_IN_MONTH_R, updateMainInMonthWorker);
  yield takeEvery(ActionTypes.GET_TEMPLATE_R, getMainInMonthTemplateWorker);
  yield takeEvery(ActionTypes.CREATE_TEMPLATE_R, createMainInMonthTemplateWorker);
  yield takeEvery(ActionTypes.UPDATE_TEMPLATE_R, updateMainInMonthTemplateWorker);
  yield takeEvery(ActionTypes.DELETE_TEMPLATE_R, deleteMainInMonthTemplateWorker);
  yield takeEvery(ActionTypes.GET_BLOCK_R, getMainInMonthBlockWorker);
  yield takeEvery(ActionTypes.CREATE_BLOCK_R, createMainInMonthBlockWorker);
  yield takeEvery(ActionTypes.UPDATE_BLOCK_R, updateMainInMonthBlockWorker);
  yield takeEvery(ActionTypes.DELETE_BLOCK_R, deleteMainInMonthBlockWorker);
}

export default function* MainInMonthSaga() {
  yield all([fork(watchFetchRequest)]);
}
