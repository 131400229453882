import Modal from '@mui/material/Modal';
import React from 'react';
import Button from '@mui/material/Button';
import './BoxModal.scss';
import { useStyles } from './styles';

interface BoxModalProps {
  open: boolean;
  value: string;
  btnConfirm?: string;
  type?: 'restore' | 'delete' | 'comeback';
  handleApprove: (isApprove: boolean) => void;
}

export const WarningModal: React.FC<BoxModalProps> = ({ btnConfirm, open, value, handleApprove, type = 'delete' }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={() => handleApprove(false)}
      className={classes.modal}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <div className='boxModal-inner' style={{ minWidth: '300px' }}>
        <div className='boxBody' style={{ textAlign: 'center' }}>
          <h2>
            {type === 'restore'
              ? `Ви дійсно хочете відновити ${value} ?`
              : type === 'delete'
              ? `Ви дійсно хочете видалити ${value} ?`
              : value}
          </h2>
        </div>
        <div className='boxFooter'>
          <div style={{ display: 'flex' }}>
            <Button color='primary' variant='contained' onClick={() => handleApprove(true)}>
              {btnConfirm || 'так'}
            </Button>
          </div>
          <Button color='inherit' variant='contained' onClick={() => handleApprove(false)}>
            скасувати
          </Button>
        </div>
      </div>
    </Modal>
  );
};
