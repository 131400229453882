import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { StylesTable } from '../Stylestable';
import { SearchDocs, setEditDoc, setSearchData } from '../../store/officialDoc/actions';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { BasicDatePicker, SelectOutline } from '../ui';
import moment from 'moment';
import { EditNormativka } from './EditNormativka';
import { colorsStatus } from '../../utils/consts';
import { useSearchAny } from '../../hooks/useSearchAny';
import { IOfficialDoc, TOfficialDocState } from '../../store/officialDoc/types';

interface Column {
  id: 'id' | 'name' | 'type' | 'approved' | 'docDate' | 'docNum' | 'update' | 'redactions' | 'delete' | 'edit';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '5%' },
  { id: 'type', label: 'Назва', align: 'left' },
  { id: 'name', label: 'Тип', align: 'center', width: '10%' },
  { id: 'approved', label: 'Статус', align: 'center' },
  {
    id: 'docDate',
    label: 'Дата документа',
    align: 'center',
  },
  {
    id: 'docNum',
    label: 'Номер документа',
    align: 'center',
  },
  {
    id: 'update',
    label: 'Дата змін',
    align: 'center',
  },
  {
    id: 'edit',
    label: 'Документ',
    align: 'center',
    width: '15%',
  },
];

type PropsType = {};

export const ListNormativka: React.FC<PropsType> = () => {
  const [id, setId] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [typeId, setTypeId] = React.useState('');
  const [docDate, setDocDate] = React.useState<Date | null>(null);
  const [docNum, setDocNum] = React.useState('');

  const { OfficialDoc, Sidebar } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classesTable = StylesTable();

  const { loading, handleSearch } = useSearchAny({
    SearchData: SearchDocs,
  });

  const obj = {
    title,
    docDate: docDate ? moment(docDate).format('YYYY-MM-DD HH:mm') : '',
    typeId,
    docNum,
  };

  const resetList = React.useCallback(
    (data: TOfficialDocState['data']) => {
      dispatch(setSearchData(data));
    },
    [dispatch]
  );

  React.useEffect(() => {
    resetList(OfficialDoc.data);
  }, [resetList, OfficialDoc.data]);

  React.useEffect(() => {
    if (Sidebar.page.type === 'main' || Sidebar.page.type === 'list') {
      dispatch(setEditDoc(null));
    }
  }, [dispatch, Sidebar.page]);

  const clearFiltres = () => {
    setId('');
    setTitle('');
    setTypeId('');
    setDocDate(null);
    setDocNum('');
    resetList(OfficialDoc.data);
  };

  if (OfficialDoc.editData && OfficialDoc.editData.data) {
    return (
      <EditNormativka
        data={OfficialDoc.editData.data as IOfficialDoc}
        handleCancel={() => dispatch(setEditDoc(null))}
      />
    );
  }

  return (
    <>
      <Box className={classesTable.header} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <TextField
          size='small'
          id='outlined-basic-id'
          label='Пошук по ID'
          variant='outlined'
          value={id}
          className={classesTable.searchField}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            const num = event.target.value.replace(/[^0-9.]/g, '');
            setId(num);
            handleSearch(num, 'id');
          }}
        />
        <TextField
          className={classesTable.searchField}
          size='small'
          id='outlined-basic-title'
          label='Назва'
          variant='outlined'
          value={title}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setTitle(event.target.value);
            handleSearch(event.target.value, 'title', obj);
          }}
        />
        <SelectOutline
          style={{ width: '210px' }}
          id='outlined-basic-type'
          title='Тип документа'
          value={typeId}
          className={classesTable.searchField}
          options={OfficialDoc.typesDoc?.map((it) => ({ value: String(it.id), label: it.name })) || []}
          handleChange={(value) => {
            setTypeId(value);
            handleSearch(value, 'typeId', obj);
          }}
        />
        <div className={classesTable.searchField} style={{ width: '210px' }}>
          <BasicDatePicker
            label='Дата документа'
            value={docDate}
            onChange={(value) => {
              setDocDate(value);
              handleSearch(value ? moment(value).format('YYYY-MM-DD HH:mm') : '', 'docDate', obj);
            }}
          />
        </div>
        <TextField
          className={classesTable.searchField}
          size='small'
          id='outlined-basic-title'
          label='Номер'
          variant='outlined'
          value={docNum}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setDocNum(event.target.value);
            handleSearch(event.target.value, 'docNum', obj);
          }}
        />
        <Button className={classesTable.searchField} variant='contained' color='primary' onClick={clearFiltres}>
          Очистити фільтри
        </Button>
        {OfficialDoc.loading || loading ? <CircularProgress size={25} /> : null}
      </Box>
      <TableContainer className={classesTable.container} style={{ maxHeight: 'calc(100vh - 295px)' }}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {OfficialDoc.searchData?.officialDoc?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>{row.id}</TableCell>
                  <TableCell className={classesTable.row}>{row.title}</TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    {row.type?.name || ''}
                  </TableCell>
                  <TableCell className={classesTable.row} align='center' style={{ color: colorsStatus[row.approved] }}>
                    {OfficialDoc.status.approved[row.approved]}
                  </TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    {moment(row.doc_date).format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    {row.doc_num}
                  </TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    {moment(row.update).format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                  <TableCell align='center'>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => dispatch(setEditDoc({ type: 'edit', data: row }))}
                    >
                      Переглянути
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
