import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import ukLocale from 'date-fns/locale/uk';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CalendarPickerView } from '@mui/lab';

interface PropsType {
  id?: string;
  label?: string;
  value: Date | null;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  views?: CalendarPickerView[];
  style?: React.CSSProperties | undefined;
  onChange: (value: Date | null) => void;
  format?: string;
}

export const BasicDatePicker: React.FC<PropsType> = ({
  id,
  value,
  required,
  onChange,
  label,
  style,
  error,
  disabled,
  views,
  format,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
      <DatePicker
        mask='__.__.____'
        inputFormat={format || 'dd.MM.yyyy'}
        label={label}
        views={Array.isArray(views) && views?.length ? views : ['year', 'month', 'day']}
        value={value}
        onChange={onChange}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            disabled={disabled}
            id={id}
            style={style}
            fullWidth
            required={required}
            size='small'
            {...params}
            error={error}
          />
        )}
      />
    </LocalizationProvider>
  );
};
