import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 128px)',
  },
  editor: {
    position: 'relative',
  },
  pagination: {
    margin: 'auto',
    width: '100%',
    position: 'absolute',
    bottom: '5px',
    display: 'flex',
    justifyContent: 'center',
  },
  item: {
    cursor: 'pointer',
    color: 'gray',
    '&:hover': {
      color: '#3f51b5',
    },
  },

  meaning: {
    maxHeight: 'calc(100vh - 189px)',
    overflow: 'auto',
    width: '200px',
    marginRight: '20px',
    listStyleType: 'none',
    padding: 0,
  },
  itemPage: {
    fontSize: '15px',
    fontWeight: 400,
    cursor: 'pointer',
    '&:hover': {
      color: '#3f51b5',
    },
  },
  itemUl: {},
  itemIi: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: 'grey',
    padding: '2px 0',
    cursor: 'pointer',
    '&:hover': {
      color: '#3f51b5',
    },
  },
  definition: {
    color: '#482BD9',
    cursor: 'pointer',
    border: 'solid red 1xp',
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
  },
}));
