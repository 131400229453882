import React from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { replacePath } from '../../utils/replacePath';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';

type PropsType = {
  label?: string;
  value?: File | string;
  required?: boolean;
  error?: boolean;
  style?: React.CSSProperties | undefined;
  className?: string;
  isDelete?: boolean;
  onChange: (file: File | string) => void;
};

export const UploadImage: React.FC<PropsType> = ({
  style,
  className,
  label,
  required,
  error,
  value,
  isDelete,
  onChange,
}) => {
  const fileName = typeof value === 'string' ? replacePath(value) : value ? value.name : '';

  const handleUpload = () => {
    const input = document.createElement('input');
    input.accept = 'image/*';
    input.type = 'file';
    input.onchange = function () {
      const files = (this as any).files as null | File[];
      const file = files ? files[0] : null;
      if (file && file.type.includes('image')) {
        onChange(file);
      }
    };
    input.click();
  };

  const handleDelete = () => {
    onChange('');
  };

  return (
    <FormControl size='small' sx={{ m: 1, width: '100%' }} variant='outlined'>
      <InputLabel htmlFor='outlined-adornment-password'>{label}</InputLabel>
      <OutlinedInput
        id='outlined-adornment-password'
        size='small'
        fullWidth
        label={label}
        value={fileName}
        required={required}
        error={error}
        disabled
        style={style}
        className={className}
        endAdornment={
          <>
            <InputAdornment position='end'>
              <IconButton aria-label='toggle password visibility' edge='end' onClick={handleUpload}>
                <UploadFileIcon color='primary' />
              </IconButton>
            </InputAdornment>
            {isDelete && (
              <InputAdornment position='end'>
                <IconButton aria-label='toggle password visibility' edge='end' onClick={handleDelete}>
                  <DeleteIcon color='error' />
                </IconButton>
              </InputAdornment>
            )}
          </>
        }
      />
    </FormControl>
  );
};
