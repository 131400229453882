import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import {
  GetProductRatings,
  SearchProductRatings,
  TypeGetProductRatingsR,
  TypeSearchProductRatingsR,
  TypeSearchProductRatingsS,
} from './actions';
import ActionTypes, { TProductRatingsState } from './types';
import { callApi } from '../../utils/callApi';

function* getProductRatingsWorker(action: ReturnType<typeof GetProductRatings.request>): Generator {
  const { data, callBack } = action.payload as TypeGetProductRatingsR;
  let success = true;
  const query = buildQuery(data);
  let resp: TProductRatingsState['data'] | null = null;

  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/product-ratings?${query}`,
    })) as TProductRatingsState['data'];
    yield put(GetProductRatings.success(resp));
  } catch (e) {
    success = false;
    yield put(GetProductRatings.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* searchProductRatingsWorker(action: ReturnType<typeof SearchProductRatings.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchProductRatingsR;

  let success = true;
  const query = buildQuery(data);
  let resp: TypeSearchProductRatingsS | null = null;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/product-ratings?${query}`,
    })) as TypeSearchProductRatingsS;
    yield put(SearchProductRatings.success(resp ? resp : null));
  } catch (e) {
    success = false;
    yield put(SearchProductRatings.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}


function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_PRODUCT_RATINGS_R, getProductRatingsWorker);
  yield takeEvery(ActionTypes.SEARCH_PRODUCT_RATINGS_R, searchProductRatingsWorker);
}

export default function* productRatingsSaga() {
  yield all([fork(watchFetchRequest)]);
}
