import { Editor } from '@tinymce/tinymce-react';

export const hideAnchor = (editor?: Editor['editor'] | null) => {
  if (!editor) return null;

  const body = editor.getBody();
  const nodes: Node[] = [];
  body.querySelectorAll('[id]').forEach((item) => nodes.push(item));

  const element = body.querySelector('.anchor');
  if (element) {
    editor.dom.removeClass(nodes, 'anchor');
  }
};
