import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import { AppStore } from '../../store/applicationState';
import { StylesTable } from '../../components/Stylestable';
import { DeleteService, GetServices, UpdatePosition } from '../../store/services/actions';
import DoneIcon from '@mui/icons-material/Done';
import IconButton from '@mui/material/IconButton/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { WarningModal } from '../../components/modals';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import { useSearchAny } from '../../hooks/useSearchAny';
import CircularProgress from '@mui/material/CircularProgress';
import { ROUTES } from '../../utils/routes';
import Link from '@mui/material/Link';

interface Column {
  id: 'id' | 'title' | 'url' | 'rubric' | 'rubrics' | 'search_tags' | 'is_public' | 'author_id' | 'delete';
  label: string;
  width?: string;
  align?: 'right' | 'left' | 'center';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: '№', width: '100px', align: 'center' },
  { id: 'title', label: 'Назва' },
  { id: 'author_id', label: 'Редактор' },
  { id: 'is_public', label: 'Публікція', width: '100px', align: 'center' },
  { id: 'url', label: 'URL' },
  { id: 'rubric', label: 'Рубрика' },
  { id: 'rubrics', label: 'Додатк. Рубрики', align: 'left' },
  { id: 'delete', label: 'Видалити', width: '100px', align: 'center' },
];

type PropsType = {};

export const ListServices: React.FC<PropsType> = () => {
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const [title, setTitle] = React.useState('');
  const [url, setUrl] = React.useState('');
  const [draggIndex, setDraggIndex] = React.useState<number | null>(null);
  const [hoverIndex, setHoverIndex] = React.useState<number | null>(null);
  const [loadingDrag, setLoadingDrag] = React.useState(false);

  const { Configurations, Services } = useSelector((store: AppStore) => store);
  const classes = StylesTable();
  const dispatch = useDispatch();

  const { loading, handleSearch } = useSearchAny({
    SearchData: GetServices,
  });

  const getServices = React.useCallback(
    (siteId: number) => {
      dispatch(
        GetServices.request({
          data: {
            siteId,
          },
        })
      );
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (Configurations.siteId) {
      getServices(Configurations.siteId);
    }
  }, [getServices, Configurations.siteId]);

  const handleClearFilters = () => {
    setTitle('');
    setUrl('');
    if (Configurations.siteId) {
      getServices(Configurations.siteId);
    }
  };

  const handleRemove = (id: number) => (isApprove: boolean) => {
    if (isApprove) {
      dispatch(
        DeleteService.request({
          id,
        })
      );
    }
    setIsModal(null);
  };

  const handleChange =
    (query: string, callBack: (value: string) => void) => (event: React.ChangeEvent<{ value: string }>) => {
      const value = event.target.value;
      const data = {
        title,
        url,
        siteId: String(Configurations.siteId),
      };
      handleSearch(value, query, data);
      callBack(value);
    };

  const handleChangeRoute =
    (path = '' as string | number) =>
    () => {
      window.open(ROUTES.routeChange(`list/${path}?site_id=${Configurations.siteId}`));
    };

  const handleDraggEnd = () => {
    if (Services.services && draggIndex !== null && hoverIndex !== null) {
      const item = Services.services[draggIndex];

      setLoadingDrag(true);

      dispatch(
        UpdatePosition.request({
          data: {
            id: item.id,
            position: hoverIndex,
          },
          callBack: () => {
            setDraggIndex(null);
            setHoverIndex(null);
            setLoadingDrag(false);
          },
        })
      );
    }
  };

  return (
    <>
      <Box className={classes.header}>
        <Button className={classes.searchField} variant='contained' color='primary' onClick={handleChangeRoute('new')}>
          Додати сервіс
        </Button>
        <TextField
          size='small'
          id='outlined-basic-title'
          label='Пошук по заголовку'
          variant='outlined'
          value={title}
          className={classes.searchField}
          onChange={handleChange('title', setTitle)}
        />
        <TextField
          className={classes.searchField}
          size='small'
          id='outlined-basic-url'
          label='URL'
          variant='outlined'
          value={url}
          onChange={handleChange('url', setUrl)}
        />
        <Button
          disabled={loading || loadingDrag}
          className={classes.searchField}
          variant='contained'
          color='primary'
          onClick={handleClearFilters}
        >
          {loading ? <CircularProgress color='inherit' size={25} /> : 'Очистити фільтри'}
        </Button>
      </Box>
      <TableContainer className={classes.container} style={{ maxHeight: 'calc(100vh - 250px)' }}>
        <Table className={classes.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Services.services?.map((row, i) => {
              return (
                <TableRow
                  style={{
                    cursor: 'grab',
                    backgroundColor: i === hoverIndex ? 'lightgrey' : '',
                    maxHeight: 50,
                  }}
                  hover
                  role='checkbox'
                  tabIndex={-1}
                  key={row.id}
                  draggable={!loading}
                  onDragOver={() => setHoverIndex(i)}
                  onDragEnd={() => handleDraggEnd()}
                  onDrag={() => !draggIndex && setDraggIndex(i)}
                >
                  <TableCell align='center'>
                    <Link sx={{ cursor: 'pointer' }} onClick={handleChangeRoute(row.id)}>
                      {row.id}
                    </Link>
                  </TableCell>
                  <TableCell className={classes.row}>{row.title}</TableCell>
                  <TableCell className={classes.row}>{row.author?.fio || ''}</TableCell>
                  <TableCell align='center' className={classes.row}>
                    {row.is_public ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell className={classes.row}>
                    <a href={row.url} rel='noreferrer' target='_blank'>
                      {row.url}
                    </a>
                  </TableCell>
                  <TableCell className={classes.row}>{row.rubric?.name || ''}</TableCell>
                  <TableCell className={classes.row}>
                    {row.service_rubric_details?.length > 0
                      ? row.service_rubric_details.map((item, idx) => (
                          <div key={`${i}-${idx}-${item.rubric?.id}`}>
                            <span>{item.rubric?.name || ''}</span>
                            <br />
                          </div>
                        ))
                      : ''}
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton
                      disabled={loading || loadingDrag}
                      aria-label='delete'
                      onClick={() => setIsModal({ id: row.id })}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isModal ? (
        <WarningModal handleApprove={handleRemove(isModal.id)} value={`№ ${isModal.id}`} open={!!isModal} />
      ) : null}
    </>
  );
};
