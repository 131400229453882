import { Editor } from '@tinymce/tinymce-react';
import { findNearestAncestorWithClassName } from '../template/helpers/findNearestAncestorWithClassName';

export const copyTemplateFile = (editor: Editor['editor']) => () => {
  if (!editor) return null;

  const node = editor.selection.getNode();
  if (!node) return null;

  const parent = findNearestAncestorWithClassName(node, ['link-download']);
  if (!parent) return null;

  const type = 'text/html';
  const blob = new Blob([parent.outerHTML], { type });
  const data = [new ClipboardItem({ [type]: blob })];

  navigator.clipboard.write(data);

  document.querySelector('.tox-pop')?.remove();
};
