import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { Alert, AlertTitle } from '@mui/lab';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { SelectSearchReq, UploadFile } from '../../ui';
import { EditorDefault } from '../../Editors';
import { StylesEdit } from '../../StylesEdit';
import { IBlock } from '../../../store/officialDocBlocks/types';
import { UpdateBlockMonth } from '../../../store/officialDocBlocks/actions';
import { useSearchOfficialDoc } from '../../../hooks/useSearchOfficialDoc';

interface PropsType {
  block: IBlock;
  handleCancel: () => void;
}

export const BlockMonth: React.FC<PropsType> = ({ block, handleCancel }) => {
  const [doc, setDoc] = React.useState(block.docId ? { id: block.docId, label: block.docName } : null);
  const [name, setName] = React.useState<string>(block?.name || '');
  const [title, setTitle] = React.useState<string>(block?.title || '');
  const [image, setImage] = React.useState<File | string>(block?.image || '');
  const [error, setError] = React.useState(false);
  const [errors, setErrors] = React.useState('');

  const editorRef = React.useRef<any | null>(null);
  const { options, officialDoc, handleSearch } = useSearchOfficialDoc();

  const { OfficialDocBlocks } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classes = StylesEdit();

  const onSubmit = () => {
    const description = editorRef.current?.getContent();

    if (!name || !title || !description || !image || !block || !doc) return setError(true);
    setError(false);
    setErrors('');
    const fd = new FormData();
    fd.append('name', name);
    fd.append('title', title);
    fd.append('description', description);
    fd.append('image', image);
    fd.append('docId', String(doc.id));

    dispatch(
      UpdateBlockMonth.request({
        data: fd,
        id: block.id,
        callBack: (success) => {
          if (!success) {
            setErrors('Сталася помилка');
          }
          if (success) {
            handleCancel();
          }
        },
      })
    );
  };

  return (
    <Box>
      <CssBaseline />
      <div className={classes.paper}>
        <Box className={classes.header}>
          <Typography component='h1' variant='h5'>
            Редагувати блок
          </Typography>
        </Box>
        <TextField
          size='small'
          id='outlined-basic-name'
          label='Назва блоку'
          variant='outlined'
          value={name}
          error={error && !name}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setName(event.target.value as string);
          }}
        />
        <TextField
          required
          size='small'
          id='outlined-basic-title'
          label='Заголовок'
          variant='outlined'
          value={title}
          error={error && !title}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setTitle(event.target.value as string);
          }}
        />
        <div style={{ marginTop: '20px' }}>
          <EditorDefault placeholder='Опис' editorRef={editorRef} initialValue={block?.description} />
        </div>
        <UploadFile
          required
          isImg
          accept='image/*'
          id='img-file'
          title='Зображення'
          value={image}
          error={error && !image}
          className={classes.textField}
          onChange={setImage}
          hint='Ширина 400px'
        />
        <SelectSearchReq
          style={{ marginTop: '20px' }}
          title='Документ'
          data={doc}
          value={officialDoc.find((it) => it.id === doc?.id)?.title || ''}
          className={classes.textField}
          onChange={setDoc}
          options={options}
          error={error && !doc}
          handleRequest={handleSearch}
        />
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={OfficialDocBlocks.loading} onClick={onSubmit}>
            {OfficialDocBlocks.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {errors ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>{errors}</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
