import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { CreateUser as ActionCreateUser, TypeCreateUserR } from '../../store/users/actions';
import { ConfigurationsActions } from '../../store/configurations/actions';
import { UserForm } from '../../components/UserForm';
import { AppStore } from '../../store/applicationState';
import { Roles } from '../../utils/consts';

export const CreateUser: React.FC = () => {
  const { Users, User, Configurations } = useSelector((store: AppStore) => store);
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const query = new URLSearchParams(search);
    const siteId = query.get('site_id');
    if (siteId) {
      dispatch(ConfigurationsActions.setSiteId(Number(siteId)));
    }
  }, [dispatch, search]);

  const handleCreate = (data: TypeCreateUserR['data']) => {
    dispatch(
      ActionCreateUser.request({
        data,
        callBack: (success, dta) => {
          if (success && dta) {
            navigate(ROUTES.routeChange(`list/${dta?.id || ''}`));
          }
        },
      })
    );
  };

  if (User.data?.role !== Roles.ADMIN) return null;

  return (
    <UserForm
      loading={Users.loading}
      error={Users.errors}
      systems={Configurations.sites}
      type='new'
      handleSubmit={handleCreate}
      handleCancel={() => navigate(ROUTES.routeChange('list'))}
    />
  );
};
