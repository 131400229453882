import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import mammoth from 'mammoth';
import Pagination from '@mui/material/Pagination';
import { CDN_URL, URI, configSites } from '../../../config';
import { useStyles } from './styles';
import { transformElement } from './entities/textTransform';
import { ACCEPT, anchorIcon, HEIGHT } from './entities/consts';
import {
  backlight,
  callTimeout,
  defineMeaning,
  defineScrollDirection,
  handleConvertSymbol,
  handleScrollMeaning,
  handleSelectedMeaning,
  parseHTML,
  setUuid,
  uuid,
} from './entities/utils';
import { callApi } from '../../../utils/callApi';
import { IMeaning } from './entities/EditorTypes';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { changeCellBackgroundColor, changeDash, changePositionTable, changeQuotes, showAnchor } from '../commands';

interface EditorProps {
  editorRef?: React.MutableRefObject<Editor['editor'] | null>;
  initialValue?: string;
  docId?: number;
}

export const EditorDocum: React.FC<EditorProps> = ({ editorRef, initialValue, docId }) => {
  const [pages, setPages] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [isScroll, setIsScroll] = React.useState(false);
  const [paragraphId, setParagraphId] = React.useState('');
  const [selectedMeaningId, setSelectedMeaningId] = React.useState<string>('');
  const [prevScrollY, setPrevScrollY] = useState<number>(0);
  const [scrollDirection, setScrollDirection] = useState<'down' | 'up'>('down');
  const [initVal, setInitVal] = useState(initialValue);

  const meaningRef = React.useRef<IMeaning[]>([]);

  const docIdRef = React.useRef({ docId: 0 });
  const classes: any = useStyles();
  const emblem =
    '<p><img ' +
    `src="${CDN_URL.trim()}/media/images/ukraine.jpg"` +
    'width="80" ' +
    'height="94"' +
    ' alt="" ' +
    'style="display: block; ' +
    'margin-left: auto; ' +
    'margin-right: auto;" ' +
    '/></p>';

  const [showActiveQuotes, setShowActiveQuotes] = useState(false);

  React.useEffect(() => {
    if (docIdRef.current && docId) {
      docIdRef.current.docId = docId;
    }
  }, [docId]);

  React.useEffect(() => {
    if (initialValue) {
      const data = defineMeaning(initialValue);
      meaningRef.current = data;
    }
    setInitVal(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    setSelectedMeaningId(paragraphId);
  }, [paragraphId, editorRef]);

  React.useEffect(() => {
    if (page && isScroll) {
      const top = (page - 1) * 870;
      //@ts-ignore
      editorRef.current?.getWin().scrollTo({ top });
      setIsScroll(false);
    }
  }, [page, isScroll, editorRef]);

  // eslint-disable-next-line
  const debounceSave = React.useCallback(
    callTimeout(() => {
      const value = editorRef?.current?.getContent();
      if (value) {
        const meaning = defineMeaning(value);
        meaningRef.current = meaning;
      }
    }, 1000),
    []
  );

  const handleChangePage = (page: number) => {
    setPage(page);
    setIsScroll(true);
  };

  const handleQuotesClick = (e: any) => {
    e.stopPropagation();
    changeQuotes(editorRef?.current);
    setShowActiveQuotes(true);
  };

  const handleDashClick = (e: any) => {
    e.stopPropagation();
    changeDash(editorRef?.current);
    setShowActiveQuotes(true);
  };

  const handleSymbolClick = (symbol: '-' | "'") => {
    handleConvertSymbol(editorRef, setShowActiveQuotes, symbol);
  };

  const defineScrollDirectionHandler = defineScrollDirection(prevScrollY, setPrevScrollY, setScrollDirection);

  useEffect(() => {
    editorRef?.current?.on('ScrollContent', defineScrollDirectionHandler);
    return () => {
      editorRef?.current?.off('ScrollContent', defineScrollDirectionHandler);
    };
  }, [defineScrollDirectionHandler, editorRef]);

  const handleWindowClick = (e: any) => {
    const content = editorRef?.current?.getContent();
    const tempElement = document.createElement('html');
    tempElement.innerHTML = content || '';
    if (!showActiveQuotes && content?.includes('<mark>')) {
      const body = backlight(false, tempElement.innerHTML);
      const editor = editorRef?.current;
      if (editor) editor.setContent(body);
    } else {
      setShowActiveQuotes(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  });

  return (
    <div className={classes.root}>
      <Meaning
        meaning={meaningRef.current}
        setParagraphId={setParagraphId}
        selectedMeaning={selectedMeaningId}
        scrollDirection={scrollDirection}
        editorRef={editorRef}
      />
      <div className={classes.editor}>
        <div
          className='tox-tbtn apostrophe'
          onClick={(e) => {
            e.stopPropagation();
            handleSymbolClick("'");
          }}
        >
          {String.fromCharCode(0x2019)}
        </div>
        <div className='tox-tbtn tire' onClick={handleDashClick}>
          <HorizontalRuleOutlinedIcon />
        </div>
        <div className='tox-tbtn quote' onClick={handleQuotesClick}>
          <KeyboardDoubleArrowLeftOutlinedIcon style={{ fontSize: '12px' }} />
          <KeyboardDoubleArrowRightOutlinedIcon style={{ fontSize: '12px' }} />
        </div>
        <Editor
          onInit={(evt, editor) => editorRef && (editorRef.current = editor)}
          apiKey='uzm54hzb90l1dcm92ia4tfjp6aj0krmgjak7eof1jhyd17pv'
          initialValue={backlight(false, initVal) ? backlight(false, initVal) : emblem}
          init={{
            convert_urls: true,
            urlconverter_callback: function (url, node, on_save, name) {
              const regexName = /\b(\w*\.expertus\.com\.ua)\/law\w*\b/g;
              const regexInt = /^\d+/g;
              if (url.match(regexName)) {
                url = url.replace(
                  // eslint-disable-next-line
                  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?/gm,
                  ''
                );
                url = url.replace(/\/laws\//gm, '/law/');
              } else if (url.match(regexInt)) {
                url = `/law/${url}`;
              }

              return url;
            },
            link_context_toolbar: true,
            target_list: [
              { title: 'Нова сторінка', value: '_blank' },
              { title: 'Та сама сторінка', value: '_self' },
            ],
            link_default_protocol: 'https',
            language: 'uk',
            height: '100%',
            width: '900px',
            plugins:
              'powerpaste advcode visualblocks image link codesample table charmap hr lists checklist ' +
              'imagetools advtable template lists fullscreen casechange searchreplace',
            toolbar:
              'undo redo | code | blocks | styleselect | searchreplace | ' +
              'bold italic underline casechange | alignleft aligncenter alignright alignjustify | ' +
              'charmap | forecolor backcolor | link imageoptions | anchorbutton | alignleft aligncenter alignright alignjustify' +
              ' | indent outdent | table tableinsertdialog tablecellprops tableprops advtablerownumbering ' +
              '| fullscreen',
            table_toolbar:
              'tableprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | ' +
              'tableinsertcolbefore tableinsertcolafter tabledeletecol | tabledelete | cellBackgroundColor | alignmentLeft alignmentRight',
            menu: {
              custom: {
                title: 'Завантажити',
                items: 'basicItem | basicItemPart',
              },
            },
            menubar: 'insert format table custom',
            quickbars_selection_toolbar: false,
            verify_html: true,
            relative_urls: false,
            extended_valid_elements: 'a[!href|rel|target|class]',
            forced_root_block: 'p',
            style_formats: [
              { title: 'Абзац', block: 'p' },
              { title: 'Заголовок', block: 'h2' },
              { title: 'Підзаголовок', block: 'h3' },
              { title: 'Шапка документа', block: 'span', classes: 'head-doc' },
              { title: 'Примітки', block: 'span', classes: 'notes' },
            ],
            table_class_list: [
              { title: 'По дефолту', value: ' ' },
              { title: 'Суцільна межа', value: 'table-solid-borders' },
              { title: 'Без меж', value: 'table-no-borders' },
            ],
            table_cell_class_list: [
              { title: 'По дефолту', value: ' ' },
              { title: 'Суцільна межа', value: 'table-solid-borders' },
              { title: 'Без меж', value: 'table-no-borders' },
            ],
            table_default_styles: {
              width: '100%',
            },
            content_style: `
              .mce-item-table:not([border]),
              .mce-item-table:not([border]) caption, 
              .mce-item-table:not([border]) td, 
              .mce-item-table:not([border]) th {
                border: 1px solid #000;
              }
              table,
              caption,
              td,
              th {
                vertical-align: initial;
              }
              table {
                width: 100%;
                height: auto;
              }
              .mce-content-body {
                margin: 0 1rem 0 2rem;
              }
              h1,h2,h3,h4,h5,p,div {
                position: relative;
              }
              .head-doc {
                display: block;
                text-align: center;
                font-size: 24px;
                font-weight: bold;
                line-height: 1.8;
                margin: 0;
              }
              .notes {
                display: block;
                font-size: 14px;
                margin: 40px 0;
              }
              .anchor:before {
                pointer-events: all;
                transform: translateY(2px);
                position: absolute;
                left: -26px;
                transition: transform .3s;
                will-change: transform;
                cursor: pointer;
                content: url(${URI.trim()}/images/img-icon/3006691.svg);
              }
              .myCopied {
                border: 1px solid black;
                border-block-end-color: gray;
                border-right-color: gray;
                background-color: white;
                color: black;
                text-align: center;
                padding: 5px;
                position: absolute;
                z-index: 1;
                font-size: small;
                font-weight: 400;
                left: 0;
                top: 0;
              }
              h1, h2, h3, h4 {
                text-align: center;
              }
              .table-no-borders,
              .table-no-borders td, 
              .table-no-borders th {
                border: none !important;
              }

              .table-solid-borders,
              .table-solid-borders td,
              .table-solid-borders th {
                border: 1px solid #000 !important;
              }

              .cell-background-color {
                background: rgba(252, 243, 237, 1);
              }
              `,
            paste_postprocess: (_plugin: any, args: any) => {
              const parser = new DOMParser();
              const doc = parser.parseFromString(args.node.innerHTML, 'text/html');
              const links = doc.querySelectorAll('a');

              for (let i = 0; i < links.length; i++) {
                const link = links[i].href;
                const domain = link.split('//')[1]?.split('/')[0] || '';
                const parentNode = links[i].parentNode;
                if (!domain.includes('expertus') && parentNode) {
                  //@ts-ignore
                  parentNode.innerHTML = parentNode.innerHTML.replace(/<a\s.*?>(.*?)<\/a>/gi, '$1');
                }
              }

              doc.querySelectorAll('td').forEach((td) => {
                if (td.textContent?.trim()) {
                  td.querySelectorAll('p').forEach((el) => {
                    if (!el.textContent?.trim()) {
                      el.remove();
                    }
                  });

                  let i = 1;
                  for (const key in td.childNodes) {
                    const el = td.childNodes[key];

                    if (el && el.nodeValue?.trim() && el.nodeType === 3) {
                      const p = document.createElement('p');
                      p.id = uuid();
                      p.innerHTML = el.nodeValue || '';
                      el.replaceWith(p);
                    } else if (i > 0 && !el.textContent?.trim() && el.nodeName) {
                      td.removeChild(el);
                    }

                    i++;
                  }
                } else {
                  Object.values(td.children).forEach((e) => {
                    td.removeChild(e);
                  });

                  const p = document.createElement('p');
                  p.id = uuid();
                  p.innerHTML = '<br>';
                  td.appendChild(p);
                }
              });

              doc.documentElement.querySelectorAll('p, h2, h3').forEach((value) => value.setAttribute('id', uuid()));

              args.node.innerHTML = doc.documentElement.innerHTML
                .replace(/<\/?div[^>]*>/g, '')
                .replaceAll('&nbsp;', ' ');
            },
            images_upload_handler: async (blobInfo, success, failure) => {
              const formData = new FormData();
              if (blobInfo) {
                formData.append('file', blobInfo.blob(), blobInfo.filename());
                formData.append('id', docId ? String(docId) : '');
                const path = await callApi({
                  method: 'post',
                  data: formData,
                  isFormData: true,
                  path: '/officialdoc-files/img',
                }).catch((err) => failure('error'));
                path && success(`/${path}`);
              } else {
                failure('error');
              }
            },
            setup: (editor) => {
              function setStyleWidthTable(html: string) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');

                doc.querySelectorAll('table').forEach((table) => {
                  if (!table.style.width) {
                    table.style.width = '100%';
                  }
                });

                doc.querySelectorAll('td').forEach((td) => {
                  if (td.textContent?.trim()) {
                    td.querySelectorAll('p').forEach((el) => {
                      if (!el.textContent?.trim()) {
                        el.remove();
                      }
                    });

                    let i = 1;
                    for (const key in td.childNodes) {
                      const el = td.childNodes[key];

                      if (el && el.nodeValue?.trim() && el.nodeType === 3) {
                        const p = document.createElement('p');
                        p.id = uuid();
                        p.innerHTML = el.nodeValue || '';
                        el.replaceWith(p);
                      } else if (i > 0 && !el.textContent?.trim() && el.nodeName) {
                        td.removeChild(el);
                      }

                      i++;
                    }
                  } else {
                    Object.values(td.children).forEach((e) => {
                      if (e.nodeName !== 'TABLE') {
                        td.removeChild(e);
                      }
                    });

                    const p = document.createElement('p');
                    p.id = uuid();
                    p.innerHTML = '<br>';
                    td.appendChild(p);
                  }
                });

                doc.querySelectorAll('.paragraph-alignment-right').forEach((item) => {
                  item.removeAttribute('class');
                  item.setAttribute('style', 'text-align: right');
                });
                doc.querySelectorAll('.paragraph-alignment-center').forEach((item) => {
                  item.removeAttribute('class');
                  item.setAttribute('style', 'text-align: center');
                });
                return doc.body.innerHTML;
              }
              editor.on('click', (node) => {
                if (node.target?.classList?.contains('anchor') && node.offsetX < 0) {
                  const id = node.target.id || editor.selection.getNode().id;
                  navigator.clipboard.writeText(
                    docIdRef.current.docId ? `${configSites[1].url}/law/${docIdRef.current.docId}#${id}` : id
                  );
                  const el = editor.dom.create('span', { class: 'myCopied' }, 'Скопійовано');
                  node.target.append(el);
                  let timeout = setTimeout(() => {
                    el.remove();
                    clearTimeout(timeout);
                  }, 1000);
                }
              });
              editor.on('ScrollContent', (e) => {
                if (e?.currentTarget?.scrollY) {
                  const _page = Math.ceil(e.currentTarget.scrollY / (HEIGHT - 10));
                  setPage(_page);
                  handleScrollMeaning(e, meaningRef, editorRef, selectedMeaningId, setSelectedMeaningId);
                }
              });
              editor.on('click', () => {
                const content = editor.getContent();
                if (!showActiveQuotes && content?.includes('<mark>')) {
                  editor.setContent(backlight(false, content));
                }
                if (showActiveQuotes) {
                  setShowActiveQuotes(false);
                }
              });
              editor.on('BeforeSetContent', (e) => {
                if (e.content === '') {
                  e.content = `<p id="${uuid()}"><br></p>`;
                } else {
                  e.content = setStyleWidthTable(e.content || '');
                }
              });
              editor.on('keyup', (e) => {
                debounceSave();
                const body = editor.getBody();
                const pages = Math.ceil(body.offsetHeight / HEIGHT);
                setPages(pages);
                const elem = editor.selection.getNode();
                if (!elem.getAttribute('id')) {
                  elem.setAttribute('id', uuid());
                }
              });
              editor.on('SetContent', (e) => {
                const body = editor.getBody();
                const pages = Math.ceil(body.offsetHeight / HEIGHT);
                setPages(pages);
              });
              editor.ui.registry.addIcon('anchorIcon', anchorIcon);
              editor.ui.registry.addButton('anchorbutton', {
                icon: 'anchorIcon',
                onAction: showAnchor(editor),
              });
              editor.ui.registry.addButton('alignmentRight', {
                icon: 'chevron-right',
                onAction: changePositionTable(editor, 'right'),
              });
              editor.ui.registry.addButton('alignmentLeft', {
                icon: 'chevron-left',
                onAction: changePositionTable(editor, 'left'),
              });
              editor.ui.registry.addButton('cellBackgroundColor', {
                icon: 'cell-background-color',
                onAction: changeCellBackgroundColor(editor),
              });
              editor.ui.registry.addMenuItem('basicItem', {
                text: 'Завантажити .docx',
                onAction: () => {
                  const inputFile = document.createElement('input');
                  inputFile.setAttribute('type', 'file');
                  inputFile.accept = ACCEPT;
                  document.body.appendChild(inputFile);
                  inputFile.click();
                  inputFile.onchange = (e) => {
                    //@ts-ignore
                    const file: File = e.target?.files[0];
                    if (!file.name.includes('.docx')) return null;
                    const render = new FileReader();
                    render.onload = () => {
                      const options = {
                        transformDocument: (doc: any) => {
                          const children = doc.children.map(transformElement);
                          return { ...doc, children };
                        },
                        styleMap: [
                          "p[style-name='Heading2'] => h2",
                          "p[style-name='Heading3'] => h3",
                          "p[style-name='ParagraphCenter'] => p.paragraph-alignment-center:fresh",
                          "p[style-name='ParagraphRight'] => p.paragraph-alignment-right:fresh",
                          // 'b => ',
                          'u => u',
                          's => del',
                          'strike => del',
                          'comment-reference => sup',
                        ],
                      };
                      mammoth.convertToHtml({ arrayBuffer: render.result as ArrayBuffer }, options).then((result) => {
                        const text = setUuid(
                          result.value.replaceAll('<td></td>', '<td> </td>').replace(/<[^/>][^>]*><\/[^>]+>/g, ''),
                          ['p', 'h2', 'h3']
                        );
                        const meaning = defineMeaning(text);
                        editorRef?.current?.setContent(setStyleWidthTable(emblem + text));
                        meaningRef.current = meaning;
                        setPage(1);
                      });
                    };
                    render.readAsArrayBuffer(file);
                  };
                  inputFile.parentNode && inputFile.parentNode.removeChild(inputFile);
                },
              });
              editor.ui.registry.addMenuItem('basicItemPart', {
                text: 'Завантажити фрагмент .docx',
                onAction: () => {
                  const inputFile = document.createElement('input');
                  inputFile.setAttribute('type', 'file');
                  inputFile.accept = ACCEPT;
                  document.body.appendChild(inputFile);
                  inputFile.click();
                  inputFile.onchange = (e) => {
                    //@ts-ignore
                    const file: File = e.target?.files[0];
                    if (!file.name.includes('.docx')) return null;
                    const render = new FileReader();
                    render.onload = () => {
                      const options = {
                        transformDocument: (doc: any) => {
                          const children = doc.children.map(transformElement);
                          return { ...doc, children };
                        },
                        styleMap: [
                          "p[style-name='Heading2'] => h2",
                          "p[style-name='Heading3'] => h3",
                          "p[style-name='ParagraphCenter'] => p.paragraph-alignment-center:fresh",
                          "p[style-name='ParagraphRight'] => p.paragraph-alignment-right:fresh",
                          // 'b => ',
                          'u => u',
                          's => del',
                          'strike => del',
                          'comment-reference => sup',
                        ],
                      };
                      mammoth.convertToHtml({ arrayBuffer: render.result as ArrayBuffer }, options).then((result) => {
                        const text = setUuid(
                          result.value.replaceAll('<td></td>', '<td> </td>').replace(/<[^/>][^>]*><\/[^>]+>/g, ''),
                          ['p', 'h2', 'h3']
                        );
                        const meaning = defineMeaning(text);
                        editorRef?.current?.insertContent(setStyleWidthTable(text));
                        meaningRef.current = meaning;
                        setPage(1);
                      });
                    };
                    render.readAsArrayBuffer(file);
                  };
                  inputFile.parentNode && inputFile.parentNode.removeChild(inputFile);
                },
              });
            },
          }}
        />
        <Pagination
          className={classes.pagination}
          count={pages}
          page={page}
          onChange={(ev, _page) => handleChangePage(_page)}
        />
      </div>
    </div>
  );
};

interface IMeaningProps {
  meaning: IMeaning[];
  setParagraphId: (key: string) => void;
  selectedMeaning: string;
  scrollDirection: 'up' | 'down';
  editorRef: any;
}

const Meaning: React.FC<IMeaningProps> = React.memo(
  ({ meaning, setParagraphId, selectedMeaning, scrollDirection, editorRef }) => {
    const classes = useStyles();
    const containerMeaningScrollRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
      handleSelectedMeaning(selectedMeaning, meaning, containerMeaningScrollRef, scrollDirection);
    }, [selectedMeaning, scrollDirection, meaning]);

    return (
      <div>
        <h2>Зміст</h2>
        <ul ref={containerMeaningScrollRef} className={classes.meaning}>
          {meaning.map(({ id, level, text }, idx) => {
            const plainText = parseHTML(text);
            return (
              <li
                id={id + '.' + idx}
                key={`${idx}-${id}`}
                className={classes.itemIi}
                style={{
                  paddingLeft: level !== 1 ? '10px' : '',
                  color: selectedMeaning === id || (!selectedMeaning && idx === 0) ? '#ffffff' : '',
                  backgroundColor: selectedMeaning === id || (!selectedMeaning && idx === 0) ? '#3f51b5' : '',
                  borderRadius: '2px',
                }}
                onClick={() => {
                  const element = editorRef.current?.dom?.get(id);
                  if (element) element.scrollIntoView({ behavior: 'instant' });
                  setParagraphId(id);
                }}
                dangerouslySetInnerHTML={{ __html: plainText }}
              />
            );
          })}
        </ul>
      </div>
    );
  }
);
