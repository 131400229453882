export interface TProductRatingsState {
  loading: boolean;
  error: string | undefined;
  productRatings: IProductRatings[];
  data: {
    pages: number;
    productRatings: IProductRatings[];
    total: number;
  } | null;
  pages: number;
  total: number;
}

export interface IProductRatings {
  id: number;
  version: number;
  created_at: Date;
  title: string;
  comment: string;
  rate: number;
  user_id: number;
}

enum ActionTypes {
  SET_PRODUCT_RATINGS = '@@product_ratings/SET_PRODUCT_RATINGS',

  RESET_PRODUCT_RATINGS = '@@product_ratings/RESET_PRODUCT_RATINGS',

  GET_PRODUCT_RATINGS_R = '@@product_ratings/GET_PRODUCT_RATINGS_R',
  GET_PRODUCT_RATINGS_S = '@@product_ratings/GET_PRODUCT_RATINGS_S',
  GET_PRODUCT_RATINGS_E = '@@product_ratings/GET_PRODUCT_RATINGS_E',

  SEARCH_PRODUCT_RATINGS_R = '@@product_ratings/SEARCH_PRODUCT_RATINGS_R',
  SEARCH_PRODUCT_RATINGS_S = '@@product_ratings/SEARCH_PRODUCT_RATINGS_S',
  SEARCH_PRODUCT_RATINGS_E = '@@product_ratings/SEARCH_PRODUCT_RATINGS_E',
}

export default ActionTypes;
