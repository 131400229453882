import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDataOpt } from '../components/ui/SelectSearchReq';
import { AppStore } from '../store/applicationState';
import { GetPublishers } from '../store/officialDoc/actions';
import { IPublisherDoc } from '../store/publishers/types';
import { APPROVED } from '../utils/consts';

export const useSearchPublishers = () => {
  const [options, setOptions] = React.useState<IDataOpt[] | undefined>();

  const { Publishers } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Publishers.data) {
      const opt = Publishers.data.publishers
        .filter((it) => it.approved === APPROVED.PUBLISH)
        .map((it) => ({
          id: it.id,
          label: it.name,
        }));
      setOptions(opt);
    }
  }, [Publishers.data]);

  const handleSearch = (value: string | IDataOpt, callBack?: (resData: IDataOpt[]) => void) => {
    if (value) {
      dispatch(
        GetPublishers.request({
          data: {
            name: (value as unknown as IDataOpt).title || String(value),
          },
          callBack: (succ, data) => {
            if (succ && Array.isArray(data) && callBack) {
              const opt = data
                .filter((it: IPublisherDoc) => it.approved === APPROVED.PUBLISH)
                .map((it: IPublisherDoc) => ({
                  id: it.id,
                  label: it.name,
                }));
              callBack(opt);
            } else {
              callBack && callBack(options || []);
            }
          },
        })
      );
    } else {
      callBack && callBack(options || []);
    }
  };

  return {
    options,
    handleSearch,
  };
};
