import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  ChangeOwner,
  CreateOfficialDocDrafts,
  DeleteOfficialDocDrafts,
  GetOfficialDocDraft,
  GetOfficialDocDrafts,
  TypeChangeOwnerR,
  TypeCreateOfficialDocDraftsR,
  TypeDeleteOfficialDocDraftsR,
  TypeGetOfficialDocDraftR,
  TypeGetOfficialDocDraftsR,
  TypeUpdateOfficialDocDraftsR,
  TypeUpdateRedactionR,
  UpdateOfficialDocDrafts,
  UpdateRedaction,
} from './actions';
import ActionTypes, { TOfficialDocDraftsState } from './types';

function* getOfficialDocDraftsWorker(action: ReturnType<typeof GetOfficialDocDrafts.request>): Generator {
  const { group_id, callBack } = action.payload as TypeGetOfficialDocDraftsR;

  let success = true;
  const query = buildQuery({ group_id });
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/official-doc-drafts?${query}`,
    })) as TOfficialDocDraftsState['data'];
    yield put(GetOfficialDocDrafts.success(resp));
  } catch (e) {
    success = false;
    yield put(GetOfficialDocDrafts.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* getOfficialDocDraftWorker(action: ReturnType<typeof GetOfficialDocDraft.request>): Generator {
  const { id, callBack } = action.payload as TypeGetOfficialDocDraftR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/official-doc-drafts/draft/${id}`,
    })) as TOfficialDocDraftsState['current'];
    yield put(GetOfficialDocDraft.success(resp));
  } catch (e) {
    success = false;
    yield put(GetOfficialDocDraft.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createOfficialDocDraftsWorker(action: ReturnType<typeof CreateOfficialDocDrafts.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateOfficialDocDraftsR;

  let success = true;
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'post',
      data,
      path: '/official-doc-drafts/draft',
    })) as TOfficialDocDraftsState['current'];
    yield put(CreateOfficialDocDrafts.success(resp));
  } catch (e: any) {
    success = false;
    resp = e;
    yield put(CreateOfficialDocDrafts.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updateOfficialDocDraftsWorker(action: ReturnType<typeof UpdateOfficialDocDrafts.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateOfficialDocDraftsR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      data,
      path: `/official-doc-drafts/draft/${id}`,
    })) as TOfficialDocDraftsState['current'];
    yield put(UpdateOfficialDocDrafts.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(UpdateOfficialDocDrafts.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* deleteOfficialDocDraftsWorker(action: ReturnType<typeof DeleteOfficialDocDrafts.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteOfficialDocDraftsR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/official-doc-drafts/draft/${id}`,
    })) as TOfficialDocDraftsState['current'];
    yield put(DeleteOfficialDocDrafts.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteOfficialDocDrafts.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* changeOwnerWorker(action: ReturnType<typeof ChangeOwner.request>): Generator {
  const { data, id, callBack } = action.payload as TypeChangeOwnerR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      data,
      path: `/official-doc-drafts/owner/${id}`,
    })) as TOfficialDocDraftsState['current'];
    yield put(ChangeOwner.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(ChangeOwner.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* updateRedactionWorker(action: ReturnType<typeof UpdateRedaction.request>): Generator {
  const { id, callBack } = action.payload as TypeUpdateRedactionR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/official-doc-drafts/redaction/${id}`,
    })) as TOfficialDocDraftsState['current'];
    yield put(UpdateRedaction.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateRedaction.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_OFFICIAL_DOC_DRAFTS_R, getOfficialDocDraftsWorker);
  yield takeEvery(ActionTypes.GET_OFFICIAL_DOC_DRAFT_R, getOfficialDocDraftWorker);
  yield takeEvery(ActionTypes.CREATE_OFFICIAL_DOC_DRAFTS_R, createOfficialDocDraftsWorker);
  yield takeEvery(ActionTypes.UPDATE_OFFICIAL_DOC_DRAFTS_R, updateOfficialDocDraftsWorker);
  yield takeEvery(ActionTypes.DELETE_OFFICIAL_DOC_DRAFTS_R, deleteOfficialDocDraftsWorker);
  yield takeEvery(ActionTypes.CHANGE_OWNER_R, changeOwnerWorker);
  yield takeEvery(ActionTypes.UPDATE_REDACTION_R, updateRedactionWorker);
}

export default function* OfficialDocDraftsSaga() {
  yield all([fork(watchFetchRequest)]);
}
