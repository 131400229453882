import React from 'react';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Alert from '@mui/lab/Alert';
import AlertTitle from '@mui/lab/AlertTitle';
import { EditorFeatured } from '../Editors';
import { BasicDatePicker, SelectSearchReq, UploadFile } from '../ui';
import { IDataOpt } from '../ui/SelectSearchReq';
import { useSearchAuthors } from '../../hooks/useSearchAuthors';
import { CATEGORIES } from '../../utils/consts';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { TextField } from '@mui/material';
import { ROUTES } from '../../utils/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { IAuthor } from '../../store/authors/types';
import { Editor } from '@tinymce/tinymce-react';
import { CreateMainInMonthBlock, GetMainInMonthBlock, UpdateMainInMonthBlock } from '../../store/mainInMonth/actions';
import { DeepPartial } from '../../utils/DeepPartial';
import { IMonthMainBlock } from '../../store/mainInMonth/types';
import { callApi } from '../../utils/callApi';

interface Publication extends IDataOpt {
  author?: IAuthor;
}

type Keys = keyof IMonthMainBlock;

interface PropsType {
  type: 'new' | 'edit';
  name: string;
  category?: CATEGORIES;
  stepBack?: number;
  fields: { [K in Keys]?: { require: boolean; label?: string; maxLength?: number } };
  publications?: Publication[];
  handleSearchPublications?: (value: string | IDataOpt, callback?: ((data: IDataOpt[]) => void) | undefined) => void;
}

export const MainMonthBlockForm: React.FC<PropsType> = ({
  type,
  name,
  category,
  stepBack = 1,
  fields,
  publications,
  handleSearchPublications,
}) => {
  const [title, setTitle] = React.useState('');
  const [publicationId, setPublicationId] = React.useState<IDataOpt | null>(null);
  const [author, setAuthor] = React.useState<IDataOpt | null>(null);
  const [link, setLink] = React.useState('');
  const [image, setImage] = React.useState<File | string>('');
  const [renewalAt, setRenewalAt] = React.useState<Date | null>(null);
  const [width, setWidth] = React.useState('');
  const [height, setHeight] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const editorRef = React.useRef<Editor['editor'] | null>(null);

  const { Configurations, MainInMonth } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const { options, handleSearchAuthors } = useSearchAuthors();

  const { id, blockId } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (blockId && !Number.isNaN(Number(blockId)) && type === 'edit') {
      setLoading(true);
      dispatch(
        GetMainInMonthBlock.request({
          id: Number(blockId),
          callBack: () => {
            setLoading(false);
          },
        })
      );
    }
  }, [dispatch, blockId, type]);

  React.useEffect(() => {
    if (MainInMonth.block && type === 'edit') {
      const data = MainInMonth.block;

      const publication = data.publication_id ? { id: data.publication_id, label: String(data.publication_id) } : null;
      setPublicationId(publication);

      setTitle(data.title);

      const author = data.author ? { id: data.author.id, label: data.author.fio } : null;
      setAuthor(author);

      setLink(data.link);
      setImage(data.image ? data.image : '');
      setRenewalAt(data.renewal_at);
      setWidth(data.width ? String(data.width) : '');
      setHeight(data.height ? String(data.height) : '');
    }
  }, [blockId, MainInMonth.block, type]);

  const checkError = (key: Keys, value?: any, length = 0) => {
    if (fields[key]?.require && error && !value) return true;
    if (length && typeof value === 'string' && value.length > length) return true;
    return false;
  };

  const handleSave = async () => {
    let imageUrl = '';

    if (loading) return null;
    setError('');
    setLoading(true);

    const description = editorRef.current?.getContent();

    if (image && typeof image === 'string') {
      imageUrl = image;
    }
    if (image && typeof image !== 'string') {
      const formData = new FormData();
      formData.append('file', image);
      formData.append('siteId', String(Configurations.siteId));
      formData.append('categoryId', String(CATEGORIES.MAIN_IN_MONTH));
      formData.append('id', String(id || ''));

      const path = await callApi({
        method: 'post',
        data: formData,
        isFormData: true,
        path: '/upload/file',
      }).catch((err) => console.log(err));

      setImage(path || '');

      if (!path) {
        setLoading(false);
        return setError('Зображення не завантажилося');
      }

      imageUrl = path;
    }

    const data: DeepPartial<IMonthMainBlock> = {
      month_main_templates_detail_id: Number(id),
      category_id: category,
      publication_id: publicationId?.id,
      author_id: author?.id || 0,
      renewal_at: renewalAt || undefined,
      width: width ? Number(width) : undefined,
      height: width ? Number(height) : undefined,
      image: imageUrl,
      description,
      title,
      link,
    };

    const require = Object.keys(fields).find((key) => fields[key as Keys]?.require && !data[key as Keys]);
    if (require) {
      setLoading(false);
      return setError('Заповніть поля');
    }

    if (type === 'new') {
      dispatch(
        CreateMainInMonthBlock.request({
          data,
          callBack: (success, err) => {
            setLoading(false);
            if (success) {
              handleBack();
            }
            if (!success && typeof err === 'string') {
              setError(err);
            }
          },
        })
      );
    }

    if (type === 'edit' && blockId && !Number.isNaN(Number(blockId))) {
      dispatch(
        UpdateMainInMonthBlock.request({
          id: Number(blockId),
          data,
          callBack: (success, err) => {
            setLoading(false);
            if (success) {
              handleBack();
            }
            if (!success && typeof err === 'string') {
              setError(err);
            }
          },
        })
      );
    }
  };

  const handleChangeTitle = (str = '') => {
    if (typeof str === 'string') {
      setTitle(str.slice(0, 150));
    }
  };

  const handleChangeNumeric =
    (callBack: (value: string) => void) => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = event.target.value?.replace(/[^0-9.]/g, '');
      if (!value) return callBack('');

      if (Number(value) <= 100) {
        callBack(value);
      }
    };

  const handleBack = () => {
    navigate(ROUTES.stepBack(stepBack));
  };

  const handleChangePublicationId = (value: IDataOpt | null) => {
    setPublicationId(value);
    if (value && publications) {
      const publication = publications.find((item) => item.id === value.id);

      const dataAuthor = publication?.author
        ? {
            id: publication.author.id,
            label: publication.author.fio,
          }
        : null;
      setAuthor(dataAuthor);

      if (publication?.title) {
        handleChangeTitle(publication.title);
      }
    }
  };

  return (
    <Box position={'relative'}>
      <CssBaseline />
      <Box width={'900px'}>
        <Typography component='h1' variant='h5'>
          {name}
        </Typography>
        <Box display={'flex'} flexDirection={'column'} gap={2} mt={2}>
          {fields['publication_id'] && (
            <SelectSearchReq
              required={fields['publication_id'].require}
              error={checkError('publication_id', publicationId?.id)}
              title='ID'
              data={publicationId}
              options={publications}
              handleRequest={handleSearchPublications}
              onChange={handleChangePublicationId}
            />
          )}
          {fields['title'] && (
            <TextField
              required={fields['title'].require}
              error={checkError('title', title, 149)}
              size='small'
              label='Заголовок'
              variant='outlined'
              value={title}
              onChange={(event: React.ChangeEvent<{ value: string }>) => {
                handleChangeTitle(event.target.value);
              }}
            />
          )}
          {fields['description'] && (
            <EditorFeatured
              docId={MainInMonth.block?.id || undefined}
              categoryId={CATEGORIES.MAIN_IN_MONTH}
              editorRef={editorRef}
              initialValue={type === 'edit' ? MainInMonth.block?.description : ''}
              siteId={Configurations.siteId}
              maxLength={fields['description'].maxLength}
            />
          )}
          {fields['author_id'] && (
            <SelectSearchReq
              required={fields['author_id'].require}
              error={checkError('author_id', author?.id)}
              title='Автор'
              data={author}
              onChange={setAuthor}
              options={options}
              handleRequest={handleSearchAuthors}
            />
          )}
          {fields['link'] && (
            <TextField
              required={fields['link'].require}
              error={checkError('link', link)}
              size='small'
              label={fields['link'].label || 'Посилання'}
              variant='outlined'
              value={link}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setLink(event.target.value as string);
              }}
            />
          )}
          {fields['image'] && (
            <UploadFile
              required={fields['image'].require}
              error={checkError('image', image)}
              isImg
              isDelete
              accept='image/*'
              id='photo'
              title={fields['image'].label || 'Зображення'}
              value={image}
              onChange={setImage}
            />
          )}
          {fields['renewal_at'] && (
            <BasicDatePicker
              required={fields['renewal_at'].require}
              error={checkError('renewal_at', renewalAt)}
              id='renewal_at'
              label={fields['renewal_at'].label || 'Дата оновлення'}
              value={renewalAt}
              onChange={setRenewalAt}
            />
          )}
          {fields['width'] && (
            <TextField
              required={fields['width'].require}
              error={checkError('width', width)}
              size='small'
              label={fields['width'].label || 'Ширина y %'}
              variant='outlined'
              value={width}
              onChange={handleChangeNumeric(setWidth)}
            />
          )}
          {fields['height'] && (
            <TextField
              required={fields['height'].require}
              error={checkError('height', height)}
              size='small'
              label={fields['height'].label || 'Висота y %'}
              variant='outlined'
              value={height}
              onChange={handleChangeNumeric(setHeight)}
            />
          )}
        </Box>
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={loading} onClick={handleSave}>
            {loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleBack}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' sx={{ width: '100%', mt: 2 }}>
            <AlertTitle>{error}</AlertTitle>
          </Alert>
        ) : null}
      </Box>
    </Box>
  );
};
