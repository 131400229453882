export const ROUTES = {
  main: '/',
  widgets: '/widgets',
  monthMain: '/month-main',
  calendar: '/calendar',
  ratings: '/ratings',
  catalogues: '/catalogues',
  consultations: '/consultations',
  forms: '/forms',
  video: '/video',
  services: '/services',
  npd: '/npd',
  authors: '/authors',
  blocks: '/blocks',
  configurator: '/configurator',
  publications: '/publications',
  factoids: '/factoids',
  archive: '/archive',
  document: '/document',
  definition: '/definition',
  users: '/users',
  documents: '/documents',
  drafts: 'drafts',
  publishers: 'publishers',
  types: 'types',
  statistics: 'statistics',
  routeChange: function (params: number | string) {
    const path = window.location.pathname.split('/')[1];
    return '/' + path + '/' + params;
  },
  stepBack: (step = 1) => {
    const paths = window.location.pathname.split('/');
    paths.splice(paths.length - step);
    return paths.join('/');
  },
};
