import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StylesTable } from '../Stylestable';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DeleteFactoidBlocks, GetFactoidBlocks, setEditBlockData } from '../../store/factoids/actions';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import DoneIcon from '@mui/icons-material/Done';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { WarningModal } from '../modals';
import { AppStore } from '../../store/applicationState';
import { IFactoids } from '../../store/factoids/types';
import { EditFactoidBlocks } from './EditFactoidBlocks';
import Typography from '@mui/material/Typography';

interface Column {
  id: 'id' | 'title' | 'created_at' | 'approved' | 'position' | 'edit' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: '№', align: 'center', width: '6%' },
  { id: 'title', label: 'Заголовок', align: 'left' },
  { id: 'approved', label: 'Опубліковано', align: 'center' },
  { id: 'position', label: 'Сортування', align: 'center' },
  { id: 'created_at', label: 'Дата створення', align: 'center' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '9%',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '8%',
  },
];

type PropsType = {
  factoid: IFactoids | null | undefined;
  handleCancel: () => void;
};

export const ListFactoidBlocks: React.FC<PropsType> = ({ factoid, handleCancel }) => {
  const [factoidId] = React.useState(factoid?.id || 0);
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const { Factoids } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classesTable = StylesTable();

  React.useEffect(() => {
    if (factoidId) {
      dispatch(
        GetFactoidBlocks.request({
          data: { factoid_id: Number(factoidId) },
        })
      );
    }
  }, [dispatch, factoidId]);

  const handleRemove = (id: number) => {
    dispatch(DeleteFactoidBlocks.request({ id }));
  };

  if (Factoids.editBlockData) {
    return (
      <EditFactoidBlocks
        factoid_id={Factoids.editBlockData.factoid_id}
        type={Factoids.editBlockData.type}
        data={Factoids.editBlockData.data}
        handleCancel={() => dispatch(setEditBlockData(null))}
      />
    );
  }

  return (
    <>
      <Box>
        <Typography component='h1' variant='h5' className='m-2'>
          Блоки фактоїду ID: {factoidId}
        </Typography>
      </Box>
      <Box className={classesTable.header}>
        <Button
          className={classesTable.searchField}
          variant='contained'
          color='primary'
          onClick={() => {
            dispatch(setEditBlockData({ type: 'new', factoid_id: factoidId }));
          }}
        >
          Створити
        </Button>
        <Button variant='contained' color='inherit' className={classesTable.searchField} onClick={handleCancel}>
          Повернутися
        </Button>
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Factoids.blocks?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>{row.id}</TableCell>
                  <TableCell className={classesTable.row}>{row.title}</TableCell>
                  <TableCell align='center' className={classesTable.row}>
                    {row.approved ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    {row.position}
                  </TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    {moment(row.created_at).format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                  <TableCell className={classesTable.row} align='center'>
                    <IconButton
                      aria-label='edit'
                      onClick={() => dispatch(setEditBlockData({ type: 'edit', data: row }))}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton aria-label='delete' onClick={() => setIsModal({ id: row.id })}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
